import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";
import moment from "moment-timezone";
import { Editor } from "primereact/editor";

import { noteUserListAPI, noteUserPostAPI } from "../../service";

import moneyConvert from "../../utilities/moneyConvert";
import {
  CpButton,
  CpButtonIcon,
  CpInput,
  CpPagination,
  CpDatePicker,
  CpDropdown,
  CpButtonGroup,
  CpRadio,
  CpColorPicker,
  CpEditor,
  CpCheckBox,
  CpPopup,
} from "../../components";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";

const dataDropdown = [
  { name: "선택", code: "" },
  { name: "아이디", code: "mb_id" },
  { name: "제목", code: "mb_level" },
];
const dataButtonGroup = [
  { name: "2. 가상계좌발급 (구리가상)", code: "0" },
  { name: "3.  ★★★ 평생 고정 계좌 안내 입니다 ★★★", code: "1" },
  { name: "1. 입금통장문의 (종이장라인)", code: "2" },
  { name: "★★★  계좌 1원 인증번호  ★★★", code: "2" },
  { name: "★★★  인증 시간 초과시 답변  ★★★", code: "2" },
  { name: "▶▶▶  평생 계좌 입금 안된다고하면 답변   ◀◀◀", code: "2" },
  { name: "확인후 처리완료", code: "2" },
  { name: "환전 마감 안내 ", code: "2" },
  { name: "충전 마감 안내 ", code: "2" },
  { name: "포인트관련문의", code: "2" },
  { name: "은행 점검 시간안내", code: "2" },
  { name: "환전 지연시", code: "2" },
];

const levelDropdown = [
  { name: "1", code: "1" },
  { name: "2", code: "2" },
  { name: "3", code: "3" },
  { name: "4", code: "4" },
  { name: "5", code: "5" },
  { name: "6", code: "6" },
  { name: "7", code: "7" },
  { name: "8", code: "8" },
  { name: "9", code: "9" },
  { name: "10", code: "10" },
];

const dataRadioBox = [
  { id: 0, value: "N", label: "숨김" },
  { id: 1, value: "Y", label: "보기" },
];
const dataRadioTitleBox = [
  { id: 0, value: "1", label: "전체" },
  { id: 1, value: "user", label: "유저" },
  { id: 1, value: "level", label: "레벨" },
];

const NoteUser = () => {
  const [keySearch, setKeySearch] = useState("");
  const [dropdownValue, setDropdownValue] = useState({
    name: "선택",
    code: "",
  });
  const [radioValueTitle, setRadioValueTitle] = useState("1");
  const [userName, setUserName] = useState("");
  const [userLevel, setUserLevel] = useState(null);
  const [title, setTitle] = useState("");
  const [editor, setEditor] = useState("");
  const [radioValue, setRadioValue] = useState("Y");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [uid, setUid] = useState('')
  const [ingredients, setIngredients] = useState([]);
  const [checked, setChecked] = useState(false);
  const getDataHelp = async () => {
    try {
      const res = await noteUserListAPI({
        page: page,
        key: keySearch,
        keyfield: dropdownValue.code,
        mb_type:'user'
      });
      // console.log(res);

      if (res.code === 200) {
        // console.log(res);
        setTotal(res.list.total);
        setData(res.list.data);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateNoteUser = async () => {
    try {
      const res = await noteUserPostAPI({
        submit: 1, // 1: Send
        process: "msg_send", // msg_send: Send || msg_delete_all1: Delete All
        recall: "1", // 1: Recall
        all: radioValueTitle === "1" ? "1" : "", // 1: Send To All Member
        level: userLevel ? userLevel.code : "", // NULL (Default)
        receiver_id: userName, // id1;id2;...
        msg_subject: title,
        msg_memo: editor,
        msg_priority: radioValue,
        mb_type:''
      });
        toast.current.show({ severity: 'success', summary: '성공', detail: res?.message, life: 3000 });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataHelp();
  }, [page]);

  const onSearch = () => {
    if (page === 1) {
      getDataHelp();
    } else {
      setPage(1);
    }
  };

  const onClear = () => {
    setDropdownValue({ name: "선택", code: "" });
    setKeySearch("");
    if (page === 1) {
      getDataHelp();
    } else {
      setPage(1);
    }
  };


  const onChoice = (e) => {
    setUid(e.uid)    
    setTitle(e.msg_subject);
    setEditor(e.msg_memo);
    setRadioValue(e.msg_priority);

  };
  useEffect(()=>{
  },[title, editor, radioValue])
  const deleteMessage = () => {
    confirmDialog({
        message: "메세지 삭제하시겠습니까?",
        header: "고객센터",
        accept: deleteMessageAction,
        acceptLabel: "확인",
        acceptClassName: "p-confirm-dialog-accept",
    });
}
const confirm1 = () => {
  confirmDialog({
      message: "고객센터 작성/수정 성공.",
      header: "고객센터",
      accept: updateNoteUser,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
  });
};
const deleteMessageAction = async() => {
  try {
    const res = await noteUserPostAPI({"submit": 1,
                                      "process": "msg_delete",
                                      'uid': uid})
    if(res.code === 200){
      toast.current.show({ severity: 'success', summary: '성공', detail: res?.message, life: 3000 });
      setUid('')    
      setTitle('');
      setEditor('');
      setRadioValue('');
      await getDataHelp()    
    }
    else{
      toast.current.show({ severity: 'error', summary: '성공', detail: res?.message, life: 3000 });
    }
    
  } catch (error) {
    console.log(error)
  }
}
const toast = useRef(null)
const deleteAll = () => {
  confirmDialog({
      message: "고객센터 삭제되었습니다.",
      header: "고객센터",
      accept: cancel,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
  });
};
const cancel = async() => {
  try {
    const res = await noteUserPostAPI({
      "submit": 1,
      "process": "msg_delete_all1",
      'mb_type': ''
      })
      if(res.code === 200){
        toast.current.show({ severity: 'success', summary: '성공', detail: res?.message, life: 3000 });
        await getDataHelp()
      }
      else{
        toast.current.show({ severity: 'error', summary: '성공', detail: res?.message, life: 3000 });
      }
  } catch (error) {
    console.log(error)
  }
}
const onIngredientsChange = (e) => {
  let _ingredients = [...ingredients];

  if (e.checked)
    _ingredients.push(e.value);
  else
    _ingredients.splice(_ingredients.indexOf(e.value), 1);

  setIngredients(_ingredients);
}
function pickall(e) {
  let newArr = []
  setChecked(e.checked)
  if (e.checked) {
    for (let i = 0; i < data.length; i++) {
      newArr.push(data[i].uid)
      setIngredients(newArr)
    }
  }
  else {
    setIngredients([])
  }
}
function deleteBoardId() {
  if (ingredients.length > 0) {
    confirmDialog({
      message: "삭제하시겠습니까?",
      header: "고객센터",
      accept: confirmDeteleBoardId,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
    });
  }
}
  async function confirmDeteleBoardId () {
  var newStr = ingredients.join('|')
  try {
    const res = await noteUserPostAPI({"submit": 1,
                                      "process": "msg_delete",
                                      'uid': newStr.toString()})
    if(res.code === 200){
      toast.current.show({ severity: 'success', summary: '성공', detail: res?.message, life: 3000 });
      await getDataHelp()
      setIngredients([])
    }
    else{
      toast.current.show({ severity: 'error', summary: '성공', detail: res?.message, life: 3000 });
    }
    
  } catch (error) {
    console.log(error)
  }
}
  return (
    <>
      <Toast ref={toast}/>
      <ConfirmDialog/>
      <div className="prid note-user">
        <div className="col-12 p-3 card mb-4 header md:p-4">
          <div className="col-12 mb-1 p-0" style={{ fontSize: "16px" }}>
            유저 쪽지
          </div>
          <div className="col-12 de-wi-box p-0 flex flex-wrap">
            <div className="p-0 col-12 sm:col-6 mt-3 md:col-3 xl:col-2 flex align-items-end md:mt-0">
              <CpDropdown
                style={{ minWidth: "100px" }}
                value={dropdownValue}
                options={dataDropdown}
                onChange={(e) => setDropdownValue(e)}
                placeholder=""
              />
            </div>
            <div className="p-0 mt-1 col-12 sm:col-6 md:col-3 xl:col-2 flex align-items-end">
              <CpInput
                placeholder="검색"
                value={keySearch}
                onChange={(e) => setKeySearch(e)}
                onKeyDown={(e)=> {if(e.key ==='Enter') onSearch()}}
              />
            </div>
            <div className="p-0 mt-3 col-5 sm:col-4 md:col-2 xl:col-1 flex align-items-end">
              <CpButton label="검색" onClick={onSearch} />
            </div>
            <div className="flex align-items-end">
              <Button style={{ background: 'red', height: '30px', marginLeft: '10px', borderRadius: '2px', width:'150px' }} onClick={deleteAll} >삼일 이전내역삭제</Button>
            </div>
          </div>
        </div>
        <div className="p-0 mt-4 col-12 flex flex-wrap content">
          <div className="left col-12 md:col-4 p-2">
            <div className="card">
              <div className="pagi-box">
                <CpPagination
                  page={page}
                  onChange={(e) => setPage(e)}
                  total={total}
                  defaulRows={15}
                />
              </div>
              <div className="content-left p-0 mt-4 mb-4">
                <div className="header-left">
                  {/* <div className="item">
                    <CpCheckBox />
                  </div> */}
                   <div className="item">
                    <Checkbox onChange={(e) => pickall(e)} checked={checked}></Checkbox>
                  </div>
                  <div className="item ml-5">아이디</div>
                  <div className="item">타이틀</div>
                  <div className="item">확인/미확인</div>
                  <div className="item"></div>
                </div>
                <div className="infor-box">
                  {data.map((v, i) => (
                    <div className="item-box" key={i}>
                      <div className="item">
                        <Checkbox onChange={(e) => onIngredientsChange(e)} value={v.uid} checked={ingredients.includes(v.uid)}></Checkbox>
                      </div>
                      <div className="item ml-5">
                        <div>{v?.mb_id}</div>
                      </div>
                      <div className="item">
                        <div>{v?.msg_subject}</div>
                      </div>
                      <div className="item">
                        <div>{v?.msg_status === "1" ? "확인" : "미확인"}</div>
                      </div>
                      <div className="item icon">
                        <CpButtonIcon
                          icon="pi pi-pencil"
                          severity=""
                          aria-label=""
                          onClick={() => onChoice(v)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pagi-box">
                <CpPagination
                  page={page}
                  onChange={(e) => {
                    setPage(e);
                  }}
                  total={total}
                  defaulRows={30}
                />
                <Button onClick={deleteBoardId} severity="warning" >삭제</Button>
              </div>
            </div>
          </div>
          <div className="right col-12 md:col-8 p-2">
            <div className="card ">
              <div
                className={`select-content mt-2 ${
                  radioValueTitle === "level" ? "mb-1" : ""
                }`}
              >
                <CpRadio
                  radioValue={radioValueTitle}
                  onChange={(e) => {
                    setRadioValueTitle(e);
                    setUserName("");
                    setUserLevel(null);
                  }}
                  dataRadio={dataRadioTitleBox}
                />
              </div>
              <div className="p-0 title mt-0 mr-0">
                {radioValueTitle === "" && ""}
                {radioValueTitle === "user" && (
                  <CpInput
                    placeholder="User"
                    value={userName}
                    onChange={(e) => setUserName(e)}
                  />
                )}
                {radioValueTitle === "level" && (
                  <CpDropdown
                    style={{ minWidth: "200px" }}
                    value={userLevel}
                    options={levelDropdown}
                    onChange={(e) => setUserLevel(e)}
                    placeholder="Level"
                  />
                )}
              </div>
              <div className="p-0 title mt-3 mr-0">
                <CpInput
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e)}
                />
              </div>
              <div className="edi mt-3">
                {/* <CpEditor /> */}
                <Editor
                  placeholder=""
                  value={editor}
                  onTextChange={(e) => setEditor(e.htmlValue)}
                  style={{ height: "320px" }}
                />
              </div>
              <div className="select-content mt-3">
                <CpRadio
                  radioValue={radioValue}
                  onChange={(e) => setRadioValue(e)}
                  dataRadio={dataRadioBox}
                />
              </div>
              <div className="bt mb-3 flex ">
                <CpButton label="저장 하기" onClick={confirm1} />
                <Button style={{ background: 'red', height: '30px', marginLeft: '10px', borderRadius: '2px' }} onClick={deleteMessage}>삭제</Button>
              </div>
            </div>
          </div>
        </div>
        <CpPopup
          visible={popupVisible}
          onHide={() => setPopupVisible(false)}
          label="유저 쪽지"
          content={popupText}
        />
      </div>
    </>

  );
};

export default NoteUser;
