import React from "react";
import { Dialog } from "primereact/dialog";

const CpButton = (props) => {
  const {
    visible = false,
    onHide,
    onOk,
    label = "test",
    content = "test",
    cancelText = "확인",
    cancelOkText = "확인",
    okText = "아니요",
  } = props;

  const styleButton = {
    background: "#333",
    padding: "6px 18px",
    color: "#eee",
    borderRadius: "5px",
    fontSize: "12px",
    cursor: "pointer",
  };

  return (
    <div className="cp-popup">
      <Dialog
        header=""
        visible={visible}
        onHide={() => onHide?.()}
        style={{ width: "400px" }}
        dismissableMask
        closeIcon=""
        closable={false}
      >
        <div
          style={{
            padding: "20px 9px 9px",
            color: "#666",
          }}
        >
          <div className="label mb-3" style={{ fontSize: "28px" }}>
            {label}
          </div>
          <div className="content mt-3 mb-4" style={{ fontSize: "15px" }}>
            {content}
          </div>
          {onOk ? (
            <div className="footer flex gap-3">
              <div style={styleButton} onClick={() => onOk?.()}>
                {okText}
              </div>
              <div style={styleButton} onClick={() => onHide?.()}>
                {cancelOkText}
              </div>
            </div>
          ) : (
            <div className="footer flex gap-3">
              <div style={styleButton} onClick={() => onHide?.()}>
                {cancelText}
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default CpButton;
