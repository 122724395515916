import React, { useEffect, useState, useRef } from "react";
import moment from "moment-timezone";
import moneyConvert from "../../utilities/moneyConvert";
import { Calendar } from "primereact/calendar";
import './CalculateMainAgent.scss'
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { addLocale } from "primereact/api";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../components/Table/TableUser";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import CalculateService from "../../service/CalculateServices";
import { InputText } from "primereact/inputtext";

const CalculateMainAgent = () => {
  const buttonFilter = [
    { button: "입출금 정산", value: "all" },
    { button: "카지노 Win-Lose 정산", value: "win-lose" },
  ]
  const currentDate = moment().toDate();
  const nextDate = moment().add(1, 'days').toDate();
  const [data, setData] = useState([{}, {}, {}, {}]);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(nextDate);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0)
  const [messageModal, setMessageModel] = useState({});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [filterBtn, setFilterBtn] = useState(buttonFilter[0])
  const [totalData, setTotalData] = useState()
  const [reqType, setReqType] = useState(1)
  const [inpValue, setInpValue] = useState('')
  const [header, setHeader] = useState([
    {
      field: "mb_ids", label: <span class="p-column-title">
        <div >
          에이전트
        </div>
      </span>,
      value: totalData?.agent,
      sortAble: false
    },
    {
      field: "charges", label:
        <span class="p-column-title">
          <div>
            <div>유저 입금</div>
            <div>총판 지급</div>
            <div>관리자 지급</div>
          </div>
        </span>,
      value:
        <span class="p-column-title">
          <div style={{ textAlign: 'center' }}>
            <div>{moneyConvert(totalData?.charge?.user_charge, true)}</div>
            <div>{moneyConvert(totalData?.charge?.distributor_charge, true)}</div>
            <div>{moneyConvert(totalData?.charge?.center_charge, true)}</div>
          </div>
        </span>,
      sortAble: false
    },
    {
      field: "exchanges", label: <span class="p-column-title"><div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div></span>, value:
        <span class="p-column-title">
          <div style={{ textAlign: 'center' }}>
            <div>{moneyConvert(totalData?.exchange?.user_exchange, true)}</div>
            <div>{moneyConvert(totalData?.exchange?.distributor_exchange, true)}</div>
            <div>{moneyConvert(totalData?.exchange?.center_exchange, true)}</div>

          </div>
        </span>,
      sortAble: false
    },
    {
      field: "profits", label: <span class="p-column-title"><div><div>유저 수익</div><div>총판 수익</div></div></span>, value:
        <span class="p-column-title">
          <div style={{ textAlign: 'center' }}>
            <div>{moneyConvert(totalData?.profit?.user_profit, true)}</div>
            <div>{moneyConvert(totalData?.profit?.distributor_profit, true)}</div>
          </div>
        </span>,
      sortAble: false
    },
    {
      field: "casinos", label: <span class="p-column-title"><div><div>Casino Bet</div><div>Casino Win</div></div></span>, value:
        <span class="p-column-title">
          <div>
            <div>{moneyConvert(totalData?.casino?.bet, true)}</div>
            <div>{moneyConvert(totalData?.casino?.win, true)}</div>
          </div>
        </span>, sortAble: false
    },
    {
      field: "slots", label: <span class="p-column-title"><div><div>Slot Bet</div><div>Slot Win</div></div></span>, value:
        <span class="p-column-title">
          <div>
            <div>{moneyConvert(totalData?.slot?.bet, true)}</div>
            <div>{moneyConvert(totalData?.slot?.win, true)}</div>
          </div>
        </span>, sortAble: false
    },
    { field: "losings", label: "총회원 루징", value: `${moneyConvert(totalData?.losing, true)}`, sortAble: false },
    { field: "slot_rollings", label: `슬롯 롤링`, value: `${moneyConvert(totalData?.slot_rolling, true)}`, sortAble: false },
    { field: "casino_rollings", label: `카지노 롤링`, value: `${moneyConvert(totalData?.casino_rolling, true)}`, sortAble: false },
    { field: "sum_rollings", label: '합산 롤링', value: `${moneyConvert(totalData?.sum_rolling, true)}`, sortAble: false },
    {
      field: "moneys", label:
        <span class="p-column-title"><div><div>유저 보유
        </div><div>전일 보유</div></div></span>,
      value: <span class="p-column-title">
        <div>
          <div>{totalData?.money}</div>
        </div>
      </span>
      , sortAble: false
    },
    { field: "settlement_amounts", label: <span class="p-column-title"><div><div>정산금</div><div>루징프로테이지%</div></div></span>, value: `${totalData?.settlement_amount.toLocaleString()}`, sortAble: false },
    {
      field: "partner_charge_exchanges", label: <span class="p-column-title"><div><div>에이전트 충전</div><div>에이전트 환전</div></div></span>,
      value:
        <span class="p-column-title">
          <div>
            <div>{totalData?.partner_charge_exchange?.charge?.toLocaleString()}</div>
            <div>{totalData?.partner_charge_exchange?.exchange?.toLocaleString()}</div>
          </div>
        </span>,
      sortAble: false
    },
    {
      field: "sub_user_charge_exchanges", label: <span class="p-column-title"><div><div>하부유저 지급</div><div>하부유저 회수</div></div></span>, value: <span class="p-column-title">
        <div>
          <div>{totalData?.sub_user_charge_exchange?.charge.toLocaleString()}</div>
          <div>{totalData?.sub_user_charge_exchange?.exchange.toLocaleString()}</div>
        </div>
      </span>, sortAble: false
    },
    { field: "rolling_to_moneys", label: `롤롱케쉬전환`, value: `${totalData?.rolling_to_money?.toLocaleString()}`, sortAble: false },
    {
      field: "user_rollings", label: <span class="p-column-title"><div><div>유저 슬롯 롤링</div><div>유저 카지노 롤링</div></div></span>, value:
        <span class="p-column-title">
          <div>
            <div>{totalData?.user_rolling?.slot.toLocaleString()}</div>
            <div>{totalData?.user_rolling?.casino.toLocaleString()}</div>
          </div>
        </span>, sortAble: false
    },

  ])
  const calculateList = new CalculateService()
  function getCalculateList(payload) {
    calculateList.calculateList(payload)
      .then((data) => {
        const res = data.list
        setData(res.data)
        setTotalRecords(res.total)
        setTotalData(data.total)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  function formatDate(date) {
    var yyyy = date?.getFullYear();
    var mm = String(date?.getMonth() + 1).padStart(2, '0');
    var dd = String(date?.getDate()).padStart(2, '0');
    return yyyy + '-' + mm + '-' + dd
  }
  useEffect(() => {
    moment.locale('ko');
  }, [])
  useEffect(()=>{
    if (filterBtn.value === 'all') {
      setReqType(1)
      setHeader([
        {
          field: "mb_ids", label: <span class="p-column-title">
            <div >
              에이전트
            </div>
          </span>,
          value: totalData?.agent,
          sortAble: false
        },
        {
          field: "charges", label:
            <span class="p-column-title">
              <div>
                <div>유저 입금</div>
                <div>총판 지급</div>
                <div>관리자 지급</div>
              </div>
            </span>,
          value:
            <span class="p-column-title">
              <div style={{ textAlign: 'center' }}>
                <div>{moneyConvert(totalData?.charge?.user_charge, true)}</div>
                <div>{moneyConvert(totalData?.charge?.distributor_charge, true)}</div>
                <div>{moneyConvert(totalData?.charge?.center_charge, true)}</div>
              </div>
            </span>,
          sortAble: false
        },
        {
          field: "exchanges", label: <span class="p-column-title"><div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div></span>, value:
            <span class="p-column-title">
              <div style={{ textAlign: 'center' }}>
                <div>{moneyConvert(totalData?.exchange?.user_exchange, true)}</div>
                <div>{moneyConvert(totalData?.exchange?.distributor_exchange, true)}</div>
                <div>{moneyConvert(totalData?.exchange?.center_exchange, true)}</div>
    
              </div>
            </span>,
          sortAble: false
        },
        {
          field: "profits", label: <span class="p-column-title"><div><div>유저 수익</div><div>총판 수익</div></div></span>, value:
            <span class="p-column-title">
              <div style={{ textAlign: 'center' }}>
                <div>{moneyConvert(totalData?.profit?.user_profit, true)}</div>
                <div>{moneyConvert(totalData?.profit?.distributor_profit, true)}</div>
              </div>
            </span>,
          sortAble: false
        },
        {
          field: "casinos", label: <span class="p-column-title"><div><div>Casino Bet</div><div>Casino Win</div></div></span>, value:
            <span class="p-column-title">
              <div>
                <div>{moneyConvert(totalData?.casino?.bet, true)}</div>
                <div>{moneyConvert(totalData?.casino?.win, true)}</div>
              </div>
            </span>, sortAble: false
        },
        {
          field: "slots", label: <span class="p-column-title"><div><div>Slot Bet</div><div>Slot Win</div></div></span>, value:
            <span class="p-column-title">
              <div>
                <div>{moneyConvert(totalData?.slot?.bet, true)}</div>
                <div>{moneyConvert(totalData?.slot?.win, true)}</div>
              </div>
            </span>, sortAble: false
        },
        { field: "losings", label: "총회원 루징", value: `${moneyConvert(totalData?.losing, true)}`, sortAble: false },
        { field: "slot_rollings", label: `슬롯 롤링`, value: `${moneyConvert(totalData?.slot_rolling, true)}`, sortAble: false },
        { field: "casino_rollings", label: `카지노 롤링`, value: `${moneyConvert(totalData?.casino_rolling, true)}`, sortAble: false },
        { field: "sum_rollings", label: '합산 롤링', value: `${moneyConvert(totalData?.sum_rolling, true)}`, sortAble: false },
        {
          field: "moneys", label:
            <span class="p-column-title"><div><div>유저 보유
            </div><div>전일 보유</div></div></span>,
          value: <span class="p-column-title">
            <div>
              <div>{totalData?.money}</div>
            </div>
          </span>
          , sortAble: false
        },
        { field: "settlement_amounts", label: `정산금`, value: `${totalData?.settlement_amount.toLocaleString()}`, sortAble: false },
        {
          field: "partner_charge_exchanges", label: <span class="p-column-title"><div><div>에이전트 충전</div><div>에이전트 환전</div></div></span>,
          value:
            <span class="p-column-title">
              <div>
                <div>{totalData?.partner_charge_exchange?.charge?.toLocaleString()}</div>
                <div>{totalData?.partner_charge_exchange?.exchange?.toLocaleString()}</div>
              </div>
            </span>,
          sortAble: false
        },
        {
          field: "sub_user_charge_exchanges", label: <span class="p-column-title"><div><div>하부유저 지급</div><div>하부유저 회수</div></div></span>, value:
            <span class="p-column-title">
              <div>
                <div>{totalData?.sub_user_charge_exchange?.charge.toLocaleString()}</div>
                <div>{totalData?.sub_user_charge_exchange?.exchange.toLocaleString()}</div>
              </div>
            </span>
          , sortAble: false
        },
        { field: "rolling_to_moneys", label: `롤링캐쉬전환`, value: `${totalData?.rolling_to_money?.toLocaleString()}`, sortAble: false },
        {
          field: "user_rollings", label: <span class="p-column-title"><div><div>유저 슬롯 롤링</div><div>유저 카지노 롤링</div></div></span>, value:
            <span class="p-column-title">
              <div>
                <div>{totalData?.user_rolling?.slot?.toLocaleString()}</div>
                <div>{totalData?.user_rolling?.casino?.toLocaleString()}</div>
              </div>
            </span>, sortAble: false
        },

      ])
    }
    if (filterBtn.value === 'win-lose') {
      setReqType(2)
      setHeader([
        {
          field: "mb_ids", label: <span class="p-column-title">
            <div >
            에이전트
            </div>
          </span>,
          value: totalData?.agent,
          sortAble: false
        },
        {
          field: "charges", label:
            <span class="p-column-title">
              <div>
                <div>유저 입금</div>
                <div>총판 지급</div>
                <div>관리자 지급</div>
              </div>
            </span>,
          value:
            <span class="p-column-title">
              <div style={{ textAlign: 'center' }}>
                <div>{moneyConvert(totalData?.charge?.user_charge, true)}</div>
                <div>{moneyConvert(totalData?.charge?.distributor_charge, true)}</div>
                <div>{moneyConvert(totalData?.charge?.center_charge, true)}</div>
              </div>
            </span>,
          sortAble: false
        },
        {
          field: "exchanges", label: <span class="p-column-title"><div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div></span>, value:
            <span class="p-column-title">
              <div style={{ textAlign: 'center' }}>
                <div>{moneyConvert(totalData?.exchange?.user_exchange, true)}</div>
                <div>{moneyConvert(totalData?.exchange?.distributor_exchange, true)}</div>
                <div>{moneyConvert(totalData?.exchange?.center_exchange, true)}</div>
    
              </div>
            </span>,
          sortAble: false
        },
        {
          field: "profits", label: <span class="p-column-title"><div><div>유저 수익</div><div>총판 수익</div></div></span>, value:
            <span class="p-column-title">
              <div style={{ textAlign: 'center' }}>
                <div>{moneyConvert(totalData?.profit?.user_profit, true)}</div>
                <div>{moneyConvert(totalData?.profit?.distributor_profit, true)}</div>
              </div>
            </span>,
          sortAble: false
        },
        {
          field: "bet_win", label: <span class="p-column-title"><div><div>실금액 Bet</div><div>실금액 Win</div></div></span>, value:
            <span class="p-column-title">
              <div>
                <div>{moneyConvert(totalData?.real_bet_win?.bet, true)}</div>
                <div>{moneyConvert(totalData?.real_bet_win?.win, true)}</div>
              </div>
            </span>, sortAble: false
        },
        {
          field: "miss_bet", label: <span class="p-column-title"><div><div>빠진 Bet</div><div>빠진 Win</div></div></span>, value:
            <span class="p-column-title">
              <div>
                <div>{moneyConvert(totalData?.missing_bet_win?.bet, true)}</div>
                <div>{moneyConvert(totalData?.missing_bet_win?.win, true)}</div>
              </div>
            </span>, sortAble: false
        },
        {
          field: "casinos", label: <span class="p-column-title"><div><div>Casino Bet</div><div>Casino Win</div></div></span>, value:
            <span class="p-column-title">
              <div>
                <div>{moneyConvert(totalData?.casino?.bet, true)}</div>
                <div>{moneyConvert(totalData?.casino?.win, true)}</div>
              </div>
            </span>, sortAble: false
        },
        { field: "casino_losings", label: `카지노 루징`, value: `${moneyConvert(totalData?.casino_losing, true)}`, sortAble: false },
        { field: "casino_rollings", label: `카지노 롤링`, value: `${moneyConvert(totalData?.casino_rolling, true)}`, sortAble: false },
        {
          field: "settlement_amounts", label:
            <span class="p-column-title"><div><div>정산금
            </div><div>루징프로테이지%</div></div></span>,
          value: <span class="p-column-title">
            <div>
              <div>{totalData?.settlement_amount?.toLocaleString()}</div>
            </div>
          </span>
          , sortAble: false
        },
        {
          field: "partner_charge_exchanges", label: <span class="p-column-title"><div><div>에이전트 충전</div><div>에이전트 환전</div></div></span>, value:
            <span class="p-column-title">
              <div>
                <div>{totalData?.partner_charge_exchange?.charge?.toLocaleString()}</div>
                <div>{totalData?.partner_charge_exchange?.exchange?.toLocaleString()}</div>
              </div>
            </span>
          , sortAble: false
        }

      ])
    }
  },[filterBtn, totalData])
  useEffect(() => {
    if(inpValue !==''){
      getCalculateList({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        req_type: reqType,
        page: page,
        key: inpValue
      })
    }
    else{
      getCalculateList({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        req_type: reqType,
        page: page,
      })
    }
    
   
  }, [page, reqType])
  useEffect(() => { }, [totalData])
  const SelectBtnGroup = () => {
    const handleChangeOpt = async (e) => {
      if(e.value ===  null){
        return
    }
      setFilterBtn(e)
    }
    const justifyTemplate = (option) => {
      return <span>{option.button}</span>;
    }
    return (
      <SelectButton value={filterBtn.value} onChange={(e) => handleChangeOpt(e)} itemTemplate={justifyTemplate} options={buttonFilter} />
    )
  }
  addLocale('ko', {
    firstDayOfWeek: 1,
    dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
    monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    today: '오늘',
    clear: '지우기'
  });
  const onPageChange = (event) => {
    setPage(event.page + 1)
    setFirst(event.first);
  };
  const bodyTemplate = (data, props) => {
    const id = data?.mb_id || ''
    return (
      <>
        {props.field === 'mb_ids' &&
          <>
            <div onClick={() => { window.open(`#/agentdetail/${id}`, '', "width=1500,height='100vh'") }}>
              <span className="p-column-title">{props.header}</span>
              {id} <i className="pi-cog pi" style={{ color: 'red' }} />
            </div>
            <div onClick={() => { window.open(`#/calculatesubagent/${id}`, '', "width=1500,height='100vh'") }}>
              <span className="p-column-title">{props.header}</span>
              {data?.agent?.name?.value} <i className="pi-star pi" style={{ color: 'green' }} />
            </div>
          </>
        }
        {props.field === 'btn_action' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div className="icon-btn">
              <span style={{}}><i style={{ color: 'white' }} className="pi-file-edit pi" /></span>

            </div>
          </>
        }
        
        {props.field === 'charges' && filterBtn.value ==='all' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column', color: 'red' }}>
              <span>{data?.charge?.user_charge?.toLocaleString()}</span>
              <span>{data?.charge?.distributor_charge?.toLocaleString()}</span>
              <span>{data?.charge?.center_charge?.toLocaleString()}</span>

            </div>
          </>
        }
        {props.field === 'exchanges' && filterBtn.value ==='all' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column', color: 'blue' }}>
              <span>{data?.exchange?.user_exchange?.toLocaleString()}</span>
              <span>{data?.exchange?.distributor_exchange?.toLocaleString()}</span>
              <span>{data?.exchange?.center_exchange?.toLocaleString()}</span>

            </div>
          </>
        }
        {props.field === 'charges' && filterBtn.value ==='win-lose' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column', color: 'red' }}>
              <span>{data?.charge?.user_charge?.toLocaleString()}</span>
              <span>{data?.charge?.distributor_charge?.toLocaleString()}</span>
              <span>{data?.charge?.center_charge?.toLocaleString()}</span>

            </div>
          </>
        }
        {props.field === 'bet_win' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{color:'red'}}>{data?.real_bet_win?.bet?.toLocaleString()}</span>
              <span style={{color:'blue'}}>{data?.real_bet_win?.win?.toLocaleString()}</span>

            </div>
          </>
        }
        {props.field === 'miss_bet' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <span style={{color:'red'}}>{data?.missing_bet_win?.bet?.toLocaleString()}</span>
              <span style={{color:'blue'}}>{data?.missing_bet_win?.win?.toLocaleString()}</span>

            </div>
          </>
        }
        {props.field === 'casino_losings' && 
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <span style={{color:'blue'}}>{data?.casino_losing?.toLocaleString()}</span>

            </div>
          </>
        }
        {props.field === 'exchanges' && filterBtn.value ==='win-lose' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column', color: 'blue' }}>
              <span>{data?.exchange?.user_exchange?.toLocaleString()}</span>
              <span>{data?.exchange?.distributor_exchange?.toLocaleString()}</span>
              <span>{data?.exchange?.center_exchange?.toLocaleString()}</span>

            </div>
          </>
        }
        {props.field === 'profits' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: 'blue' }}>{data?.profit?.user_profit?.toLocaleString()}</span>
              <span style={{ color: 'red' }}>{data?.profit?.distributor_profit?.toLocaleString()}</span>
            </div>
          </>
        }
        {props.field === 'casinos' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: 'red' }}>{data?.casino?.bet?.toLocaleString()}</span>
              <span style={{ color: 'blue' }}>{data?.casino?.win?.toLocaleString()}</span>
            </div>
          </>
        }
        {props.field === 'slots' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: 'blue' }}>{data?.slot?.bet?.toLocaleString()}</span>
              <span style={{ color: 'red' }}>{data?.slot?.win?.toLocaleString()}</span>
            </div>
          </>
        }
        {props.field === 'losings' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: 'blue' }}>{data?.losing?.toLocaleString()}</span>
            </div>
          </>
        }
        {props.field === 'slot_rollings' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#FFD42E' }}>{data?.slot_rolling?.amount?.toLocaleString()}</span>
              <span style={{ color: 'green' }}>{data?.slot_rolling?.rate?.toLocaleString() +'%'}</span>
            </div>
          </>
        }
        {props.field === 'casino_rollings' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#FFD42E' }}>{data?.casino_rolling?.amount?.toLocaleString()}</span>
              <span style={{ color: 'green' }}>{data?.casino_rolling?.rate?.toLocaleString() +'%'}</span>
            </div>
          </>
        }
        {props.field === 'sum_rollings' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#FFD42E' }}>{data?.sum_rolling?.toLocaleString()}</span>
            </div>
          </>
        }
        {props.field === 'moneys' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column', color: '#34A1F3' }}>
              <span>{data?.money?.current_money?.toLocaleString()}</span>
              <span>{data?.money?.pre_money?.toLocaleString()}</span>
            </div>
          </>
        }
        {props.field === 'settlement_amounts' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: 'red' }}>{data?.settlement_amount?.toLocaleString()}</span>
              {/* <span>{data?.settlement_amount?.rate}</span> */}
            </div>
          </>
        }
        {props.field === 'partner_charge_exchanges' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: 'red' }}>{data?.partner_charge_exchange?.charge?.toLocaleString()}</span>
              <span style={{ color: 'blue' }}>{data?.partner_charge_exchange?.exchange?.toLocaleString()}</span>
            </div>
          </>
        }
        {props.field === 'sub_user_charge_exchanges' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: 'red' }}>{data?.sub_user_charge_exchange?.charge?.toLocaleString()}</span>
              <span style={{ color: 'blue' }}>{data?.sub_user_charge_exchange?.exchange?.toLocaleString()}</span>
            </div>
          </>
        }
        {props.field === 'rolling_to_moneys' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#64ABB1' }}>{data?.rolling_to_money?.toLocaleString()}</span>
            </div>
          </>
        }
        {props.field === 'user_rollings' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{data?.user_rolling?.slot?.toLocaleString()}</span>
              <span>{data?.user_rolling?.casino?.toLocaleString()}</span>
            </div>
          </>
        }
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    )
  }

  const headerGroup = (
    <ColumnGroup>
      <Row>
        {header.map((v, i) => {
          return (
            <Column header={v.label} />
          )
        })}
      </Row>
      <Row >
        {header.map((v, i) => {
          return (
            <Column header={v.value} style={{background:'rgba(139, 195, 74, 0.34)'}}/>
          )
        })}
      </Row>

    </ColumnGroup>
  );
  function handleFilterBtnClick() {
    if (filterBtn.value === 'all') {
      getCalculateList({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        req_type: 1,
        page: page,
        key: inpValue,

      })
    }
    if (filterBtn.value === 'win-lose') {
      getCalculateList({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        req_type: 2,
        page: page,
        key: inpValue,
      })
    }
  }
  function onBtnSearch() {
    if (filterBtn.value === 'all' && inpValue !=='') {
      getCalculateList({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        req_type: 1,
        page: page,
        key: inpValue,
      })
    }
    if (filterBtn.value === 'win-lose'&& inpValue !=='') {
      getCalculateList({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        req_type: 2,
        page: page,
        key: inpValue,
      })
    }
    if(filterBtn.value === 'all' && inpValue ===''){
      getCalculateList({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        req_type: 1,
        page: page,
      })
    }
    if(filterBtn.value === 'win-lose' && inpValue ===''){
      getCalculateList({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        req_type: 2,
        page: page,
      })
    }
  }
  return (
    <>
      <div className='refresh card'>
        <span className="text">정산 기록</span>
        <div className="tool-withdraw">
          <div className="date-filter">
            <Calendar value={startDate}
              hourFormat="24" onChange={(e) => setStartDate(e.value? e.value: currentDate)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
            <Calendar value={endDate} onChange={(e) => setEndDate(e.value? e.value: '')}
              hourFormat="24" locale="ko" showIcon dateFormat="yy-mm-dd"/>
            <div className="item1">
              <Button onClick={handleFilterBtnClick}>검색</Button>
            </div>
          </div>
          <div>
          <InputText style={{marginBottom:'0px !important'}} placeholder='찾는 ID 입력해주세요' onChange={(e) => setInpValue(e.target.value)} value={inpValue} onKeyDown={(e) => { if (e.key === 'Enter') onBtnSearch() }} />
          </div>
          <div className="item">
            <div className="button-group-filter">
              <SelectBtnGroup />
            </div>
          </div>
          <div className="item1">
              <Button onClick={() => { window.open(`#/settlement`, '', "width=1200, height=800") }} style={{background:'green'}}>내역</Button>
            </div>
        </div>
        
      </div>
      <div className="inventory card">
        <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
          rows={30}
          template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

        />
        <CustomDataTable
          data={data}
          headers={header}
          bodyTemplate={bodyTemplate}
          modalMessage={messageModal}
          openConfirm={openModalConfirm}
          scrollable={true}
          showGridlines={true}
          headerColumnGroup={headerGroup}
        />
        <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
          rows={30}
          template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
        />
      </div>
    </>
  );
};

export default CalculateMainAgent;
