import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import './Level.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { TabPanel, TabView } from "primereact/tabview";


const Level = () => {
    const [selectOption, setSelectOption] = useState('선택');
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [first, setFirst] = useState(0);
    const [percentInput, setPercentInput] = useState(0)
    const [conditionInput, setConditionInput] = useState(0)


    const header = [
        { field: "level", label: "Level", sortAble: false },
        { field: "id", label: `ID`, sortAble: false },
        { field: "explain", label: `설명`, sortAble: false },
        { field: "percent", label: "퍼센테이지", sortAble: false },
        { field: "condition", label: "조건", sortAble: false },
        { field: "delete", label: `삭제`, sortAble: false },
    ];
    useEffect(() => {
        // const userList = new getUserList();
        // userList.getUserList(page)
        //     .then((data) => {
        //         const res = data.list
        //         setData(res.data)
        //         setTotalRecords(res.total)
        //         console.log(res)
        //     })
    }, [page])
    const tabView = [
        {header:'1레벨' },
        {header:'2레벨' },
        {header:'3레벨' },
        {header:'4레벨' },
        {header:'5레벨' },
        {header:'6레벨' },
        {header:'7레벨' },

    ]
    const confirm1 = () => {
        confirmDialog({
            message: '레벨정보가 업데이트 되어습니다.',
            header: '레벨',
            accept,
            acceptLabel: '확인',
            acceptClassName: 'p-confirm-dialog-accept'
        });
    };
    const accept = () => {
        action()
    }
    const action = ()=>{
        console.log('this is function')
      }
    const buttonGroup = [
        { button: '저장 하기', value: '저장 하기', onclick: 'none' },
        { button: '레벨 생성', value: '레벨 생성', onclick: 'modal' },
    ]
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
   
    const bodyTemplate = (data, props) => {
        return (
            <>
                {props.field === 'delete' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div className="icon-btn">
                            <Button>삭제</Button>
                        </div>
                    </>
                }
                {props.field ==='percent' &&
                    <>
                    <span className="p-column-title">{props.header}</span>
                    
                    <div className={`table-input`}>
                        <span className={`p-float-label`}>
                            <InputText
                            id=''
                            type="text"
                            defaultValue={data?.no}
                            onChange={(e) => setPercentInput(e.target.value)}
                            //   onKeyDown={(e) => onKeyDown?.(e)}
                            />
                            <label ></label>
                        </span>
                    </div>
                </>
                }
            </>
        )
    }

    return (
        <>
            <div className='refresh card'>
                <span className="text">레벨 설정</span>
                <div className="tool-level">
                    {buttonGroup.map((v, i) => {
                        return (
                            <Button label={v.button} severity="info" key={i} onClick={()=> {v.onclick ==='modal'?confirm1():console.log('button2')}} />
                        )
                    })}
                </div>
                
            </div>
            <div className="inventory card">
                <TabView>
                    {tabView.map((v, i)=>{
                        return(
                            <TabPanel header={v.header} leftIcon='pi pi-check mr-2' key={i}/>
                        )
                    } )}
                </TabView>
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
                <CustomDataTable
                    data={data}
                    headers={header}
                    bodyTemplate={bodyTemplate}
                    modalMessage={messageModal}
                    openConfirm={openModalConfirm}
                    scrollable={true}
                />
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
            </div>
        </>
    )
}

export default Level;