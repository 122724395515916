import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import './AgentRegister.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import { confirmDialog } from "primereact/confirmdialog";
import AgentService from "../../../service/AgentServices";
import { Toast } from "primereact/toast";

const AgentRegister = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0);
    const toast = useRef(null)
    const header = [
        { field: "mb_id", label: "아이디", sortAble: false },
        { field: "mb_hp", label: `연락처`, sortAble: false },
        { field: "mb_step_1", label: `본사`, sortAble: false },
        { field: "mb_step_2", label: "부본사", sortAble: false },
        { field: "mb_step_3", label: "지사", sortAble: false },
        { field: "mb_step_4", label: `총판`, sortAble: false },
        { field: "mb_step_5", label: `매장`, sortAble: false },
        { field: "mb_step_6", label: `매장`, sortAble: false },
        { field: "mb_bank_name", label: `은행명`, sortAble: false },
        { field: "mb_bank_own", label: `입금자명`, sortAble: false },
        { field: "mb_bank_no", label: `계좌번호`, sortAble: false },
        { field: "mb_regtime", label: `등록일`, sortAble: false },
        { field: "status__", label: `상태`, sortAble: false },
        { field: "accept", label: `수락`, sortAble: false },
        { field: "waiting", label: `대기`, sortAble: false },
        { field: "reject", label: `삭제`, sortAble: false },
    ];
    const agentList = new AgentService();
    function getAgentRegisterList() {
        const payload = {
            page: page
        }
        agentList.agentRegisterListAPI(payload)
            .then((data) => {
                const res = data?.list
                setData(res?.data)
                setTotalRecords(res?.total)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        getAgentRegisterList()
    }, [page])


    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    function onHandleButtonAction(e) {
        const urlAction = new URL(e?.link)
        const queryString = urlAction.search
        const message = e.confirm
        const header = e.label
        confirmDialog({
            message: message,
            header: header,
            accept: () => { confirm(`member${queryString}`) },
            acceptLabel: header,
            acceptClassName: "p-confirm-dialog-accept",
        });
    }
    function confirm(e) {
        agentList.agentRegisterActionAPI(e).then((data) => {
            if(data.code === 200){
                toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                getAgentRegisterList()
              }
              else{
                toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
              }
        }).catch((err) => { console.log(err) })
    }
    const bodyTemplate = (data, props) => {
        const acceptAction = data.action_button.accept
        const waitingAction = data.action_button.hold
        const rejectAction = data.action_button.delete

        return (
            <>
                {
                    props.field === 'accept' && data.mb_status === '1' &&
                    <>
                        <span className="p-column-title-edit">{props.header}</span>
                        <Button className="confirm"
                            onClick={() => {
                                onHandleButtonAction(acceptAction)
                            }}>
                            수락
                        </Button>
                    </>
                }
                {
                    props.field === 'status__' &&
                    <>
                        <span>{data.mb_status === '1' ? '문의' : ''}</span>
                        <span>{data.mb_status === '4' ? '대기' : ''}</span>
                    </>
                }
                {props.field === 'waiting' && data.mb_status === '1' &&
                    <>
                        <span className="p-column-title-delete">{props.header}</span>
                        <Button className="edit"
                            onClick={(e) => {
                                onHandleButtonAction(waitingAction)
                            }}>
                            대기
                        </Button>
                    </>
                }
                {props.field === 'accept' && data.mb_status === '4' &&
                    <>
                        <span className="p-column-title-edit">{props.header}</span>
                        <Button className="confirm"
                            onClick={() => {
                                onHandleButtonAction(acceptAction)
                            }}>
                            수락
                        </Button>
                    </>
                }
                {
                    props.field === 'reject' &&
                    <>
                        <span className="p-column-title-edit">{props.header}</span>
                        <Button className="delete"
                            onClick={() => {
                                onHandleButtonAction(rejectAction)
                            }}>
                            삭제
                        </Button>
                    </>
                }

                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    return (
        <>
            <Toast ref={toast} />
            <div className='refresh card'>
                <span className="text">에이전트 승인대기</span>
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
                <CustomDataTable
                    data={data}
                    headers={header}
                    bodyTemplate={bodyTemplate}
                    scrollable={true}
                />
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
            </div>
        </>
    )
}

export default AgentRegister;