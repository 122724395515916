import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import getUserConnectionList from '../../../service/UserService'
import { Paginator } from 'primereact/paginator';
import './style.css';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
const UserConnectionList = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(1)
    const [totalRecords, setTotalRecords] = useState(0)
    const [data, setData] = useState()
    const toast = useRef(null);
    const userService = new getUserConnectionList();
    function fetchUser() {
        userService.getUserConnectionList(page)
            .then((data) => {
                // console.log(data.data);
                const res = data.data
                setData(res.data)
                setTotalRecords(res.total)
            }
            )
            .catch((err) => {
                console.log(err)
            })
    }

    const confirm1 = () => {
        confirmDialog({
            message: '벨런스 새로고침을 사용하시겠습니까 ? 해당기능은 30분에 한번 사용가능합니다.',
            header: '벨런스 업데이트',
            icon: 'pi pi-exclamation-triangle',
            accept,
            acceptLabel: '예',
            rejectLabel: '아니요',
            rejectClassName: 'p-button-danger'
        });
    };

    const accept = () => {
        fetchUser()
        toast.current.show({ severity: 'success', summary: '성공', detail: '데이터가 성공적으로 새로 고쳐졌습니다', life: 3000 });
    }
    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
                {props.field === "angent_tree" && (
                    <div className="container-box-agentBalance"
                    // style={{display:'flex', justifyContent:'center'}}
                    >
                        {data?.agents.map((i, index) => {
                            const color = [
                                "#ff5722",
                                "#2196f3",
                                "#ffc107",
                                "#4caf50",
                                "#4eff14",
                                "#d92e11",
                            ];
                            const bgcolor = [
                                "#ff5722",
                                "#000",
                                "#4caf50",
                                "#ff7214",
                                "#4eff14",
                                "#d92e11",
                            ];
                            // console.log(i);
                            return (
                                <React.Fragment key={index}>
                                    {i.before && (
                                        <span
                                            className="box-agentBalance"
                                            style={{ backgroundColor: bgcolor[index] ?? "#d92e11" }}
                                        >
                                            {i?.before}
                                        </span>
                                    )}{" "}
                                    <span style={{ color: color[index] ?? "#d92e11" }}>
                                        &nbsp;{i?.value}
                                    </span>{" "}
                                    <span style={{ color: "#000", fontWeight: "700" }}>
                                        {index < 5 ? i?.after : ''}
                                    </span>{" "}
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}
            </>
        );
    };

    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    useEffect(() => {
        fetchUser()
    }, [page]);
    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />

            <div className='refresh card'>
                <span>접속 유저 리스트</span>
                <Button label="벨런스 업데이트" onClick={confirm1} className="mr-2 mb-2 button" severity="success" />
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
                <DataTable value={data} style={{ marginBottom: '20px' }} removableSort className="p-datatable-orders" rows={30} selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} filterDisplay='row'rowGroupMode="rowspan"
                    scrollable scrollHeight="800px" groupRowsBy='mb_id' 
                >
                    <Column field="mb_id" header="아이디" filter filterPlaceholder="에이전트 아이디" sortable body={bodyTemplate} />
                    <Column field="mb_money" sortable header="보유금액" body={bodyTemplate} />
                    <Column field="mb_point" sortable header="포인트" body={bodyTemplate} />
                    <Column field="logindate" header="접속일" sortable body={bodyTemplate} />
                    <Column field="ip" header="아이피" body={bodyTemplate} />
                    <Column field="angent_tree" header="에이전트" body={bodyTemplate} />
                </DataTable>
                <Paginator
                    first={first}
                    totalRecords={totalRecords}
                    rows={30}
                    onPageChange={onPageChange}
                    template={{ layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink' }}
                />
            </div>
        </>
    )
}
export default UserConnectionList;