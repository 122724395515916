import { mainAxios } from "../libs/axios";
const accessToken =  localStorage.getItem('User')
const jsonToken = JSON.parse(accessToken)
const token = `Bearer ${jsonToken}`

export default class BetService {
fetchBettingHis(payload){
    return mainAxios.request({
      methodType: 'GET',
      url: `/game?`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          Accept: 'application/json',
          Authorization: `${token}`
        }
      }
    })
  }

}