import React, { useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import AppMenu from "./AppMenu";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { ToggleButton } from "primereact/togglebutton";
import { CpButtonGroupActive, CpButtonGroup } from "./components";
import { NavLink } from "react-router-dom";
import { logout } from "./service/UserLogin";
import { mute, delayPlaySound } from './utilities/sound'
import SettingService from "./service/SettingService";
import './AppTopbar.scss'

const AppTopbar = (props) => {
  let topbarMenuClassName = classNames("layout-profile-menu fadeInDown ", {
    "layout-profile-menu-active": props.topbarUserMenuActive,
  });
  let menuButtonClassName = classNames("layout-menubutton ", {
    "layout-menubutton-active": props.menuActive,
  });
  const localMedia = JSON.parse(localStorage.getItem('media'))||[];
  const [activeBtb, setActiveBtn] = useState(`${localMedia.muted}`)
  const getInk = (el) => {
    for (let i = 0; i < el.children.length; i++) {
      if (
        typeof el.children[i].className === "string" &&
        el.children[i].className.indexOf("p-ink") !== -1
      ) {
        return el.children[i];
      }
    }
    return null;
  };
  const [data, setData] = useState()
  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };
  const dataButtonGroup = [
    { name: "유저 충전", code: "c1", link: "/deposit", data: data?.user_charge_request },
    { name: "유저 환전", code: "c2", link: "/withdraw", data: data?.user_exchange_request },
    { name: "에이전트 충전", code: "c3", link: "/depositagent", data: data?.agent_charge_request },
    { name: "에이전트 환전", code: "c4", link: "/withdrawagent", data: data?.agent_exchange_request },
    { name: "가입", code: "c5", link: "/userreglist", data: data?.user_ready_cnt },
    // { name: "에이전트 충/환전", code: "c4", link: "/agentwithdraw", data: data.charge_request },
    { name: "에이전트 가입", code: "c6", link: "/agentreg", data: data?.agent_ready_cnt },
    { name: "에이전트 문의", code: "c7", link: "/agenthelp", data: data?.agent_inquiry_wait_cnt },
    { name: "유저 문의", code: "c8", link: "/help", data: data?.user_inquiry_wait_cnt },

  ];
  const onItemClick = (event) => {
    // const ink = getInk(event.currentTarget);
    // if (ink) {
    //   removeClass(ink, "p-ink-active");
    // }
  };
  const settingAPI = new SettingService()
  function getNoti (){
      settingAPI.getNoti()
        .then((data)=>{
          setData(data.data)
        })
        .catch((err)=>{
          console.log(err)
        })
  }
  useEffect(()=>{
    getNoti()
    const timeRunning = setInterval(() => {
      getNoti();
    }, 60000); 
    return () => {
      clearInterval(timeRunning);
    };
  },[])
  const onLogout =async()=>{
    try {
      logout()
      .then((data)=>{
        console.log(data.message)
      })
      .catch((err)=>{console.log(err)})
      localStorage.removeItem("access");
      window.location.href = "/#/login";
    } catch (error) {
      console.log(error)
    }
  }
  const Link = (v, i) => {
    const [linkStyle, setLlinkStyle] = useState({
      color: "#fff",
      padding: "6px 14px 6px",
      background: "#ffb401",
      height: "30px",
      boxShadow: `0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16)`,
    });
    return (
      <NavLink
        to={v.v.link}
        key={i}
        style={linkStyle}
        onMouseEnter={() =>
          setLlinkStyle({ ...linkStyle, background: "#1e88e5" })
        }
        onMouseLeave={() =>
          setLlinkStyle({ ...linkStyle, background: "#ffb401" })
        }
        className={v.v.data > 0? 'blinked':''}
      >
        {v.v.name} {v.v.data}
      </NavLink>
    );
  };

  return (
    <div className="layout-topbar">
      <button
        type="button"
        className={menuButtonClassName}
        onClick={props.onMenuButtonClick}
      >
        <div className="layout-menubutton-icon" />
      </button>
      <div>
        <div className="p-0">
          {/* <CpButtonGroup
            dataGroup={dataButtonGroup}
            onChange={(e) => console.log(e)}
          /> */}

          <NavLink to={"/#"}></NavLink>
        </div>
        <div className="flex flex-wrap">
          {dataButtonGroup.map((v, i) => (
            <Link v={v} i={i} ></Link>
          ))}
        </div>
      </div>
      <div className="layout-topbar-grid">
        <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
          <button
            type="button"
            className="layout-logo p-link"
            onClick={() => {
              window.location = "/#";
            }}
          >
            <img
              src="assets/layout/images/logo-white.svg"
              alt="sapphire-layout"
            />
          </button>
        </div>

        <div className="layout-topbar-grid-column">
          <AppMenu
            model={props.model}
            horizontal={props.horizontal}
            menuHoverActive={props.menuHoverActive}
            isMobile={props.isMobile}
            onMenuItemClick={props.onMenuItemClick}
            onRootMenuItemClick={props.onRootMenuItemClick}
            onSidebarClick={props.onSidebarClick}
          />
        </div>

        {/* <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
                    <span className="layout-topbar-search">
                        <span className="p-input-icon-right">
                            <InputText id="search" type="text" placeholder="Search" />
                            <i className="topbar-icon pi pi-search"></i>
                        </span>
                    </span>
                </div> */}

        <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
          <div className="mb-2">
            <CpButtonGroupActive
              dataGroup={[
                { label: "On", value: "0", callBack: () => {
                  delayPlaySound()
                  setActiveBtn('0')
                } },
                { label: "Off", value: "1", callBack: () =>{
                  mute()
                  setActiveBtn('1')
                } },
              ]}
              value={activeBtb}
            />
          </div>
          <button
            type="button"
            className="p-link profile-menu-button"
            onClick={props.onTopbarUserMenuButtonClick}
          >
            <img src="assets/layout/images/avatar.png" alt="Profile" />
          </button>
          <ul
            className={topbarMenuClassName}
            onClick={props.onTopbarUserMenuClick}
          >
            <li className="layout-profile-menu-search">
              <span className="p-float-label">
                <InputText type="text" />
                <label>Search</label>
              </span>
            </li>

            <li role="menuitem">
              <button
                type="button"
                className="p-link p-ripple"
                onClick={onItemClick}
              >
                <i className="pi pi-user"></i>
                <span>Profile</span>
                <Ripple />
              </button>
            </li>
            <li role="menuitem">
              <button
                type="button"
                className="p-link p-ripple"
                onClick={onItemClick}
              >
                <i className="pi pi-envelope"></i>
                <span>Inbox</span>
                <Ripple />
              </button>
            </li>
            <li role="menuitem">
              <button
                type="button"
                className="p-link p-ripple"
                onClick={onItemClick}
              >
                <i className="pi pi-cog"></i>
                <span>Settings</span>
                <Ripple />
              </button>
            </li>
            <li role="menuitem">
              <button
                type="button"
                className="p-link p-ripple"
                onClick={onLogout}
              >
                <i className="pi pi-times"></i>
                <span>Logout</span>
                <Ripple />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppTopbar;
