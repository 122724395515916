import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { Paginator } from "primereact/paginator";
import 'primeicons/primeicons.css';
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import './UserIp.scss'
import { Dropdown } from "primereact/dropdown";

const UserIp = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [exchangeDetail, setExchangeDetail] = useState()
    const [stopUser, setStopUser] = useState({})
    const [first, setFirst] = useState(0);
    const [visible, setVisible] = useState(false);
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [selectOption, setSelectOption] = useState('선택');
    const [filterValue, setFilterValue] = useState('')

    const userList = new getUserList();
    const fetchUser = () => {
        userList.getUserIP(page)
            .then((data) => {
                const res = data.data
                setData(res.data)
                setTotalRecords(res.total)
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    useEffect(() => {
        fetchUser()
    }, [page])
    const header = [
        { field: "ip", label: `아이피`, sortAble: false },
        { field: "mb_id", label: "아이디", sortAble: true },
        { field: 'logindate', label: "접속일", sortAble: true },
    ];
    function formatName(id) {
        return (
            <div onClick={() => { window.open(`#/userdetail/${id}`, '',"width=1500,height='100vh'") }}>
            <span>{id}</span>&nbsp;
            <i className="pi pi-star" style={{ fontSize: ' 12px', color: 'rgb(255, 87, 34)' }} />
        </div>
        )
    }
    const bodyTemplate = (data, props) => {
        const id = data?.mb_id
        const formattedValue =  props.field === 'mb_id' ? formatName(id) : data[props.field];
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {formattedValue}
                {props.field === 'mb_ids' &&
                    <div onClick={() => { window.open(`#/userdetail/${data.mb_id}`, '', "width=1500,height='100vh'") }}>

                        <span className="p-column-title">{props.header}</span>
                        <span>{id}</span>&nbsp;
                        <i className="pi pi-star" style={{ fontSize: ' 12px', color: 'rgb(255, 87, 34)' }} />
                    </div>
                }
            </>
        );
    };
    const menuDropdown = [
        '선택',
        '아이디',
        '이름',
        '추천인',
        '전화번호',
        '에이전트',
        'IP 검색',
        '계좌번호',
        '닉네임'
    ]
    const keyfield = [
        { mb_id: "아이디" },
        { mb_name: "이름" },
        { mb_rec_id: "추천인" },
        { mb_hp: "전화번호" },
        { agent: "에이전트" },
        { ip: "IP 검색" },
        { mb_bank_no: "계좌번호" },
        { nickname: "닉네임" }
    ]
    const chooseOption = (e) => {
        if (e === '선택') {
            setFilterValue('')
        }
        setSelectOption(e)
    }
    function findCorrespondingKey(v) {
        for (const item of keyfield) {
            const key = Object.keys(item)[0];
            const value = item[key];

            if (value === v) {
                return key;
            }
        }

        return null;
    }
    const filterUser = (page) => {
        const params = {
            keyfield: findCorrespondingKey(selectOption),
            key: filterValue
        }
        userList.getUserIP(page, params)
            .then((data) => {
                const res = data.data
                setData(res.data)
                setTotalRecords(res.total)
            })
    }
    const onHandleSearch = () => {
        if (selectOption === '선택') {
            fetchUser()
        }
        else {
            filterUser(0)
        }
    }
    return (
        <>
            <div className='refresh'>
                <div className="tool-userip">
                    <div className="cp-dropdown">
                        <Dropdown
                            options={menuDropdown}
                            onChange={(e) => chooseOption(e.value)}
                            value={selectOption}
                        />
                    </div>
                    <span className={`p-float-label`}>
                        <InputText
                            type="text"
                            value={filterValue}
                            onChange={(e) => setFilterValue(e.target.value)}
                            onKeyDown={(e)=> {if(e.key ==='Enter') onHandleSearch()}}
                        />
                        <label>검색</label>
                    </span>
                    <Button onClick={onHandleSearch}>검색</Button>
                </div>
            </div>
            <div className="col-12 lg:col-12">
                <div className="inventory card">
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                    <CustomDataTable
                        data={data}
                        headers={header}
                        bodyTemplate={bodyTemplate}
                        modalMessage={messageModal}
                        openConfirm={openModalConfirm}
                        showGridlines={true}
                        groupRowsBy='ip'
                        scrollable={true}
                        sortMode="multiple"
                    />
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                </div>
            </div>
        </>
    )
}

export default UserIp