import React, { useEffect, useState } from "react";
import './Balance.scss'
import { Button } from "primereact/button";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../../components/Table/TableUser";
import UserService from "../../../../service/UserService";
import { useParams } from "react-router-dom";

const BalanceFluctuation = () => {
    const id = useParams()
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dataPoint, setDataPoint] = useState();
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0)
    const [page, setPage] = useState(0);
    const [data, setData] = useState([]);
    const [payload, setPayload] = useState({...id,
        top: 1, 
        page: page})
    const useList = new UserService();
    function getStatusName(statusCode) {
        switch (statusCode) {
            case '0':
                return "요청";
            case '1':
                return "승인대기";
            case '2':
                return "처리";
            case '3':
                return "삭제";
            default:
                return "잘못된 상태입니다";
        }
    }
    function formatDate(date) {
        var yyyy = date?.getFullYear();
        var mm = String(date?.getMonth() + 1).padStart(2, '0');
        var dd = String(date?.getDate()).padStart(2, '0');
        return yyyy + '-' + mm + '-' + dd
    }
    function handleSearch(){
        if(startDate){
            setPayload({
                ...id,
                sdate: formatDate(startDate),
                edate: formatDate(endDate), 
                page: page
            })
        }
        
    }
    const bodyTemplate = (data, props) => { 
        const type = data?.type[1]
        const money = data?.money[0]
        const restMoney = data?.cur_money[0]
        const status = data?.type[0]
        var statusName = getStatusName(status)
        return(
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
                {props.field === 'balance+' &&
                    <>
                        {type === '충전' ? <span style={{color:'green'}}>{money}</span> : <span>-</span>}

                    </>
                }
                {props.field === 'balance-' &&
                    <>
                        {type === '환전' ? <span style={{color:'red'}}>{money}</span> : <span>-</span>}

                    </>
                }
                {props.field ==='rest-balance'&&
                         <span>{restMoney}</span>

                }
                {props.field === 'types' &&
                    <>
                        {type === '환전' ? <span style={{color:'red'}}> {type} ({statusName})</span> : <span style={{color:'green'}}>{type} ({statusName})</span>}

                    </>
                }
            </>
        )
    }
    const headerBalanceTable = [
        { field: "balance+", label: `벨런스 +`, sortAble: false },
        { field: "balance-", label: "벨런스 -", sortAble: false },
        { field: "rest-balance", label: "남은 벨런스", sortAble: false },
        // { field: "game-balance", label: "게임 벨런스", sortAble: false },
        { field: "okadmin", label: "에이전트", sortAble: false },
        { field: 'types', label: "타입", sortAble: false },
        // { field: 'game-info', label: "게임정보", sortAble: false },
        // { field: 'type-balance', label: "벨런스타입", sortAble: false },
        { field: 'okdate', label: "요청시간", sortAble: false },
    ];
    function fetchBalanceHistory(){
        useList.fetchUserBalance(payload)
            .then((data)=>{
                const res = data?.data
                setData(res?.data)
                setTotalRecords(res?.total)
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    useEffect(()=>{
        fetchBalanceHistory()
        
    },[page, payload])
    useEffect(()=>{
        if(!startDate){
            setPayload({
                ...id,
                top: 1, 
                page: page
            })
        }
    },[startDate])
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    addLocale('ko', {
        firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
    const headerTablePoint = [
        { field: 'Point', label: '합산', data: dataPoint },
    ]
    return (
        <div className="balance-tab">
            <div>
                <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd" />
                <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                <Button label="포인트" className="ml-2 mr-5 point-btn" />
                <Button label='확 인' className="ml-5" onClick={handleSearch}/>
            </div>
            <div className="table-dep">
                <DataTable data={dataPoint} showGridlines headers={headerTablePoint} >
                    <Column field="Total" header='합산' sortable={false} style={{ color: 'red' }}></Column>
                </DataTable>
            </div>
            <div className="inventory">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
                <CustomDataTable data={data} headers={headerBalanceTable} bodyTemplate={bodyTemplate} onShowButton={false} scrollable={true}/>
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
            </div>
        </div>
    )
}
export default BalanceFluctuation