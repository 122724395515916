import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";

// import "./SCSS/index.scss";

const CpCheckBox = (props) => {
  const { checked = false, onChange, label } = props;
  const [checkItem, setCheckItem] = useState(false);

  useEffect(() => {
    setCheckItem(checked);
  }, [checked]);

  return (
    <div className="cp-checkbox">
      <div className="field-checkbox">
        <Checkbox
          inputId="checkOption"
          name="option"
          checked={checkItem}
          onChange={onChange}
        />
        <label htmlFor="checkOption2">{label}</label>
      </div>
    </div>
  );
};

export default CpCheckBox;
