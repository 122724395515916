import React, { useState, useEffect } from "react";
// import { Editor } from "primereact/editor";
import { Editor } from "primereact/editor";

const CpEditor = (props) => {
  const { defaulValue = "", onChange, placeholder = "", height = 320 } = props;
  const [text, setText] = useState("");

  useEffect(() => {
    if (defaulValue) {
      setText(defaulValue);
    }
  }, []);
  const changeEditor = (e) => {
    console.log(e)
    setText(e);
    onChange?.(e);
    console.log(text)
  };

  return (
    <div className="cp-editor">
      <Editor
        value={text}
        onTextChange={(e) => changeEditor(e.htmlValue)}
        style={{ height: `${height}px` }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CpEditor;
