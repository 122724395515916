export const buildMyQueryString = (obj) => {
    let queryString = '';
    queryString = Object.keys(obj)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        )
        .join('&');
    return queryString ? `?${queryString}` : queryString;
};
