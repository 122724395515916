import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";
import moment from "moment-timezone";

import moneyConvert from "../../utilities/moneyConvert";
import {
  CpButton,
  CpButtonIcon,
  CpInput,
  CpPagination,
  CpDatePicker,
  CpDropdown,
  CpButtonGroup,
  CpRadio,
  CpColorPicker,
  CpEditor,
  CpCheckBox,
} from "../../components";
import { Dropdown } from "primereact/dropdown";

const dataDropdown = [
  { name: "선택", code: "ALL" },
  { name: "아이디", code: "OT1" },
  { name: "제목", code: "OT2" },
];
const dataButtonGroup = [
  { name: "2. 가상계좌발급 (구리가상)", code: "0" },
  { name: "3.  ★★★ 평생 고정 계좌 안내 입니다 ★★★", code: "1" },
  { name: "1. 입금통장문의 (종이장라인)", code: "2" },
  { name: "★★★  계좌 1원 인증번호  ★★★", code: "2" },
  { name: "★★★  인증 시간 초과시 답변  ★★★", code: "2" },
  { name: "▶▶▶  평생 계좌 입금 안된다고하면 답변   ◀◀◀", code: "2" },
  { name: "확인후 처리완료", code: "2" },
  { name: "환전 마감 안내 ", code: "2" },
  { name: "충전 마감 안내 ", code: "2" },
  { name: "포인트관련문의", code: "2" },
  { name: "은행 점검 시간안내", code: "2" },
  { name: "환전 지연시", code: "2" },
];
const dataRadioBox = [
  { id: 0, value: "0", label: "숨김" },
  { id: 1, value: "1", label: "보기" },
];
const dataRadioTitleBox = [
  { id: 0, value: "0", label: "모바일 매치 상단 광고" },
  { id: 1, value: "1", label: "모바일 왼쪽 광고" },
];

const Advertisement = () => {
  const [dropdownValue, setDropdownValue] = useState({
    name: "선택",
    code: "ALL",
  });
  const [title, setTitle] = useState("");
  const [colorTitle, setColorTitle] = useState("1975d2");
  const [radioValueTitle, setRadioValueTitle] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [data, setData] = useState([{}, {}, {}, {}]);
  const navigate = useNavigate();

  return (
    <div className="prid advertisement">
      <div className="col-12 p-3 card mb-4 header md:p-4">
        <div className="col-12 mb-1 p-0" style={{ fontSize: "16px" }}>
          광고 공지
        </div>
      </div>
      <div className="p-0 mt-4 col-12 flex flex-wrap content">
        <div className="left col-12 md:col-4 p-2">
          <div className="card">
            <div className="pagi-box">
              <CpPagination onChange={(e) => console.log(e)} total={0} />
            </div>
            <div className="content-left p-0 mt-4 mb-4">
              <div className="header-left">
                <div className="item">타이틀</div>
                <div className="item">보기/숨김</div>
                <div className="item icon"></div>
              </div>
              <div className="infor-box">
                {data.map((v, i) => (
                  <div className="item-box" key={i}>
                    <div className="item">
                      <div>낭만본사</div>
                    </div>
                    <div className="item">
                      <div>낭만본사</div>
                    </div>
                    <div className="item icon">
                      <CpButtonIcon
                        icon="pi pi-pencil"
                        severity=""
                        aria-label=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="pagi-box">
              <CpPagination onChange={(e) => console.log(e)} total={0} />
            </div>
          </div>
        </div>
        <div className="right col-12 md:col-8 p-2">
          <div className="card ">
            <div className="p-0 title mt-0 mr-0">
              <CpInput
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e)}
              />
            </div>
            <div className="p-0 title mt-3 mr-0">
              <CpInput
                placeholder="순서"
                value={title}
                onChange={(e) => setTitle(e)}
              />
            </div>
            <div className="select-content mt-3">
              <CpRadio
                radioValue={radioValueTitle}
                onChange={(e) => setRadioValueTitle(e)}
                dataRadio={dataRadioTitleBox}
              />
            </div>
            <div className="edi mt-2">
              <CpEditor />
            </div>
            <div className="select-content mt-3">
              <CpRadio
                radioValue={radioValue}
                onChange={(e) => setRadioValue(e)}
                dataRadio={dataRadioBox}
              />
            </div>
            <div className="bt mb-3">
              <CpButton label="저장 하기" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;
