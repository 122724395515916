import React, { useEffect, useState } from "react";
import { RadioButton } from "primereact/radiobutton";

// import "./SCSS/index.scss";

const CpRadio = (props) => {
  const { radioValue = null, onChange, dataRadio = [] } = props;

  return (
    <div className="cp-radio">
      <div className="grid col-12 gap-5">
        {dataRadio &&
          dataRadio.map((v, i) => (
            <div className="item" key={i}>
              <div className="field-radiobutton">
                <RadioButton
                  inputId="v.id"
                  name="option"
                  value={v.value}
                  checked={radioValue === v.value}
                  onChange={(e) => onChange?.(e.value)}
                />
                <label htmlFor="option1">{v.label}</label>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CpRadio;
