import { mainAxios } from '../libs/axios';
const accessToken = localStorage.getItem('User');
const jsonToken = JSON.parse(accessToken);
const token = `Bearer ${jsonToken}`;
export default class NoteServicesApi {
    noteEventListAPI(params) {
        return mainAxios.request({
            methodType: 'GET',
            url: `/board?mode=note_event&board=board02`,
            params: params,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: 'application/json',
                },
            },
        });
    }
    noteEventActionAPI(params) {
        return mainAxios.request({
            methodType: 'POST',
            url: `/board?mode=note_event&board=board02`,
            payload: params,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: 'application/json',
                },
            },
        });
    }
    noteEventDetailAPI(params) {
        return mainAxios.request({
            methodType: 'GET',
            url: `/board${params}`,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: 'application/json',
                },
            },
        });
    }
    noteEventSubmitEditAPI(params, payload) {
        return mainAxios.request({
            methodType: 'POST',
            url: `/${params}`,
            payload: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: 'application/json',
                },
            },
        });
    }
    noteEventDeleteAPI(params, payload) {
        return mainAxios.request({
            methodType: 'POST',
            url: `/${params}`,
            payload: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: 'application/json',
                },
            },
        });
    }
}
