import { mainAxios } from "../libs/axios";
const accessToken = localStorage.getItem('User')
const jsonToken = JSON.parse(accessToken)
const token = `Bearer ${jsonToken}`
export default class CalculateService {
    calculateList(payload) {
        return mainAxios.request({
            methodType: "GET",
            url: `/money?mode=calculate_main_agent`,
            params: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    calculateDetailAgent(params){
        return mainAxios.request({
            methodType: "GET",
            url: `/money?mode=calculate_sub_agent`,
            params: params,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    calculateAgent(params){
        return mainAxios.request({
            methodType: "GET",
            url: `/money?mode=calculate_agent`,
            params: params,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    getSettlementHistory(params){
        return mainAxios.request({
            methodType: "GET",
            url: `/money?mode=money_list_settlement`,
            params: params,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    actionSettlementAPI(params){
        return mainAxios.request({
            methodType: "POST",
            url: `/${params}`,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
}