import React, { useEffect, useRef, useState } from "react";
import './UserInfo.scss'
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import UserService from "../../../../service/UserService";
import { useParams } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

const UserInfoTab = (props) => {
    const initiaValue = props?.initialValue
    const [statusMember, setStatusMember] = useState();
    const [colorName, setColorName] = useState();
    const [dataUpdate, setDataUpdate] = useState();
    const [banStatus, setBanStatus] = useState({
        ps: true,
        q: true,
        w: true,
        c3: true,
        c5: true,
        m3: true,
        m5: true,
        s3: true,
        s5: true,
        b: true,
        c: true,
        s: true,
        cxlive: true,
        '0': true,
        cxrng: true,
    });
    const userList = new UserService()
    const params = useParams();
    const toast = useRef(null)
    const fetchBasicDataUser = (value) => {
        const payload = {
            'id': params?.id,
            'submit': "1",
            ...value
        }
        userList.getBasicData(payload)
            .then((data) => {
                console.log(data.message)
                if(data.code === 200){
                    toast.current.show({ severity: 'success', summary: '성공', detail: '정보가 업데이트 돠었습니다', life: 3000 });
                }
                else{
                    toast.current.show({ severity: 'error', summary: '성공', detail: data.message, life: 3000 }); 
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    function getBanList() {
        var newBanStatus = { ...banStatus }

        initiaValue?.mb_bet_block?.value?.map((v) => {
            return newBanStatus[v] = false
        });
        setBanStatus(newBanStatus)
    }
    console.log(initiaValue)
    useEffect(() => {
        setStatusMember(props?.status)
        setColorName(props?.color)
        setDataUpdate({
            MemberLevel: initiaValue?.MemberLevel?.value,
            Usercredit: initiaValue?.Usercredit?.value,
            apiKey: initiaValue?.apiKey?.value,
            level_sub: initiaValue?.level_sub?.value,
            level_top: initiaValue?.level_top?.value,
            log_before: initiaValue?.log_before?.value,
            mb_bet_block: initiaValue?.mb_bet_block?.value,
            mb_check_type: initiaValue?.mb_check_type?.value,
            mb_code: initiaValue?.mb_code?.value,
            mb_color: initiaValue?.mb_color?.value,
            mb_exch_pw: initiaValue?.mb_exch_pw?.value,
            mb_hp: initiaValue?.mb_hp?.value,
            mb_id: initiaValue?.mb_id?.value,
            mb_level: initiaValue?.mb_level?.value,
            mb_level_origin: initiaValue?.mb_level_origin?.value,
            mb_max_payment: initiaValue?.mb_max_payment?.value,
            mb_max_payment_cnt: initiaValue?.mb_max_payment_cnt?.value,
            mb_name: initiaValue?.mb_name?.value,
            mb_no: initiaValue?.mb_no?.value,
            cxlive: {rate_1: initiaValue?.cxlive?.rate_1},
            cxrng: {rate_1: initiaValue?.cxrng?.rate_1},
            mb_old_code: initiaValue?.mb_old_code?.value,
            mb_password: initiaValue?.mb_password?.value,
            mb_payment_one_max: initiaValue?.mb_payment_one_max?.value,
            mb_status: initiaValue?.mb_status?.value,
            member: initiaValue?.member?.value,
            mb_blacklist: initiaValue?.member_in?.item?.mb_blacklist?.value,
            mb_memo: initiaValue?.member_in?.item?.mb_memo?.value,
            admin_memo: initiaValue?.note?.items?.admin_memo?.value,
            changemm: initiaValue?.note?.items?.changemm?.value,
            mb_bank_name: initiaValue?.mb_bank?.options?.mb_bank_name?.value,
            mb_bank_own: initiaValue?.mb_bank?.options?.mb_bank_own?.value,
            mb_bank_no: initiaValue?.mb_bank?.options?.mb_bank_no?.value,
            user_options: initiaValue?.user_options?.value,
        })
        getBanList()
    }, [props])
    useEffect(() => {

    }, [banStatus, dataUpdate])
    const handleChange = (e, key) => {
        const newDataUpdate = { ...dataUpdate };
        newDataUpdate[key] = e;
        setDataUpdate(newDataUpdate);
        if(key ==='slot'){
            newDataUpdate.cxrng.rate_1 = e
            setDataUpdate(newDataUpdate);
        }
        if(key ==='casino'){
            newDataUpdate.cxlive.rate_1 = e
            setDataUpdate(newDataUpdate);
        }
        if (key.includes('mb_bet_block')) {
            const newArray = Object.keys(e).filter((key) => e[key] === false);
            newDataUpdate[key] = newArray
            setDataUpdate(newDataUpdate)
        }
        else{
            setDataUpdate(newDataUpdate)

        }
    }

    const actionSubmit = () => {
        console.log(dataUpdate)
        fetchBasicDataUser(dataUpdate)
    }

    const handleBanChange = (i, value, key) => {
        const newBanStatus = { ...banStatus };
        newBanStatus[i] = value;
        setBanStatus(newBanStatus);
        handleChange(newBanStatus, key)
    };
    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast}/>
            <div className="user-info-body">
                {props.data.map((value, index) => {
                    return (
                        <>
                            {value.type === 'input' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        <InputText defaultValue={value?.data} disabled={value.key === 'mb_rec_id' ? true : false} placeholder={value.key === 'mb_rec_id' ? '추천아이디' : ''} onChange={(e) => handleChange(e.target.value, value.key )} 
                                        type={value.key==='slot'|| value.key==='casino'? 'number': 'text'}/>
                                    </div>
                                </div>
                            }
                            {value.type === 'input-name' &&
                                <div className="row">
                                    <div className="title" >
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        <InputText defaultValue={value.data} style={{ color: colorName?.includes('#') ? colorName : `#${colorName}` }} onChange={(e) => handleChange(e.target.value, value.key)} />
                                    </div>
                                </div>
                            }
                            {value.type === 'input-button' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data button-color-group">
                                        <InputText value={colorName} style={{ color: colorName }} />
                                        <Button style={{ backgroundColor: '#007bff' }} onClick={() => { setColorName('#007bff'); handleChange('#007bff', value.key) }}>#007bff</Button>
                                        <Button style={{ backgroundColor: '#28a745' }} onClick={() => { setColorName('#28a745'); handleChange('#28a745', value.key) }}>#28a745</Button>
                                        <Button style={{ backgroundColor: '#fd7e14' }} onClick={() => { setColorName('#fd7e14'); handleChange('#fd7e14', value.key) }}>#fd7e14</Button>
                                        <Button style={{ backgroundColor: '#dc3545' }} onClick={() => { setColorName('#dc3545'); handleChange('#dc3545', value.key) }}>#dc3545</Button>
                                        <Button style={{ backgroundColor: '#000000' }} onClick={() => { setColorName('#000000'); handleChange('#000000', value.key) }}>#000000</Button>
                                    </div>
                                </div>
                            }
                            {value.type === 'text' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        {value.data}
                                    </div>
                                </div>
                            }
                            {value.type === 'radius' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" name="active" value="2" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '2'} />
                                            <label htmlFor={statusMember} className="ml-2">사용중</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient2" name="banned" value="0" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '0'} />
                                            <label htmlFor={statusMember} className="ml-2">사용정지</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient3" name="removed" value="3" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '3'} />
                                            <label htmlFor={statusMember} className="ml-2">졸업</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient4" name="pending" value="1" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '1'} />
                                            <label htmlFor={statusMember} className="ml-2">사용대기</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient4" name="pending" value="4" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '4'} />
                                            <label htmlFor={statusMember} className="ml-2">보류</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {value.type === 'text-tree' &&
                                <div className="row-tree">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="tree">

                                    {value?.data?.map((i, index) => {
                                        const color = [
                                            "#ff5722",
                                            "#2196f3",
                                            "#ffc107",
                                            "#4caf50",
                                            "#4eff14",
                                            "#d92e11",
                                        ];
                                        const bgcolor = [
                                            "#ff5722",
                                            "#000",
                                            "#4caf50",
                                            "#ff7214",
                                            "#4eff14",
                                            "#d92e11",
                                        ];
                                        return (
                                            <div className="agent-tree" key={index}>
                                                {i.before && (
                                                    <span
                                                        className="box-agentBalance"
                                                        style={{ backgroundColor: bgcolor[index] ?? "#d92e11" }}
                                                    >
                                                        {i?.before}
                                                    </span>
                                                )}{" "}
                                                <span style={{ color: color[index] ?? "#d92e11" }}>
                                                    {i?.value}
                                                </span>{" "}
                                                <span style={{ color: "#000", fontWeight: "700" }}>
                                                    {i?.after}
                                                </span>{" "}
                                            </div>
                                        );
                                    })}
                                    </div>
                                </div>
                            }
                            {value.type === 'text-tree-agent' &&
                                <div className="row-tree">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="tree">
                                    {value?.data?.map((i, index) => {
                                        const color = [
                                            "#ff5722",
                                            "#2196f3",
                                            "#ffc107",
                                            "#4caf50",
                                            "#4eff14",
                                            "#d92e11",
                                        ];
                                        const bgcolor = [
                                            "#ff5722",
                                            "#000",
                                            "#4caf50",
                                            "#ff7214",
                                            "#4eff14",
                                            "#d92e11",
                                        ];
                                        return (
                                            <div className="agent-tree" key={index}>
                                                {i.before && (
                                                    <span
                                                        className="box-agentBalance"
                                                        style={{ backgroundColor: bgcolor[index] ?? "#d92e11" }}
                                                    >
                                                        {i?.before}
                                                    </span>
                                                )}{" "}
                                                <span style={{ color: color[index] ?? "#d92e11" }}>
                                                    {i?.value}
                                                </span>{" "}
                                                <span style={{ color: "#000", fontWeight: "700" }}>
                                                {index < 5 ? i?.after : ''}
                                                </span>{" "}
                                            </div>
                                        );
                                    })}
                                    </div>
                                </div>
                            }
                            {value.type === 'radius-group' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        <div className="group-radius">
                                            {value?.data && Object.keys(value?.data)?.map((v, i) => {
                                                return (
                                                    <div className="group-casino" key={v}>
                                                        <div className="flex align-items-center div-space">
                                                            <RadioButton
                                                                inputId={`ingredient-${v}-true`}
                                                                name={`casino-ban-${v}`}
                                                                value="true"
                                                                onChange={(e) => { handleBanChange(v, true, value.key) }}
                                                                checked={banStatus[v] === true}
                                                            />
                                                            <label htmlFor={`ingredient-${v}-true`} className="ml-2">{value?.data[v]} 사용</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <RadioButton
                                                                inputId={`ingredient-${v}-false`}
                                                                name={`casino-ban-${v}`}
                                                                value="false"
                                                                onChange={(e) => { handleBanChange(v, false, value.key) }}
                                                                checked={banStatus[v] === false}
                                                            />
                                                            <label htmlFor={`ingredient-${v}-false`} className="ml-2">{value?.data[v]} 미사용</label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                            }

                        </>
                    )
                })}
                <Button className="btn-submit" onClick={() => props.action(actionSubmit)}>저장하기</Button>
            </div>
        </>

    )
}
export default UserInfoTab