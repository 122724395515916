import React, { useState } from "react";
import {
  CpButton,
  CpInput,
  CpDropdown,
  CpCheckBox,
  CpRadio,
  CpTabView,
  CpAutoComplete,
  CpColorPicker,
  CpPagination,
  CpEditor,
  CpDatePicker,
  CpButtonGroup,
  CpButtonGroupActive,
} from "../components";

const dataRadioBox = [
  { id: 0, value: "ha noi", label: "Hà Nội" },
  { id: 1, value: "da nang", label: "Đà Nẵng" },
  { id: 2, value: "hue", label: "Huế" },
  { id: 3, value: "ho chi minh", label: "TP Hồ Chí Minh" },
];
const dataDropdown = [
  { name: "선택", code: "NY" },
  { name: "에이전트", code: "RM" },
  { name: "생성일", code: "LDN" },
  { name: "수정", code: "IST" },
  { name: "에이전트", code: "PRS" },
];

const dataTabs = [
  { icon: "pi-check", id: 0, value: "ha noi", label: "Hà Nội" },
  { icon: "pi-check", id: 1, value: "fdas fdas", label: "Đà Nẵng" },
  { icon: "pi-check", id: 2, value: "da fdas", label: "Huế" },
  { icon: "pi-check", id: 3, value: "f fd", label: "TP Hồ Chí Minh" },
];

const filtValueAutoComplete = [
  { name: "1234", code: "NY" },
  { name: "2345", code: "RM" },
  { name: "3456", code: "LDN" },
  { name: "467654", code: "IST" },
  { name: "57923", code: "PRS" },
];

const dataButtonGroup = [
  { name: "선택", code: "NY" },
  { name: "에이전트", code: "RM" },
  { name: "생성일", code: "LDN" },
  { name: "수정", code: "IST" },
  { name: "에이전트", code: "PRS" },
];

const Test = () => {
  const [check, setCheck] = useState(false);
  const [radioValue, setRadioValue] = useState("hue");
  const [dropdownValue, setDropdownValue] = useState("hue");

  return (
    <div className="test">
      test<div>fdsafdas</div>
      <div>
        <CpButton label="검색" onClick={() => alert("test Button")} />
        <br />
        <CpAutoComplete
          defaulValue=""
          filtValue={filtValueAutoComplete}
          onChange={(e) => console.log(e)}
        />
        <br />
        <CpInput />
        <br />
        <CpPagination
          defaulPage={1}
          defaulRows={20}
          total={160}
          onChange={(e) => console.log(e)}
        />
        <CpPagination onChange={(e) => console.log(e)} total={120} />
        <br />
        <CpButtonGroup
          dataGroup={dataButtonGroup}
          onChange={(e) => console.log(e)}
        />
        <CpButtonGroupActive dataGroup={dataButtonGroup} />
        <br />
        <CpDatePicker />
        <br />
        <CpEditor />
        <br />
        <CpColorPicker />
        <br />
        <CpCheckBox
          checked={check}
          onChange={() => setCheck(!check)}
          label="test"
        />
        <br />
        <CpRadio
          radioValue={radioValue}
          onChange={(e) => setRadioValue(e)}
          dataRadio={dataRadioBox}
        />
        <br />
        <CpDropdown
          style={{ minWidth: "100px" }}
          value={dropdownValue}
          options={dataDropdown}
          onChange={(e) => setDropdownValue(e)}
          placeholder="test"
        />
        <br />
        <CpTabView
          dataTab={dataTabs}
          defaulActive="da fdas"
          onChange={(e) => console.log(e)}
        />
      </div>
    </div>
  );
};

export default Test;
