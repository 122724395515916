// import { notification } from 'antd' //message,
import axios from "axios";
import { createBrowserHistory } from "history";
// import dataCode from 'common/constants/codeError.json'

const history = createBrowserHistory();
// const { REACT_APP_API_SERVER } = process.env;

export const Axios = axios.create({
  // baseURL: REACT_APP_API_SERVER,
  baseURL: "https://api.bracesunny.com/api/operator/v1",
});

// eslint-disable-next-line no-underscore-dangle
const _axios = axios.create({
  // baseURL: REACT_APP_API_SERVER,
  baseURL: "https://api.bracesunny.com/api/operator/v1",
});

const mainAxios = {
  request: async (parameter) => {
    const {
      methodType,
      url,
      payload,
      requiresToken,
      config,
      notError,
      getError,
      params,
    } = parameter;
    const access = await localStorage.getItem("access");

    return new Promise((resolve, reject) => {
      // axios request default options
      const headers = config && config.headers ? config.headers : {};

      if (headers.contentType) {
        headers["Content-Type"] = headers.contentType;
        delete headers.contentType;
      } else {
        headers["Content-Type"] = "application/json";
      }

      // if API endpoint requires a token
      if (requiresToken) {
        const acToken = JSON.parse(access);

        if (!access) {
          localStorage.removeItem("User");
          localStorage.removeItem("Popup");
          window.location.href = "/#/login";
        }
        // if (acToken?.value) acToken = acToken.value;
        if (acToken) headers["Authorization"] = `Bearer ${acToken}`;
      }
      _axios
        .request({
          url,
          method: methodType,
          data: payload,
          headers,
          params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          if (err.response) {
            // console.log("fdsaf==>", err.response.status);
            if (err.response.status === 401) {
              // let acToken = getAlls(TABLE_USER);
              // if (acToken?.accessToken) {
              //   clear(TABLE_USER);
              //   window.location.reload();
              // }

              localStorage.removeItem("User");
              localStorage.removeItem("Popup");
              localStorage.removeItem("access");

              // notification.open({
              //   message: "token expires",
              //   placement: "bottomRight",
              // });

              window.location.reload();
            } else {
              if (!notError) {
                // const data = dataCode
                // const errData = err.response.data
                // message.info(data[errData.errorCode?.trim()]);
                // notification.open({
                //   message: data[errData.errorCode?.trim()] + '.',
                //   placement: 'bottomRight'
                // })
                resolve({ data: { datas: [] } });
              } else {
                if (getError) {
                  resolve({ data: { datas: [] }, error: err.response.data });
                }
              }
            }
          }
          reject(err);
        });
    });
  },

  getRequest: async function (parameter) {
    parameter.methodType = "GET";
    return this.request(parameter);
  },

  postRequest: async function (parameter) {
    parameter.methodType = "POST";
    return this.request(parameter);
  },

  putRequest: async function (parameter) {
    parameter.methodType = "PUT";
    return this.request(parameter);
  },

  patchRequest: async function (parameter) {
    parameter.methodType = "PATCH";
    return this.request(parameter);
  },

  deleteRequest: async function (parameter) {
    parameter.methodType = "DELETE";
    return this.request(parameter);
  },
};

export { mainAxios };
