import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import './SettlementHistory.scss'
import CustomDataTable from "../../../../components/Table/TableUser";
import { addLocale } from "primereact/api";
import UserService from "../../../../service/UserService";
import { useParams } from "react-router-dom";
import { SelectButton } from "primereact/selectbutton";
const SettlementHistoryTab = () => {
    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }
      const today = convertTZ(new Date(), "Asia/Seoul")
      const pass = convertTZ(new Date(), "Asia/Seoul")
      pass.setDate(today.getDate() -1)
    const buttonFilter = [
        { button: '입출금/통계', value: 'all'},
        { button: 'casino', value: 'casino' },
        { button: 'slot', value: 'slot' },
    ]
    const [startDate, setStartDate] = useState(pass);
    const [endDate, setEndDate] = useState(today);
    const [dataTotal, setDataTotal] = useState(null);
    const [data, setData] = useState(null)
    const [dataDate, setDataDate] = useState(null)
    const useList = new UserService()
    const [filterCondition, setFilterCondition] = useState(buttonFilter[0])
    const [condition, setCondition] = useState('all')
    const [limit, setLimit] = useState(1)
    const [pastDate, setPastDate] = useState('2023-1-1')
    const [headerTotalTable , setHeaderTotalTable] = useState([
        { field: "date_all", label: `날짜 토탈`, sortAble: false },
        { field: "deposit", label: "충전(회원/건수/합계)", sortAble: false },
        { field: "withdraw", label: "환전(회원/건수/합계)", sortAble: false },
        { field: "sum", label: "합 충전-환전", sortAble: false },
        { field: "betting", label: "배팅(회원/건수/합계)", sortAble: false },
        { field: 'reward_win', label: "당첨(회원/건수/합계)", sortAble: false },
        { field: 'betting_win', label: "배팅 -당첨", sortAble: false },
    ])
    const [headerDateTable, setHeaderDateTable] = useState([
        { field: "day_month", label: `날짜`, sortAble: false },
        { field: "deposit", label: "충전(회원/건수/합계)", sortAble: false },
        { field: "withdraw", label: "환전(회원/건수/합계)", sortAble: false },
        { field: "sum", label: "충전 /환전", sortAble: false },
        { field: "betting", label: "배팅(회원/건수/합계)", sortAble: false },
        { field: 'reward_win', label: "당첨(회원/건수/합계)", sortAble: false },
        { field: 'betting_win', label: "배팅 - 환전", sortAble: false },
    ])
    const params = useParams()
    function getLimit(sdate, edate){
        const StartDate = new Date(sdate);
        const EndDate = new Date(edate);
        const timeDifference = EndDate.getTime() - StartDate.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        setLimit(daysDifference)        
    }
    function fetchSettlementHis(condition) {
        if(limit >90){
            alert('날짜 범위는 90일을 초과할 수 없습니다.')
        }
        else{

        const payload = {
            id: params.id,
            sdate: startDate? formatDate(startDate):pastDate, //formatDate(startDate)
            limit: limit,
            ...condition
        }
        useList.fetchUserSettlementHis(payload)
            .then((data) => {
                const res = data?.data
                setData(res)
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
    function formatDate(date) {
        var yyyy = date?.getFullYear();
        var mm = String(date?.getMonth() + 1).padStart(2, '0');
        var dd = String(date?.getDate()).padStart(2, '0');
        return yyyy + '-' + mm + '-' + dd
    }
    addLocale('ko', {
        firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
    useEffect(() => {
        if(condition ==='all'){
            fetchSettlementHis()
        }
    }, [ condition, pastDate])
    useEffect(()=>{
        if(startDate){
            getLimit(startDate, endDate)
        }
        if(endDate !== today && !startDate && endDate){
            var currentDate = new Date();
            currentDate.setDate(endDate.getDate() - 365);
            var pass = currentDate.toISOString().split('T')[0];
            setPastDate(pass)
            getLimit(pass, endDate)
        }
    },[endDate, startDate])
    useEffect(() => {
        if (data?.length === 0 && condition ==='all') {
            setDataTotal([
                {date_all:'토탈', deposit: '0/0/0', withdraw: '0/0/0', sum: '0',betting: '0/0/0', reward_win: '0/0/0', betting_win: '0', }]
            )
            
        }
        if (data?.length === 0 && condition !=='all') {
            setDataTotal([
                {date_all_casino:'토탈', betting_casino: '0/0/0', reward_win_casino: '0/0/0',success_casino: '0/0/0', sum_casino: '0'}]
            )
            
        }
        
        else{
            setDataTotal([
                {date_all:'', deposit: '', withdraw: '', sum: '',betting: '', reward_win: '', betting_win: '' }]
            )
            if(condition !=='all'){
                setDataTotal([
                    {date_all_casino:'', betting_casino: '', reward_win_casino: '',success_casino: '', sum_casino: '' }
                ]
                )
            }
        }
    }, [data])
    function formatMoney(value) {
        return new Intl.NumberFormat('ko-KR').format(value);
    }
    function getSum(value, type, number, element) {
        var arr = [];
        if(value){
            if(element !== 0){
                for (let i = 0; i < value?.length; i++) {
                    if (value[i] && value[i][type] && value[i][type][element] && value[i][type][element][number]) {
                        arr.push(parseInt(value[i][type][element][number]));
                    }
                }
            }
            if(number === 0){
                for (let i = 0; i < value?.length; i++) {
                    if (value[i] && value[i][type] ) {
                        arr.push(parseInt(value[i][type]));
                    }
                }
            }
            else{
                for (let i = 0; i < value?.length; i++) {
                    if (value[i] && value[i][type] && value[i][type][number]) {
                        arr.push(parseInt(value[i][type][number]));
                    }
                }
            }   
        }
        var sum = arr.reduce((a, b) => a + parseInt(b), 0)
        return sum
    }

    const bodyTemplateTotal = (dataAll, props) => {
        return (
            <>
                { condition ==='all' &&
                    <>
                        {props.field === 'reward_win' &&
                            <>
                                <span>{getSum(data, 'win', 'member',0)}/ {getSum(data, 'win', 'count',0)}/ <span style={{ color: 'red' }}>{formatMoney(getSum(data, 'win', 'total',0))}</span></span>
                            </>
                        }
                        {props.field === 'betting' &&
                            <>
                                <span>{getSum(data, 'bet', 'member',0)}/ {getSum(data, 'bet', 'count',0)}/ <span style={{ color: 'red' }}>{formatMoney(getSum(data, 'bet', 'total',0))}</span></span>
                            </>
                        }
                        {props.field === 'betting_win' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span style={{ color: 'blue' }}>{formatMoney(getSum(data, 'bet', 'total',0) - getSum(data, 'win', 'total',0))}</span>
                            </>
                        }
                        {props.field === 'date_all' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span>토탈</span>
                            </>
                        }
                        {props.field === 'deposit' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span>{getSum(data, 'charge_exchange', 'member','charge')}/ {getSum(data, 'charge_exchange', 'count', 'charge')}/ <span style={{ color: 'red' }}>{formatMoney(getSum(data, 'charge_exchange', 'total','charge'))}</span></span>
                            </>
                        }
                        {props.field === 'withdraw' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span>{getSum(data, 'charge_exchange', 'member', 'exchange')}/ {getSum(data, 'charge_exchange', 'count', 'exchange')}/ <span style={{ color: 'red' }}>{formatMoney(getSum(data, 'charge_exchange', 'total', 'exchange'))}</span></span>
                            </>
                        }
                        {props.field === 'sum' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span style={{ color: 'blue' }}>{formatMoney(getSum(data, 'charge_exchange', 'total', 'charge') - getSum(data, 'charge_exchange', 'total', 'exchange'))}</span>
                            </>
                        }

                        <span className="p-column-title">{props.header}</span>
                        {dataAll[props.field]}

                    </>
                }
                {data?.length !== 0 && condition !=='all' &&
                    <>
                        {props.field === 'reward_win_casino' &&
                            <>
                                <span>{getSum(data, 'win', 'member', 0)}/ {getSum(data, 'win', 'count', 0)}/ <span style={{ color: 'red' }}>{formatMoney(getSum(data, 'win', 'total', 0))}</span></span>
                            </>
                        }
                        {props.field === 'betting_casino' &&
                            <>
                                <span>{getSum(data, 'bet', 'member', 0)}/ {getSum(data, 'bet', 'count', 0)}/ <span style={{ color: 'red' }}>{formatMoney(getSum(data, 'bet', 'total', 0))}</span></span>
                            </>
                        }
                        {props.field === 'success_casino' &&
                            <>
                                <span>{getSum(data, 'miss', 'member', 0)}/ {getSum(data, 'miss', 'count', 0)}/ <span style={{ color: 'red' }}>{formatMoney(getSum(data, 'miss', 'total', 0))}</span></span>
                            </>
                        }
                        {props.field === 'date_all_casino' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span>토탈</span>
                            </>
                        }
                        {props.field === 'sum_casino' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span style={{ color: 'blue' }}>{formatMoney(getSum(data, 'sub_win_miss', 0, 0))}</span>
                            </>
                        }
                    </>
                }
                <span className="p-column-title">{props.header}</span>
                        {dataAll[props.field]}
            </>
        )
    }
    const bodyTemplateDate = (dataDetail, props) => {
        return(
            <>  
                { condition ==='all'&& 
                    <>
                        {props.field === 'reward_win' &&
                            <>
                                <span>{dataDetail?.win?.member}/ {dataDetail?.win?.count}/ <span style={{ color: 'red' }}>{formatMoney(dataDetail?.win?.total)}</span></span>
                            </>
                        }
                        {props.field === 'betting' &&
                            <>
                                <span>{dataDetail?.bet?.member}/ {dataDetail?.bet?.count}/ <span style={{ color: 'red' }}>{formatMoney(dataDetail?.bet?.total)}</span></span>
                            </>
                        }
                        {props.field === 'betting_win' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span style={{ color: 'blue' }}>{formatMoney(dataDetail?.bet?.total - dataDetail?.win?.total)}</span>
                            </>
                        }
                        {props.field === 'day_month' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span>{dataDetail.date}</span>
                            </>
                        }
                        {props.field === 'deposit' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span>{dataDetail?.charge_exchange?.charge?.member}/ {dataDetail?.charge_exchange?.charge?.count}/ <span style={{ color: 'red' }}>{formatMoney(dataDetail?.charge_exchange?.charge?.total)}</span></span>
                            </>
                        }
                        {props.field === 'withdraw' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span>{dataDetail?.charge_exchange?.exchange?.member}/ {dataDetail?.charge_exchange?.exchange?.count}/ <span style={{ color: 'red' }}>{formatMoney(dataDetail?.charge_exchange?.exchange?.total)}</span></span>
                            </>
                        }
                        {props.field === 'sum' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span style={{ color: 'blue' }}>{formatMoney(dataDetail?.charge_exchange?.sub)}</span>
                            </>
                        }

                        <span className="p-column-title">{props.header}</span>
                        {dataDetail[props.field]}

                    </>
                }
                { condition !== 'all' && 
                    <>
                        {props.field === 'reward_win_casino' &&
                            <>
                                <span>{dataDetail?.win?.member}/ {dataDetail?.win?.count}/ <span style={{ color: 'red' }}>{formatMoney(dataDetail?.win?.total)}</span></span>
                            </>
                        }
                        {props.field === 'betting_casino' &&
                            <>
                                <span>{dataDetail?.bet?.member}/ {dataDetail?.bet?.count}/ <span style={{ color: 'red' }}>{formatMoney(dataDetail?.bet?.total)}</span></span>
                            </>
                        }
                        {props.field === 'betting_win_casino' &&
                            <>
                                <span>{dataDetail?.win?.member}/ {dataDetail?.win?.count}/ <span style={{ color: 'red' }}>{formatMoney(dataDetail?.win?.total)}</span></span>
                            </>
                        }
                        {props.field === 'day_month_casino' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span>{dataDetail.date}</span>
                            </>
                        }
                        {props.field === 'success_score_casino' &&
                            <>
                                 <span>{dataDetail?.miss?.member}/ {dataDetail?.miss?.count}/ <span style={{ color: 'red' }}>{formatMoney(dataDetail?.miss?.total)}</span></span>
                            </>
                        }
                        {props.field === 'process_casino' &&
                            <>
                                <span style={{color:'blue'}}>{dataDetail?.sub_win_miss}</span>
                            </>
                        }
                        {props.field === 'sum' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <span style={{ color: 'blue' }}>{formatMoney(dataDetail?.charge_exchange?.sub)}</span>
                            </>
                        }

                        <span className="p-column-title">{props.header}</span>
                        {dataDetail[props.field]}

                    </>
                }
                    
            </>
                    
        )
    }
    const handleButtonClick = () => {
        if(condition === 'all'){
            fetchSettlementHis()
        }
        if(condition !== 'all'){
            fetchSettlementHis({process: condition})
        }
        // const pass =  new Date(today)
        // pass.setDate(today.getDate() - params)
        // setStartDate(pass);
        // setEndDate(today);
    };
    const FilterGroup = () => {
        const handleChange = async(e) => {
            setCondition(e.value)
            setFilterCondition(e)
            const changeCondition = e.value
            if(changeCondition ==='all'){
                fetchSettlementHis()
                setHeaderTotalTable([
                    { field: "date_all", label: `날짜 토탈`, sortAble: false },
                    { field: "deposit", label: "충전(회원/건수/합계)", sortAble: false },
                    { field: "withdraw", label: "환전(회원/건수/합계)", sortAble: false },
                    { field: "sum", label: "합 충전-환전", sortAble: false },
                    { field: "betting", label: "배팅(회원/건수/합계)", sortAble: false },
                    { field: 'reward_win', label: "당첨(회원/건수/합계)", sortAble: false },
                    { field: 'betting_win', label: "배팅 -당첨", sortAble: false },
                ])
                setHeaderDateTable([
                    { field: "day_month", label: `날짜`, sortAble: false },
                    { field: "deposit", label: "충전(회원/건수/합계)", sortAble: false },
                    { field: "withdraw", label: "환전(회원/건수/합계)", sortAble: false },
                    { field: "sum", label: "충전 /환전", sortAble: false },
                    { field: "betting", label: "배팅(회원/건수/합계)", sortAble: false },
                    { field: 'reward_win', label: "당첨(회원/건수/합계)", sortAble: false },
                    { field: 'betting_win', label: "배팅 - 환전", sortAble: false },
                ])
            }
            if(changeCondition ==='casino'){
                setHeaderTotalTable([
                    { field: "date_all_casino", label: `날짜 토탈`, sortAble: false },
                    { field: "betting_casino", label: "배팅(회원/건수/합계)", sortAble: false },
                    { field: 'reward_win_casino', label: "당첨(회원/건수/합계)", sortAble: false },
                    { field: "success_casino", label: "낙점(회원/건수/합계)", sortAble: false },
                    { field: "sum_casino", label: "총수익금", sortAble: false },
                ])
                setHeaderDateTable([
                    { field: "day_month_casino", label: `날짜`, sortAble: false },
                    { field: "betting_casino", label: "배팅(회원/건수/합계)", sortAble: false },
                    { field: 'betting_win_casino', label: "당첨(회원/건수/합계)	", sortAble: false },
                    { field: "success_score_casino", label: "낙점(회원/건수/합계)", sortAble: false },
                    { field: "process_casino", label: "수익금", sortAble: false },
                ])
                fetchSettlementHis({process:'casino'})
            }
            if(changeCondition==='slot'){
                setHeaderTotalTable([
                    { field: "date_all_casino", label: `날짜 토탈`, sortAble: false },
                    { field: "betting_casino", label: "배팅(회원/건수/합계)", sortAble: false },
                    { field: 'reward_win_casino', label: "당첨(회원/건수/합계)", sortAble: false },
                    { field: "success_casino", label: "낙점(회원/건수/합계)", sortAble: false },
                    { field: "sum_casino", label: "총수익금", sortAble: false },
                ])
                setHeaderDateTable([
                    { field: "day_month_casino", label: `날짜`, sortAble: false },
                    { field: "betting_casino", label: "배팅(회원/건수/합계)", sortAble: false },
                    { field: 'betting_win_casino', label: "당첨(회원/건수/합계)	", sortAble: false },
                    { field: "success_score_casino", label: "낙점(회원/건수/합계)", sortAble: false },
                    { field: "process_casino", label: "수익금", sortAble: false },
                ])
                fetchSettlementHis({process:'slot'})
            }
        }
        const justifyTemplate = (option) => {
            return <span>{option.button}</span>;
        }
            return (
                <SelectButton value={filterCondition.value} onChange={(e) => handleChange(e)} itemTemplate={justifyTemplate} options={buttonFilter} />
            )
    }
    return (
        <div className="settlement-tab">
            <div className="card">
                <div className="title">
                    <span>
                        정산 기록
                    </span>
                </div>
                <div className="mt-5">
                    <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                    <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                    <Button label="확 인" className="ml-2 mr-5" onClick={handleButtonClick}/>
                </div>
                <div className="button-group-filter mt-3">
                        <FilterGroup />
                </div>
            </div>
            <div className="card">
                <div className="data-all">
                    <CustomDataTable data={dataTotal} headers={headerTotalTable} bodyTemplate={bodyTemplateTotal} onShowButton={false} scrollable={true} />
                </div>
                <div>
                    <CustomDataTable data={data} headers={headerDateTable} bodyTemplate={bodyTemplateDate} onShowButton={false} scrollable={true} />
                </div>
            </div>
        </div>
    )
}
export default SettlementHistoryTab;