import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";

// import "./SCSS/index.scss";

const CpDropdown = (props) => {
  const { value = null, onChange, options = [], placeholder } = props;
  const [dropdownValue, setDropdownValue] = useState(null);

  useEffect(() => {
    if (value) {
      setDropdownValue(value);
    }
  }, [value]);
  
  return (
    <div className="cp-dropdown">
      <Dropdown
        value={dropdownValue}
        onChange={(e) => onChange(e.value)}
        options={options}
        optionLabel="name"
        placeholder={placeholder}
      />
      {/* <Dropdown /> */}
    </div>
  );
};

export default CpDropdown;
