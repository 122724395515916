import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";

const CpTabView = (props) => {
  const { defaulActive = 0, dataTab = [], onChange } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    if (defaulActive) {
      setActiveIndex(
        dataTab.indexOf(dataTab.filter((e) => e.value === defaulActive)[0])
      );
    }
  }, []);
  const onChangeTab = (e) => {
    setActiveIndex(e);
    // console.log(dataTab[e].value);
    // console.log(dataTab[e]);
    onChange?.(dataTab[e]);
  };
  return (
    <div className="cp-tab-view">
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => onChangeTab(e.index)}
      >
        {dataTab.map((v, i) => (
          <TabPanel
            header={v.label}
            leftIcon={`pi mr-2 ${v.icon}`}
            key={i}
          ></TabPanel>
        ))}
      </TabView>
    </div>
  );
};

export default CpTabView;
