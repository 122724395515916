import React, { useEffect, useState, useRef } from "react";

import AdminService from "../../service/AdminServices";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../components/Table/TableUser";
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";


const HelpTemplate = () => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState();
  const [totalRecords, setTotalRecords] = useState(0)
  const [first, setFirst] = useState(0);
  const [bodyContent, setBodyContent] = useState('')
  const [title, setTitle] = useState('')
  const [saveBtnUrl, setSaveBtnUrl] = useState('')
  const [delBtnUrl, setDelBtnUrl] = useState('')
  const toast = useRef(null);

  const header = [
    { field: "msg_subjects", label: `타이틀`, sortAble: false },
    { field: "status", label: "상태", sortAble: false },
    { field: "btn_action", label: "", sortAble: false },
  ];
  const noteService = new AdminService();
 
  const fetchNote = () => {
    var newPayload = {
      page: page
    }
    noteService.getHelpTemplate(newPayload)
      .then((data) => {
        const res = data.list
        setData(res.data)
        setTotalRecords(res.total)
      })
      .catch((err) => { console.log(err) })
  }
  useEffect(() => {
    fetchNote()
  }, [page])
  useEffect(() => {
  }, [ bodyContent])
  
  const onPageChange = (event) => {
    setPage(event.page + 1)
    setFirst(event.first);
  };
  
  const bodyTemplate = (data, props) => {
    function onHandleEdit(data) {
      const url = new URL(data[1][0]?.base)
      const queryString  = url.search
      const delUrl = new URL(data[1][1]?.base)
      const queryDelString = delUrl.search
      setSaveBtnUrl(`board${queryString}`)
      setDelBtnUrl(`board${queryDelString}`)
      noteService.actionHelpTemplate(`board${queryString}`)
        .then((data)=>{
          const res = data.data.form
          setTitle(res.subject.value)
          setBodyContent(res.contents.value)
        })
        .catch((err)=>{
          console.log(err)
        })
    }
    return (
      <>
        {props.field === 'btn_action' &&
          <>
            <div className="icon-btn" style={{ boxShadow: '0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16)', cursor:'pointer' }}
              onClick={() => onHandleEdit(data)}
            >
              <span ><i style={{ color: 'white' }} className="pi-file-edit pi" /></span>
            </div>
          </>
        }
        {props.field === 'msg_subjects' &&
          <>
            <span style={{ whiteSpace: 'break-spaces' }}>{data[0]}</span>
          </>
        }
        {props.field === 'status' &&
          <>
            <span > </span>
          </>
        }
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    )
  }
  const changeEditor = (e) => {
    setBodyContent(e)
  }
  const confirm1 = () => {
    confirmDialog({
      message: "고객센터 작성/수정 성공.",
      header: "고객센터",
      accept: action,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
    });
  };

  const deleteMessage = () => {
    confirmDialog({
      message: "메세지 삭제하시겠습니까?",
      header: "고객센터",
      accept: deleteMessageAction,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
    });
  }
  
  const deleteMessageAction = () => {
    const payload ={
      "submit": 1, 
    }
    noteService.actionHelpTemplate(delBtnUrl, payload).then((data) => {
      toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
      noteService.getHelpTemplate({page: page})
    }).catch((err) => { console.log(err) })
  }

  const action = () => {
    const payload ={
      "submit": 1, 
      "subject": title,
      "contents": bodyContent,
    }
    noteService.actionHelpTemplate(saveBtnUrl, payload).then((data) => {
      toast.current.show({ severity: 'success', summary: '성공', detail: '정보가 업데이트 돠었습니다', life: 3000 });
      noteService.getHelpTemplate({page: page})
    }).catch((err) => { console.log(err) })
  };
  return (
    <>
      <Toast ref={toast} />
      <div className='refresh card'>
        <span className="text">고객센터 템플릿</span>
      </div>
      <div className="grid-div">
        <div className="item1 card">
          <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
            rows={30}
            template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

          />
          <CustomDataTable
            data={data}
            headers={header}
            bodyTemplate={bodyTemplate}
            scrollable={true}
          />
          <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
            rows={30}
            template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

          />
        </div>
        <div className="item2 card">
          <div className='cp-input'>
            <span className='p-float-label'>
              <InputText
                id=''
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <label htmlFor="username">Title</label>
            </span>
          </div>
          <Editor value={bodyContent}
            onTextChange={(e) => changeEditor(e.htmlValue)}
            style={{ height: `420px` }}
          />
          <div className="button-bottom">
            <Button onClick={confirm1}>저장 하기</Button>
            <Button style={{ background: 'red' }} onClick={deleteMessage}>삭제 하기</Button>

          </div>
        </div>
      </div>
    </>
  )
};

export default HelpTemplate;
