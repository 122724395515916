import React, { useEffect, useRef, useState } from "react";
import './UserInfo.scss'
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import UserService from "../../../../service/UserService";
import { useParams } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Checkbox } from "primereact/checkbox";

const UserInfoTab = (props) => {
    const initiaValue = props?.initialValue
    const agentData = props?.data
    const [statusMember, setStatusMember] = useState();
    const [colorName, setColorName] = useState();
    const [dataUpdate, setDataUpdate] = useState();
    const [underExchange, setUnderExchange] = useState()
    const [data, setData] = useState() 
    const userList = new UserService()
    const params = useParams();
    function getDataAgent (data, key){
        var value = data.filter((k)=> k.key === key)
        console.log(value)
        return value[0].data
    }
    const fetchBasicDataUser = (value) => {
        const payload = {
            'id': params?.id,
            'submit': "0",
            ...value
        }
        userList.getBasicData(payload)
            .then((data) => {
                console.log(data, agentData)
                const res = data.data
                var user_option = Object.keys(res.user_options.option).filter(key =>res.user_options.option[key] === '1')
                var under_exchange = res.user_options.option.mb_option_under_exchange
                setUnderExchange(under_exchange? under_exchange: '0')
                setDataUpdate({
                    Usercredit: res.Usercredit.value,
                    log_before: res.log_before.value,
                    mb_name:res.mb_name.value,
                    mb_password: res.mb_password.value,
                    mb_exch_pw: res.mb_exch_pw.value,
                    mb_hp: res.mb_hp.value,
                    mb_status: res.mb_status.value,
                    id: params?.id,
                    user_options: user_option,
                    mb_bank_name: res.mb_bank.options.mb_bank_name.value,
                    mb_bank_own: res.mb_bank.options.mb_bank_own.value,
                    mb_bank_no: res.mb_bank.options.mb_bank_no.value,
                    mb_memo:getDataAgent(agentData, 'notes'),
                    mb_color: getDataAgent(agentData, 'mb_color'),
                    submit:'1',
                    mb_level: res.mb_level.value === ''? "01": res.mb_level.value,
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // function getBanList() {
    //     var newBanStatus = { ...banStatus }

    //     initiaValue?.mb_bet_block?.value?.map((v) => {
    //         return newBanStatus[v] = false
    //     });
    //     setBanStatus(newBanStatus)
    // }
    useEffect(() => { }, [underExchange, dataUpdate])
    useEffect(() => {
        setStatusMember(props?.status)
        setColorName(props?.color)
        // setDataUpdate({
        //     MemberLevel: initiaValue?.기본정보.MemberLevel?.value,
        //     Usercredit: initiaValue?.Usercredit?.value,
        //     apiKey: initiaValue?.apiKey?.value,
        //     level_sub: initiaValue?.level_sub?.value,
        //     level_top: initiaValue?.level_top?.value,
        //     log_before: initiaValue?.log_before?.value,
        //     mb_bet_block: initiaValue?.mb_bet_block?.value,
        //     mb_check_type: initiaValue?.mb_check_type?.value,
        //     mb_code: initiaValue?.mb_code?.value,
        //     mb_color: initiaValue?.mb_color?.value,
        //     mb_exch_pw: initiaValue?.mb_exch_pw?.value,
        //     mb_hp: initiaValue?.mb_hp?.value,
        //     mb_id: initiaValue?.mb_id?.value,
        //     mb_level: initiaValue?.mb_level?.value,
        //     mb_level_origin: initiaValue?.mb_level_origin?.value,
        //     mb_max_payment: initiaValue?.mb_max_payment?.value,
        //     mb_max_payment_cnt: initiaValue?.mb_max_payment_cnt?.value,
        //     mb_name: initiaValue?.mb_name?.value,
        //     mb_no: initiaValue?.mb_no?.value,
        //     mb_old_code: initiaValue?.mb_old_code?.value,
        //     mb_password: initiaValue?.mb_password?.value,
        //     mb_payment_one_max: initiaValue?.mb_payment_one_max?.value,
        //     mb_status: initiaValue?.mb_status?.value,
        //     member: initiaValue?.member?.value,
        //     mb_blacklist: initiaValue?.member_in?.item?.mb_blacklist?.value,
        //     mb_memo: initiaValue?.member_in?.item?.mb_memo?.value,
        //     admin_memo: initiaValue?.note?.items?.admin_memo?.value,
        //     changemm: initiaValue?.note?.items?.changemm?.value,
        //     mb_bank_name: initiaValue?.mb_bank?.options?.mb_bank_name?.value,
        //     mb_bank_own: initiaValue?.mb_bank?.options?.mb_bank_own?.value,
        //     mb_bank_no: initiaValue?.mb_bank?.options?.mb_bank_no?.value,
        //     user_options: initiaValue?.user_options?.value
        // })
        // getBanList()
    }, [props])
    useEffect(() => {
        fetchBasicDataUser()
    }, [])
    useEffect(()=>{},[dataUpdate])
    const handleChange = (e, key) => {
        var newDataUpdate = { ...dataUpdate };
        newDataUpdate[key] = e;
        setDataUpdate(newDataUpdate);
        if (key.includes('mb_bet_block')) {
            const newArray = Object.keys(e).filter((key) => e[key] === false);
            newDataUpdate[key] = newArray
            setDataUpdate(newDataUpdate)
        }
        if(key.includes('notes')){
            newDataUpdate['mb_memo'] = e
            setDataUpdate(newDataUpdate)
        }
        
    }

    const actionSubmit = () => {
        // console.log(dataUpdate)
        fetchBasicDataUser(dataUpdate)
    }

    // const handleBanChange = (i, value, key) => {
    //     const newBanStatus = { ...banStatus };
    //     newBanStatus[i] = value;
    //     setBanStatus(newBanStatus);
    //     handleChange(newBanStatus, key)
    // };
    function handleCheckBox(e, data) {
        var updatedUserOption = [...dataUpdate.user_options, 'mb_option_under_exchange'];
        if (e === false) {
            setUnderExchange('0')
            var updatedUserOptions = dataUpdate.user_options.filter(option => option !== 'mb_option_under_exchange');
            setDataUpdate({
                ...dataUpdate,
                user_options: updatedUserOptions
              });
        }
        else {
            setUnderExchange('1')
            setDataUpdate({
                ...dataUpdate,
                user_options: updatedUserOption
              });
        }
    }
    return (
        <>
            <ConfirmDialog />
            <div className="user-info-body">
                {props.data.map((value, index) => {
                    return (
                        <>
                            {value.type === 'input' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        <InputText defaultValue={value.data} disabled={value.key === 'mb_rec_id' ? true : false} placeholder={value.key === 'mb_rec_id' ? '추천아이디' : ''} onChange={(e) => handleChange(e.target.value, value.key)} />
                                    </div>
                                </div>
                            }
                            {value.type === 'input-wheel' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data-input-wheel">
                                        <InputText defaultValue={value.data} onChange={(e) => handleChange(e.target.value, value.key)} />
                                        <span>롤링개수 빼기</span>
                                    </div>
                                </div>
                            }
                            {value.type === 'input-name' &&
                                <div className="row">
                                    <div className="title" >
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        <InputText defaultValue={value.data} style={{ color: colorName?.includes('#') ? colorName : `#${colorName}` }} onChange={(e) => handleChange(e.target.value, value.key)} />
                                    </div>
                                </div>
                            }
                            {value.type === 'input-button' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data button-color-group">
                                        <InputText value={colorName} style={{ color: colorName }} />
                                        <Button style={{ backgroundColor: '#007bff' }} onClick={() => { setColorName('#007bff'); handleChange('#007bff', value.key) }}>#007bff</Button>
                                        <Button style={{ backgroundColor: '#28a745' }} onClick={() => { setColorName('#28a745'); handleChange('#28a745', value.key) }}>#28a745</Button>
                                        <Button style={{ backgroundColor: '#fd7e14' }} onClick={() => { setColorName('#fd7e14'); handleChange('#fd7e14', value.key) }}>#fd7e14</Button>
                                        <Button style={{ backgroundColor: '#dc3545' }} onClick={() => { setColorName('#dc3545'); handleChange('#dc3545', value.key) }}>#dc3545</Button>
                                        <Button style={{ backgroundColor: '#000000' }} onClick={() => { setColorName('#000000'); handleChange('#000000', value.key) }}>#000000</Button>
                                    </div>
                                </div>
                            }
                            {value.type === 'text' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        {value.data}
                                    </div>
                                </div>
                            }
                            {value.type === 'radius' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" name="active" value="2" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '2'} />
                                            <label htmlFor={statusMember} className="ml-2">사용중</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient2" name="banned" value="0" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '0'} />
                                            <label htmlFor={statusMember} className="ml-2">사용정지</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient3" name="removed" value="3" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '3'} />
                                            <label htmlFor={statusMember} className="ml-2">졸업</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient4" name="pending" value="1" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '1'} />
                                            <label htmlFor={statusMember} className="ml-2">사용대기</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient4" name="pending" value="4" onChange={(e) => { setStatusMember(e.value); handleChange(e.value, value.key) }} checked={statusMember === '4'} />
                                            <label htmlFor={statusMember} className="ml-2">보류</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* {value.type === 'radius-group' &&
                                <div className="row">
                                    <div className="title">
                                        {value.title}
                                    </div>
                                    <div className="data">
                                        <div className="group-radius">
                                            {value?.data && Object.keys(value?.data)?.map((v, i) => {
                                                return (
                                                    <div className="group-casino" key={v}>
                                                        <div className="flex align-items-center div-space">
                                                            <RadioButton
                                                                inputId={`ingredient-${v}-true`}
                                                                name={`casino-ban-${v}`}
                                                                value="true"
                                                                onChange={(e) => { handleBanChange(v, true, value.key) }}
                                                                checked={banStatus[v] === true}
                                                            />
                                                            <label htmlFor={`ingredient-${v}-true`} className="ml-2">{value?.data[v]} 사용</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <RadioButton
                                                                inputId={`ingredient-${v}-false`}
                                                                name={`casino-ban-${v}`}
                                                                value="false"
                                                                onChange={(e) => { handleBanChange(v, false, value.key) }}
                                                                checked={banStatus[v] === false}
                                                            />
                                                            <label htmlFor={`ingredient-${v}-false`} className="ml-2">{value?.data[v]} 미사용</label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                            } */}
                            {value.type === 'checkbox'
                                // && value.data.기본정보?.mb_option.mb_option_under_exchange 
                                &&
                                <div className="row">
                                    <div className="title"></div>
                                    <div className="data">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId={value.title} value={value?.data?.mb_option_under_exchange === underExchange ? true : false} checked={ underExchange ==='1'?true:false} onChange={(e) => handleCheckBox(e.checked, value.data)} />
                                            <label htmlFor={value.title} className="ml-2">{value.title}</label>
                                        </div>
                                    </div>
                                </div>


                            }

                        </>
                    )
                })}
                <Button className="btn-submit" onClick={() => props.action(actionSubmit)}>저장하기</Button>
            </div>
        </>

    )
}
export default UserInfoTab