import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import { CpDropdown, CpInput, CpPagination } from "../../../components";
import { Button } from "primereact/button";
import "./AgentHelp.scss";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import AgentService from "../../../service/AgentServices";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
const AgentHelp = () => {
  const [selectOption, setSelectOption] = useState({ name: "선택", code: "" });
  const [keySearch, setKeySearch] = useState("");
  const [page, setPage] = useState(0);
  const [data, setData] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [messageModal, setMessageModel] = useState({});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [first, setFirst] = useState(0);
  const [questionTime, setQuestionTime] = useState("-");
  const [answerTime, setAnswerTime] = useState("-");

  const [content, setContent] = useState("-");
  const [bodyContent, setBodyContent] = useState("");
  const [contentEditor, setContentEditor] = useState("-");
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [mbID, setMbID] = useState()
  const toast = useRef(null);
  const [rippleID, setRippleId] = useState(null)
  const [ingredients, setIngredients] = useState([]);
  const [checked, setChecked] = useState(false);
  const header = [
    { field: "checkbox", label: <Checkbox onChange={(e) => pickall(e)} checked={checked}></Checkbox>, sortAble: false },
    { field: "mb_name", label: `제목`, sortAble: false },
    { field: "mb_ids", label: "아이디", sortAble: false },
    { field: "mb_status", label: "상태", sortAble: false },
    { field: "btn_action", label: "", sortAble: false },
  ];
  const agentList = new AgentService();

  const getDataList = () => {
    const payload ={
      submit: 0, // 0: Show List || 1: Processing (POST Side)
      mb_type: "agent", // null <=> user (Default) || agent
      id: id,
      keyfield: selectOption.code,
      key: keySearch,
      page: page
    }
    agentList.agentHelpListAPI(payload)
        .then((data) => {
          const res = data?.list;
          setData(res?.data);
          setTotalRecords(res?.total);
        })
        .catch((err)=>{
          console.log(err)
        })
  };
  useEffect(() => {
    getDataList();
  }, [page]);
  useEffect(()=>{},[bodyContent, questionTime])
  const sampleContentButtonGroupLoad = [
    {
      name: "1. 입금통장문의(종이장라인)",
      value:
        "<p>★★★ 계좌 안내 입니다 ★★★</p><p><br></p><p><br></p><p>입금시마다 반드시 계좌문의후 이용부탁드립니다.</p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p>충전계좌 확인 &gt;&gt; 입금 &gt;&gt; 충전신청 순서대로 해주시면</p><p><br></p><p>빠른 처리 해드리겠습니다.</p><p><br></p><p>※타명의 계좌로 이름만 바꿔서 입금할시 당사에서 전부확인할수 있으며</p><p><br></p><p>반환처리또한 해드리지 않으니 꼭 본인명의 계좌로만 입금해 주시기 바랍니다</p><p><br></p><p>다른 문의사항 있으시면 언제든 문의 주시기 바랍니다.</p>",
    },
    {
      name: "2. 편생 고정 계좌 안내 입니다",
      value: `<p>안녕하세요. 스타 카지노 입니다.</p><p><br></p><p>현재 회원님들께서 요청해주신 환전업무는 요청해주신 순서대로 진행되고 있습니다.</p><p><br></p><p>배팅내역 확인하는데 시간이 소요되는 점 양해부탁드립니다</p><p><br></p><p>항상 믿음과 신용을 최우선으로 하는 스타 카지노가 되겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p>&nbsp;</p><p><br></p><p>다른 문의사항 있으시면 언제든 문의 주시기 바랍니다.</p><p><br></p><p>항상 스타 카지노 에서 회원님들의 건승 기원합니다.</p>`,
    },
    {
      name: "3. ★★★가상 계좌발급(구리가상)★★★",
      value:
        "<p>★★★ 평생 고정 계좌 안내 입니다 ★★★</p><p><br></p><p>수협은행 :&nbsp;</p><p><br></p><p>예 금 주 : 마켓프렌즈</p><p><br></p><p>해당 계좌로 입금시 3자입금(타명의 입금)불가능하며 현재 ★본사 등록된 본인 명의 계좌만 입금가능한 계좌★입니다</p><p><br></p><p>입금 &gt;&gt; 충전신청 순서대로 해주시면 빠른 처리 해드리겠습니다.</p><p><br></p><p>해당계좌 받으신후 특별공지가 따로 없는한 계좌문의 없이 해당계좌로 입금하시면 됩니다</p>",
    },
    {
      name: "★★★ 계좌 1원 인증번호 ★★★",
      value:
        "<p>운영진입니다&nbsp;</p><p><br></p><p>등록된 환전 계좌번호로 1원이 입금되어 인증 번호가 발송되었습니다&nbsp;</p><p><br></p><p>계좌인증 ( x x x x ) 세이퍼트 4자리 인증번호 알려주시면&nbsp;</p><p><br></p><p>계좌발급후 평생계좌 안내해드리겠습니다</p><p><br></p><p>해당 인증번호 고객센터에 남겨주시면 쪽지로 평생계좌 보내드리겟습니다</p>",
    },
    {
      name: "★★★ 인증 시간 초과시 답변 ★★★",
      value:
        "<p>안녕하세요. 고객센터 입니다.</p><p><br></p><p>인증시간이 초과되어 다시 인증번호 보내드렸습니다&nbsp;</p><p><br></p><p>인증번호 고객센터로 알려주시면&nbsp;</p><p><br></p><p>평생계좌 발급해드리겠습니다</p>",
    },
    {
      name: "►►► 평생 계좌 입금 안된다고하면 답변 ◄◄◄",
      value:
        "<h1>환전 등록된 계좌에서만 입금 가능하십니다</h1><h1><br></h1><h1>ATM입금 , 토스&nbsp;또는 카카오페이 계좌로&nbsp;연결하여 입금하시면 입금 안되십니다</h1><h1><br></h1><h1>이용에 감사드리며, 더 나은 베팅환경을 만들기 위해 노력하겠습니다.</h1>",
    },
    {
      name: "확인후 처리완료",
      value:
        "<p>안녕하세요 스타 카지노입니다.</p><p><br></p><p>문의/요청하신 사항은 모두 처리완료 되었습니다.</p><p><br></p><p>다른 문의사항 있으시면 언제든 문의 주시기 바랍니다.</p><p>항상 스타 카지노에서 회원님들의 건승 기원합니다.</p>",
    },
    {
      name: "환전 마감 안내",
      value:
        "<p>환전 마감 안내&nbsp;</p><p><br></p><p>안녕하세요 회원님&nbsp;</p><p><br></p><p><br></p><p><br></p><p><br></p><h2>스타 카지노 입니다.</h2><p><br></p><p>매일 밤 23시 30분 부터 익일 00시 30분 까지는&nbsp;</p><p><br></p><p>은행 점검과 정산마감으로 인하여 출금이 제한 됩니다.</p><p><br></p><p>00시30분이후에 환전신청 부탁드립니다</p>",
    },
    {
      name: "충전 마감 안내",
      value:
        "<p>충전 마감 안내&nbsp;</p><p><br></p><p>안녕하세요 회원님&nbsp;</p><p><br></p><p><br></p><p><br></p><h2>스타 카지노 입니다.</h2><p><br></p><p>매일 밤 23시 35분 부터 익일 00시 10분 까지는&nbsp;</p><p><br></p><p>은행 점검과 정산마감으로 인하여 입금이 제한 됩니다.</p><p><br></p><p>00시 10분이후에 문의 부탁드립니다</p>",
    },
    {
      name: "포인트관련문의",
      value:
        "<p>안녕하세요 스타 카지노 입니다.</p><p><br></p><p>고객센터에는 포인트 관련 권한이 없습니다</p><p><br></p><p><br></p><p>상위총판에 문의 부탁드립니다</p><p><br></p><p>다른 문의사항 있으시면 언제든 문의 주시기 바랍니다.</p><p>항상 스타 카지노에서 회원님들의 건승 기원합니다.</p>",
    },
    {
      name: "은행 점검 시간안내",
      value:
        "<p>안녕하세요 스타 카지노 입니다.</p><p><br></p><p><br></p><p>23시30분부터 00시30분까지</p><p><br></p><p>은행 점검으로 인하여 환전이 불가합니다.</p><p><br></p><p>점검이 풀리는데로 환전업무 진행하도록 하겠습니다.</p><p><br></p><p><br></p><p>다른 문의사항 있으시면 언제든 문의 주시기 바랍니다.</p><p>항상 스타 카지노에서 회원님들의 건승 기원합니다.</p>",
    },
    {
      name: "환전 지연시",
      value:
        "<p>안녕하세요. 스타 카지노 입니다.</p><p><br></p><p>현재 회원님들께서 요청해주신 환전업무는 요청해주신 순서대로 진행되고 있습니다.</p><p><br></p><p>배팅내역 확인하는데 시간이 소요되는 점 양해부탁드립니다</p><p><br></p><p>항상 믿음과 신용을 최우선으로 하는 스타 카지노가 되겠습니다.</p><p><br></p><p>감사합니다.</p><p><br></p><p>&nbsp;</p><p><br></p><p>다른 문의사항 있으시면 언제든 문의 주시기 바랍니다.</p><p><br></p><p>항상 스타 카지노 에서 회원님들의 건승 기원합니다.</p>",
    },
  ];
  const menuDropdown = [
    { name: "선택", code: "" },
    { name: "아이디", code: "mb_id" },
    { name: "이름", code: "mb_name" }
  ];
  const chooseOption = (e) => {
    setSelectOption(e);
  };


  const onIngredientsChange = (e) => {
    let _ingredients = [...ingredients];

    if (e.checked)
      _ingredients.push(e.value);
    else
      _ingredients.splice(_ingredients.indexOf(e.value), 1);

    setIngredients(_ingredients);
  }
  function pickall(e) {
    let newArr = []
    setChecked(e.checked)
    if (e.checked) {
      for (let i = 0; i < data.length; i++) {
        newArr.push(data[i].board_id)
        setIngredients(newArr)
      }
    }
    else {
      setIngredients([])
    }
  }
  function deleteBoardId() {
    if (ingredients.length > 0) {
      confirmDialog({
        message: "삭제하시겠습니까?",
        header: "고객센터",
        accept: confirmDeteleBoardId,
        acceptLabel: "확인",
        acceptClassName: "p-confirm-dialog-accept",
      });
    }
  }
  function confirmDeteleBoardId() {
    var newStr = ingredients.join('|')
    agentList.agentHelpActionAPI({
      "submit": 1,
      "process": "help_hidden",
      "id": newStr.toString()
    }).then((data) => {
      if (data.code === 200) {
        toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
        getDataList();
        setIngredients([])
      }
      else {
        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
      }
    }).catch((err) => { console.log(err) })
  }
  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
  };
  const bodyTemplate = (data, props) => {
    const url = new URL(data?.handle?.value?.show?.link)
    const queryString  = url.search
    const rippleID = data?.ripple_id
    const ripple_content = data?.ripple_content
    const handleGetShow=(e)=>{
      setQuestionTime(data?.reg_date?.value)
      agentList.getActionApi(`board${e}`)
        .then((data)=>{
          const res = data?.data
          const titleValue = res?.board_subject?.value
          const req_date =  res?.ripple_date
          const contentValue = res?.board_content?.value
          const mbId = res?.mb_id?.value
          setBodyContent(ripple_content)
          setRippleId(rippleID)
          setTitle(titleValue)
          setAnswerTime(req_date)
          setContent(contentValue)
          setMbID(mbId)
        })
        .catch((err)=>{
          console.log(err)
        })
        console.log(rippleID)
    }
    return (
      <>
        {props.field === "btn_action" ? (
          <>
            <span className="p-column-title">{props.header}</span>
            <div className="icon-btn">
              <span onClick={() => {setId(data.board_id); handleGetShow(queryString)}}>
                <i style={{ color: "white" }} className="pi-file-edit pi" />
              </span>
            </div>
          </>
        ) : props.field === "mb_ids" ? (
          // <Link to={`/agentdetail/${data.mb_id.value}`}>
         
          // </Link>
          <div onClick={() => { window.open(`#/agentdetail/${data.mb_id.value}`, '', "width=1500,height='100vh'") }}>
             <span className="id" >{data.mb_id.value}<br/><i className="pi pi-star" style={{fontSize:' 12px', color: 'rgb(255, 87, 34)'}}/>
          
          </span>
          </div>
        ) : props.field === "mb_name" ? (
          <span className="name" style={{whiteSpace:'break-spaces', overflowWrap:'anywhere'}}>{data.subject.value}</span>
        ) : props.field === "mb_status" ? (
          <>
            {data.state.value ==='답변' &&
              <span className="status">
                {data.state.value}&nbsp; <i className="pi pi-times" style={{ fontSize: '1rem', cursor:'pointer' }}onClick={()=>{setId(data.board_id);deleteMessage()}}></i>
              </span>
            }
            {data.state.value ==='문의' &&
              <span className="status">
                {data.state.value}
              </span>
            }
          </>
          
        ) : props.field ==='checkbox'?(
          <Checkbox  onChange={(e) => onIngredientsChange(e)} value={data.board_id} checked={ingredients.includes(data.board_id)}>

          </Checkbox>
        ):(
          ""
        )}
        {/* {props.field === "mb_name" && (
          <span className="p-column-title">{data.mb_hp}</span>
        )} */}
      </>
    );
  };
  const confirm1 = () => {
    confirmDialog({
      message: "고객센터 작성/수정 성공.",
      header: "고객센터",
      accept,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
    });
  };
  const confirm2 = () => {
    confirmDialog({
      message: "메세지 삭제하시겠습니까?",
      header: "고객센터",
      accept: action2,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
    });
  };
  const deleteMessage =()=>{
    confirmDialog({
      message: "메세지 삭제하시겠습니까?",
      header: "고객센터",
      accept: deleteMessageAction,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
    });
  }
  const delete1 = () => {
    confirmDialog({
      message: "고객센터 삭제되었습니다.",
      header: "고객센터",
      cancel,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
    });
  };
  const deleteMessageAction = () =>{
    agentList.agentHelpActionAPI({
      "submit": 1,    // 0: Show List (GET Side) || 1: Processing
      "process": "help_hidden",  // ripple_input: Save || ripple_delete: Delete || help_delete_in_3_days: Delete helps in 3 days || help_hidden: Delete help inquiry
      "id": id,    // board_id || ripple_id
    }).then((data)=>{
      if(data.code === 200){
        toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
        getDataList();
      }
      else{
        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
      }
      
    }).catch((err)=>{console.log(err)})
  }
  const action2 =()=>{
    denie()
  }
  const denie = ()=>{
    agentList.agentHelpActionAPI({
      "submit": 1,    // 0: Show List (GET Side) || 1: Processing
      "process": "ripple_delete",  // ripple_input: Save || ripple_delete: Delete || help_delete_in_3_days: Delete helps in 3 days || help_hidden: Delete help inquiry
      "id": rippleID,    // board_id || ripple_id
    }).then((data)=>{
      if(data.code === 200){
        toast.current.show({ severity: 'success', summary: '성공', detail: '삭제가 완료되었습니다.', life: 3000 });
        getDataList();

      }
      else{
        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
      }
    }).catch((err)=>{console.log(err)})
  }
  const accept = () => {
    action();
  };
  const cancel = () =>{
    agentList.agentHelpActionAPI({
      "submit": 1,    // 0: Show List (GET Side) || 1: Processing
      "process": "help_delete_in_3_days",  // ripple_input: Save || ripple_delete: Delete || help_delete_in_3_days: Delete helps in 3 days || help_hidden: Delete help inquiry
    }).then((data)=>{
      if(data.code === 200){
        toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
        getDataList();

      }
      else{
        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
      }
    }).catch((err)=>{console.log(err)})
  }
  const action = () => {
    agentList.agentHelpActionAPI({
      "submit": 1,    // 0: Show List (GET Side) || 1: Processing
      "process": "ripple_input",  // ripple_input: Save || ripple_delete: Delete || help_delete_in_3_days: Delete helps in 3 days || help_hidden: Delete help inquiry
      "id": id,    // board_id || ripple_id
      "board_subject": title,
      "mb_id": mbID,
      // "ripple_name": "test",
      // "ripple_date": "", //no need fill
      "ripple_content": bodyContent
      // "parent_id": ""
    }).then((data)=>{
      // toast.current.show({ severity: 'success', summary: '성공', detail: '정보가 업데이트 돠었습니다', life: 3000 });
      if(data.code === 200){
        toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
        getDataList();

      }
      else{
        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
      }
    }).catch((err)=>{console.log(err)})
  };
  const changeEditor = (e) => {
    setBodyContent(e)
  };


  return (
    <>
      <Toast ref={toast}/>
      <div className="refresh card">
        <span className="text">에이전트 고객센터</span>
        <div className="tool">
          <div className="cp-dropdown">
            <CpDropdown
              options={menuDropdown}
              onChange={(e) => chooseOption(e)}
              value={selectOption}
            />
          </div>
          <CpInput
            placeholder="검색"
            value={keySearch}
            onChange={(e) => setKeySearch(e)}
            onKeyDown={(e)=> {if(e.key ==='Enter') getDataList()}}
          />
          <Button onClick={getDataList}>검색</Button>
        </div>
        <Button className="delete-3-days" style={{background:'red'}} onClick={delete1}>삼일 이전내역</Button>
      </div>
      <div className="grid-div">
        <div className="item1 card">
          <CpPagination
            page={page}
            onChange={(e) => setPage(e)}
            total={totalRecords}
            defaulRows={30}
          />
          <CustomDataTable
            data={data}
            headers={header}
            bodyTemplate={bodyTemplate}
            modalMessage={messageModal}
            openConfirm={openModalConfirm}
            scrollable={true}
          />
          <div className="item-row">
            <CpPagination
              page={page}
              onChange={(e) => setPage(e)}
              total={totalRecords}
              defaulRows={30}
            />
            <Button onClick={deleteBoardId} severity="warning" >삭제</Button>
          </div>
          
        </div>
        <div className="item2 card">
          <CpInput placeholder="Title" value={title}/>
          <div className="div-box">
            <div className="title">
              <span style={{ color: `#fff` }}>문의 시간</span>
            </div>
            <div className="time">
              <span>{questionTime}</span>
            </div>
          </div>
          <div className="div-box">
            <div className="title">
              <span style={{ color: `#fff` }}>내용</span>
            </div>
            <div className="time"dangerouslySetInnerHTML={{ __html: content }}>
              {/* <span>{content}</span> */}
            </div>
          </div>
          <Editor
            value={bodyContent}
            onTextChange={(e) => changeEditor(e.htmlValue)}
            style={{ height: `320px` }}
          />
          <div className="div-editor">
            <div className="title">
              <span>답변 시간</span>
            </div>
            <div className="content-editor">
              <span>{answerTime}</span>
            </div>
          </div>
          <div className="btn-group-sample-content">
            {sampleContentButtonGroupLoad.map((v, i) => {
              return (
                <Button key={i} onClick={() => setBodyContent(v.value)}>
                  {v.name}
                </Button>
              );
            })}
          </div>
          <div className="button-bottom">
            <Button onClick={confirm1}>저장 하기</Button>
            {rippleID? 
            <Button onClick={confirm2} style={{background:'red'}}>삭제</Button>
            :<></>}
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentHelp;
