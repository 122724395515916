import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";

// import "./SCSS/index.scss";

const CpInput = (props) => {
  const {
    id,
    placeholder,
    onChange,
    type,
    onKeyDown,
    value = "",
    color = "#000000",
    readOnly
  } = props;
  const [floatValue, setFloatValue] = useState("");
  const onChangeInput = (e) => {
    setFloatValue(e);
    onChange?.(e);
  };
  useEffect(() => {
      setFloatValue(value);
  }, [value]);

  return (
    <div className={`cp-input`}>
      <span className={`p-float-label`}>
        <InputText
          readOnly={readOnly}
          id={id ?? ""}
          type={type ?? "text"}
          value={floatValue}
          onChange={(e) => onChangeInput(e.target.value)}
          onKeyDown={(e) => onKeyDown?.(e)}
          style={{ color: color }}
        />
        <label htmlFor="username">{placeholder ?? ""}</label>
      </span>
    </div>
  );
};

export default CpInput;
