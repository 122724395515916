import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { useParams } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import CustomDataTable from "../../../../components/Table/TableUser";
import AgentServices from "../../../../service/AgentServices";
import './MoneyExchange.scss';
import { formatDate } from "../../../../utilities/date";
import { addLocale } from "primereact/api";
import { Toast } from "primereact/toast";

const labels = {
    type: '타입',
    agent: '에이젼트',
    date: '일시',
    content: '내용',
    balance: '벨런스 +-',
    remaining: '남은 벨런스'
}

const   MoneyExchangeTab = () => {
    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }
    const today = convertTZ(new Date(), "Asia/Seoul")
    const pass = convertTZ(new Date(), "Asia/Seoul")
    pass.setDate(today.getDate() -1)
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0)
    const page = useRef(1);
    const [data, setData] = useState([]);
    const [label, setLabel] = useState(labels);
    const [sDate, setSDate ] = useState(pass)
    const [eDate, setEDate] = useState(today)
    const userList = new AgentServices();
    const params = useParams(); 
    const toast = useRef(null);

    function fetchChargeMoney(query = {}) {
        const myQuery = {
            sdate: formatDate(pass),
            edate: formatDate(today),
            page: page.current,
            mode: 'agent_balance_log',
            keyfield: 'mb_id',
            key: params.id,
            ...query
        }
        userList.fetchAgentBalanceLog(myQuery)
            .then((resp) => {
                setData(resp.list.data)
                // setLabel(resp.label['머니이동'])
                // // setTotalRecords(40)
                setTotalRecords(resp.list.total)
            })
            .catch((err) => {
                toast.current.show({ severity: 'warn', summary: 'Failed', detail: 'Request data failed', life: 3000 });
            })
    }

    useEffect(fetchChargeMoney, [])

    useEffect(() =>{
        if(!sDate){
            setFirst(0)
            setEDate('')
            page.current = 1
            fetchChargeMoney()
        }
    }, [sDate])

    const onPageChange = (event) => {
        if(page.current === (event.page + 1)) return
        page.current = (event.page + 1 )
        setFirst(event.first)
        fetchChargeMoney({
            sdate: formatDate(sDate),
            edate: formatDate(eDate),
            page: event.page + 1
        })
    };

    const headerHistory = Object.keys(label || {}).map(e =>{
        return {field: e, label: label[e], data}
    });

    const handleFilter = () =>{
        if(!eDate || !sDate) return;
        if(sDate > eDate) {
            toast.current.show({ severity: 'warn', summary: 'Failed', detail: `Start day can't larger than end day`, life: 3000 });
            return
        };
        setFirst(0)
        page.current = 1
        fetchChargeMoney({
            sdate: formatDate(sDate),
            edate: formatDate(eDate),
            page: page.current
        })
    }

    const bodyTemplate = (data, props) => {
        const {type, agent, registration_date, tree, money, remaining_amount} = data
        return (
            <>
                {props.field === 'type' &&
                    <div style={{color: type.color}}>
                        {type.label || ''}
                    </div>
                }
                {props.field === 'agent' &&
                    <>
                        {agent.value || ''}
                    </>
                }
                
                {props.field === 'date' &&
                    <>
                        {registration_date}
                    </>
                }
                {props.field === 'content' &&
                    tree.length && tree.map((e, i) => {
                        return <span> {e.value}  {i < tree.length - 1 && <>={">"}{">"}</>}</span>
                    })
                }
                {props.field === 'balance' &&
                    <>
                        {money}
                    </>
                }
                {props.field === 'remaining' &&
                    <>
                        {remaining_amount}
                    </>
                }
            </>
        )
    }
    addLocale('ko', {
        firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
    return (
        <>
            <div className="money-exchange-tab">
                <div className="grid">
                    <div className="col-3">
                        <Calendar value ={sDate} onChange={(e) => setSDate(prev => prev = e.value) } locale="ko"  showIcon dateFormat="yy-mm-dd"/>
                    </div>
                    <div className="col-3">
                        <Calendar value={eDate} onChange={(e) => setEDate(prev => prev = e.value) } locale='ko'  showIcon dateFormat="yy-mm-dd"/>
                    </div>
                    <div className="col-2">
                        <Button onClick={handleFilter}>확 인</Button>
                    </div>
                </div>
                <div className="inventory">
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />  
                    <CustomDataTable data={data} headers={headerHistory} bodyTemplate={bodyTemplate} scrollable={true}/>
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                </div>
            </div>
            <Toast ref={toast}/>
        </>
    )
}

export default MoneyExchangeTab
