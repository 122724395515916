import React, { useEffect, useMemo, useRef, useState } from "react";
import './AgentDetail.scss';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { useParams } from "react-router-dom";
import { TabView, TabPanel } from 'primereact/tabview';
import UserInfoTab from "./UserInfor/UserInfor";
import HistoryBetting from "./BettingHistory/BettingHistory";
import MoneyExchangeTab from "./MoneyExchange/MoneyExchange";
import MessageTab from "./Message/Message";
import LoginIpTab from "./LoginIp/LoginIpTab";
import BalanceFluctuation from "./BalanceFluctuation/BalanceFluctuation";
import SettlementHistoryTab from "./SettlementHistory/SettlementHistory";
import CasinoRolling from "./CasinoRolling";
import AgentPaymentNRecovery from "./AgentPaymentNRecovery";
import UserService from "../../../service/UserService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import AgentService from "../../../service/AgentServices";

const AgentDetail = () => {
    const params = useParams()
    const [data, setData] = useState();
    const toast = useRef(null);

    const [tabHeader, setTabHeader] = useState('기본 정보');
    const [confirmMessage, setConfirmMessage] = useState({ message: '정보를 업데이트 하시겠습니까?', header: '통보' })
    const agentList = new AgentService()
    const fetchUser = () => {
        agentList.agentDetailAPI({ id: params.id, tab: 'basic' })
            .then((data) => {
                const res = data
                setData(res?.list)
            })
            .catch((err)=>{
                console.log(err)
            })
    }

    useEffect(() => {
        fetchUser();

    }, [])
    useEffect(() => {
        if (tabHeader === '기본 정보') {
            setConfirmMessage({ message: '정보를 업데이트 하시겠습니까?', header: '통보' })
        }
        if (tabHeader === '충전/환전') {
            setConfirmMessage({ message: '정상 처리되지 않음. 관리자에게 문의 부탁드림니다', header: '유저 디테일' })
        }
    }, [tabHeader])
    function convertNumber(e) {
        return parseFloat(e?.replace(/,/g, ''))
    }
    function parseToString(e) {
        return e.toLocaleString()
    }
    function getStatus(status) {
        switch (status) {
            case '2':
                return '사용중'
            case '0':
                return '사용정지'
            case '3':
                return '졸업'
            case '1':
                return '사용대기'
            default:
                break;
        }
    }
    function getTree(data) {
        const color = [
            "#ff5722",
            "#2196f3",
            "#ffc107",
            "#4caf50",
            "#4eff14",
            "#d92e11",
        ];
        const bgcolor = [
            "#ff5722",
            "#000",
            "#4caf50",
            "#ff7214",
            "#4eff14",
            "#d92e11",
        ];
        return (
            <div className="treeBox">
                
                {data?.mb_step_1 && (
                    <>
                        <span
                            className="box-agentTree"
                            style={{ backgroundColor: bgcolor[0] }}
                        >
                            본
                        </span>

                        <span style={{ color: color[0] ?? "#d92e11" }}>
                            &nbsp;{data?.mb_step_1}
                        </span>{" "}
                    </>

                )}{" "}
                {data?.mb_step_2 && (
                    <>
                    <span style={{ color: "#000", fontWeight: "700" }}>
                        {'>>'}
                        </span>{" "}
                        <span
                            className="box-agentTree"
                            style={{ backgroundColor: bgcolor[1] }}
                        >
                            본
                        </span>

                        <span style={{ color: color[1] ?? "#d92e11" }}>
                            &nbsp;{data?.mb_step_2}
                        </span>{" "}
                        
                    </>

                )}{" "}
                {data?.mb_step_3 && (
                    <>
                    <span style={{ color: "#000", fontWeight: "700" }}>
                        {'>>'}
                        </span>{" "}
                        <span
                            className="box-agentTree"
                            style={{ backgroundColor: bgcolor[2] }}
                        >
                            본
                        </span>

                        <span style={{ color: color[2] ?? "#d92e11" }}>
                            &nbsp;{data?.mb_step_3}
                        </span>{" "}
                        
                    </>

                )}{" "}
                {data?.mb_step_4 && (
                    <>
                    <span style={{ color: "#000", fontWeight: "700" }}>
                        {'>>'}
                        </span>{" "}
                        <span
                            className="box-agentTree"
                            style={{ backgroundColor: bgcolor[3] }}
                        >
                            본
                        </span>

                        <span style={{ color: color[3] ?? "#d92e11" }}>
                            &nbsp;{data?.mb_step_4}
                        </span>{" "}
                        
                    </>

                )}{" "}
                {data?.mb_step_5 && (
                    <>
                    <span style={{ color: "#000", fontWeight: "700" }}>
                        {'>>'}
                        </span>{" "}
                        <span
                            className="box-agentTree"
                            style={{ backgroundColor: bgcolor[4] }}
                        >
                            본
                        </span>

                        <span style={{ color: color[4] ?? "#d92e11" }}>
                            &nbsp;{data?.mb_step_5}
                        </span>{" "}
                        
                    </>

                )}{" "}
                {data?.mb_step_6 && (
                    <>
                    <span style={{ color: "#000", fontWeight: "700" }}>
                        {'>>'}
                        </span>{" "}
                        <span
                            className="box-agentTree"
                            style={{ backgroundColor: bgcolor[5] }}
                        >
                            본
                        </span>

                        <span style={{ color: color[5] ?? "#d92e11" }}>
                            &nbsp;{data?.mb_step_6}
                        </span>{" "}
                        
                    </>

                )}{" "}
            </div>
        );
    }
    const agentData = [
        { title: '트리', data: getTree(data?.left_side?.tree) }, //data?.left_side?.tree },
        { title: '아이디', data: data?.left_side?.mb_id, color: `#${data?.기본정보?.mb_color}` },
        { title: '비밀번호', data: data?.left_side?.mb_password },
        { title: '전화번호', data: data?.left_side?.mb_hp },
        { title: '회원상태', data: getStatus(data?.left_side?.mb_status) },
        { title: '벨런스', data: data?.left_side?.mb_money },
        { title: '포인트', data: data?.left_side?.mb_point },
        { title: '롤링', data: '-' },
        { title: '루징', data: '-' },
    ]
    const basicAgentData = [
        { title: '닉네임', data: data?.기본정보?.mb_name, type: 'input-name', key: 'mb_name' },
        { title: '비밀번호', data: data?.기본정보?.mb_password, type: 'input', key: 'mb_password' },
        { title: '전화번호', data: data?.기본정보?.mb_hp, type: 'input', key: 'mb_hp' },
        { title: '은행명', data: data?.기본정보?.mb_bank_name, type: 'input', key: 'mb_bank_name' },
        { title: '통장번호', data: data?.기본정보?.mb_bank_no, type: 'input', key: 'mb_bank_no' },
        { title: '예금주', data: data?.기본정보?.mb_bank_own, type: 'input', key: 'mb_bank_own' },
        { title: '가입일', data: data?.기본정보?.mb_regtime, type: 'text' },
        { title: '최근접속일', data: data?.기본정보?.last_login_date, type: 'text' },
        { title: '가입IP', data: data?.기본정보?.mb_ip, type: 'text' },
        // { title: '간단메모', data: data?.기본정보?.mb_memo || '', type: 'input', key: 'member_ins' },
        { title: '메모', data: data?.기본정보?.mb_memo, type: 'input', key: 'notes' },
        { title: '아이디 색상', data: data?.기본정보?.mb_color, type: 'input-button', key: 'mb_color' },
        { title: '회원 상태', data: data?.기본정보?.mb_status, type: 'radius', key: 'mb_status' },
        { title: '슬롯 바퀴당', data: '0', type: 'input-wheel', key:'wheel' },
        { title: '카지노 바퀴당', data: '0', type: 'input-wheel', key:'wheel' },
        { title: '하위파트너 충환전 가능', data: data?.기본정보?.mb_option, type: 'checkbox' }
    ];

    const confirm1 = (actionSubmit) => {
        confirmDialog({
            message: confirmMessage.message,
            header: confirmMessage.header,
            accept: () => action1(actionSubmit),
            acceptLabel: '확인',
            acceptClassName: 'p-confirm-dialog-accept'
        });
    };
    const action1 = (action) => {
        toast.current.show({ severity: 'success', summary: '성공', detail: '정보가 업데이트 돠었습니다', life: 3000 });
        action()
    }
    const [activeIndex, setActiveIndex] = useState(0);
    const handleTabChange = (e) => {
        setActiveIndex(e.index);
        setTabHeader(e.originalEvent.target.innerText)
    };
    console.log(basicAgentData);
    return (
        <>
            <Toast ref={toast} />
            <div className="body">
                <div className="breadscrum">
                    <i className="pi pi-home"></i>
                    <i className="pi pi-angle-right"></i>
                    AgentDetail
                </div>
                <div className="box-info">
                    <div className="box box-1">
                        <p>에이전트 기본정보</p>
                        <div className="box-1-info">
                            {agentData.map((value, index) => {
                                return (
                                    <div className="info-row" key={index}>
                                        <span style={{ minWidth: '50px'}}>{value.title} </span>
                                        <span style={{ color: value.color ? `#${value.color}` : 'black' }}> {value?.data}</span>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    <div className="box box-2">

                        <TabView activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e)} >
                            <TabPanel header='기본 정보'>
                                <UserInfoTab data={basicAgentData} action={confirm1} initialValue={data} status={data?.기본정보?.mb_status} color={data?.기본정보?.mb_color} />
                            </TabPanel>
                            <TabPanel header='정산 배당설정'>
                                <CasinoRolling />
                            </TabPanel>
                            <TabPanel header='로그인 기록'>
                                <LoginIpTab />
                            </TabPanel>
                            <TabPanel header='머니이동'>
                                <MoneyExchangeTab />
                            </TabPanel>
                            <TabPanel header='에이전트 지급/회수'>
                                <AgentPaymentNRecovery />
                            </TabPanel>
                            <TabPanel header='정산내역'>
                                <SettlementHistoryTab />
                            </TabPanel>
                        </TabView>

                    </div>
                </div>
            </div>
        </>

    )
}
export default AgentDetail
