import React, { useEffect, useMemo, useRef, useState } from "react";
import './AgentAdd.scss'
import { Button } from "primereact/button";
import { Tree } from "primereact/tree";
import { InputText } from "primereact/inputtext";
import AgentService from "../../../service/AgentServices";
import UserService from "../../../service/UserService";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { CpDropdown } from "../../../components";

const AgentAdd = () => {
    const [nodes, setNodes] = useState();
    const [selectedTreeNodeKeys, setSelectedTreeNodeKeys] = useState(null);
    const [selectValue, setSelectValue] = useState();
    const [data, setData] = useState(null);
    const [actionType, setActionType] = useState('default')
    const [title, setTitle] = useState('본사')
    const [dataAgent, setDataAgent] = useState()
    const [id, setId] = useState()
    const [exchPw, setExchPw] = useState('')
    const [isNew, setIsNew] = useState(false)
    const dataDropdown = [
        { name: "활동정지", code: "0" },
        { name: "등록대기", code: "1" },
        { name: "일반회원", code: "2" },
        { name: "탈퇴", code: "3" },
        { name: "보류", code: "4" },
      ];
    const [dropdownValue, setDropdownValue] = useState({ name: "일반회원", code: "2" });

    const agentInfor = [
        { title: '아이디', label: '아이디', value: dataAgent?.id || '' },
        { title: '닉네임', label: '닉네임', value: dataAgent?.name || '' },
        { title: '비밀번호', label: '비밀번호', value:dataAgent?.password || '' },
        { title: '전화번호', label: '전화번호', value:dataAgent?.phone || ''  },
        { title: '은행명', label: '은행명', value: dataAgent?.bank_name || '' },
        { title: '계좌번호', label: '계좌번호', value: dataAgent?.bank_no || '' },
        { title: '입금자명', label: '입금자명', value: dataAgent?.bank_own || '' },
        { title: '루징', label: '루징', value: dataAgent?.lossrng?.rate_1  },
        { title: '슬롯 롤링', label: '슬롯 롤링', value: dataAgent?.cxrng?.rate_1  },
        { title: '카지노 롤링', label: '카지노 롤링', value: dataAgent?.cxlive?.rate_1  },
        // { title: '카지노 루징', label: '카지노 루징', value: dataAgent?.losslive?.rate_1  },
    ]
    const editAgent = 
    [
        { title: '아이디', label: '아이디', value: dataAgent?.id || '' , disable:true},
        { title: '닉네임', label: '닉네임', value: dataAgent?.name || '' },
        { title: '비밀번호', label: '비밀번호', value:dataAgent?.password || '' },
        { title: '전화번호', label: '전화번호', value:dataAgent?.phone || ''  },
        { title: '은행명', label: '은행명', value: dataAgent?.bank_name || '' },
        { title: '계좌번호', label: '계좌번호', value: dataAgent?.bank_no || '' },
        { title: '입금자명', label: '입금자명', value: dataAgent?.bank_own || '' },
    ]
    const agentList = new AgentService()
    const useApi = new UserService()
    function fetchAgentAddList() {
        agentList.agentAddAPI()
            .then((data) => {
                const res = data?.data
                setData(res)
                setDataAgent({
                    id: '',
                    name: '',
                    password: '',
                    phone: '',
                    bank_name: '',
                    bank_no: '',
                    bank_own: '',
                    cxlive: {rate_1: '0'},
                    cxrng: {rate_1: '0'},
                    // losslive: {rate_1: '0'},
                    lossrng: {rate_1: '0'},
                    exchange_pw: '',
                    status: '2',
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }
    function fetchAgentDetail(payload){

        useApi.getBasicData(payload)
            .then((data) => {
                const res = data.data
                setDataAgent({
                    id: res.mb_id.value,
                    name: res.mb_name.value,
                    password: res.mb_password.value,
                    phone: res.mb_hp.value,
                    bank_name: res.mb_bank.options.mb_bank_name.value,
                    bank_no: res.mb_bank.options.mb_bank_no.value,
                    bank_own: res.mb_bank.options.mb_bank_own.value,
                    lossrng: {rate_1: 0},
                    cxrng: {rate_1: 0},
                    cxlive: {rate_1: 0},
                    // losslive: {rate_1: 0},
                    exchange_pw: res.mb_exch_pw.value,
                    status: res.mb_status.value,
                    user_credit: res.Usercredit.value,
                    log_before: res.log_before.value,
                    user_option: res.user_options.value,
                    mb_level: res.mb_level.value === ''? "01": res.mb_level.value,

                })
            })
            .catch((err) => {
                console.log(err)
            })

    }
    function transformData(data) {
        const result = [];
        data.forEach((v, numIndex) => {
            const newArr = {
                key: numIndex + 1,
                name: v.mb_id,
                label: v.mb_id,
                phone: v.mb_rec_id,
                children: [
                    {label:'+ 부본사 추가', action: true, id: v.mb_id}
                ],
            };

            if (v.step2 && v.step2.length > 0) {
                v.step2.forEach((step2Item, step2Index) => {
                    const newItem = 
                    {
                        key: `${numIndex + 1}-${step2Index}`,
                        name: step2Item.mb_id,
                        label: step2Item.mb_id,
                        phone: step2Item.mb_rec_id,
                        children: [
                            {label:'+ 부본사 추가', action: true, id: step2Item.mb_id}
                        ],
                    };

                    if (step2Item.step3 && step2Item.step3.length > 0) {
                        step2Item.step3.forEach((step3Item, step3Index) => {
                            const newSubItem = {
                                key: `${numIndex + 1}-${step2Index}-${step3Index}`,
                                name: step3Item.mb_id,
                                label: step3Item.mb_id,
                                phone: step3Item.mb_rec_id,
                                add_new: true,
                                children: [
                                    {label:'+ 부본사 추가', action: true, id: step3Item.mb_id}
                                ],
                            };

                            if (step3Item.step4 && step3Item.step4.length > 0) {
                                step3Item.step4.forEach((step4Item, step4Index) => {
                                    const newSubSubItem = {
                                        key: `${numIndex + 1}-${step2Index}-${step3Index}-${step4Index}`,
                                        name: step4Item.mb_id,
                                        label: step4Item.mb_id,
                                        phone: step4Item.mb_rec_id,
                                        children: [
                                            // {label:'+ 부본사 추가', action: true, id: step4Item.mb_id}
                                        ],
                                    };

                                    if (step4Item.step5 && step4Item.step5.length > 0) {
                                        step4Item.step5.forEach((step5Item, step5Index) => {
                                            const newSubSubSubItem = {
                                                key: `${numIndex + 1}-${step2Index}-${step5Index}-${step4Index}-${step5Index}`,
                                                name: step5Item.mb_id,
                                                label: step5Item.mb_id,
                                                phone: step5Item.mb_rec_id,
                                                children: [
                                                    {label:'+ 부본사 추가', action: true, id: step2Item.mb_id}

                                                ],
                                            };
                                            // newSubSubItem.children.push(newSubSubSubItem);
                                            if (step5Item.step6 && step5Item.step6.length > 0) {
                                                step5Item.step6.forEach((step6Item, step6Index) => {
                                                    const newSubSubSubItem6 = {
                                                        key: `${numIndex + 1}-${step2Index}-${step3Index}-${step4Index}-${step5Index}-${step6Index}`,
                                                        name: step6Item.mb_id,
                                                        label: step6Item.mb_id,
                                                        phone: step6Item.mb_rec_id,
                                                        children: [
                                                            {label:'+ 부본사 추가', action: true, id: step6Item.mb_id}

                                                        ],
                                                    };

                                                    newSubSubSubItem.children.push(newSubSubSubItem6);
                                                });
                                            }
                                            newSubSubItem.children.push(newSubSubSubItem);
                                        });
                                    }
                                    newSubItem.children.push(newSubSubItem);
                                });
                            }
                            newItem.children.push(newSubItem);
                        });
                    }

                    newArr.children.push(newItem);
                });
            }

            result.push(newArr);
        });

        setNodes(result) //result;
    }
    useEffect(() => {
        fetchAgentAddList();
        
    }, []);
    useEffect(() => {
        if (data) {
            transformData(data)
        }
    }, [data])
    useEffect(()=>{
        if(selectedTreeNodeKeys){
            setTitle('본사')
        }
    },[actionType, selectedTreeNodeKeys])
    const onSelectAgent = (e) => {

        if(e.label !=='+ 부본사 추가'){
            setSelectValue(e)
            setActionType('agentInfor')
            fetchAgentDetail({id: e.name, submit: '0'})
        }
        if(e.label ==='+ 부본사 추가'){
            setActionType('addNewAgent')
        }

    }
    const nodeTemplate = (node, options) => {
        let label = <b onClick={() => handleLabelClick(node, options)}>{node.label}</b>;
        
        if (node.phone) {
            label = <div className="text-700" rel="noopener noreferrer">{node.label}</div>;
        }
        
        return <span className={options.className} >{label}</span>;
    }
    const handleLabelClick = (node, options) => {
        if (node.action && node.label === '+ 부본사 추가') {
            setId(node.id)
            setTitle(node.id + ' 아래 부본사 추가')
            var newData = {...dataAgent}
            newData={
                ...dataAgent,
                id: '',
                name: '',
                password: '',
                phone: '',
                bank_name: '',
                bank_no: '',
                bank_own: '',
                lossrng: {rate_1: 0},
                cxrng: {rate_1: 0},
                cxlive: {rate_1: 0},
                // losslive: {rate_1: 0},
                exchange_pw: '',
                status: '2',
            }
            setDataAgent(newData)
        }
    }
    const handleChange =(v, e)=>{
        if(e === '닉네임') setDataAgent({...dataAgent, name: v})
        if(e === '아이디') setDataAgent({...dataAgent, id: v})
        if(e === '비밀번호') setDataAgent({...dataAgent, password: v})
        if(e === '전화번호') setDataAgent({...dataAgent, phone: v})
        if(e === '은행명') setDataAgent({...dataAgent, bank_name: v})
        if(e === '계좌번호') setDataAgent({...dataAgent, bank_no: v})
        if(e === '입금자명') setDataAgent({...dataAgent, bank_own: v})
        if(e === '슬롯 롤링') setDataAgent({...dataAgent, cxrng: {rate_1: v}})
        if(e === '카지노 롤링') setDataAgent({...dataAgent, cxlive: {rate_1: v}})
        if(e === '루징') setDataAgent({...dataAgent, lossrng: {rate_1: v}})
        // if(e === '카지노 루징') setDataAgent({...dataAgent, losslive: {rate_1 :v}})

    }
    const toast = useRef(null)
    const handleActionClick = () =>{
        confirmDialog({
            message: '에이전트 수정 에 성공하셨습니다.',
            header: '에이전트',
            accept: editAgentInfor,
            acceptLabel: '확인',
            acceptClassName: 'p-confirm-dialog-accept'
        });
    }
    function editAgentInfor(){
        const payload = {
            id : dataAgent.id,
            mb_password: dataAgent.password,
            mb_name: dataAgent.name,
            mb_hp: dataAgent.phone,
            mb_bank_name: dataAgent.bank_name,
            mb_bank_no: dataAgent.bank_no,
            mb_bank_own: dataAgent.bank_own,
            mb_exch_pw: dataAgent.exchange_pw,
            mb_status: dataAgent.status,
            Usercredit:dataAgent.user_credit,
            log_before: dataAgent.log_before,
            submit: '1',
            user_options : dataAgent.user_option,
            mb_level: dataAgent.mb_level
        }
        useApi.getBasicData(payload)
            .then((data)=>{
                if(data.code ===200){
                    if(data.code === 200){
                        toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
                        fetchAgentAddList()
                    }
                    else{
                        toast.current.show({ severity: 'error', summary: '성공', detail: data.message, life: 3000 });
                    }
                }
                else{
                    toast.current.show({ severity: 'error', summary: '성공', detail: data.message, life: 3000 });
                }
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    const handleChangeInputNewAgent =(v, e)=>{
        if(e === '닉네임') setDataAgent({...dataAgent, name: v})
        if(e === '아이디') setDataAgent({...dataAgent, id: v})
        if(e === '비밀번호') setDataAgent({...dataAgent, password: v})
        if(e === '전화번호') setDataAgent({...dataAgent, phone: v})
        if(e === '은행명') setDataAgent({...dataAgent, bank_name: v})
        if(e === '계좌번호') setDataAgent({...dataAgent, bank_no: v})
        if(e === '입금자명') setDataAgent({...dataAgent, bank_own: v})
        if(e === '환전 비밀번호') {setExchPw(v); setDataAgent({...dataAgent, exchange_pw: v})}
        if(e === '슬롯 롤링') setDataAgent({...dataAgent, cxrng: {rate_1: `${v}`}})
        if(e === '카지노 롤링') setDataAgent({...dataAgent, cxlive: {rate_1: `${v}`}})
        if(e === '루징') setDataAgent({...dataAgent, lossrng: {rate_1: `${v}`}})
        // if(e === '카지노 루징') setDataAgent({...dataAgent, losslive: {rate_1 :`${v}`}})

    }
    function creatNewAgent(){
        if(dataAgent.name === '') alert("닉네임 비워두면 안됩니다")
        else if(dataAgent.id === '') alert("아이디 비워두면 안됩니다")
        else if(dataAgent.password === '') alert("비밀번호 비워두면 안됩니다")
        else if(dataAgent.phone === '') alert("전화번호 비워두면 안됩니다")
        else if(dataAgent.bank_name === '') alert("은행명 비워두면 안됩니다")
        else if(dataAgent.bank_no === '') alert("계좌번호 비워두면 안됩니다")
        else if(dataAgent.bank_own === '') alert("입금자명 비워두면 안됩니다")
        else if(dataAgent.exchange_pw === '') alert("환전 비밀번호 비워두면 안됩니다")
        else{
            confirmDialog({
                message: '새로운 에이전트 생성하시겠습니까?',
                header: '에이전트',
                accept: createNewAgentAccept,
                acceptLabel: '확인',
                acceptClassName: 'p-confirm-dialog-accept'
            });
        }
    }
    function createNewAgentAccept (){
        const payload ={
            "mb_rec_id": id,
            "mb_id" : dataAgent.id,
            "mb_password" : dataAgent.password,
            "mb_exch_pw": dataAgent.exchange_pw ,
            "mb_bank_no": dataAgent.bank_no,
            "mb_bank_own" : dataAgent.bank_own,
            "mb_hp" : dataAgent.phone,
            "mb_bank_name" : dataAgent.bank_name, 
            "mb_status" : dataAgent.status,
            "mb_name" : dataAgent.name,
            "submit" : "1",
            cxlive: dataAgent.cxlive,
            cxrng: dataAgent.cxrng,
            // losslive: dataAgent.losslive,
            lossrng: dataAgent.lossrng,
            mb_rec_option: '1'
            // 'Usercredit':dataAgent.user_credit,
            // 'log_before': dataAgent.log_before,
            // 'user_options' : dataAgent.user_option,
        }
        useApi.createNewAgent(payload)
            .then((data)=>{
                if(data.code === 200){
                    toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
                    fetchAgentAddList()
                }
                else{
                    toast.current.show({ severity: 'error', summary: '성공', detail: data.message, life: 3000 });
                }
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    function defaultCreateNewAgent (){
        const payload ={
            "mb_id" : dataAgent.id,
            "mb_password" : dataAgent.password,
            "mb_exch_pw": dataAgent.exchange_pw ,
            "mb_bank_no": dataAgent.bank_no,
            "mb_bank_own" : dataAgent.bank_own,
            "mb_hp" : dataAgent.phone,
            "mb_bank_name" : dataAgent.bank_name, 
            "mb_status" : dataAgent.status,
            "mb_name" : dataAgent.name,
            cxlive: dataAgent.cxlive,
            cxrng: dataAgent.cxrng,
            // losslive: dataAgent.losslive,
            lossrng: dataAgent.lossrng,
            "submit" : "1"
        }
        agentList.agentCreateNewAPI(payload)
            .then((data)=>{
                if(data.code === 200){
                    toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
                    fetchAgentAddList()
                }
                else{
                    toast.current.show({ severity: 'error', summary: '성공', detail: data.message, life: 3000 });
                }
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    function handleDefaultCreateNewAgent(){
        // if(!isNew){
        //     alert('hehe') 
        //     return
        // }
        setActionType('default')
        if(dataAgent){
            if(dataAgent.name === '') alert("닉네임 비워두면 안됩니다")
            else if(dataAgent.id === '') alert("아이디 비워두면 안됩니다")
            else if(dataAgent.password === '') alert("비밀번호 비워두면 안됩니다")
            else if(dataAgent.phone === '') alert("전화번호 비워두면 안됩니다")
            else if(dataAgent.bank_name === '') alert("은행명 비워두면 안됩니다")
            else if(dataAgent.bank_no === '') alert("계좌번호 비워두면 안됩니다")
            else if(dataAgent.bank_own === '') alert("입금자명 비워두면 안됩니다")
            else if(dataAgent.exchange_pw === '') alert("환전 비밀번호 비워두면 안됩니다")
            else{
                confirmDialog({
                    message: '새로운 에이전트 생성하시겠습니까?',
                    header: '에이전트',
                    accept: defaultCreateNewAgent,
                    acceptLabel: '확인',
                    acceptClassName: 'p-confirm-dialog-accept'
                });
            }
        }
        else{
            alert("닉네임 비워두면 안됩니다")
            setDataAgent({
                id: '',
                name: '',
                password: '',
                phone: '',
                bank_name: '',
                bank_no: '',
                bank_own: '',
                cxlive: {rate_1: '0'},
                cxrng: {rate_1: '0'},
                // losslive: {rate_1: '0'},
                lossrng: {rate_1: '0'},
                exchange_pw: '',
                status: '',
            })
        }

        
        
    }
    function btnCreateAgent(){
        setActionType('default')
        setDataAgent({
            id: '',
            name: '',
            password: '',
            phone: '',
            bank_name: '',
            bank_no: '',
            bank_own: '',
            cxlive: {rate_1: '0'},
            cxrng: {rate_1: '0'},
            // losslive: {rate_1: '0'},
            lossrng: {rate_1: '0'},
            exchange_pw: '',
            status: '2',
        })
    }
    return (
        <>
             <Toast ref={toast}/>
             <ConfirmDialog />
            <div className='refresh card'>

                <Button className="btn-create" onClick={btnCreateAgent}>최상위 에이전트 생성</Button>
                <div className="tool-add">
                    <Button className="btn-no-benefit">베네핏 누르지마세요</Button>
                    <Button className="btn-no-update">업데이트 누르지마세요</Button>
                </div>
            </div>
            <div className="flex box-info-agent">
                <div className="agent-tree card">
                    <Tree
                        selectionMode="single"
                        selectionKeys={selectedTreeNodeKeys}
                        onSelect={(e) => onSelectAgent(e.node)}
                        onSelectionChange={(e) => { setSelectedTreeNodeKeys(e.value) }}
                        value={nodes}
                        nodeTemplate={nodeTemplate}
                        className="w-full md:w-20rem"
                    />
                </div>
                {actionType ==='default' &&
                    <div className="agent-info-body card">
                        <span>본사</span>
                        {agentInfor.map((v, i) => {
                            return (
                                <div className="row-agent flex">
                                    <span>
                                        {v.title}
                                    </span>
                                    <div className={`cp-input`} key={i}>
                                        <span className={`p-float-label`}>
                                            <InputText
                                                id=''
                                                type="text"
                                                value={v.value}
                                                onChange={(e) => handleChangeInputNewAgent(e.target.value,v.title )}
                                            />
                                            <label htmlFor={v.name}>{v.label}</label>
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="row-agent flex">
                                    <span>
                                        환전 비밀번호
                                    </span>
                                    <div className={`cp-input`}>
                                        <span className={`p-float-label`}>
                                            <InputText
                                                id=''
                                                type="text"
                                                value={exchPw}
                                                onChange={(e) => handleChangeInputNewAgent(e.target.value, '환전 비밀번호')}
                                            />
                                            <label >환전 비밀번호</label>
                                        </span>
                                    </div>
                                </div>
                        <div className='row-agent flex'>
                            <span>
                                상태
                            </span>
                            <div className={`cp-input`}>
                                <span className={`p-float-label`}>
                                <CpDropdown
                                    style={{ minWidth: "100px" }}
                                    value={dropdownValue}
                                    options={dataDropdown}
                                    onChange={(e) => {setDropdownValue(e);setDataAgent({...dataAgent, status: e.code})}}
                                    placeholder=""
                                />

                                </span>
                            </div>

                        </div>
                        <Button onClick={handleDefaultCreateNewAgent}><i className="pi pi-external-link" />&nbsp; 저장 하기 1</Button>
                    </div>
                }
                {actionType ==='addNewAgent' &&
                    <div className="agent-info-body card">
                        <span>{title}</span>
                        {agentInfor.map((v, i) => {
                            return (
                                <div className="row-agent flex">
                                    <span>
                                        {v.title}
                                    </span>
                                    <div className={`cp-input`} key={i}>
                                        <span className={`p-float-label`}>
                                            <InputText
                                                id=''
                                                type="text"
                                                value={v.value}
                                                onChange={(e) => handleChangeInputNewAgent(e.target.value,v.title )}
                                            />
                                            <label htmlFor={v.name}>{v.label}</label>
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="row-agent flex">
                                    <span>
                                        환전 비밀번호
                                    </span>
                                    <div className={`cp-input`}>
                                        <span className={`p-float-label`}>
                                            <InputText
                                                id=''
                                                type="text"
                                                value={exchPw}
                                                onChange={(e) => handleChangeInputNewAgent(e.target.value, '환전 비밀번호')}
                                            />
                                            <label >환전 비밀번호</label>
                                        </span>
                                    </div>
                                </div>
                        <div className='row-agent flex'>
                            <span>
                                상태
                            </span>
                            <div className={`cp-input`}>
                                <span className={`p-float-label`}>
                                <CpDropdown
                                    style={{ minWidth: "100px" }}
                                    value={dropdownValue}
                                    options={dataDropdown}
                                    onChange={(e) => {setDropdownValue(e);setDataAgent({...dataAgent, status: e.code})}}
                                    placeholder=""
                                />

                                </span>
                            </div>

                        </div>
                        <Button onClick={creatNewAgent}><i className="pi pi-external-link" />&nbsp; 저장 하기 2</Button>
                    </div>
                }
                {actionType ==='agentInfor' &&
                    <div className="agent-info-body card">
                        <span>{title}</span>
                        {editAgent.map((v, i) => {
                            return (
                                <div className="row-agent flex" key={i}>
                                    <span>
                                        {v.title}
                                    </span>
                                    <div className={`cp-input`}>
                                        <span className={`p-float-label`}>
                                            <InputText
                                                id=''
                                                type="text"
                                                value={v.value}
                                                onChange={(e) => handleChange(e.target.value, v.title)}
                                                disabled={v.disable}
                                            />
                                            <label htmlFor={v.name}>{v.label}</label>
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                        <Button onClick={handleActionClick}><i className="pi pi-external-link" />&nbsp; 저장 하기 3</Button>
                    </div>
                }
            </div>
        </>
    )
}

export default AgentAdd;