import React, { useEffect, useRef, useState } from "react";
import './SettlementPage.scss'
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { addLocale } from "primereact/api";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import CalculateService from "../../../service/CalculateServices";
import moneyConvert from "../../../utilities/moneyConvert";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
const SettlementPage = () => {
    function convertTZ(date, tzString) {
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString(
                "en-US",
                { timeZone: tzString }
            )
        );
    }
    const today = convertTZ(new Date(), "Asia/Seoul");
    const pass = convertTZ(new Date(), "Asia/Seoul");
    pass.setDate(today.getDate() - 1);
    const [startDate, setStartDate] = useState(pass)
    const [endDate, setEndDate] = useState(today);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [data, setData] = useState([])
    const [totalRecords, setTotalRecords] = useState(0);
    const [inpValue, setInpValue] = useState('')

    const header = [
        { field: 'date', label: '날짜', sortAble: false },
        { field: 'agent_name', label: '에이전트명', sortAble: false },
        { field: 'amounts', label: '정산금', sortAble: false },
        { field: 'actions', label: '승인 / 취소', sortAble: false },
    ]
    addLocale('ko', {
        firstDayOfWeek: 1,
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
    const calculateList = new CalculateService()

    function getSettlementHistory(payload) {
        calculateList.getSettlementHistory(payload)
            .then((data) => {
                const res = data.list
                setData(res.data)
                setTotalRecords(res.total)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    function handleFilterBtnClick() {
        getSettlementHistory({
            sdate: startDate,
            edate: endDate,
            page: page
        })
    }
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    useEffect(() => {
        getSettlementHistory({
            sdate: startDate,
            edate: endDate,
            page: page
        })
    }, [page])
    function onBtnSearch() {
        getSettlementHistory({
            sdate: startDate,
            edate: endDate,
            page: page,
            key: inpValue,
          })
    }
    function confirmApprove(link){
        confirmDialog({
            message: '요청 승인하시겠습니까?',
            header:'통보',
            accept:()=>{accept(link)},
            acceptLabel: '예',
        });
    }
    function accept(link){
        var newApi = link.split('v1/')[1]
        calculateList.actionSettlementAPI(newApi)
            .then((data)=>{
                if(data.code === 200){
                    toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
                    getSettlementHistory({
                        sdate: startDate,
                        edate: endDate,
                        page: page
                    })
                }
                else{
                    toast.current.show({ severity: 'error', summary: '성공', detail: data.message, life: 3000 });
                }
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    function confirmReject(link){
        confirmDialog({
            message: '요청 삭제하시겠습니까?',
            header:'통보',
            accept:()=>{reject(link)},
            acceptLabel: '예',
          });
    }
    function reject (link){
        var newApi = link.split('v1/')[1]
        calculateList.actionSettlementAPI(newApi)
            .then((data)=>{
                if(data.code === 200){
                    toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
                    getSettlementHistory({
                        sdate: startDate,
                        edate: endDate,
                        page: page
                    })
                }
                else{
                    toast.current.show({ severity: 'error', summary: '성공', detail: data.message, life: 3000 });
                }
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    const toast = useRef(null)
    const bodyTemplate = (data, props) => {
        const status = data.action.status
        return (
            <>
                {props.field === 'amounts' &&
                    <div>
                        {moneyConvert(data.amount, true)}
                    </div>
                }
                {props.field === 'actions' &&
                    <>
                        {data.action.status ? <span style={status ==='완료'?{color: 'green'}:{color:'red'}}>{status}</span> :
                        <div className="action-btn">
                            <Button style={{ background: 'green' }} onClick={(e)=>{confirmApprove(data.action.approve.link)}}>승인</Button>
                            <Button style={{ background: 'red' }} onClick={(e)=>confirmReject(data.action.cancel.link)}>취소</Button>
                        </div>
                        }
                    </>
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    return (
        <>
            <Toast ref={toast}/>
            {/* <ConfirmDialog /> */}
            <div className="settlement-page">
                <div className="body card">
                    <span>정착 내역</span>
                    <div className="tool-withdraw-settlement">
                        <div className="date-filter">
                            <Calendar value={startDate}
                                hourFormat="24" onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd" />
                            <Calendar value={endDate} onChange={(e) => setEndDate(e.value)}
                                hourFormat="24" locale="ko" showIcon dateFormat="yy-mm-dd" />
                            <Button className="btn-search" onClick={handleFilterBtnClick}>검색</Button>
                            <InputText style={{marginBottom:'0px !important', marginLeft: '5px'}} placeholder='찾는 ID 입력해주세요' onChange={(e) => setInpValue(e.target.value)} value={inpValue} onKeyDown={(e) => { if (e.key === 'Enter') onBtnSearch() }} />
                        </div>
                        
                    </div>
                </div>
                <div className="inventory card">
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                    <CustomDataTable
                        data={data}
                        headers={header}
                        bodyTemplate={bodyTemplate}
                        scrollable={true}
                        showGridlines={true}
                    />
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
                    />
                </div>
            </div>
        </>

    )
}
export default SettlementPage