import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { Paginator } from "primereact/paginator";
import 'primeicons/primeicons.css';
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import './userTodayList.scss'
import { Dropdown } from "primereact/dropdown";
import CpInput from "../../../components/CpInput";
import { OverlayPanel } from "primereact/overlaypanel";
import moneyConvert from "../../../utilities/moneyConvert";
import { Toast } from "primereact/toast";

const UserTodayList = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0);
    const [visible, setVisible] = useState(false);
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [selectOption, setSelectOption] = useState('선택');
    const [userAction, setUserAction] = useState();
    const [filterValue, setFilterValue] = useState('')
    const [changeStatusUser, setChangeStatusUser] = useState()

    const op = useRef(null);
    const userList = new getUserList();
    const fetchUser = () => {
        userList.getUserTodayList(page)
            .then((data) => {
                const res = data.list
                setData(res.data)
                setTotalRecords(res.total)
            })
    }
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    useEffect(() => {
        if (selectOption === '선택') {
            fetchUser()
        }
        else {
            filterUser(page)
        }
    }, [page])
    const header = [
        { field: "name", label: `아이디, 이름`, sortAble: false },
        { field: "mb_id", label: "닉네임, 폰", sortAble: true },
        { field: "mb_money", label: "보유금액", sortAble: true },
        { field: "rolling", label: "롤링", sortAble: true },
        { field: "action", label: " ", sortAble: false },
        { field: 'bonus', label: "포인트", sortAble: true },
        { field: 'last_charge_money', label: "마지막 충전", sortAble: true },
        { field: 'last_exchange_money', label: "마지막 환전", sortAble: true },
        { field: 'regist_date', label: "가입일", sortAble: true },
        { field: 'login_date', label: "접속일", sortAble: true },
        { field: 'stop_user', label: '', sortAble: false },
        { field: 'agent_tree', label: "에이전트", sortAble: true },
    ];
    const DialogExchange = () =>{
        const categories = [
            { name: '충전', key: 'in_money', mode:'money_result' },
            { name: '환전', key: 'out_money', mode:'money_result_out' }
        ];
        const quickPickMoney = [
            {name: '10,000', value:10000},
            {name: '50,000', value:50000},
            {name: '100,000', value:100000},
            {name: '500,000', value:500000},
            {name: '1,000,000', value:1000000},
        ]
        const [selectedCategory, setSelectedCategory] = useState(categories[1]);
        const [addValue, setAddValue] =useState(0)
        const [pw, setpw] = useState('')

        const id = userAction?.mb_id
        const actionMoneyValue = () =>{
            const payload = {
                admin_pw: pw,
                submit: '1',
                money: `${addValue}`
            }
            userList.chargeExchangeMoney(selectedCategory.mode, selectedCategory.key, id,  payload)
                .then((data)=>{
                    setVisible(false)
                    if(data.code === 200){
                        toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                        fetchUser();
                    }
                    else{
                        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
                        fetchUser();   
                    }
                })
        }
        const handleInputChange = (e) => {
            const rawValue = e;
            const numericValue = parseFloat(rawValue.replace(/,/g, '')) || 0; 
            setAddValue(numericValue); 
            
          };
          function formatNumber(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        return (
            <Dialog visible={visible} onHide={()=>setVisible(false)} >
                <div className="modal-action-money" >
                {categories.map((category) => {
                    return (
                        <div key={category.key}>
                            <RadioButton inputId={category.key} name="category" value={category} onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory.key === category.key}  />
                            <label htmlFor={category.key} className="ml-2">{category.name}</label>
                        </div>
                    );
                })}
                </div>
                <div className="input-button">
                    <InputText value={formatNumber(addValue)}
                        onChange={(e)=>{ handleInputChange(e.target.value)}}
                    />
                    <InputText placeholder="계정 비밀번호" style={{marginLeft:'5px', width:'100px'}} type="password"
                        onChange={(e)=>{ setpw(e.target.value)}}
                    />
                    <Button onClick={actionMoneyValue}>지급/회수</Button>
                </div>
                <div className="button-group-money-pick">
                    {quickPickMoney.map((e, index)=>{
                        return(
                            <Button key={index} onClick={()=> setAddValue(e.value)}>{e.name}</Button>
                        )
                    })}
                </div>
                <Button className="cancel-button" onClick={()=>setVisible(false)}>
                    정정
                </Button>
            </Dialog>
        )
    }
    function formatMoney(value) {
        return new Intl.NumberFormat('ko-KR').format(value);
    }
    function formatDate(value){
        if(value ===''){
            return '-'
        }
        else{
            return value
        }
    }
    function formatName(id, tel){
        return (
            <div>
                <span>{id}</span>
                <br />
                <span>{`(${tel})`}</span>
            </div>
        )
    }
    const bodyTemplate = (data, props) => {
        // console.log(data, props?.field);
        const tel = data?.mb_hp
        const id = data?.mb_id
        const formattedValue = props.field === 'mb_money' || props.field === 'rolling' ? formatMoney(data[props.field]) : props.field === 'last_charge_money' || props.field === 'last_exchange_money'?formatDate(data[props.field]): props.field ==='mb_id'? formatName(id, tel):  data[props.field];
        const status = data?.mb_status
        return (
            <>
                {props.field === 'name' &&
                    <div onClick={() => { window.open(`#/userdetail/${data.mb_id}`, '', "width=1500,height='100vh'") }}>
                        <span className="p-column-title">{props.header}</span>
                        {data.mb_name}
                        &nbsp;
                        <i className="pi pi-star" style={{ fontSize: ' 12px', color: 'rgb(255, 87, 34)' }} /><br />
                        {data.mb_id}

                    </div>
                }
                {props.field === 'mb_ids' &&
                    <div>
                        <span>{id}</span>
                        <br />
                        <span>{`(${tel})`}</span>
                    </div>
                }
                <span className="p-column-title">{props.header}</span>
                {formattedValue}
                {props.field === 'last_inmoney' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {data.last_charge_money ? data.last_charge_money : '-'}
                    </>
                }
                {props.field === 'last_change_point' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {data.last_exchange_money ? data.last_exchange_money : '-'}
                    </>
                }
                {props.field === 'money' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {formattedValue}
                    </>
                }
                {props.field === 'action' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <Button onClick={(e) => { setVisible(true); setUserAction(data) }}>충/환전</Button>
                    </>
                }
                {props.field === "agent_tree" && (
                    <div className="container-box-agentBalance">
                      {data.agent.map((i, index) => {
                        const color = [
                          "#ff5722",
                          "#2196f3",
                          "#ffc107",
                          "#4caf50",
                          "#4eff14",
                          "#d92e11",
                        ];
                        const bgcolor = [
                          "#ff5722",
                          "#000",
                          "#4caf50",
                          "#ff7214",
                          "#4eff14",
                          "#d92e11",
                        ];
                        // console.log(i);
                        return (
                          <React.Fragment key={index}>
                            {i.before && (
                              <span
                                className="box-agentBalance"
                                style={{ backgroundColor: bgcolor[index] ?? "#d92e11" }}
                              >
                                {i?.before}
                              </span>
                            )}{" "}
                            <span style={{ color: color[index] ?? "#d92e11" }}>
                                &nbsp;{i?.value}
                            </span>{" "}
                            <span style={{ color: "#000", fontWeight: "700" }}>
                              {index < 5 ?i?.after:''}
                            </span>{" "}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
                {props.field === 'stop_user' &&
                    <>
                        {status !== "2" ?
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <Button className="active-user"
                                    onClick={(e) => {
                                        setChangeStatusUser(2)
                                        setUserAction(data)
                                        setMessageModel({ message: '유저 정보 를 변경하시겠습니까', header: '유저 저장' });
                                        setOpenModalConfirm(true)
                                    }}>
                                    사용
                                </Button>
                            </>
                            :
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <Button className="stop-user"
                                    onClick={(e) => {
                                        setChangeStatusUser(0)
                                        setUserAction(data)
                                        setMessageModel({ message: '유저 정보 를 변경하시겠습니까', header: '유저 저장' });
                                        setOpenModalConfirm(true)
                                    }}>
                                    중지
                                </Button>
                            </>
                        }
                    </>
                }
            </>
        );
    };
    const action = () => {
        const payload = {
            status: changeStatusUser,
            id: userAction.mb_id
        }
        userList.chargeStatusMember(payload)
            .then((data) => {
                if (data.code === 200) {
                    toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                    fetchUser();
                }
                else {
                    toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
                    fetchUser();
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const menuDropdown = [
        '선택',
        '아이디',
        '이름',
        '추천인',
        '전화번호',
        '에이전트',
        'IP 검색',
        '계좌번호',
        '닉네임'
    ]

    const keyfield = [
        { mb_id: "아이디" },
        { mb_name: "이름" },
        { mb_rec_id: "추천인" },
        { mb_hp: "전화번호" },
        { agent: "에이전트" },
        { ip: "IP 검색" },
        { mb_bank_no: "계좌번호" },
        { nickname: "닉네임" }
    ]
    const chooseOption = (e) => {
        if (e === '선택') {
            setFilterValue('')
        }
        setSelectOption(e)
    }
    function findCorrespondingKey(v) {
        for (const item of keyfield) {
            const key = Object.keys(item)[0];
            const value = item[key];

            if (value === v) {
                return key;
            }
        }

        return null;
    }
    const filterUser = (page) => {
        const params = {
            keyfield: findCorrespondingKey(selectOption),
            key: filterValue
        }
        userList.getUserTodayList(page, params)
            .then((data) => {
                const res = data.list
                setData(res.data)
                setTotalRecords(res.total)
            })
    }
    const onHandleSearch = () => {
        if (selectOption === '선택') {
            fetchUser()
        }
        else {
            filterUser(0)
        }
    }
    const toast = useRef(null)

    return (
        <>
            <DialogExchange visible={visible} className='dialog-exchange' />
            <Toast ref={toast}/>

            <div className='refresh'>
                <div className="tool">
                    <div className="cp-dropdown">
                        <Dropdown
                            options={menuDropdown}
                            onChange={(e) => chooseOption(e.value)}
                            value={selectOption}
                        />
                    </div>
                    <span className={`p-float-label`}>
                        <InputText
                            type="text"
                            value={filterValue}
                            onChange={(e) => setFilterValue(e.target.value)}
                            onKeyDown={(e)=> {if(e.key ==='Enter') onHandleSearch()}}
                        />
                        <label>검색</label>
                    </span>
                    <Button onClick={onHandleSearch}>검색</Button>
                </div>
                <OverlayPanel ref={op}>
                    <span onClick={(e) => op.current.toggle(e)}><i className="pi pi-file" /> 유저 저장</span>
                </OverlayPanel>
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
                <CustomDataTable data={data} headers={header} bodyTemplate={bodyTemplate} modalMessage={messageModal} action={action} openConfirm={openModalConfirm} sortMode="multiple" scrollable={true}/>
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
            </div>
        </>
    )
}

export default UserTodayList