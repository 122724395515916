import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import './Lobby.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { RadioButton } from "primereact/radiobutton";
import SettingService from "../../../service/SettingService";
import { Toast } from "primereact/toast";

const radioGroup = [
    {label:'사용', value: 0},
    {label:'미사용', value: 1}
]

const Lobby = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [first, setFirst] = useState(0);
    const arrayGameId = []
    const settingList = new SettingService();
    function settingLobby (){
        const payload = {
            pnum: 30,
            page: page,
        }
        settingList.lobbyMainApi(payload)
        .then((data) => {
            const res = data.data
            setData(res.data)
            setTotalRecords(res.total)
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    function editLobby (payload){
        settingList.lobbyMainActionApi(payload)
            .then((data)=>{
                if(data.code === 200){
                    toast.current.show({
                      severity: "success",
                      summary: "성공",
                      detail: data.message,
                      life: 3000,
                    });
                  }
                  else{
                    toast.current.show({
                      severity: "error",
                      summary: "성공",
                      detail: data.message,
                      life: 3000,
                    });
                  }
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    const RenderRadioGroup =({radioGroup,data, value})=>{
        const [selectUse, setSelectUse] = useState(value)
        const handleChange = (v, id, mask_langs) => {
            setSelectUse(v);
            const index = arrayGameId.findIndex(item => item.no === id);
            if (index !== -1) {
              arrayGameId[index].ishidden = v;
            }
            else{
                arrayGameId.push({no: id, ishidden: v, lobby_id: data.lobby_id})
            }
        };
        
        return (
          <>  
            {radioGroup.map((v, i)=>{
                return(
                    <>
                        <RadioButton 
                            inputId={`ingredient${data?.no}`}
                            value={v.value}
                            onChange={(e) => {handleChange(e.value, data.no, data.mask_langs)}}
                            checked={selectUse === v.value }
                            key={data?.no} />
                        <label className="ml-2" htmlFor={`ingredient${data?.no}`}>{v.label}</label>
                    </>
                    )
                })
            }
        </>
        )
    }
    const header = [
        { field: "vendor", label: "카테고리", sortAble: false },
        {field:'lobby_id', label:'로비 아이디', sortAble:false},
        { field: "english", label: `영어`, sortAble: false },
        { field: "korean", label: `한국어`, sortAble: false },
        { field: "use", label: "사용", sortAble: false },
        { field: "queue_number", label: "정렬 순서", sortAble: false },
        { field: "edit", label: `수정`, sortAble: false },
    ];
    useEffect(() => {
        settingLobby()
    }, [page])
   
    const confirm1 = (id) => {
        confirmDialog({
            message: '정보를 변경하시겠습니까?',
            header: '설정',
            accept:()=>{action(id)},
            acceptLabel: '확인',
            acceptClassName: 'p-confirm-dialog-accept'
        });
    };
    const action = (id)=>{
        const foundObject = arrayGameId.find(item => item.no === id);
        if(foundObject){
            editLobby(foundObject)
        }
      }
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    const handleChangePosition =(data, id, lobby_id)=>{
        const index = arrayGameId.findIndex(item => item.no === id);
        if (index !== -1) {
          arrayGameId[index].position = data;
        }
        else{
            // arrayGameId.push({no: id, position:data, mask_langs: JSON.parse(mask_langs)})
            arrayGameId.push({no: id, position:data, lobby_id: lobby_id})
        }
    }
    const handleChangeKoreaLang =(data, id, lobby_id)=>{
        const index = arrayGameId.findIndex(item => item.no === id);
        if (index !== -1) {
          arrayGameId[index].mask_title = data;
        }
        else{
            arrayGameId.push({no: id, mask_title: data, lobby_id: lobby_id})
        }
    }
    const bodyTemplate = (data, props, rowData) => {
        const lang = JSON.parse(data.mask_langs || '{}')
        return (
            <>
                {props.field === 'edit' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div className="btn">
                            <Button onClick={()=>{console.log(data); confirm1(data.no)}}>확인</Button>
                        </div>
                    </>
                }
                {props.field ==='queue_number' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        
                        <div className={`table-input`}>
                            <span className={`p-float-label`}>
                                <InputText
                                id=''
                                type="number"
                                defaultValue={data.position !== null ? data.position : ''}
                                onChange={(e) => handleChangePosition(e.target.value, data.no, data.lobby_id)}
                                />
                                <label ></label>
                            </span>
                        </div>
                    </>
                }
                { props.field ==='korean' &&
                 <>
                    <span className="p-column-title">{props.header}</span>
                    
                    <div className={`table-input`}>
                        <span className={`p-float-label`}>
                            <InputText
                            id=''
                            type="text"
                            defaultValue={data?.mask_title || ''}
                            onChange={(e) => handleChangeKoreaLang(e.target.value, data.no, data.lobby_id)}
                            />
                            <label ></label>
                        </span>
                    </div>
                </>
                }
                { props.field ==='use' &&
                    <RenderRadioGroup radioGroup={radioGroup} data={data} value={data.ishidden}/>
                }
                {props.field === 'english' &&
                    <span>{data.title} </span>
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    const toast = useRef(null)
    return (
        <>  
            <Toast ref={toast}/>
            <div className='refresh card'>
                <span className="text">로비 설정</span>
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
                <CustomDataTable
                    data={data}
                    headers={header}
                    bodyTemplate={bodyTemplate}
                    modalMessage={messageModal}
                    openConfirm={openModalConfirm}
                    scrollable={true}
                    selectionMode="multiple"
                />
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
            </div>
        </>
    )
}

export default Lobby;
