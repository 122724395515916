import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import './Betting.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../../components/Table/TableUser";
import UserService from "../../../../service/UserService";
import { useParams } from "react-router-dom";
import moneyConvert from "../../../../utilities/moneyConvert";
import { DateComponent } from "@fullcalendar/core/internal";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
const HistoryBetting = (props) => {
    const today = new Date()
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0)
    const [page, setPage] = useState(0);
    const [data, setData] = useState([]);
    const [tab, changeTab] = useState(0);
    const [mode, setMode] = useState('casino')
    const useList = new UserService()
    const [tabHeader, setTabHeader] = useState('카지노');
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndexHis, setActiveIndexHis] = useState(0);
    const [tabHeaderHis, setTabHeaderHis] = useState('마지막충전');
    const [paramsChange, setParamsChange] = useState(5)
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState();
    const id = useParams()

    const headerCasino = [
        { field: "betRegDate", label: `IDX 배팅일`, sortAble: false },
        { field: "vendor", label: "아이디 에이전트", sortAble: false },
        { field: "betting_value", label: "배팅금 / 이긴금액 / 차액", sortAble: false },
        { field: "Balance", label: "남은금액", sortAble: false },
        { field: "Agent", label: "에이전트/지급/퍼센트/보유", sortAble: false },
        { field: 'gameTitle', label: "정보", sortAble: false },
        { field: 'result', label: "결과", sortAble: false },

    ];
    const headerSlot = [
        { field: "betRegDate", label: `IDX 배팅일`, sortAble: false },
        { field: "vendor", label: "아이디 에이전트", sortAble: false },
        { field: "betting_value", label: "배팅금 이긴금액 차액", sortAble: false },
        { field: "Balance", label: "남은금액", sortAble: false },
        { field: "Agent", label: "에이전트/지급/퍼센트/보유", sortAble: false },
        { field: 'gameTitle', label: "정보", sortAble: false },
        { field: 'result', label: "결과", sortAble: false },
    ]
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    const TabCasinoHistory = [
        { header: '마지막충전', data: data },
        { header: '마지막두번째', data: data },
        { header: '마지막세번째', data: data },
        { header: '마지막네번째', data: data },
    ]
    const TabSlotHistory = [
        { header: '마지막충전', data: data },
        { header: '마지막두번째', data: data },
        { header: '마지막세번째', data: data },
        { header: '마지막네번째', data: data },
    ]
    const bodyTemplate = (data, props) => {
        const betMoney = data?.betTotal;
        const winMoney = data?.winTotal;
        const total = data?.betTotal - data?.winTotal
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
                {props.field === 'betting_value' &&
                    <div>
                        <span>
                            <span style={{ color: 'green' }}>{moneyConvert(data?.betTotal)}</span> / <span style={{ color: 'red' }}>{moneyConvert(data?.winTotal)}</span> / <span style={betMoney > winMoney ? { color: 'green' } : betMoney < winMoney ? { color: 'red' } : { color: 'black' }}> {moneyConvert(total)}</span>
                        </span>
                    </div>
                }
                {props.field === 'result' &&
                    <span>{total > 0 ? <span style={{ color: 'green' }}>이기다</span> : total < 0 ? <span style={{ color: 'red' }}>잃다</span> : <span>무승부</span>}</span>
                }
            </>

        )
    }
    function fetchHistoryBetting() {
        const payload = {
            mode: mode,
            ...id,
            sdate: formatDate(startDate) || '',
            edate: !endDate ? '' : formatDate(endDate),
            page: page,
        }
        useList.fetchUserBettingHis(payload)
            .then((data) => {
                console.log(data)
                const res = data?.data
                setData(res?.data)
                setTotalRecords(res?.total)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        if (tabHeader === '슬롯') {
            setMode('slot')
        }
        else {
            setMode('casino')
        }
    }, [tab, tabHeader, mode])
    console.log(page)
    useEffect(() => {
        fetchHistoryBetting()
    }, [mode, page, startDate, endDate])
    const handleTabChange = (e) => {
        setActiveIndex(e.index);
        setTabHeader(e.originalEvent.target.innerText)
    };
    function formatDate(date) {
        var yyyy = date?.getFullYear();
        var mm = String(date?.getMonth() + 1).padStart(2, '0');
        var dd = String(date?.getDate()).padStart(2, '0');
        return yyyy + '-' + mm + '-' + dd
    }
    addLocale('ko', {
        firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
    return (
        <TabView activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e)}>
            <TabPanel header='카지노' className="history-betting">
                <div className="inventory card">
                    <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                    <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={100}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
                    />
                    <CustomDataTable rows={100} data={data} headers={headerCasino} bodyTemplate={bodyTemplate} onShowButton={false} scrollable={true} />
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={100}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
                    />
                </div>
                {/* <TabView activeIndex={activeIndexHis}  onTabChange={(e)=>handleButtonClick(e)}>
                {TabCasinoHistory.map((v, i)=>{
                    return (
                    <TabPanel header={v.header}>
                        <div className="inventory card">
                            <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                                rows={100}
                                template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
                            />
                            <CustomDataTable rows={100} data={data} headers={headerCasino} bodyTemplate={bodyTemplate} onShowButton={false} scrollable={true}/>
                            <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                                rows={100}
                                template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
                            />
                        </div>
                    </TabPanel>
                    )
                })}
                </TabView> */}
            </TabPanel>
            <TabPanel header='슬롯' className="history-betting">
                <div className="inventory card">
                    <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                    <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={100}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
                    />
                    <CustomDataTable rows={100} scrollable={true} data={data} headers={headerSlot} bodyTemplate={bodyTemplate} onShowButton={false} />
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={100}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
                    />
                </div>
            </TabPanel>
        </TabView>
    )

}

export default HistoryBetting