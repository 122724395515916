import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";
import moment from "moment-timezone";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { addLocale } from "primereact/api";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import AgentService from "../../service/AgentServices";
import { InputText } from "primereact/inputtext";

const dataDropdown = [
  { name: "선택", code: "ALL" },
  { name: "아이디", code: "OT1" },
  { name: "에이전트", code: "OT2" },
  { name: "에이전트(하부포함)", code: "OT3" },
];
const dataButtonGroup = [
  { name: "배팅시간", code: "0" },
  { name: "이긴금액", code: "1" },
  { name: "배팅금액", code: "2" },
];

const Calculate = () => {
  function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }
  const today = convertTZ(new Date(), "Asia/Seoul")
  const pass = convertTZ(new Date(), "Asia/Seoul")
  pass.setDate(today.getDate() - 1)
  const [page, setPage] = useState(0);
  const [dropdownValue, setDropdownValue] = useState("");
  const [startDate, setStartDate] = useState(pass);
  const [totalRecords, setTotalRecords] = useState(0);
  const [endDate, setEndDate] = useState(today);
  const agentList = new AgentService();
  const [first, setFirst] = useState(0);
  const [totalData, setTotalData] = useState();
  const [loadTotal, setLoadtotal] = useState(false);
  const [inpValue, setInpValue] = useState('')
  const [data, setData] = useState([
    {
      value1: 1,
      value2: 2,
      value3: 3,
      value4: 4,
      value5: 5,
      value6: 6,
      value7: 7,
      value8: 8,
      value9: 9,
      value10: 10,
    },
    {},
  ]);
  const navigate = useNavigate();
  addLocale("ko", {
    firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
    dayNames: [
      "일요일",
      "월요일",
      "화요일",
      "수요일",
      "목요일",
      "금요일",
      "토요일",
    ],
    dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
    dayNamesMin: ["일", "월", "화", "수", "목", "금", "토"],
    monthNames: [
      "1월",
      "2월",
      "3월",
      "4월",
      "5월",
      "6월",
      "7월",
      "8월",
      "9월",
      "10월",
      "11월",
      "12월",
    ],
    monthNamesShort: [
      "1월",
      "2월",
      "3월",
      "4월",
      "5월",
      "6월",
      "7월",
      "8월",
      "9월",
      "10월",
      "11월",
      "12월",
    ],
    today: "오늘",
    clear: "지우기",
  });

  function fetchAgentCalculate(condition) {
    const payload = {
      page: page,
      //   pnum:30 ,
      ...condition,
    };
    agentList
      .fetchAgentCalculate(payload)
      .then((data) => {
        const res = data?.data;
        setData(res?.data);
        setTotalRecords(res.total);
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function fetchAgentCalculateTotal(condition) {
    setLoadtotal(true);
    const payload = {
      page: page,
      process: "total",
      //   pnum:30 ,
      ...condition,
    };
    agentList
      .fetchAgentCalculate(payload)
      .then((data) => {
        const res = data?.total;
        setTotalData(res);
        setLoadtotal(false);
      })
      .catch((err) => {
        setLoadtotal(false);
        console.log(err);
      });
  }
  const handleSearch = () => {
    const dayRang = getLimit(startDate, endDate);
    if (dayRang > 90) {
      toast.current.show({
        severity: "warn",
        summary: "성공",
        detail: "검색기간은 3개월 미만이어야 합니다.",
        life: 3000,
      });
    } else if (dayRang < 0) {
      toast.current.show({
        severity: "warn",
        summary: "성공",
        detail: "시작일은 종료일 이전이어야 합니다.",
        life: 3000,
      });
    } else {
      fetchAgentCalculate({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        id: inpValue
      });
      fetchAgentCalculateTotal({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        id: inpValue
      });
    }
  };
  function handleInpSearch(){
    const dayRang = getLimit(startDate, endDate);
    if (dayRang > 90) {
      toast.current.show({
        severity: "warn",
        summary: "성공",
        detail: "검색기간은 3개월 미만이어야 합니다.",
        life: 3000,
      });
    } else if (dayRang < 0) {
      toast.current.show({
        severity: "warn",
        summary: "성공",
        detail: "시작일은 종료일 이전이어야 합니다.",
        life: 3000,
      });
    } else if(!inpValue) {
      fetchAgentCalculate({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
      });
      fetchAgentCalculateTotal({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
      });
    }
    else if(inpValue){
      fetchAgentCalculate({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
        id: inpValue
      });
      fetchAgentCalculateTotal({
        sdate: formatDate(startDate),
        edate: formatDate(endDate),
      });
    }
  }
  function getLimit(sdate, edate) {
    const StartDate = new Date(sdate);
    const EndDate = new Date(edate);
    const timeDifference = EndDate.getTime() - StartDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
  }
  const formatDate = (date) => {
    var yyyy = date?.getFullYear();
    var mm = String(date?.getMonth() + 1).padStart(2, "0");
    var dd = String(date?.getDate()).padStart(2, "0");
    return yyyy + "-" + mm + "-" + dd;
  };
  function formatMoney(value) {
    // if (value !== "Number") return 0;
    return new Intl.NumberFormat("ko-KR").format(value);
  }
  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
  };
  useEffect(() => {
    fetchAgentCalculate({
      sdate: formatDate(startDate),
      edate: formatDate(endDate),
    });
    fetchAgentCalculateTotal({
      sdate: formatDate(startDate),
      edate: formatDate(endDate),
    });
  }, [page]);
  useEffect(()=>{
  },[totalData])
  const toast = useRef(null);
  // console.log(loadTotal)
  return (
    <div className="prid calculate">
      <Toast ref={toast} />
      <div className="col-12 p-3 card mb-4 header md:p-4">
        <div className="col-12 mb-2 p-0" style={{ fontSize: "16px" }}>
          정산 기록
        </div>
        <div className="col-12 de-wi-box p-0 flex flex-wrap mb-3 align-items-end">
          <div className="p-0 sm:col-6 md:col-3 xl:col-2 flex align-items-end">
            <Calendar
              value={startDate}
              onChange={(e) => setStartDate(e.value)}
              locale="ko"
              showIcon
              dateFormat="yy-mm-dd"
            />
          </div>
          <div className="p-0 sm:col-6 mt-2 md:col-3 xl:col-2 sm:mt-2 flex align-items-end">
            <Calendar
              value={endDate}
              onChange={(e) => setEndDate(e.value)}
              locale="ko"
              showIcon
              dateFormat="yy-mm-dd"
            />
          </div>
          <div className=" flex align-items-end">
            <Button
              label="확 인"
              className="ml-2 mr-5"
              onClick={handleSearch}
            />
          </div>
          <div>
          <InputText style={{marginBottom:'0px !important'}} placeholder='찾는 ID 입력해주세요' onChange={(e) => setInpValue(e.target.value)} value={inpValue} onKeyDown={(e) => { if (e.key === 'Enter') handleSearch() }} />
          </div>
        </div>
      </div>
      <div className="card">
        {/* <div className="pagi-box">
          <CpPagination onChange={(e) => console.log(e)} total={0} />
        </div> */}
        <Paginator
          first={first}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
          rows={30}
          template={{
            layout:
              "FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink",
          }}
        />
        <div className="infor-de-wi-ad my-3">
          <div
            className="depost-infor p-0"
            style={{ maxHeight: "800px", position: "relative" }}
          >
            <div
              className="header p-0"
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
                borderTop: "1px solid",
              }}
            >
              <div className="item" style={{ width: "8.94%" }}>
                <span className="p-column">에이전트</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">센터충전</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">센터환전</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">에이젼트 지급</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">에이젼트 회수</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">회원 잔액</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">배팅</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">당첨</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">배팅 - 당첨</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">에이전트 충전</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">에이전트 환전</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">에이전트 잔액</span>
              </div>
              <div className="item" style={{ width: "7.56%" }}>
                <span className="p-column">매출액</span>
              </div>
            </div>
            <div className="content-box">
              {!loadTotal && totalData? (
                <div className="item-infor head">
                  <div className="item" style={{ width: "8.94%" }}>
                    <span className="p-column">정산 합계</span>
                  </div>
                  <div
                    className="item"
                    style={{
                      width: "7.56%",
                    }}
                  >
                    <span className="p-column">
                      {formatMoney(totalData?.money_center_charge)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.money_center_exchange)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.agent_pay)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.agent_recovery)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.money_agent)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.bet)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.win)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.sub)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.charge_money)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.exchange_money)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.money_member)}
                    </span>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <span className="p-column">
                      {formatMoney(totalData?.sale)}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="item-infor head">
                  <div className="item" style={{ width: "8.94%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                  <div className="item" style={{ width: "7.56%" }}>
                    <Skeleton></Skeleton>
                  </div>
                </div>
              )}

              {data &&
                data.length > 0 &&
                data.map((v, i) => (
                  <div className="item-infor" key={i}>
                    <div className="item" style={{ width: "8.94%" }}>
                      <span className="p-column">{v.mb_id}</span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">
                        {formatMoney(v.money_center_charge)}
                      </span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">
                        {formatMoney(v.money_center_exchange)}
                      </span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">
                        {formatMoney(v.agent_pay)}
                      </span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">
                        {formatMoney(v.agent_recovery)}
                      </span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">
                        {formatMoney(v.money_member)}
                      </span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">{formatMoney(v.bet)}</span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">{formatMoney(v.win)}</span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">{formatMoney(v.sub)}</span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">
                        {formatMoney(v.charge_money)}
                      </span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">
                        {formatMoney(v.exchange_money)}
                      </span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">
                        {formatMoney(v.money_agent)}
                      </span>
                    </div>
                    <div className="item" style={{ width: "7.56%" }}>
                      <span className="p-column">{v.sale}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <Paginator
          first={first}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
          rows={30}
          template={{
            layout:
              "FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink",
          }}
        />
      </div>
    </div>
  );
};

export default Calculate;
