import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import CountryService from "../service/CountryService";

const CpAutoComplete = (props) => {
  const {
    defaulValue = null,
    onChange,
    filtValue = [],
    placeholder = "",
    name = "",
  } = props;
  const [autoFilteredValue, setAutoFilteredValue] = useState([]);
  const [selectedAutoValue, setSelectedAutoValue] = useState("");

  useEffect(() => {
    if (defaulValue) {
      setAutoFilteredValue(defaulValue);
    }
  }, []);

  const searchCountry = (event) => {
    if (!event.query.trim().length) {
      setAutoFilteredValue([...filtValue]);
    } else {
      setAutoFilteredValue(
        filtValue.filter((e) => {
          return e.name.toLowerCase().includes(event.query.toLowerCase());
        })
      );
    }
  };
  const changeComplete = (e) => {
    if (typeof e.value === "string") {
      onChange?.(e.value);
    } else {
      onChange?.(e.value?.name);
    }
    setSelectedAutoValue(e.value);
  };

  return (
    <div className="cp-auto-complete">
      <AutoComplete
        placeholder={placeholder}
        id="dd"
        name={name}
        value={selectedAutoValue}
        onChange={changeComplete}
        suggestions={autoFilteredValue}
        completeMethod={searchCountry}
        field="name"
      />
    </div>
  );
};

export default CpAutoComplete;
