import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { Paginator } from "primereact/paginator";
import 'primeicons/primeicons.css';

import { InputText } from "primereact/inputtext";
import './BlockIp.scss'

const BlockIp = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0);    
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [changeStatusUser, setChangeStatusUser] = useState()
    const [userAction, setUserAction] = useState();
    const [inpIP, setInpIP] = useState('')
    const [memoIP, setMemoIP] = useState('')

    const userList = new getUserList();
    const fetchUser = () => {
        userList.getUserBlockedIP(page)
            .then((data) => {
                const res = data?.data
                setData(res?.data)
                setTotalRecords(res?.total ? res.total : 0)
            })
    }
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    useEffect(() => {
        fetchUser()
    }, [page])
    const header = [
        { field: "ip", label: `아이피`, sortAble: false },
        { field: "ip_memo", label: "아이디" , sortAble: false },
        { field: 'regDate', label: "접속일" , sortAble: false },
        { field: 'btn_action', label: '삭제', sortAble: false}
    ];
   
    const bodyTemplate = (data, props) => {
        const id = data?.mb_id
        const status = data?.mb_status
        return (
          <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
                {props.field === 'mb_ids' &&
                    <div onClick={() => { window.open(`#/userdetail/${data.mb_id}`, '', "width=1500,height='100vh'") }}>
                        <span className="p-column-title">{props.header}</span>
                        <span>{id}</span>&nbsp;
                        <i className="pi pi-star" style={{fontSize:' 12px', color: 'rgb(255, 87, 34)'}}/>
                    </div>
                }
                {props.field === 'btn_action' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <Button className="active-user"
                            onClick={(e) => {
                                setChangeStatusUser(2)
                                setUserAction(data)
                                setMessageModel({ message: '차단 삭제하시겠습니까?', header: 'ip 차단 삭제' });
                                setOpenModalConfirm(true)
                            }}>
                            사용
                        </Button>
                    </>
                }
          </>
        );
      };    
      const action = () => {
        const payload = 
            {
                submit: "1",  
                process: "black_ip_deldata",  
                ip: userAction.ip ,
                ip_memo: '',
                no: userAction.no
            }
        userList.blockIP(payload)
            .then(()=>{
                fetchUser()
            })
    }
      
    const onHandleBanIP = () => {
        const payload = 
            {
                submit: "1",  
                process: "block_ip_add",  
                ip: inpIP ,
                ip_memo: memoIP
            }
        userList.blockIP(payload)
            .then(()=>{
                fetchUser()
            })
        
    }
      return (
        <>  
            <div className='refresh'>
                <div className="tool-blockip">
                    <span className={`p-float-label`}>
                        <InputText
                            type="text"
                            value={inpIP}
                            onChange={(e) => setInpIP(e.target.value)}
                        />
                        <label>아이피</label>
                    </span> 
                    <span className={`p-float-label`}>
                        <InputText
                            type="text"
                            value={memoIP}
                            onChange={(e) => setMemoIP(e.target.value)}
                        />
                        <label>저장</label>
                    </span>                   
                    <Button onClick={onHandleBanIP}>등록</Button>
                </div>
            </div>
                <div className="inventory card">
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                    <CustomDataTable    
                        data={data} 
                        headers={header} 
                        bodyTemplate={bodyTemplate} 
                        modalMessage={messageModal} 
                        action={action} 
                        openConfirm={openModalConfirm} 
                        showGridlines={true}
                        groupRowsBy='ip'
                    />
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                </div>
        </>
    )
}

export default BlockIp