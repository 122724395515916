import { mainAxios } from "../libs/axios";
import {buildMyQueryString} from '../utilities/query';

const accessToken =  localStorage.getItem('User')
const jsonToken = JSON.parse(accessToken)
const token = `Bearer ${jsonToken}`

export default class AdminService {
  getHelpTemplate(payload){
    return mainAxios.request({
      methodType: "GET",
      url: `/board?mode=sample01`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token
        },
      },
    });
  }
  actionHelpTemplate(url, payload){
    return mainAxios.request({
        methodType: "POST",
        url: `/${url}`,
        payload: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            contentType: "application/json",
            Authorization: token
          },
        },
      });
  }
  getEditLog(query, payload){
    return mainAxios.request({
      methodType: "GET",
      url: `/config${buildMyQueryString({...query, mode: 'edit_log'})}`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token
        },
      },
    });
  }
}
