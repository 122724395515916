import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";
import moment from "moment-timezone";
import './CalculateAgent.scss'
import moneyConvert from "../../utilities/moneyConvert";

import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { addLocale } from "primereact/api";
import CalculateService from "../../service/CalculateServices";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../components/Table/TableUser";
import { formatDate } from "../../utilities/date";


const CalculateAgent = () => {
  const currentDate = moment().toDate();
  const nextDate = moment().add(1, 'days').toDate();
  const [dropdownValue, setDropdownValue] = useState();
  const [menuDropDown, setMenuDropDown] = useState()
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0)
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(nextDate);
  const [totalData, setTotalData] = useState()
  const [id , setId] = useState()
  const header = [
    {
      field: "date", label: `날짜 (년/월/일)`,
      value: `정산 합계`,
      sortAble: false
    },
    {
      field: "charges", label:`충전`,
      value: totalData?.charge,
      sortAble: false
    },
    {
      field: "exchanges", label: '환전', value: `${totalData?.exchange}`,
      sortAble: false
    },
    {
      field: "partner_payments", label: '파트너 지급', value: totalData?.partner_payment,
      sortAble: false
    },
    {
      field: "partner_deductions", label: '파트너 차감', value: totalData?.partner_deduction, sortAble: false
    },
    {
      field: "current_moneys", label: '회원 잔액', value: totalData?.current_money, sortAble: false
    },
    { field: "bettings", label: "배팅", value: totalData?.betting, sortAble: false },
    { field: "winnings", label: `당첨`, value: totalData?.win, sortAble: false },
    { field: "bet_wins", label: `배팅 - 당첨`, value: totalData?.sub_betting_win, sortAble: false },
    { field: "partner_charges", label: '파트너 충전', value: totalData?.partner_charge, sortAble: false },
    {
      field: "partner_exchanges", label: '파트너 환전',
      value: totalData?.partner_exchange, sortAble: false
    },
    { field: "partner_balances", label: '파트너 잔액', value: totalData?.partner_balance, sortAble: false },
    {
      field: "revenues", label: '매출액',
      value: totalData?.revenue,
      sortAble: false
    }
  ]
  const chooseOption = (e) => {
    console.log(e)
    setDropdownValue(e)
    if(e.mb_id !=='all'){
      setId({mb_id: e.mb_id})
    }
    else{
      setId({mb_id: 'all'})
    }
  }
  const calculateList = new CalculateService()
  function getCalculateAgentList (param){
    calculateList.calculateAgent(param)
        .then((data)=>{
          var arr = [{mb_name:'에이전트 선택', mb_id:'all'}]
          Object.keys(data.general.filter.mb_id).forEach(key => {
            const value = data.general.filter.mb_id[key];
            arr.push(value)
          });
          setMenuDropDown(arr)
          setData(data.list.details.data)
          setTotalRecords(data.list.details.total)
          setTotalData(data.list.total)
        })
        .catch((err)=>{
          console.log(err)
        })
  }
  useEffect(()=>{
    getCalculateAgentList({ sdate: formatDate(startDate), edate: formatDate(endDate)})
  },[])
  useEffect(()=>{
  },[menuDropDown, totalData])
  addLocale('ko', {
    firstDayOfWeek: 1,
    dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
    monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    today: '오늘',
    clear: '지우기'
  });
  function handleFilterBtnClick() {
    if( id && id?.mb_id !== 'all'){
      getCalculateAgentList({mb_id: id.mb_id, sdate: formatDate(startDate), edate: formatDate(endDate)})
    }
    else{
      getCalculateAgentList({ sdate: formatDate(startDate), edate: formatDate(endDate)})
    }
  }
  const headerGroup = (
    <ColumnGroup>
      <Row>
        {header.map((v, i) => {
          return (
            <Column header={v.label} />
          )
        })}
      </Row>
      <Row >
        {header.map((v, i) => {
          return (
            <Column header={v.value} style={{background:'rgba(139, 195, 74, 0.34)'}}/>
          )
        })}
      </Row>

    </ColumnGroup>
  ); 
  const bodyTemplate = (data, props) => {
    return (
      <>
        
        {props.field === 'charges' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'red'}}> {data.charge}</span>
            </div>
          </>
        }
        {props.field === 'exchanges' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'blue'}}> {data.exchange}</span>
            </div>
          </>
        }
        {props.field === 'partner_payments' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'red'}}> {data.partner_payment}</span>
            </div>
          </>
        }
       {props.field === 'partner_deductions' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'blue'}}> {data.partner_deduction}</span>
            </div>
          </>
        }
        {props.field === 'current_moneys' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'green'}}> {data.current_money}</span>
            </div>
          </>
        }
        {props.field === 'bettings' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'red'}}> {data.betting}</span>
            </div>
          </>
        }
        {props.field === 'winnings' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'red'}}> {data.win}</span>
            </div>
          </>
        }
        {props.field === 'bet_wins' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'blue'}}> {data.sub_betting_win}</span>
            </div>
          </>
        }
        {props.field === 'partner_charges' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'red'}}> {data.partner_charge}</span>
            </div>
          </>
        }
        {props.field === 'partner_exchanges' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'red'}}> {data.partner_exchange}</span>
            </div>
          </>
        }
        {props.field === 'partner_balances' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'red'}}> {data.partner_balance}</span>
            </div>
          </>
        }
        {props.field === 'revenues' &&
          <>
            <span className="p-column-title">{props.header}</span>
            <div>
              <span style={{color:'blue'}}> {data.revenue}</span>
            </div>
          </>
        }
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    )
  }
  const onPageChange = (event) => {
    setPage(event.page + 1)
    setFirst(event.first);
  };
  return (
    <div className="prid calculate-agent">
      <div className="col-12 p-3 card mb-4 header md:p-4">
        <div className="col-12 mb-2 p-0" style={{ fontSize: "16px" }}>
          정산 기록
        </div>
        <div className="item-box">
          <div className="box-item">

            <Dropdown
              options={menuDropDown}
              onChange={(e) => chooseOption(e.value)}
              value={dropdownValue}
              optionLabel="mb_name"
              placeholder="에이전트 선택"
              filter 
            />
          </div>
          <div className="box-item">
            <div className="date-filter-calculate">
              <Calendar value={startDate}
                hourFormat="24" onChange={(e) => setStartDate(e.value ? e.value : currentDate)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
              <Calendar value={endDate} onChange={(e) => setEndDate(e.value ? e.value : '')}
                hourFormat="24" locale="ko" showIcon dateFormat="yy-mm-dd"/>
              <div className="item1">
                <Button onClick={handleFilterBtnClick}>검색</Button>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div className="card">
      <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
          rows={30}
          template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

        />
        <CustomDataTable
          data={data}
          headers={header}
          bodyTemplate={bodyTemplate}
          
          scrollable={true}
          showGridlines={true}
          headerColumnGroup={headerGroup}
        />
        <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
          rows={30}
          template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
        />
        
      </div>
    </div>
  );
};

export default CalculateAgent;
