import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import './AgentNotice.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { Editor } from "primereact/editor";
import { confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import AgentService from "../../../service/AgentServices";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";

const AgentNotice = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0);
    const [statusNotice, setStatusNotice] = useState();
    const [bodyContent, setBodyContent] = useState('');
    const [title, setTitle] = useState('')
    const [payload, setPayload] = useState({
        "type": "list",  // list: Show List || Otherwise: Processing (POST side)
        "mb_type": "agent",
    })
    const [saveParams, setSaveParams] = useState('')
    const [deleteParams, setDeleteParams] = useState('')
    const [posX, setPosX] = useState('')
    const [posY, setPosY] = useState('')
    const toast = useRef(null)
    const [ingredients, setIngredients] = useState([]);
    const [checked, setChecked] = useState(false);
    const header = [
        { field: "checkbox", label: <Checkbox onChange={(e) => pickall(e)} checked={checked}></Checkbox>, sortAble: false },
        { field: "board_id", label: `번호`, sortAble: false },
        { field: "mb_id", label: "에이전트", sortAble: false },
        { field: "board_subjects", label: "타이틀", sortAble: false },
        { field: "action", label: "", sortAble: false },

    ];
    const agentList = new AgentService();
    function fetchAgentNoticeList() {
        const newParams = {
            ...payload,
            page: page
        }
        agentList.agentNoticeListAPI(newParams)
            .then((data) => {
                const res = data.list
                setData(res.data)
                setTotalRecords(res.total)
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        fetchAgentNoticeList()
    }, [page])
    useEffect(()=>{},[bodyContent, title, statusNotice])
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    function onHandleEdit(e){
        const urlAction = new URL(e?.handle?.show?.link)
        const urlSaveAction = new URL(e?.handle?.save?.link)
        const urlDeleteAction = new URL(e?.handle?.delete?.link)
        setSaveParams(urlSaveAction.search)
        setDeleteParams(urlDeleteAction.search)
        const queryString  = urlAction.search
        if(e.board_field7 === '1'){
            setStatusNotice('1')
        }else{
            setStatusNotice('0')
        }
        agentList.agentRegisterActionAPI(`board${queryString}`)
            .then((data)=>{
                const res = data?.data?.general
                setTitle(res?.board_subject)
                setBodyContent(res?.board_content)
                setPosX(e.pos_x)
                setPosY(e.pos_y)
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    const bodyTemplate = (data, props) => {
        return (
            <>
                {props.field === 'checkbox' &&
                    <Checkbox onChange={(e) => onIngredientsChange(e)} value={data.board_id} checked={ingredients.includes(data.board_id)}></Checkbox>
                }
                {props.field === 'board_subjects' &&
                    <span style={{ whiteSpace: 'break-spaces' }}>
                        {data.board_subject}
                    </span>
                }
                {props.field === 'action' &&
                    <>
                        <div className="icon-btn" style={{ boxShadow: '0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16)' }}
                            onClick={() => onHandleEdit(data)}
                        >
                            <span ><i style={{ color: 'white' }} className="pi-file-edit pi" /></span>
                        </div>
                    </>
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];
    
        if (e.checked)
          _ingredients.push(e.value);
        else
          _ingredients.splice(_ingredients.indexOf(e.value), 1);
    
        setIngredients(_ingredients);
      }
      function pickall(e) {
        let newArr = []
        setChecked(e.checked)
        if (e.checked) {
          for (let i = 0; i < data.length; i++) {
            newArr.push(data[i].board_id)
            setIngredients(newArr)
          }
        }
        else {
          setIngredients([])
        }
      }
      function deleteBoardId() {
        if (ingredients.length > 0) {
          confirmDialog({
            message: "삭제하시겠습니까?",
            header: "고객센터",
            accept: confirmDeteleBoardId,
            acceptLabel: "확인",
            acceptClassName: "p-confirm-dialog-accept",
          });
        }
      }
      function confirmDeteleBoardId() {
        var newStr = ingredients.join('|')
        agentList.agentNoticeActionAPI({
          "type": 'delete',
          "id": newStr.toString()
        }).then((data) => {
          if (data.code === 200) {
            toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
            fetchAgentNoticeList();
            setIngredients([])
          }
          else {
            toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
          }
        }).catch((err) => { console.log(err) })
      }
    const changeEditor = (e) => {
        setBodyContent(e)
    }
    const confirm1 = () => {
        confirmDialog({
            message: '고객센터 작성/수정 성공.',
            header: '고객센터',
            accept: accept,
            acceptLabel: '확인',
            acceptClassName: 'p-confirm-dialog-accept'
        });
    };
    const deleteNotice = () => {
        confirmDialog({
            message: '고객센터 삭제되었습니다.',
            header: '고객센터',
            accept: acceptDelete,
            acceptLabel: '확인',
            acceptClassName: 'p-confirm-dialog-accept'
        });
    }
    const accept = () => {
        if(saveParams){
            agentList.agentEditNoticeAPI(`board${saveParams}`, {
                "board_subject": title,
                "board_content":bodyContent,
                pos_x: posX,
                pos_y: posY,
                board_field7: statusNotice,
                board_field4: 1
                })
                .then((data) => {
                    if(data.code === 200){
                        toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
        
                    }
                    else{
                        toast.current.show({ severity: 'error', summary: '성공', detail: data?.error.message, life: 3000 });
        
                    }
                })
                .catch((err) => { console.log(err) })
        }
        else{
            agentList.agentEditNoticeAPI(`board?mode=user_agent_notice&board=board03`, {
                "board_subject": title,
                "board_content":bodyContent,
                pos_x: posX,
                pos_y: posY,
                board_field7: statusNotice,
                board_field4: 1
                })
                .then((data) => {
                    if(data.code === 200){
                        toast.current.show({ severity: 'success', summary: '성공', detail:'공지 생성되었습니다', life: 3000 });
        
                    }
                    else{
                        toast.current.show({ severity: 'error', summary: '성공', detail: data?.error.message, life: 3000 });
        
                    }
                })
                .catch((err) => { console.log(err) })
        }
        
    }
    const acceptDelete = () => {
        agentList.agentEditNoticeAPI(`board${deleteParams}`, {
            "board_subject": title,
            "board_content":bodyContent,
            pos_x: posX,
            pos_y: posY,
            board_field7: statusNotice
            })
            .then((data) => {
                toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                fetchAgentNoticeList()
            })
            .catch((err) => { console.log(err) })
    }
    
    return (
        <>  
            <Toast ref={toast}/>
            <div className='refresh card'>
                <span className="text">에이전트 공지</span>
            </div>
            <div className="grid-div-notice">
                <div className="item1 card">
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                    />
                    <CustomDataTable
                        data={data}
                        headers={header}
                        bodyTemplate={bodyTemplate}
                    />
                    <div className="item-row">
                        <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                            rows={30}
                            template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                        />
                        <Button onClick={deleteBoardId} severity="warning" >삭제</Button>
                    </div>
                </div>
                <div className="item2 card">
                    <div className='cp-input'>
                        <span className='p-float-label'>
                            <InputText
                                id=''
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            //   onKeyDown={(e) => onKeyDown?.(e)}
                            />
                            <label htmlFor="username">Title</label>
                        </span>
                    </div>

                    <Editor value={bodyContent}
                        onTextChange={(e) => changeEditor(e.htmlValue)}
                        style={{ height: `320px` }}
                    />
                    
                    <div className='input'>
                        <span className='p-float-label'>
                            <InputText
                                id=''
                                type="text"
                                value={posX}
                                onChange={(e) => setPosX(e.target.value)}
                            //   onKeyDown={(e) => onKeyDown?.(e)}
                            />
                            <label htmlFor="pos">Pos X</label>
                        </span>
                    </div>
                    <div className='input'>
                        <span className='p-float-label'>
                            <InputText
                                id=''
                                type="text"
                                value={posY}
                                onChange={(e) => setPosY(e.target.value)}
                            //   onKeyDown={(e) => onKeyDown?.(e)}
                            />
                            <label htmlFor="pos">Pos Y</label>
                        </span>
                    </div>
                    <div className="radius">
                        <div className="flex align-items-center">
                            <RadioButton inputId="ingredient1" name="active" value="0" onChange={(e) => { setStatusNotice(e.value) }} checked={statusNotice === '0'} />
                            <label htmlFor={statusNotice} className="ml-2">숨김 </label>
                        </div>
                        <div className="flex align-items-center">
                            <RadioButton inputId="ingredient2" name="banned" value="1" onChange={(e) => { setStatusNotice(e.value) }} checked={statusNotice === '1'} />
                            <label htmlFor={statusNotice} className="ml-2">보기</label>
                        </div>                    
                    </div>
                    <div className="button-bottom">
                        <Button onClick={confirm1}>저장 하기</Button>
                        <Button style={{ background: 'red' }} onClick={deleteNotice}>삭제</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgentNotice;