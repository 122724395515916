import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import './SettingPage.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { RadioButton } from "primereact/radiobutton";
import SettingService from "../../../service/SettingService";
import { mute, delayPlaySound, addNewSound } from "../../../utilities/sound";

const SettingPage = () => {
    const setting = new SettingService()
    const [selectOption, setSelectOption] = useState('');
    const [inpValue, setInpValue] = useState('')
    const [selectPopupCheck, setSelectPopupCheck] = useState('아니오');
    const [alarmChange, setAlarmChange] = useState('알람1');
    const [userAction, setUserAction] = useState('아니오');
    const [inpMaxReward1, setInpMaxReward1] = useState('');
    const [inpMaxReward2, setInpMaxReward2] = useState('')
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [data, setData] = useState(undefined);
    const localMedia = JSON.parse(localStorage.getItem('media'));

    useEffect(() =>{
        setting.fetchSettings().then(({form}) =>{
            setSelectPopupCheck(form.site_close.value === '1' ? '에' : '아니오')
        }).catch(() =>{
        })
        const mediaFileNum =  localMedia?.sound.replace('Alarm', '').replace('.mp3', '')
        setAlarmChange(`알람${mediaFileNum || '01'}`)
    }, [])
    const confirm1 = () => {
        confirmDialog({
            message: '레벨정보가 업데이트 되어습니다.',
            header: '레벨',
            accept,
            acceptLabel: '확인',
            acceptClassName: 'p-confirm-dialog-accept'
        });
    };
    const accept = () => {
        action()
    }
    const action = () => {
        console.log('this is function')
    }
    const chooseOption = (e) => {
        console.log(e)
        setSelectOption(e)
    }
    const CheckPopupService = () => {
        const menuPopupCheck = [
            '에',
            '아니오'
        ]
        const handleButton = () =>{
            const val = selectPopupCheck === '에'? '1' : '0'
            setting.postSettings({site_close: val, submit: 1}).then(() =>{
                // localStorage.setItem('media', JSON.stringify({...localMedia, muted: +val}))
                // if(val === '1'){
                //     mute()
                // }else{
                //     delayPlaySound()
                // }
            }).catch(() =>{
                alert('error')
            })
        }
        return (
            <>
                <span>
                    서비스 점검 팝업
                </span>
                <Dropdown
                    options={menuPopupCheck}
                    onChange={(e) => setSelectPopupCheck(e.value)}
                    value={selectPopupCheck}
                />
                <Button onClick={handleButton}>적용</Button>
            </>
        )
    }
    const AlarmChange = () => {
        const menuAlarm = [
            '알람01', '알람11', '알람12',
            '알람13', '알람14', '알람15'
        ]
        const handleButton = () =>{
            const alarm = alarmChange.replace('알람', '')
            localStorage.setItem('media', JSON.stringify({...localMedia, sound: `Alarm${alarm}.mp3`}))
            addNewSound(`Alarm${alarm}.mp3`)
        }
        return (
            <>
                <span>
                    알람음 변경
                </span>
                <Dropdown
                    options={menuAlarm}
                    onChange={(e) => setAlarmChange(e.value)}
                    value={alarmChange}
                />
                <Button onClick={handleButton}>적용</Button>
            </>
        )
    }
    const UserMoneyAction = () => {
        const menuUserAction = [
            '에',
            '아니오'
        ]
        return (
            <>
                <span>
                    하단 모든 유저 회수/지급
                </span>
                <Dropdown
                    options={menuUserAction}
                    onChange={(e) => setUserAction(e.value)}
                    value={userAction}
                />
                <Button>적용</Button>
            </>
        )
    }
    const handleChange = (e, condition) => {
        const value = e.replace(/\D/g, ''); 
        const formattedValue = Number(value).toLocaleString();
        if(condition === 'inp1'){
            setInpMaxReward1(formattedValue);
        }
        else if(condition === 'inp2'){
            setInpMaxReward2(formattedValue);
        }
      };
    return (
        <>
            <div className='refresh card'>
                <span className="text">설정</span>
                <div className="tool-setting">
                    <div>
                        <CheckPopupService />
                    </div>
                    <div>
                        <AlarmChange />
                    </div>
                </div>
            </div>
            <div className="inventory-setting card">
                <span> 텔레그램 추가후 @notificationBalance </span>
                <span>
                    <span className="red">asa</span> {'<='} 해당코드 입력후 아래 보이는 텔레그램 업데이트 버튼을 눌러주세요
                </span>
                <Button>텔레그램 업데이트</Button>
            </div>
        </>
    )
}

export default SettingPage;
