import { mainAxios } from "../libs/axios";

export const dashboardAPI = (params) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/dashboard?mode=index`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
