import { Paginator } from "primereact/paginator";
import React, { useEffect, useState } from "react";
import CustomDataTable from "../../../../components/Table/TableUser";
import UserService from "../../../../service/UserService";
import { useParams } from "react-router-dom";

const LoginIpTab = () => {
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0)
    const [page, setPage] = useState(0);
    const [data, setData] = useState([]);
    const userList = new UserService();
    const params = useParams()
    const fetchUser = () => {
        const payload = {
            id: params.id,
            page: page
        }
        userList.fetchUserIp(payload)
            .then((data) => {
                const res = data
                console.log(res)
                setData(res?.data?.data)
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    useEffect(()=>{
        fetchUser()
    },[page])
    const bodyTemplate = (data, props) => {
        console.log(data, props)
        return(
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>

        )
     }
    const headerLoginIp = [
        { field: "os", label: `OS`, sortAble: false },
        { field: "browser", label: "browser", sortAble: false },
        { field: "ip", label: "아이피", sortAble: false },
        { field: "domain", label: "도매인", sortAble: false },
        { field: "regdate", label: "요청시간", sortAble: false },
        { field: 'login_status', label: "결과", sortAble: false },
    ];
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    return (
        <div className="inventory">
            <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                rows={30}
                template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

            />
            <CustomDataTable data={data} headers={headerLoginIp} bodyTemplate={bodyTemplate} onShowButton={false} />
            <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                rows={30}
                template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

            />
        </div>
    )
}

export default LoginIpTab