import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { Paginator } from "primereact/paginator";
import 'primeicons/primeicons.css';
import { InputText } from "primereact/inputtext";
import './UserDomain.scss'
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

const UserDomain = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0);
    const [inpCode, setInputCode] = useState();
    const [domainRegister, setDomainRegister] = useState();
    const [listDomain, setListDomain] = useState();
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [userAction, setUserAction] = useState();
    const [menuDropDown, setMenuDropDown] = useState([])
    const [selectOption, setSelectOption] = useState()
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    const toast = useRef(null)
    const userList = new getUserList();
    const fetchUser = () => {
        userList.getUserDomainList(page)
            .then((data) => {
                const res = data?.list
                setData(res?.data)
                setTotalRecords(res?.total)
                setListDomain(data?.register?.mb_domain?.options)
            })
    }
    useEffect(() => {
        fetchUser()
    }, [page])
    useEffect(() => {
        transformMbDomainToMenuOptions()
    }, [data])
    useEffect(() => {
        if (selectOption) {
            const result = findNumberForSelectOption(selectOption, listDomain);
            setDomainRegister(result);
        }
    }, [selectOption])
    const header = [
        { field: "mb_domain", label: `도메인`, sortAble: true },
        { field: "mb_code", label: "코드", sortAble: true },
        { field: "mb_domain_reg", label: "생성일", sortAble: true },
        { field: "btn_action", label: "삭제", sortAble: false }
    ];
    function transformMbDomainToMenuOptions() {
        var list = []
        listDomain?.map((v) => {
            if (v) {
                const domains = v[1]
                list.push(domains)
            }
        })
        setMenuDropDown(list)
        setSelectOption(list[0])
    }
    const bodyTemplate = (data, props) => {
        const handleResetDomain = (data) => {
            setUserAction(data)
            setMessageModel({ message: '유저 도메인 삭제하시겠습니까?', header: '유저 저장' });
            setOpenModalConfirm(true)
        }
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
                {props.field === 'btn_action' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <Button style={{ background: 'red' }} onClick={() => handleResetDomain(data)}>삭제</Button>
                    </>
                }
            </>
        );
    };
    const onHandleCreateDomain = () => {
        userList.createDomain(inpCode, domainRegister)
            .then((data) => {
                toast.current.show({
                    severity: 'success',
                    summary: '성공',
                    detail: data?.message,
                    life: 3000
                })
            })
            .then(() => {
                fetchUser()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const action = () => {
        const payload = {
            id: userAction?.mb_code,
            // mb_domain: numberDomain
        }
        userList.resetDomain(payload)
            .then((data) => {
                if(data.code === 200){
                    toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                    fetchUser();
                }
                else{
                    toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
                    fetchUser();   
                }
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    const chooseOption = (e) => {
        setSelectOption(e)
    }
    function findNumberForSelectOption(option, array) {
        for (const item of array) {
            if (item[1] === option) {
                return item[0];
            }
        }
        return null;
    }
    return (
        <>
            <Toast ref={toast}/>
            <div className='refresh'>
                <span className="title">가입 도메인 등록</span>
                <div className="tool-kit">
                    <span className={`p-float-label`}>
                        <InputText
                            type="text"
                            value={inpCode}
                            onChange={(e) => setInputCode(e.target.value)}
                        />
                        <label>가입 코드</label>
                    </span>
                    <span className={`p-float-label`}>
                        <Dropdown
                            options={menuDropDown}
                            onChange={(e) => chooseOption(e.value)}
                            value={selectOption}
                        />
                        <label>도메인</label>
                    </span>
                    <Button onClick={onHandleCreateDomain}>도메인 등록</Button>
                </div>
            </div>
            <div className="col-12 lg:col-12">
                <div className="inventory card">
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                    <CustomDataTable data={data}
                        headers={header}
                        bodyTemplate={bodyTemplate}
                        scrollable={true}
                        action={action}
                        sortAble={true}
                        openConfirm={openModalConfirm}
                        modalMessage={messageModal}
                        sortMode='multiple' />
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                </div>
            </div>
        </>
    )
}

export default UserDomain