import { mainAxios } from "../libs/axios";
import { buildMyQueryString } from "../utilities/query";
const accessToken =  localStorage.getItem('User')
const jsonToken = JSON.parse(accessToken)
const token = `Bearer ${jsonToken}`
export default class AgentService {
  agentHelpListAPI(payload) {
    return mainAxios.request({
      methodType: "GET",
      url: `/board?mode=user_agent_help&board=board01`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  getActionApi(link) {
    return mainAxios.request({
      methodType: "GET",
      url: `/${link}`,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentHelpActionAPI(payload) {
    return mainAxios.request({
      methodType: "POST",
      url: `/board?mode=user_agent_help&board=board01`,
      payload: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentNoteListAPI(payload) {
    return mainAxios.request({
      methodType: "GET",
      url: `/member?mode=user_agent_note`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentNoteSendApi(payload) {
    return mainAxios.request({
      methodType: "POST",
      url: `/member?mode=user_agent_note`,
      payload: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentCodeListAPI(payload) {
    return mainAxios.request({
      methodType: "GET",
      url: `/member?mode=agent_code_list`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentCodeActionAPI(params, payload) {
    return mainAxios.request({
      methodType: "POST",
      url: `/${params}`,
      payload: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentRegisterListAPI(params) {
    return mainAxios.request({
      methodType: "POST",
      url: `/member?mode=agent_reg_list`,
      params: params,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentRegisterActionAPI(params) {
    return mainAxios.request({
      methodType: "POST",
      url: `/${params}`,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentRemoveListAPI(params) {
    return mainAxios.request({
      methodType: "GET",
      url: `/member?mode=agent_remove_list`,
      params: params,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentNoticeListAPI(params) {
    return mainAxios.request({
      methodType: "GET",
      url: `/board?mode=user_agent_notice&board=board03`,
      params: params,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentNoticeActionAPI(params) {
    return mainAxios.request({
      methodType: "POST",
      url: `/board?mode=user_agent_notice&board=board03`,
      payload: params,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentEditNoticeAPI(params, payload) {
    return mainAxios.request({
      methodType: "POST",
      url: `/${params}`,
      payload: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentDetailAPI(query) {
    const handleEndpoint = () => {
      if (query?.mode) {
        if (query.mode === "agent_detail") return "member";
        return "money";
      } else {
        return "member";
      }
    };
    return mainAxios.request({
      methodType: "GET",
      url: `/${handleEndpoint()}${buildMyQueryString({
        ...query,
        mode: query.mode || "agent_detail",
      })}`,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  updateAgentAPI({ id, tab }, payload) {
    return mainAxios.request({
      methodType: "POST",
      url: `/member?mode=agent_detail&id=${id}&tab=${tab}`,
      payload: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentCreateNewAPI(payload){
    return mainAxios.request({
      methodType: "POST",
      url: `/member?mode=member_form`,
      payload: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  postAgentMoney(query) {
    return mainAxios.request({
      methodType: "POST",
      url: `/member${buildMyQueryString(query)}`,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentAddAPI() {
    return mainAxios.request({
      methodType: "GET",
      url: `/agent?mode=tree`,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentWithdraw(payload) {
    return mainAxios.request({
      methodType: "GET",
      url: `/member?mode=agent_withdraw`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  getDepWithHisNewAPI(payload){
    return mainAxios.request({
      methodType: "GET",
      url: `/member?mode=agent_withdraw`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          contentType: "application/json",
          Authorization: token,
        },
      },
    });
  }
  getDepositHis(payload) {
    return mainAxios.request({
      methodType: "GET",
      url: `/money?mode=money_list_0`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      },
    });
  }
  getWithdrawHis(payload) {
    return mainAxios.request({
      methodType: "GET",
      url: `/money?mode=money_list_1`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      },
    });
  }
  agentBalanceLog(payload) {
    return mainAxios.request({
      methodType: "GET",
      url: `/money?mode=agent_balance_log`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      },
    });
  }
  fetchAgentBalaceList(payload) {
    return mainAxios.request({
      methodType: "GET",
      url: `/agent?mode=balance`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: `${token}`,
        },
      },
    });
  }
  fetchAgentChangeStatusBalace(link) {
    return mainAxios.request({
      methodType: "GET",
      url: `/${link}`,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: `${token}`,
        },
      },
    });
  }
  fetchAgentCalculate(payload) {
    return mainAxios.request({
      methodType: "GET",
      url: `/agent?mode=calculate`,
      params: payload,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: `${token}`,
        },
      },
    });
  }
  fetchAgentBalanceLog(payload){
    return mainAxios.request({
      methodType: "GET",
      url: `/money${buildMyQueryString(payload)}`,
      requiresToken: true,
      notError: true,
      getError: true,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: `${token}`,
        },
      },
    });
  }
};
