import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";
import moment from "moment-timezone";
import MoneyService from "../../service/DepositeWithdraw";

import moneyConvert from "../../utilities/moneyConvert";
import {
  CpButton,
  CpButtonIcon,
  CpInput,
  CpPagination,
  CpDatePicker,
  CpDropdown,
  CpButtonGroup,
} from "../../components";
import { Dropdown } from "primereact/dropdown";
import { formatDate } from "../../utilities/date";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";

const dataDropdown = [
  { name: "선택", code: "" },
  { name: "아이디", code: "id" },
  { name: "이름", code: "name" },
  { name: "에이전트", code: "role" },
];

const DeporsitAndWithdrawAdmin = () => {
  const {fetchUserMoneyAdmin} = new MoneyService()
  const [dropdownValue, setDropdownValue] = useState({ name: "선택", code: "" });
  const [sDate, setSDate] = useState(new Date())
  const [eDate, setEDate] = useState(new Date())
  const [page, setPage] = useState(1);
  const [data, setData] = useState(undefined);
  const [total, setTotal] = useState(undefined)
  const inputRef = useRef('');

  const getData = (query) =>{
    const myQuery = {
      sdate: formatDate(sDate),
      edate: formatDate(eDate)
    }
    fetchUserMoneyAdmin(query || myQuery).then(resp =>{
      setData({...resp.data })
      setTotal({...resp.total})
    }).catch(() =>{

    })
  }

  useEffect(getData, [])

  const handlePageChange = (p) =>{
    if(!sDate || !eDate) return
    if(dropdownValue.code && !inputRef.current ) return
    const myQuery = {
      sdate: formatDate(sDate),
      edate: formatDate(eDate),
      page: p,
      ...(dropdownValue.code && { [dropdownValue.code]: inputRef.current })
    }
    setPage(p)
    getData(myQuery)
  }

  const handleFilter = () =>{
    if(!sDate || !eDate) return
    if(dropdownValue.code && !inputRef.current ) return
    const myQuery = {
      sdate: formatDate(sDate),
      edate: formatDate(eDate),
      page: 1,
      ...(dropdownValue.code && { [dropdownValue.code]: inputRef.current })
    }
    setPage(1)
    console.log(myQuery);
    getData(myQuery)
  }

  const makeTotal = useMemo(() =>{
    return +(total?.charge?.sum.replaceAll(',', '')) - +(total?.exchange?.sum.replaceAll(',', ''))
  }, [total])
  addLocale('ko', {
    firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
    dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
    monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    today: '오늘',
    clear: '지우기'
});
  return (
    <div className="prid de-wi-ad-box">
      <div className="col-12 p-3 card mb-4 header md:p-4">
        <div className="col-12 mb-4 p-0" style={{ fontSize: "16px" }}>
          입급/출금 완료
        </div>
        <div className="col-12 de-wi-box p-0 sm:flex sm:flex-wrap">
          <div className="p-0 sm:col-6 md:col-3 xl:col-3 flex align-items-end">
            {/* <CpDatePicker showTime={false} defaulDate={sDate} onChange={(e) => setSDate(prev => prev = e)} dateFormat="yy-mm-dd"/> */}
            <Calendar value={sDate} onChange={(e) => setSDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
          </div>
          <div className="p-0 sm:col-6 mt-2 md:col-3 xl:col-3 sm:mt-2 flex align-items-end">
            {/* <CpDatePicker  showTime={false}  defaulDate={eDate} onChange={(e) => setEDate(prev => prev = e)} dateFormat="yy-mm-dd"/> */}
            <Calendar value={eDate} onChange={(e) => setEDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>

          </div>
          <div className="p-0 sm:col-6 mt-3 md:col-3 xl:col-2 flex align-items-end md:mt-0">
            <CpDropdown
              style={{ minWidth: "100px" }}
              value={dropdownValue}
              options={dataDropdown}
              onChange={(e) => setDropdownValue({...e})}
              placeholder=""
            />
          </div>
          <div className="p-0 mt-1 sm:col-6 md:col-3 xl:col-3 flex align-items-end">
            <CpInput onChange={(e) => inputRef.current = e} placeholder="검색" onKeyDown={(e)=> {if(e.key ==='Enter') handleFilter()}}/>
            <CpButtonIcon onClick={handleFilter} icon="pi pi-search" severity="" aria-label="" />
          </div>
        </div>
        <div className="total col-12 p-0 mt-4">
          <div className="infor-total col-12 sm:col-8 ">
            <div className="p-0 infor-header col-12 flex">
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item red">
                  <i className="pi pi-info-circle mr-1"></i>충전
                </div>
              </div>
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">
                  <div className="icon">
                    <i className="pi pi-info mr-1"></i>
                  </div>
                  환전
                </div>
              </div>
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">
                  <div className="icon">
                    <i className="pi pi-info mr-1"></i>
                  </div>
                  충전 - 환전
                </div>
              </div>
            </div>
            <div className="p-0 infor-content col-12 flex">
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">{total?.charge?.sum || 0}</div>
              </div>
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">{total?.exchange?.sum || 0}</div>
              </div>
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">{moneyConvert(makeTotal || 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="pagi-box">
        {data && <CpPagination page={page} defaulRows={data?.per_page} onChange={handlePageChange} total={data?.total} />} 
        </div>
        <div className="infor-de-wi-ad">
          <div className="depost-infor p-0">
            <div className="header p-0">
              <div className="item" style={{ width: "100px" }}>
                <span className="p-column">종류</span>
              </div>
              <div className="item" style={{ width: "120px" }}>
                <span className="p-column">아이디</span>
              </div>
              <div className="item" style={{ width: "120px" }}>
                <span className="p-column">닉네임</span>
              </div>
              <div className="item" style={{ width: "200px" }}>
                <span className="p-column">입금자/출금자</span>
              </div>
              <div className="item" style={{ width: "100px" }}>
                <span className="p-column">입금/출금</span>
              </div>
              <div className="item" style={{ width: "250px" }}>
                <span className="p-column">보너스/롤링</span>
              </div>
              <div className="item" style={{ width: "120px" }}>
                <div className="p-column">
                  <span>금일충전</span>
                  <br />
                  <span>금일환전</span>
                </div>
              </div>
              <div className="item" style={{ width: "140px" }}>
                <div className="p-column">
                  <span>신청날짜</span>
                  <br />
                  <span>완료날짜</span>
                </div>
              </div>
              <div className="item" style={{ width: "200px" }}>
                <div className="p-column">
                  <span>소속</span>
                  <br />
                  <span>소속트리</span>
                </div>
              </div>
              <div className="item" style={{ width: "80px" }}>
                <span className="p-column">상태</span>
              </div>
            </div>
            <div className="content-box" style={{ height: '500px'}}>
              {(data && data?.data?.length) ?
                data.data.map((v, i) => (
                  <div className="item-infor" key={i}>
                    <div className="item" style={{ width: "100px" }}>
                      <div className="p-message" style={{backgroundColor: v.mode === 'money_list_0' ? '#8bc34a' : '#ffc107'}}>
                        <span
                          className="material-icons mr-1"
                          style={{
                            color: "#fff",
                            fontSize: "18px",
                            marginBottom: "-2px",
                          }}
                        >
                          {v.mode === 'money_list_0' ? 'check_circle' : 'warning'}
                        </span>
                        {v.mode === 'money_list_0' ? '지급' : '회수'}
                      </div>
                    </div>
                    <div className="item" style={{ width: "120px" }}>
                      {v.mb_id}
                      <i
                        className="pi pi-star"
                        style={{ color: "#ff5722", marginTop: "-4px" }}
                      ></i>
                    </div>
                    <div className="item" style={{ width: "120px" }}>
                      {v.mb_name}
                    </div>
                    <div className="item" style={{ width: "200px" }}>
                      {v.ok_admin}
                    </div>
                    <div
                      className="item"
                      style={{ width: "100px", color: "#ff0000" }}
                    >
                      {moneyConvert(v.money, true)}
                    </div>
                    <div className="item" style={{ width: "250px" }}>
                      <div className="box-se">
                        <div className="text">보너스</div>
                        <div className="box-ip flex col-12">
                          <CpInput readOnly value={v.rolling} className="number" />
                          <CpInput
                            readOnly
                            value={v.admin_text}
                            placeholder="사유"
                            className="text"
                          />
                        </div>
                        <div className="box-bt flex col-12 p-0">
                          {/* <CpButtonGroup
                            dataGroup={dataButtonGroup}
                            onChange={(e) => console.log(e)}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="item" style={{ width: "120px" }}>
                      <div>
                        <div style={{ color: "#ff0000" }}>
                          {v.money_in_today}
                        </div>
                        <div style={{ color: "#0000ff" }}>
                          {v.money_out_today}
                        </div>
                      </div>
                    </div>
                    <div className="item" style={{ width: "140px" }}>
                      <div>
                        <div>{v.regdate}</div>
                        <div>{v.okdate}</div>
                      </div>
                    </div>
                    <div className="item" style={{ width: "200px" }}>
                      <div>
                        <div className="p-tag-6">
                          <span className="label-tag" onClick={() => {window.open(`#/agentdetail/${v.tree[v.tree.length - 1].value}`, '', "width=1500,height='100vh'") }}>
                            <div
                              className="material-icons mr-1"
                              style={{ color: "#fff", fontSize: "18px" }}
                            >
                              check_circle
                            </div>
                            {v.tree[v.tree.length - 1].value}
                          </span>
                          <span>
                            <i
                              className="pi pi-cog"
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginTop: "6px",
                              }}
                            ></i>
                          </span>
                        </div>
                        <div className="p-tag-66">
                          {
                            v.tree.map((e,i) =>{
                              return (
                                <>
                                  { e.before && <span className={i === 0? 'label-1': i === 1 ? 'label-2' : 'label-3'} >{e.before}</span> }
                                  <span className={i === 0? 'text-1': i === 1 ? 'text-2' : 'text-3'}>{e.value}</span>
                                  { i !== v.tree.length - 1 &&  <span className="arrow">{e.after}</span>}
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div
                      className="item"
                      style={{ width: "80px", color: "#3eb15b" }}
                    >
                      {v.situation}
                    </div>
                  </div>
                )) : ''}
            </div>
          </div>
        </div>
        <div className="pagi-box">
          {data && <CpPagination page={page} defaulRows={data?.per_page} onChange={handlePageChange} total={data?.total} />} 
        </div>
      </div>
    </div>
  );
};

export default DeporsitAndWithdrawAdmin;
