import axios from "axios";
import { mainAxios } from "../libs/axios";
const accessToken =  localStorage.getItem('User')
const jsonToken = JSON.parse(accessToken)
const token = `Bearer ${jsonToken}`

export default class UserService {
    getUserConnectionList(page) {
        return mainAxios.request({
            methodType: 'GET',
            url: `/member?mode=ip_list&page=${page}`,
            // params: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
              headers: {
                Accept: 'application/json',
                Authorization: token
              }
            }
          })
    }
    getUserList(page, payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=user_list&role=member&page=${page}`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getUserTodayList(page, payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=user_list_today&page=${page}`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getUserGHostList(page, payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=user_ghost_list&page=${page}`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getUserGuardian(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=user_graduation_list`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getUserIP(page, payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=user_ip_list&page=${page}`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getUserBlockedIP(page, payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=block_ip_list&page=${page}`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getRegUserList(page, payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=user_reg_list&page=${page}`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getUserDomainList(page, payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=domain&page=${page}`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    chargeExchangeMoney(mode, process,id, payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/money?mode=${mode}&process=${process}&id=${id}`,
        payload: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    resetDomain(payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/member?mode=domain&mb_domain=reset&submit=1`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    createDomain(id, payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/member?mode=domain&id=${id}`,
        payload: 
          {"mb_domain":payload,
            "submit":1
          },
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getUserStopList(page, payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=user_stop_list&page=${page}`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    chargeStatusMember( payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/member?mode=list&process=status_change`,
        payload: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getUserDetail(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=member_id`,
        params: payload,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getBasicData(payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/member?mode=modify_form`,
        payload: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    createNewAgent(payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/member?mode=member_form`,
        payload: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    blockIP(payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/member?mode=block_ip_list`,
        payload: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    getExcelDownload(payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/member?mode=excel_form`,
        payload: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
     fetchBalanceInToday (params) {
      return mainAxios.request({
        methodType: 'GET',
        url: `/money?mode=money_result`,
        params: params,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchBalanceOutToday (params) {
      return mainAxios.request({
        methodType: 'GET',
        url: `/money?mode=money_result_out`,
        params: params,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchMessageUser (payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=message_list`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchMsgView (payload) {
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=message_list&process=message_view&id=${payload}`,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchSendMsg(payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/member?mode=message_reform`,
        payload: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchChargeMoney(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/money?mode=money_list_0`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchExchangeMoney(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/money?mode=money_list_1`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchUserMoney(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/money?mode=money_list_01`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchUserIp(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=login_ip`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchUserBettingHis(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/game?`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchUserBalance(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/money?mode=money_result_all`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchUserSettlementHis(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/member?mode=records`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
}
