import React from "react";
import { Button } from "primereact/button";

const CpButtonGroup = (props) => {
  const { dataGroup=[], onChange, name } = props;
  return (
    <div className="cp-button-group">
      {/* <Button label={label ?? "Search"} onClick={onClick} /> */}
      {dataGroup.map((v, i) => (
        <div className="cp-button button-item" key={i}>
          <Button severity={v.name === name ? 'warning' : 'info'} label={v.name} onClick={() => onChange?.(v)} />
        </div>
      ))}
    </div>
  );
};

export default CpButtonGroup;
