import { Button } from "primereact/button";
import React, { useEffect, useState, useRef } from "react";
import "./AgentBalanceList.scss";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { InputText } from "primereact/inputtext";
import AgentService from "../../../service/AgentServices";
import { Toast } from "primereact/toast";
import { FilterMatchMode } from "primereact/api";
import { SelectButton } from "primereact/selectbutton";
import { Dropdown } from "primereact/dropdown";
import { CpInput } from "../../../components";
const AgentBalanceList = () => {
  const filterButton = [
    { button: "모두", value: "" },
    { button: "최상위", value: 1 },
    { button: "부본사", value: 2 },
    { button: "총판", value: 3 },
    { button: "에이전트", value: 4 },
  ];
  const [page, setPage] = useState(0);
  const [data, setData] = useState();
  const [step, setStep] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [visible, setVisible] = useState(false);
  const [userAction, setUserAction] = useState();
  const [messageModal, setMessageModel] = useState({});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [linkConfirm, setLinkComfirm] = useState();
  const [conditionPage, setConditionPage] = useState();
  const [filterAgentList, setFilterAgentList] = useState(filterButton[0])
  const agentList = new AgentService();
  const userList = new getUserList();
  const [inpValue, setInpValue] = useState('')
  const [selectOption, setSelectOption] = useState('선택');

  const [timeoutA, setTimeoutA] = useState(null);

  const filters = {
    'mb_name': { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    'mb_id': { value: '', matchMode: FilterMatchMode.STARTS_WITH },
    'mb_realname': { value: '', matchMode: FilterMatchMode.STARTS_WITH },
  }
  function fetchAgentBalance(condition) {
    const payload = {
      page: page,
      //   pnum:30 ,
      step: condition,
    };
    agentList
      .fetchAgentBalaceList(payload)
      .then((data) => {
        const res = data?.data;
        setData(res?.data);
        setTotalRecords(res.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    if (step && step !== '') {
      fetchAgentBalance(step);
    } else {
      fetchAgentBalance();
    }


  }, [page, step]);
  const header = [
    {
      field: "id",
      label: `ID`,
      sortAble: false,
      filter: true,
      filterPlaceholder: "에이전트 아이디",
      filterField: "mb_id"
    },
    { field: "mb_name", label: "닉네임", sortAble: true, filter: true, filterField: "mb_name" },
    { field: "mb_realname", label: "이름", sortAble: true, filter: true, filterField: "mb_realname" },
    { field: "return", label: "지급/회수", sortAble: false },
    { field: "mb_money", label: "보유알", sortAble: true },
    { field: "closings", label: "루징", sortAble: true },
    { field: "closing_casino", label: "카지노 루징", sortAble: true },
    { field: "rolling", label: "롤링", sortAble: true },
    { field: "rolling_slot", label: "슬롯 롤링", sortAble: true },
    { field: "rolling_casino", label: "카지노 롤링", sortAble: true },
    { field: "stop", label: "중지", sortAble: false },
    {
      field: "agent_tree",
      label: "에이전트/트리",
      sortAble: false,
    },
  ];
  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
  };
  const handleType = (type) => {
    var tilte = type.target.value
    if (tilte === null) return
    setFilterAgentList(type)
    setConditionPage(tilte);
    setStep(tilte);
    // fetchAgentBalance(tilte);
  };

  const DialogExchange = () => {
    const categories = [
      { name: "충전", key: "in_money", mode: "money_result" },
      { name: "환전", key: "out_money", mode: "money_result_out" },
    ];
    const quickPickMoney = [
      { name: "10,000", value: 10000 },
      { name: "50,000", value: 50000 },
      { name: "100,000", value: 100000 },
      { name: "500,000", value: 500000 },
      { name: "1,000,000", value: 1000000 },
    ];
    const [selectedCategory, setSelectedCategory] = useState(categories[1]);
    const [addValue, setAddValue] = useState(0);
    const [pw, setpw] = useState("");

    const id = userAction?.mb_id;
    const actionMoneyValue = () => {
      const payload = {
        admin_pw: pw,
        submit: "1",
        money: `${addValue}`,
      };
      userList
        .chargeExchangeMoney(
          selectedCategory.mode,
          selectedCategory.key,
          id,
          payload
        )
        .then((data) => {
          setVisible(false);
          if (data.code !== 200) {
            toast.current.show({
              severity: "error",
              summary: "실패",
              detail: data?.message,
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "success",
              summary: "성공",
              detail: data?.message,
              life: 3000,
            });
          }

          //  fetchUser();
          fetchAgentBalance(conditionPage);
        });
    };
    const handleInputChange = (e) => {
      const rawValue = e;
      const numericValue = parseFloat(rawValue.replace(/,/g, "")) || 0;
      setAddValue(numericValue);
    };
    function formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
      <Dialog visible={visible} onHide={() => setVisible(false)}>
        <div className="modal-action-money">
          {categories.map((category) => {
            return (
              <div key={category.key}>
                <RadioButton
                  inputId={category.key}
                  name="category"
                  value={category}
                  onChange={(e) => setSelectedCategory(e.value)}
                  checked={selectedCategory.key === category.key}
                />
                <label htmlFor={category.key} className="ml-2">
                  {category.name}
                </label>
              </div>
            );
          })}
        </div>
        <div className="input-button">
          <InputText
            value={formatNumber(addValue)}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
          />
          <InputText
            placeholder="계정 비밀번호"
            style={{ marginLeft: "5px", width: "100px" }}
            type="password"
            onChange={(e) => {
              setpw(e.target.value);
            }}
          />
          <Button onClick={actionMoneyValue}>지급/회수</Button>
        </div>
        <div className="button-group-money-pick">
          {quickPickMoney.map((e, index) => {
            return (
              <Button key={index} onClick={() => setAddValue(e.value)}>
                {e.name}
              </Button>
            );
          })}
        </div>
        <Button className="cancel-button" onClick={() => setVisible(false)}>
          정정
        </Button>
      </Dialog>
    );
  };
  const bodyTemplate = (data, props) => {
    const formattedValue =
      props.field === "rolling" || props.field === 'mb_money'
        ? formatMoney(data[props.field])
        : props.field === "last_charge_money" ||
          props.field === "last_exchange_money"
          ? formatDate(data[props.field])
          : props.field === "closing_casino" ||
            props.field === "rolling_slot" ||
            props.field === "rolling_casino"
            ? formatAddPercent(data[props.field])
            : data[props.field];
    return (
      <>
        {props.field === "stop" && (
          <div>
            {data?.disable.text ==='중지'? 
            <Button
              style={{background:'red'}}
              onClick={() => {
                setMessageModel({
                  message:
                    "해당 에이전트를 중시시키면 하단 에이전트가 모두 중지됩니다 중지 시키겠습니까?.",
                  header: "에이전트",
                });
                setOpenModalConfirm(true);
                setLinkComfirm(data?.disable?.parse);
              }}
            >
              {" "}
              {data?.disable.text}
            </Button>
            :
            <Button
            onClick={() => {
              setMessageModel({
                message:
                  "정상으로 변경하시겠습니까?",
                header: "에이전트",
              });
              setOpenModalConfirm(true);
              setLinkComfirm(data?.disable?.parse);
            }}
          >
            {" "}
            {data?.disable.text}
          </Button>}
          </div>
        )}
        {props.field === 'closings' &&
          <div>
            <span>{data.closing}%</span>
          </div>
        }
        {props.field === "id" && (
          <div
            onClick={() => {
              window.open(
                `#/agentdetail/${data.mb_id}`,
                "",
                "width=1500,height='100vh'"
              );
            }}
          >
            {data.mb_id}
            &nbsp;
            <i
              className="pi pi-cog"
              style={{ fontSize: " 12px", color: "red" }}
            />
          </div>
          //   <div>
          //     <span>{data.mb_id}</span>
          //   </div>
        )}
        {props.field === "nickname" && (
          <div>
            <span>{data.mb_name}</span>
          </div>
        )}
        {props.field === "name" && (
          <div>
            <span>{data.mb_realname}</span>
          </div>
        )}
        {props.field === "return" && (
          <div>
            <Button
              onClick={(e) => {
                setVisible(true);
                setUserAction(data);
              }}
            >
              {data.mb_charge_exchange}
            </Button>
          </div>
        )}
        {props.field === "owning" && (
          <div>
            <span> {formatMoney(data.mb_money)}</span>
          </div>
        )}

        <span className="p-column-title">{props.header}</span>
        {props.field === "rolling_slot" || props.field === "rolling_casino" ? (
          <div>
            <span style={{ color: "green" }}> {formattedValue}</span>
          </div>
        ) : (
          <div>
            <span> {formattedValue}</span>
          </div>
          //   { formattedValue }
        )}

        {props.field === "agent_tree" && (
          <div className="container-box-agentBalance">
            {data.tree.map((i, index) => {
              const color = [
                "#ff5722",
                "#2196f3",
                "#ffc107",
                "#4caf50",
                "#4eff14",
                "#d92e11",
              ];
              const bgcolor = [
                "#ff5722",
                "#000",
                "#4caf50",
                "#ff7214",
                "#4eff14",
                "#d92e11",
              ];
              // console.log(i);
              return (
                <React.Fragment key={index}>
                  {i.before && (
                    <span
                      className="box-agentBalance"
                      style={{ backgroundColor: bgcolor[index] ?? "#d92e11" }}
                    >
                      {i?.before}
                    </span>
                  )}{" "}
                  <span style={{ color: color[index] ?? "#d92e11" }}>
                    {i?.value}
                  </span>{" "}
                  <span style={{ color: "#000", fontWeight: "700" }}>
                    {i?.after}
                  </span>{" "}
                </React.Fragment>
              );
            })}
          </div>
        )}
        {props.field === "status" && (
          <span
            style={{
              backgroundColor: "gray",
              padding: "10px 5px 10px 5px",
              borderRadius: "50%",
            }}
          >
            {data?.situation?.label}
          </span>
        )}

      </>
    );
  };
  function formatMoney(value) {
    return new Intl.NumberFormat("ko-KR").format(value);
  }
  function formatAddPercent(value) {
    return value + "%";
  }
  function formatDate(value) {
    if (value === "") {
      return "-";
    } else {
      return value;
    }
  }
  const action = () => {
    agentList.fetchAgentChangeStatusBalace(linkConfirm)
      .then((data)=>{
        if(data.code === 200) {
            toast.current.show({
            severity: "success",
            summary: "성공",
            detail: data?.message,
            life: 3000,
          });
          fetchAgentBalance(conditionPage);
        }
        else{
          toast.current.show({
            severity: "error",
            summary: "성공",
            detail: data?.message,
            life: 3000,
          });
        }
      });
  };
  const toast = useRef(null);
  const justifyTemplate = (option) => {
    return <span>{option.button}</span>;
  }
  function onFilter(e) {
    var value = e.filters.mb_id.value
    if (value !== '')
      setTimeout(() => {
        agentList
          .fetchAgentBalaceList({ id: value })
          .then((data) => {
            const res = data?.data;
            setData(res?.data);
            setTotalRecords(res.total);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 3000);
    else {
      fetchAgentBalance();
    }
  }
  const menuDropdown = [
    '선택',
    '아이디',
  ]
  const chooseOption = (e) => {
    if (e === '선택') {
      setInpValue('')
    }
    setSelectOption(e)
  }
  const keyfield = [
    { mb_id: "아이디" },
  ]
  function findCorrespondingKey(v) {
    for (const item of keyfield) {
      const key = Object.keys(item)[0];
      const value = item[key];

      if (value === v) {
        return key;
      }
    }

    return null;
  }
  function onBtnSearch() {
    if (selectOption !== '선택') {
      const payload = { id: inpValue }
      agentList
        .fetchAgentBalaceList(payload)
        .then((data) => {
          const res = data?.data;
          setData(res?.data);
          setTotalRecords(res.total);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      fetchAgentBalance();
    }
  }
  return (
    <>
      <DialogExchange visible={visible} className="dialog-exchange" />
      <Toast ref={toast} />
      <div className="refresh">
        에이전트 벨런스
        <div className="tool-balance-list">
          <div className="button-group-filters">
            <SelectButton value={filterAgentList.value} onChange={(e) => handleType(e)} itemTemplate={justifyTemplate} options={filterButton} />
          </div>
          <div className="cp-dropdown">
            <Dropdown
              options={menuDropdown}
              onChange={(e) => chooseOption(e.value)}
              value={selectOption}
            />
          </div>
          <InputText style={{marginBottom:'0px !important'}} placeholder='검색' onChange={(e) => setInpValue(e.target.value)} value={inpValue} onKeyDown={(e) => { if (e.key === 'Enter') onBtnSearch() }} />
        </div>
        <div className="inventory">
          <Paginator
            first={first}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
            rows={30}
            template={{
              layout:
                "FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink",
            }}
          />
          <CustomDataTable
            data={data}
            headers={header}
            bodyTemplate={bodyTemplate}
            modalMessage={messageModal}
            openConfirm={openModalConfirm}
            filterDisplay="row"
            scrollable={true}
            action={action}
            showGridlines={true}
            filters={filters}
            sortMode="multiple"
            // onFilter={onFilter}
          />
          <Paginator
            first={first}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
            rows={30}
            template={{
              layout:
                "FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AgentBalanceList;
