import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";

const CpColorPicker = (props) => {
  const { value = "1976D2", onChange } = props;
  const [colorValue, setColorValue] = useState("1976D2");

  useEffect(() => {
    if (value) {
      setColorValue(value);
    }
  }, [value]);
  const onChangeColor = (e) => {
    if (!value) {
      setColorValue(e);
    }
    onChange?.(e);
  };

  return (
    <div className="cp-color-picker">
      <ColorPicker
        value={colorValue}
        onChange={(e) => onChangeColor(e.value)}
        style={{ width: "1rem" }}
      />
    </div>
  );
};

export default CpColorPicker;
