import React, { useEffect, useState, useRef } from 'react';
import { Editor } from 'primereact/editor';
import './style.scss';

import CustomDataTable from '../../../components/Table/TableUser';
import { Paginator } from 'primereact/paginator';
import NoticeUserServicesApi from '../../../service/NoticeUserServicesApi';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

import { ColorPicker } from 'primereact/colorpicker';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';

const dataRadio = [
    { label: '보기', value: '0' },
    { label: '숨김', value: '1' },
];
// NoticeUser
const NoticeUser = () => {
    const [rippleID, setRippleId] = useState(null)
    const [ingredients, setIngredients] = useState([]);
    const [checked, setChecked] = useState(false);
    const toast = useRef(null);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [bodyContent, setBodyContent] = useState('');
    const [dataLocal, setDataLocal] = useState({});
    const [dataDetail, setDataDetail] = useState({ board_visible: '0' });
    const [general, setGeneral] = useState({});
    const [perPage, setPerPage] = useState();
    const noteEventListAPI = new NoticeUserServicesApi();

    const getDataNoteEvent = () => {
        var newPayload = {
            page: page,
            type: 'list',
        };
        noteEventListAPI
            .noteEventListAPI(newPayload)
            .then((data) => {
                const res = data.list;
                setPerPage(res.per_page);
                setGeneral(data.general);
                setTotalRecords(res.total);
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const header = [
        { field: "checkbox", label: <Checkbox onChange={(e) => pickall(e)} checked={checked}></Checkbox>, sortAble: false },
        { field: 'msg_subjects', label: `번호`, sortAble: false },
        { field: 'board_visible', label: '타이틀', sortAble: false },
        { field: 'show_detail', label: '', sortAble: false },
    ];
    function onHandleEdit(data) {
        const urlAction = new URL(data?.handle?.show?.link);
        const queryString = urlAction.search;
        noteEventListAPI
            .noteEventDetailAPI(queryString)
            .then((res) => {
                if (res.code === 200) {
                    setDataLocal({ ...dataLocal, ...res.data.general });
                    setDataDetail({ ...dataDetail, ...res.data.general });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const changeEditor = (e) => {
        setBodyContent(e);
    };

    const onPageChange = (event) => {
        setPage(event.page + 1);
        setFirst(event.first);
    };
    const actionEdit = (param) => {
        if (param.board_id) {
            var itemEdit = data.find(
                (item) => item.board_id === param.board_id
            );
            const urlAction = new URL(itemEdit?.handle?.save?.link);
            const queryString = urlAction.search;
            noteEventListAPI
                .noteEventSubmitEditAPI(`board${queryString}`, {
                    type: 'mod',
                    board_field4: 2,
                    ...param,
                })
                .then((res) => {
                    if (res.code === 200) {
                        setDataDetail({ board_visible: '0' });
                        getDataNoteEvent();
                        toast.current.show({
                            severity: 'success',
                            summary: '성공',
                            detail: '정보가 업데이트 돠었습니다',
                            life: 3000,
                        });
                    }
                    else {
                        toast.current.show({
                            severity: 'error',
                            summary: '성공',
                            detail: res.message,
                            life: 3000,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            const urlAction = new URL(general?.handle?.add_new?.link);
            const queryString = urlAction.search;
            noteEventListAPI
                .noteEventSubmitEditAPI(`board${queryString}`, {
                    type: '', // 1: Send || NULL: List (GET Side)
                    board_field4: 2,
                    ...param,
                })
                .then((res) => {
                    if (res.code === 200) {
                        setDataDetail({ board_visible: '0' });
                        getDataNoteEvent();
                        toast.current.show({
                            severity: 'success',
                            summary: '성공',
                            detail: '정보가 업데이트 돠었습니다',
                            life: 3000,
                        });
                    }
                    else {
                        toast.current.show({
                            severity: 'error',
                            summary: '성공',
                            detail: res.message,
                            life: 3000,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const onEditAndCreate = (param) => {
        if (param.board_visible !== '' && param.board_content !== '') {
            confirmDialog({
                message: '고객센터 작성/수정 성공.',
                header: '고객센터',
                accept: () => {
                    actionEdit(param);
                },
                acceptLabel: '확인',
                acceptClassName: 'p-confirm-dialog-accept',
            });
        }
    };

    const deleteMessage = (param) => {
        if (param.board_id) {
            confirmDialog({
                message: '메세지 삭제하시겠습니까?',
                header: '고객센터',
                accept: () => deleteMessageAction(param),
                acceptLabel: '확인',
                acceptClassName: 'p-confirm-dialog-accept',
            });
        } else {
            return;
        }
    };
    const deleteMessageAction = (param) => {
        var itemEdit = data.find((item) => item.board_id === param.board_id);
        const urlAction = new URL(itemEdit?.handle?.delete?.link);
        const queryString = urlAction.search;
        noteEventListAPI
            .noteEventDeleteAPI(`board${queryString}`)
            .then((data) => {
                setDataDetail({ board_visible: '0' });
                getDataNoteEvent();
                toast.current.show({
                    severity: 'success',
                    summary: '성공',
                    detail: data?.message,
                    life: 3000,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getDataNoteEvent();
    }, [page]);

    useEffect(() => {
        setDataDetail({ ...dataDetail, board_content: bodyContent });
    }, [bodyContent]);
    const bodyTemplate = (data, props) => {
        return (
            <>
                {props.field === 'checkbox' &&
                    <Checkbox onChange={(e) => onIngredientsChange(e)} value={data.board_id} checked={ingredients.includes(data.board_id)}></Checkbox>
                }
                {props.field === 'show_detail' && (
                    <>
                        <div
                            className='icon-btn'
                            style={{
                                boxShadow:
                                    '0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16)',
                            }}
                            onClick={() => onHandleEdit(data)}
                        >
                            <span>
                                <i
                                    style={{ color: 'white' }}
                                    className='pi-file-edit pi'
                                />
                            </span>
                        </div>
                    </>
                )}
                {props.field === 'msg_subjects' && (
                    <div>
                        <span
                            style={{
                                whiteSpace: 'break-spaces',
                            }}
                        >
                            {data.board_subject}
                        </span>
                    </div>
                )}
                {props.field === 'status' && (
                    <>
                        <span
                            style={
                                data.msg_status === '1'
                                    ? {
                                        color: 'green',
                                        whiteSpace: 'break-spaces',
                                    }
                                    : { color: 'red' }
                            }
                        >
                            {/* {data.msg_status === '1'
                                ? `확인 (숨김처리) ${formattedDate}`
                                : '미확인'} */}
                        </span>
                    </>
                )}
                <span className='p-column-title'>{props.header}</span>
                {data[props.field]}
            </>
        );
    };
    const onChange = (e) => {
        const { name, value } = e.target;
        setDataDetail({ ...dataDetail, [name]: value });
    };

    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
    }
    function pickall(e) {
        let newArr = []
        setChecked(e.checked)
        if (e.checked) {
            for (let i = 0; i < data.length; i++) {
                newArr.push(data[i].board_id)
                setIngredients(newArr)
            }
        }
        else {
            setIngredients([])
        }
    }
    function deleteBoardId() {
        if (ingredients.length > 0) {
            confirmDialog({
                message: "삭제하시겠습니까?",
                header: "고객센터",
                accept: confirmDeteleBoardId,
                acceptLabel: "확인",
                acceptClassName: "p-confirm-dialog-accept",
            });
        }
    }
    function confirmDeteleBoardId() {
        var newStr = ingredients.join('|')
        noteEventListAPI.noteEventActionAPI({
            "type": 'delete',
            //   "process": "help_hidden",
            "id": newStr.toString()
        }).then((data) => {
            if (data.code === 200) {
                toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
                getDataNoteEvent();
                setIngredients([])
            }
            else {
                toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
            }
        }).catch((err) => { console.log(err) })
    }
    return (
        <>
            <Toast ref={toast} />
            <div className='prid note-event containerNoticeUser'>
                <div className='col-12 p-3 card mb-4 header md:p-4'>
                    <div className='col-12 mb-1 p-0' style={{ fontSize: '16px' }}>
                        유저페이지 공지
                    </div>
                </div>
                <div className='grid-div'>
                    <div className='item card'>
                        <Paginator
                            first={first}
                            totalRecords={totalRecords}
                            onPageChange={onPageChange}
                            rows={perPage}
                            template={{
                                layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink',
                            }}
                        />
                        <CustomDataTable
                            data={data}
                            headers={header}
                            bodyTemplate={bodyTemplate}
                            scrollable={true}
                        />
                        <div className="item-row">
                            <Paginator
                                first={first}
                                totalRecords={totalRecords}
                                onPageChange={onPageChange}
                                rows={10}
                                template={{
                                    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
                                }}
                            />
                            <Button onClick={deleteBoardId} severity="warning" >삭제</Button>
                        </div>
                    </div>
                    <div className='item2 card '>
                        <div className='cp-input'>
                            <span className='p-float-label'>
                                <InputText
                                    id=''
                                    type='text'
                                    name='board_subject'
                                    value={
                                        dataDetail?.board_subject
                                            ? dataDetail?.board_subject
                                            : ''
                                    }
                                    onChange={onChange}
                                />
                                <label htmlFor='username'>Title</label>
                            </span>
                        </div>
                        <div className='p-0 title mt-2 mr-0'>
                            {/* <ColorPicker
                            value={colorTitle}
                            onChange={(e) => setColorTitle(e.value)}
                            style={{ width: '1rem' }}
                        /> */}
                            <ColorPicker
                                name='board_color'
                                value={dataDetail?.board_color}
                                onChange={onChange}
                                style={{ width: '1rem' }}
                            />
                        </div>
                        {/* <div className='p-0 title mt-2 mr-0'>
                        <CpColorPicker
                            value={colorTitle}
                            onChange={(e) => setColorTitle(e)}
                        />
                    </div> */}
                        <div className='edi mt-5'>
                            <Editor
                                placeholder=''
                                value={dataDetail.board_content}
                                onTextChange={(e) => changeEditor(e.htmlValue)}
                                style={{ height: '320px' }}
                            />
                        </div>
                        <div className='select-content mt-3'>
                            {dataRadio && (
                                <div className='input-radio'>
                                    {dataRadio.map((v, i) => (
                                        <div className='col-2 md:col-2' key={i}>
                                            <div className='field-radiobutton'>
                                                <RadioButton
                                                    inputId='v.id'
                                                    name='board_visible'
                                                    value={v.value}
                                                    checked={
                                                        dataDetail.board_visible ===
                                                        v.value
                                                    }
                                                    onChange={onChange}
                                                />
                                                <label htmlFor='option1'>
                                                    {v.label}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className='bt mb-3 flex gap-4'>
                            <Button onClick={() => onEditAndCreate(dataDetail)}>
                                저장 하기
                            </Button>
                            <Button
                                onClick={() =>
                                    setDataDetail({ board_visible: '0' })
                                }
                                style={{ background: '#ffb401' }}
                            >
                                초기화
                            </Button>
                            <Button
                                style={{ background: 'red' }}
                                onClick={() => deleteMessage(dataDetail)}
                            >
                                삭제
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NoticeUser;
