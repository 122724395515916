let clicked = false
let audio;
let interval;
let spam = 0

export const makeSound = () => {
    return new Promise(rs =>{
        const localMedia = JSON.parse(localStorage.getItem('media'));
        if(!localMedia){
            localStorage.setItem('media', JSON.stringify({ muted: 0, sound: 'Alarm01.mp3' }))
        }
        rs()
    })
}

export const handleFirstEvent = () => {
    const localMedia = JSON.parse(localStorage.getItem('media'));
    audio = new Audio(`/assets/sounds/${localMedia.sound}`);
    if (localMedia?.muted) return
    window.addEventListener('click', () => {
        if (clicked) return
        clicked = true;
        delayPlaySound();
    })
};

const playSound = () => {
    audio?.play().catch(() => {
        alert('play audio failed')
        clearInterval(interval)
    })
}

export const mute = () =>{
   if(audio){
    spam = 0
    audio.pause();
    audio.currentTime = 0
    const localMedia = JSON.parse(localStorage.getItem('media'));
    localStorage.setItem('media', JSON.stringify({...localMedia, muted: 1}))
    clearInterval(interval);
   }
}

export const delayPlaySound = () => {
    let localMedia = JSON.parse(localStorage.getItem('media'));
    if(spam) return
    spam = 1
    localStorage.setItem('media', JSON.stringify({...localMedia, muted: 0}))
    interval = setInterval(() => {
        const accessToken = localStorage.getItem('access');
        if(!accessToken) {
            clearInterval(interval);
            return;
        }
        localMedia = JSON.parse(localStorage.getItem('media'));
        if (localMedia && localMedia?.muted) return
        playSound();
    }, 5000)
}
export const addNewSound = (media) =>{
    mute();
    audio.setAttribute('src', `/assets/sounds/${media}`);
    audio.load();
    delayPlaySound();
}
