import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { Paginator } from "primereact/paginator";
import 'primeicons/primeicons.css';
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import './userHostList.scss'
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import moneyConvert from "../../../utilities/moneyConvert";
import { Toast } from "primereact/toast";
import { SelectButton } from "primereact/selectbutton";

const UserHostList = () => {
    const today = new Date()
    const pass = new Date(today)
    pass.setDate(today.getDate() - 5)
    const userList = new getUserList();
    const filterButtons = [
        { button: "5일 미접속", value: 5 },
        { button: "10일 미접속", value: 10 },
        { button: "15일 미접속", value: 15 },
        { button: "20일 미접속", value: 20 },
        { button: "30일 이상", value: 30 },
    ];
    const filterButton = [
        { title: '5일 미접속', params: 5, id: '' },
        { title: '10일 미접속', params: 10, id: '' },
        { title: '15일 미접속', params: 15, id: '' },
        { title: '20일 미접속', params: 20, id: '' },
        { title: '30일 이상', params: 30, id: '' },
    ]
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0);
    const [visible, setVisible] = useState(false);
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [startDate, setStartDate] = useState(pass);
    const [endDate, setEndDate] = useState(today);
    const [userAction, setUserAction] = useState();
    const [paramsChange, setParamsChange] = useState(5)
    const [changeStatusUser, setChangeStatusUser] = useState()
    const [filterList, setFilterList] = useState(filterButtons[0])

    function formatDate(date) {
        var yyyy = date?.getFullYear();
        var mm = String(date?.getMonth() + 1).padStart(2, '0');
        var dd = String(date?.getDate()).padStart(2, '0');
        return yyyy + '-' + mm + '-' + dd
    }

    const fetchUser = () => {
        const payload = {
            start_date: formatDate(startDate),
            end_date: formatDate(endDate)
        }
        userList.getUserGHostList(page, payload)
            .then((data) => {
                const res = data.list
                setData(res.data)
                setTotalRecords(res.total)
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };

    useEffect(() => {
        fetchUser();
    }, [page, paramsChange])
    const header = [
        { field: "name", label: `아이디, 이름`, sortAble: false },
        { field: "mb_id", label: "닉네임, 폰", sortAble: true },
        { field: "mb_money", label: "보유금액", sortAble: true },
        { field: "rolling", label: "롤링", sortAble: true },
        { field: "action", label: " ", sortAble: false },
        { field: 'bonus', label: "포인트", sortAble: true },
        { field: 'last_charge_money', label: "마지막 충전", sortAble: true },
        { field: 'last_exchange_money', label: "마지막 환전", sortAble: true },
        { field: 'regist_date', label: "가입일", sortAble: true },
        { field: 'login_date', label: "접속일", sortAble: true },
        { field: 'stop_user', label: '', sortAble: false },
        { field: 'agent_tree', label: "에이전트", sortAble: true },
    ];
    const DialogExchange = () => {
        const categories = [
            { name: '충전', key: 'in_money', mode: 'money_result' },
            { name: '환전', key: 'out_money', mode: 'money_result_out' }
        ];
        const quickPickMoney = [
            { name: '10,000', value: 10000 },
            { name: '50,000', value: 50000 },
            { name: '100,000', value: 100000 },
            { name: '500,000', value: 500000 },
            { name: '1,000,000', value: 1000000 },
        ]
        const [selectedCategory, setSelectedCategory] = useState(categories[1]);
        const [addValue, setAddValue] = useState(0)
        const [pw, setpw] = useState('')

        const id = userAction?.mb_id
        const actionMoneyValue = () => {
            const payload = {
                admin_pw: pw,
                submit: '1',
                money: `${addValue}`
            }
            userList.chargeExchangeMoney(selectedCategory.mode, selectedCategory.key, id, payload)
                .then((data) => {
                    setVisible(false)
                    if (data.code === 200) {
                        toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                        fetchUser();
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
                        fetchUser();
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        const handleInputChange = (e) => {
            const rawValue = e;
            const numericValue = parseFloat(rawValue.replace(/,/g, '')) || 0;
            setAddValue(numericValue);

        };
        function formatNumber(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return (
            <Dialog visible={visible} onHide={() => setVisible(false)} >
                <div className="modal-action-money" >
                    {categories.map((category) => {
                        return (
                            <div key={category.key}>
                                <RadioButton inputId={category.key} name="category" value={category} onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory.key === category.key} />
                                <label htmlFor={category.key} className="ml-2">{category.name}</label>
                            </div>
                        );
                    })}
                </div>
                <div className="input-button">
                    <InputText value={formatNumber(addValue)}
                        onChange={(e) => { handleInputChange(e.target.value) }}
                    />
                    <InputText placeholder="계정 비밀번호" style={{ marginLeft: '5px', width: '100px' }} type="password"
                        onChange={(e) => { setpw(e.target.value) }}
                    />
                    <Button onClick={actionMoneyValue}>지급/회수</Button>
                </div>
                <div className="button-group-money-pick">
                    {quickPickMoney.map((e, index) => {
                        return (
                            <Button key={index} onClick={() => setAddValue(e.value)}>{e.name}</Button>
                        )
                    })}
                </div>
                <Button className="cancel-button" onClick={() => setVisible(false)}>
                    정정
                </Button>
            </Dialog>
        )
    }
    function formatMoney(value) {
        return new Intl.NumberFormat('ko-KR').format(value);
    }
    function formatDateBody(value) {
        if (value === '') {
            return '-'
        }
        else {
            return value
        }
    }
    function formatName(id, tel) {
        return (
            <div>
                <span>{id}</span>
                <br />
                <span>{`(${tel})`}</span>
            </div>
        )
    }
    const bodyTemplate = (data, props) => {
        const tel = data?.mb_hp
        const id = data?.mb_id
        const money = moneyConvert(data?.mb_money)
        const value = money.replace(/\D/g, '');
        const status = data?.mb_status
        const formattedValue = props.field === 'mb_money' || props.field === 'rolling' ? formatMoney(data[props.field]) : props.field === 'last_charge_money' || props.field === 'last_exchange_money' ? formatDateBody(data[props.field]) : props.field === 'mb_id' ? formatName(id, tel) : data[props.field];

        return (
            <>
                {props.field === 'name' &&
                    <div onClick={() => { if(status === '3')return
                     window.open(`#/userdetail/${data.mb_id}`, '', "width=1500,height='100vh'")}}>
                        <span className="p-column-title">{props.header}</span>
                        {data.mb_name}
                        &nbsp;
                        <i className="pi pi-star" style={{ fontSize: ' 12px', color: 'rgb(255, 87, 34)' }} /><br />
                        {data.mb_id}

                    </div>
                }
                {props.field === 'mb_ids' &&
                    <div>
                        <span>{id}</span>
                        <br />
                        <span>{`(${tel})`}</span>
                    </div>
                }
                <span className="p-column-title">{props.header}</span>
                {formattedValue}
                {props.field === 'last_inmoney' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {data.last_charge_money ? data.last_charge_money : '-'}
                    </>
                }
                {props.field === 'last_change_point' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {data.last_exchange_money ? data.last_exchange_money : '-'}
                    </>
                }
                {props.field === 'money' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {formattedValue}
                    </>
                }
                {props.field === 'action' && 
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <Button onClick={(e) => { setVisible(true); setUserAction(data) }} disabled={status !=='3'? false: true}>충/환전</Button>
                    </>
                }
                {props.field === "agent_tree" && (
                    <div className="container-box-agentBalance">
                        {data.agent.map((i, index) => {
                            const color = [
                                "#ff5722",
                                "#2196f3",
                                "#ffc107",
                                "#4caf50",
                                "#4eff14",
                                "#d92e11",
                            ];
                            const bgcolor = [
                                "#ff5722",
                                "#000",
                                "#4caf50",
                                "#ff7214",
                                "#4eff14",
                                "#d92e11",
                            ];
                            // console.log(i);
                            return (
                                <React.Fragment key={index}>
                                    {i.before && (
                                        <span
                                            className="box-agentBalance"
                                            style={{ backgroundColor: bgcolor[index] ?? "#d92e11" }}
                                        >
                                            {i?.before}
                                        </span>
                                    )}{" "}
                                    <span style={{ color: color[index] ?? "#d92e11" }}>
                                        &nbsp;{i?.value}
                                    </span>{" "}
                                    <span style={{ color: "#000", fontWeight: "700" }}>
                                        {index < 5 ? i?.after : ''}
                                    </span>{" "}
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}
                {props.field === 'stop_user' &&
                    <>
                    <>
                        {status === '0' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <Button className="active-user" style={{marginRight:'3px'}}
                                    onClick={(e) => {
                                        setUserAction(data)
                                        setChangeStatusUser(2)
                                        setMessageModel({ message: '유저 정보 를 변경하시겠습니까', header: '유저 저장' });
                                        setOpenModalConfirm(true)
                                    }}>
                                    사용
                                </Button>
                                <Button className="stop-user" severity="warning"
                                    onClick={(e) => {
                                        setUserAction(data)
                                        setChangeStatusUser(3)
                                        setMessageModel({ message: '유저 정보 를 변경하시겠습니까', header: '유저 저장' });
                                        setOpenModalConfirm(true)
                                    }}>
                                    삭제
                                </Button>
                            </>
                        }
                        {status === '2' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <Button className="stop-user"
                                    onClick={(e) => {
                                        setUserAction(data)
                                        setChangeStatusUser(0)
                                        setMessageModel({ message: '유저 정보 를 변경하시겠습니까', header: '유저 저장' });
                                        setOpenModalConfirm(true)
                                    }}>
                                    중지
                                </Button>
                            </>
                        }
                        {status === '1' &&
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <Button className="active-user" style={{marginRight:'3px'}}
                                    onClick={(e) => {
                                        setUserAction(data)
                                        setChangeStatusUser(2)
                                        setMessageModel({ message: '유저 정보 를 변경하시겠습니까', header: '유저 저장' });
                                        setOpenModalConfirm(true)
                                    }}>
                                    사용
                                </Button>
                                <Button className="edit" severity="info" style={{marginRight:'3px'}}
                                    onClick={(e) => {
                                        setChangeStatusUser(4)
                                        setUserAction(data)
                                        setMessageModel({message:'회원을 승인하시겠습니까?', header:'유저 가입 처리'});
                                        setOpenModalConfirm(true)
                                    }}>
                                    대기
                                </Button>
                                <Button className="stop-user"
                                    onClick={(e) => {
                                        setUserAction(data)
                                        setChangeStatusUser(0)
                                        setMessageModel({ message: '유저 정보 를 변경하시겠습니까', header: '유저 저장' });
                                        setOpenModalConfirm(true)
                                    }}>
                                    중지
                                </Button>
                            </>
                        }
                         {status === '3' && 
                            <span style={{color:'red'}}>졸업한 유저</span>
                         }
                    </>
                        {/* {status !== "2" ?
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <Button className="active-user"
                                    onClick={(e) => {
                                        setChangeStatusUser(2)
                                        setUserAction(data)
                                        setMessageModel({ message: '유저 정보 를 변경하시겠습니까', header: '유저 저장' });
                                        setOpenModalConfirm(true)
                                    }}>
                                    사용
                                </Button>
                            </>
                            :
                            <>
                                <span className="p-column-title">{props.header}</span>
                                <Button className="stop-user"
                                    onClick={(e) => {
                                        setChangeStatusUser(0)
                                        setUserAction(data)
                                        setMessageModel({ message: '유저 정보 를 변경하시겠습니까', header: '유저 저장' });
                                        setOpenModalConfirm(true)
                                    }}>
                                    중지
                                </Button>
                            </>
                        } */}
                    </>
                }
            </>
        );
    };
    const action = () => {
        const payload = {
            status: changeStatusUser,
            id: userAction.mb_id
        }
        userList.chargeStatusMember(payload)
            .then((data) => {
                if (data.code === 200) {
                    toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                    fetchUser();
                }
                else {
                    toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
                    fetchUser();
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    addLocale('ko', {
        firstDayOfWeek: 1,
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
   
    const onBtnSearchDateRange = () => {
        if (startDate > endDate) {
            alert('다시 선택하세요. 종료 날짜가 시작 날짜보다 적으면 안됩니다')
        }
        else {
            fetchUser()
        }
    }
    const toast = useRef(null)
    const justifyTemplate = (option) => {
        return <span>{option.button}</span>;
    }
    const handleType = (type) => {
        var params = type.target.value
        setFilterList(type)
        setParamsChange(params)
        const pass = new Date(today)
        pass.setDate(today.getDate() - params)
        setStartDate(pass);
        setEndDate(today);
    };
    return (
        <>
            <DialogExchange visible={visible} className='dialog-exchange' />
            <Toast ref={toast} />
            <div className='refresh card'>
                <div className="tool-ghost">
                    <div className="row-1">
                        <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} locale="ko" maxDate={today} dateFormat="yy-mm-dd" />
                        <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} locale="ko" disabled dateFormat="yy-mm-dd" />
                        <Button onClick={onBtnSearchDateRange}>검색</Button>
                    </div>


                    <div className="button-group-filters-date">
                        <SelectButton value={filterList.value} onChange={(e) => handleType(e)} itemTemplate={justifyTemplate} options={filterButtons} />
                    </div>
                </div>
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
                <CustomDataTable data={data} headers={header} bodyTemplate={bodyTemplate} modalMessage={messageModal} action={action} openConfirm={openModalConfirm} scrollable={true} sortMode="multiple" />
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
                />
            </div>
        </>
    )
}

export default UserHostList