import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";
import moment from "moment-timezone";
import { Editor } from "primereact/editor";

import { helpListAPI, helpdetailAPI } from "../../service";

import moneyConvert from "../../utilities/moneyConvert";
import {
  CpButton,
  CpButtonIcon,
  CpInput,
  CpPagination,
  CpDatePicker,
  CpDropdown,
  CpButtonGroup,
  CpButtonGroupActive,
  CpEditor,
  CpPopup,
} from "../../components";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import AgentService from "../../service/AgentServices";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { check } from "prettier";

const dataDropdown = [
  { name: "선택", code: "" },
  { name: "아이디", code: "mb_id" },
  { name: "제목", code: "board_subject" },
];
const dataButtonGroup = [
  {
    name: "2. 가상계좌발급 (구리가상)", code: `<p>
  <strong>★★★ 계좌 안내 입니다 ★★★</strong>
  <br>
  입금시마다 반드시 계좌문의후 이용부탁드립니다.
  
  <br>
  <br>

  <strong>케이뱅크 : </strong>0-010-5968-2315
  <br>
  <br>
  
  <strong>예 금 주 : </strong>임 성 빈
  <br>
  <br>
  
  <strong>충전계좌 확인 >> 입금 >> 충전신청</strong> 순서대로 해주시면
  
  빠른 처리 해드리겠습니다.
  
  <br>
  
  ※타명의 계좌로 이름만 바꿔서 입금할시 당사에서 전부확인할수 있으며
  
  반환처리또한 해드리지 않으니 꼭 본인명의 계좌로만 입금해 주시기 바랍니다
  
  <br>
  
  다른 문의사항 있으시면 언제든 문의 주시기 바랍니다.
  </p>`},
  {
    name: "3.  ★★★ 평생 고정 계좌 안내 입니다 ★★★", code: `<h1>★★★ 평생 고정 계좌 안내 입니다 ★★★</h1>

  <p><strong>수협은행 :</strong></p>
  
  <ul>
    <li>예 금 주 : 마켓프렌즈</li>
  </ul>
  
  <p>해당 계좌로 입금시 3자입금(타명의 입금)불가능하며 현재 <strong>본사 등록된 본인 명의 계좌만 입금가능한 계좌</strong>입니다</p>
  
  <p>입금 &gt;&gt; 충전신청 순서대로 해주시면 빠른 처리 해드리겠습니다.</p>
  
  <p>해당계좌 받으신후 특별공지가 따로 없는한 계좌문의 없이 해당계좌로 입금하시면 됩니다</p>` },
  {
    name: "1. 입금통장문의 (종이장라인)", code: `<h1>★★★ 계좌 안내 입니다 ★★★</h1>
  <br>

  <p>입금시마다 반드시 계좌문의후 이용부탁드립니다.</p>
  <br>

  <p>충전계좌 확인 &gt;&gt; 입금 &gt;&gt; 충전신청 순서대로 해주시면</p>
  <br>

  <p>빠른 처리 해드리겠습니다.</p>
  <br>

  <p>※타명의 계좌로 이름만 바꿔서 입금할시 당사에서 전부확인할수 있으며</p>
  <br>

  <p>반환처리또한 해드리지 않으니 꼭 본인명의 계좌로만 입금해 주시기 바랍니다</p>
  <br>

  <p>다른 문의사항 있으시면 언제든 문의 주시기 바랍니다.</p>
` },
  {
    name: "★★★  계좌 1원 인증번호  ★★★", code: `<p>
  <strong>운영진입니다</strong>
</p>
<br>
<p>
  등록된 환전 계좌번호로 1원이 입금되어 인증 번호가 발송되었습니다.
</p>
<br>
<p>
  <strong>계좌 인증 방법:</strong>
</p>
<ol>
  <li>계좌 인증 (x x x x) 세이퍼트 4자리 인증번호를 알려주시면 계좌 발급 후 평생 계좌 안내를 드립니다.</li>
  <li>해당 인증번호를 고객센터에 남겨주시면 쪽지로 평생 계좌를 보내드립니다.</li>
</ol>
<br>

<p>
  빠른 처리를 위해 4자리 인증번호를 알려주시면 감사하겠습니다.
</p>` },
  { name: "★★★  인증 시간 초과시 답변  ★★★", code: `<h1>안녕하세요. 고객센터 입니다.</h1>
    <br>
  <p>인증시간이 초과되어 다시 인증번호 보내드렸습니다</p>
  <br>

  <p>인증번호 고객센터로 알려주시면</p>
  <br>

  <p>평생계좌 발급해드리겠습니다</p>` },
  { name: "▶▶▶  평생 계좌 입금 안된다고하면 답변   ◀◀◀", code: `
  <strong><p>환전 등록된 계좌에서만 입금 가능하십니다</p>
  <br>

  <p>ATM 입금, 토스 또는 카카오페이 계좌로 연결하여 입금하시면 입금되지 않습니다</p>
  <br>

  <p>이용에 감사드리며, 더 나은 베팅 환경을 만들기 위해 노력하겠습니다.</p>
  </strong>` },
  { name: "확인후 처리완료", code: `<p>안녕하세요. 스타 카지노입니다.</p>
    <br>
  <p>문의/요청하신 사항은 모두 처리완료 되었습니다.</p>
  <br>

  <p>다른 문의사항 있으시면 언제든 문의 주시기 바랍니다.</p>
  
  <p>항상 스타 카지노에서 회원님들의 건승 기원합니다.</p>` },
  { name: "환전 마감 안내 ", code: `<p>충전 마감 안내</p>
    <br>
  <p>안녕하세요 회원님,</p>
  <br>

  <strong><p>스타 카지노 입니다.</p></strong>
  <br>
  <p>매일 밤 23시 35분부터 익일 00시 10분까지는</p>
  <br>
  <p>은행 점검과 정산마감으로 인하여 입금이 제한됩니다.</p>
  <br>
  <p>00시 10분 이후에 문의 부탁드립니다.</p>` },
  { name: "충전 마감 안내 ", code: "충전 마감 안내" },
  { name: "포인트관련문의", code: "포인트관련문의" },
  { name: "은행 점검 시간안내", code: "시간안내" },
  { name: "환전 지연시", code: "환전 지연시" },
];

const Help = () => {
  const navigate = useNavigate();
  const [dropdownValue, setDropdownValue] = useState({
    name: "선택",
    code: "",
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [key, setKey] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [checkVisible, setCheckVisible] = useState(false);
  const [checkText, setCheckText] =
    useState("고객센터페이지에서 삭제되었습니다");
  const [time, setTime] = useState("-");
  const [note, setNote] = useState("-");
  const [editor, setEditor] = useState("");
  const [endTime, setEndTime] = useState("-");
  const [subject, setSubject] = useState('')
  const [mbID, setMbID] = useState()
  const [idBoard, setIdBoard] = useState('')
  const [rippleId, setRippleId] = useState(null)
  const agentList = new AgentService();
  const [bodyContent, setBodyContent] = useState("");

  const getDataHelp = async () => {
    var payload = {
      page: page,
      keyfield: dropdownValue.code,
      key: key,
    }
    try {
      const res = await helpListAPI(payload);
      if (res.code === 200) {
        setTotal(res.list.total);
        setData(res.list.data);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDataDetailHelp = async (id) => {
    setIdBoard(id)
    try {
      const res = await helpdetailAPI({
        id: id,
      });
      if (res.code === 200) {
        // console.log(res);
        setMbID(res.data.mb_id.value)
        setNote(res.data.board_content.value);
        setEndTime(res.data.ripple_date);
        setSubject(res.data.board_subject.value)
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataHelp();
  }, [page]);
  useEffect(() => {
  }, [bodyContent])
  const onSearch = () => {
    setPage();
    getDataHelp();
  };
  const onDelete = () => {
    setPopupVisible(true);
  };

  const onChoice = (v) => {
    setTime(v.reg_date.value);
    getDataDetailHelp(v.board_id);
    setRippleId(v.ripple_id)
    setBodyContent(v.ripple_content)
  };
  const confirm1 = () => {
    confirmDialog({
      message: "고객센터 작성/수정 성공.",
      header: "고객센터",
      accept: action,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
    });
  };
  const confirm2 = () => {
    confirmDialog({
      message: "메세지 삭제하시겠습니까?",
      header: "고객센터",
      accept: denie,
      acceptLabel: "확인",
      acceptClassName: "p-confirm-dialog-accept",
    });
  };
  const action = () => {
    agentList.agentHelpActionAPI({
      "submit": 1,    // 0: Show List (GET Side) || 1: Processing
      "process": "ripple_input",  // ripple_input: Save || ripple_delete: Delete || help_delete_in_3_days: Delete helps in 3 days || help_hidden: Delete help inquiry
      "id": idBoard,    // board_id || ripple_id
      "board_subject": subject,
      "mb_id": mbID,
      // "ripple_name": "test",
      // "ripple_date": "", //no need fill
      "ripple_content": bodyContent//bodyContent
      // "parent_id": ""
    }).then((data) => {
      if (data.code === 200) {
        toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
        getDataHelp();
        setIngredients([])
      }
      else {
        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
      }
    }).catch((err) => { console.log(err) })
  };
  const denie = () => {
    agentList.agentHelpActionAPI({
      "submit": 1,    // 0: Show List (GET Side) || 1: Processing
      "process": "ripple_delete",  // ripple_input: Save || ripple_delete: Delete || help_delete_in_3_days: Delete helps in 3 days || help_hidden: Delete help inquiry
      "id": rippleId//rippleID,    // board_id || ripple_id
    }).then((data) => {
      if (data.code === 200) {
        toast.current.show({ severity: 'success', summary: '성공', detail: '삭제가 완료되었습니다.', life: 3000 });
        getDataHelp();

      }
      else {
        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
      }
    }).catch((err) => { console.log(err) })
  }
  const toast = useRef()
  const [ingredients, setIngredients] = useState([]);
  const [checked, setChecked] = useState(false);

  const onIngredientsChange = (e) => {
    let _ingredients = [...ingredients];

    if (e.checked)
      _ingredients.push(e.value);
    else
      _ingredients.splice(_ingredients.indexOf(e.value), 1);

    setIngredients(_ingredients);
  }
  function pickall(e) {
    let newArr = []
    setChecked(e.checked)
    if (e.checked) {
      for (let i = 0; i < data.length; i++) {
        newArr.push(data[i].board_id)
        setIngredients(newArr)
      }
    }
    else {
      setIngredients([])
    }
  }
  function deleteBoardId() {
    if (ingredients.length > 0) {
      confirmDialog({
        message: "삭제하시겠습니까?",
        header: "고객센터",
        accept: confirmDeteleBoardId,
        acceptLabel: "확인",
        acceptClassName: "p-confirm-dialog-accept",
      });
    }
  }
  function confirmDeteleBoardId() {
    var newStr = ingredients.join('|')
    agentList.agentHelpActionAPI({
      "submit": 1,
      "process": "help_hidden",
      "id": newStr.toString()
    }).then((data) => {
      if (data.code === 200) {
        toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
        getDataHelp();
        setIngredients([])
      }
      else {
        toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
      }
    }).catch((err) => { console.log(err) })
  }
  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="prid help-wrap">
        <div className="col-12 p-3 card mb-4 header md:p-4">
          <div className="col-12 mb-1 p-0" style={{ fontSize: "16px" }}>
            고객센터
          </div>
          <div className="col-12 de-wi-box p-0 sm:flex sm:flex-wrap">
            <div className="p-0 sm:col-6 mt-3 md:col-3 xl:col-2 flex align-items-end md:mt-0">
              <CpDropdown
                style={{ minWidth: "100px" }}
                value={dropdownValue}
                options={dataDropdown}
                onChange={(e) => {
                  setDropdownValue(e);
                }}
                placeholder=""
              />
            </div>
            <div className="p-0 mt-1 sm:col-6 md:col-2 xl:col-2 flex align-items-end">
              <CpInput placeholder="검색" onChange={(e) => setKey(e)} onKeyDown={(e) => { if (e.key === 'Enter') onSearch() }} />
            </div>
            <div className="p-0 mt-3 sm:col-6 md:col-1 xl:col-1 flex align-items-end">
              <CpButton label="검색" onClick={onSearch} />
            </div>
          </div>
          <div className="p-0 mt-3">
            <CpButton label="환전 지연시" onClick={onDelete} />
          </div>
        </div>
        <div className="p-0 mt-4 col-12 flex flex-wrap content">
          <div className="left col-12 md:col-4 md:pr-2 p-0">
            <div className="card col-12">
              <div className="pagi-box">
                <CpPagination
                  page={page}
                  onChange={(e) => setPage(e)}
                  total={total}
                  defaulRows={30}
                />
              </div>
              <div className="content-left p-0">
                <div className="header-left">
                  <div className="item">
                    <Checkbox onChange={(e) => pickall(e)} checked={checked}></Checkbox>
                  </div>
                  <div className="item">제목</div>
                  <div className="item">아이디</div>
                  <div className="item">상태</div>
                  <div className="item"></div>
                </div>
                <div className="infor-box">
                  {data.map((v, i) => (
                    <div className="item-box" key={i}>
                      <div className="item">
                        <Checkbox onChange={(e) => onIngredientsChange(e)} value={v.board_id} checked={ingredients.includes(v.board_id)}></Checkbox>
                      </div>
                      <div
                        className="item"
                        style={{ color: v?.subject?.subject_color }}
                        dangerouslySetInnerHTML={{ __html: v?.subject?.value }}
                      >
                      </div>
                      <div className="item">
                        <div>
                          {v?.mb_id?.value}{" "}
                          <i
                            className="pi pi-star"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </div>
                      </div>
                      <div className="item other">
                        <div>
                          <div>
                          </div>
                          <div>{v?.state?.value}</div>
                        </div>
                      </div>
                      <div className="item">
                        <CpButtonIcon
                          icon="pi pi-pencil"
                          severity=""
                          aria-label=""
                          onClick={() => onChoice(v)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pagi-box">
                <CpPagination
                  page={page}
                  onChange={(e) => setPage(e)}
                  total={total}
                  defaulRows={30}
                />
                <Button onClick={deleteBoardId} severity="warning" >삭제</Button>
              </div>
            </div>
          </div>
          <div className="right col-12 md:col-8 md:pl-2 p-0">
            <div className="card col-12 ">
              <div className="p-0 title mt-2">
                <CpInput placeholder="Title" value={subject} />
              </div>
              <div className="time-question mt-3">
                <div className="name">문의 시간</div>
                <div className="value">{time}</div>
              </div>
              <div className="note mt-3">
                <div className="name">내용</div>
                <div className="value">{note}</div>
              </div>
              <div className="edi mt-3">
                <Editor
                  placeholder=""
                  value={bodyContent}
                  onTextChange={(e) => setBodyContent(e.htmlValue)}
                  style={{ height: "320px" }}
                />
              </div>
              <div className="time-answer mt-3">
                <div className="name">답변 시간</div>
                <div className="value">{endTime}</div>
              </div>
              <div className="select-content mt-3">
                <CpButtonGroup
                  dataGroup={dataButtonGroup}
                  onChange={(e) => {
                    setBodyContent(e.code);
                  }}
                />
              </div>
              <div className="chot mt-3 mb-3 col-12 flex">
                <div className="col-6 p-0">
                  <Button onClick={confirm1}>저장 하기</Button>
                </div>
                {rippleId ?
                  <div className="col-6 p-0">
                    <Button onClick={confirm2} style={{ background: 'red' }}>삭제</Button>
                  </div>
                  : <></>}
              </div>
            </div>
          </div>
        </div>
        <CpPopup
          visible={popupVisible}
          onHide={() => setPopupVisible(false)}
          label="유저 고객센터"
          content="유저 고객센터 삭제합니다 3일전 내역은 모두 삭제됩니다."
          onOk={() => {
            setPopupVisible(false);
            setCheckVisible(true);
          }}
          okText="확인"
          cancelOkText="취소"
        />
        <CpPopup
          visible={checkVisible}
          onHide={() => setCheckVisible(false)}
          label="유저 고객센터"
          content={checkText}
        />
      </div>
    </>

  );
};

export default Help;
