import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import './AgentNote.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import { Editor } from "primereact/editor";
import { confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import AgentService from "../../../service/AgentServices";
import { CpDropdown } from "../../../components";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";

const AgentNote = () => {
    const [selectOption, setSelectOption] = useState({ name: "선택", code: "" });
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [first, setFirst] = useState(0);
    const [questionTime, setQuestionTime] = useState('-');
    const [content, setContent] = useState('-');
    const [bodyContent, setBodyContent] = useState('')
    const [contentEditor, setContentEditor] = useState('-')
    const [radioValue, setRadioValue] = useState('all')
    const [title, setTitle] = useState('')
    const [checked, setChecked] = useState(false);
    const [ingredients, setIngredients] = useState([]);
    const [payload, setPayload] = useState({
        "mb_type": "agent",  // NULL <=> user (Default) || agent
        "keyfield": "",    // NULL (Default) || mb_id || mb_level
        "key": "",
    })
    const [uid, setUid] = useState('')
    const [userName, setUserName] = useState('')
    const [keySearch, setKeySearch] = useState("");

    const toast = useRef(null);

    const header = [
        { field: "checkbox", label: <Checkbox onChange={(e) => pickall(e)} checked={checked}></Checkbox>, sortAble: false },
        { field: "msg_subjects", label: `타이틀`, sortAble: false },
        { field: "mb_step_1", label: "에이전트", sortAble: false },
        { field: "status", label: "확인/ 미확인", sortAble: false },
        { field: "btn_action", label: "", sortAble: false },
    ];
    const agentList = new AgentService();
    const onHandleSearch = () => {
        setPayload({
            "mb_type": "agent",  // NULL <=> user (Default) || agent
            "keyfield": selectOption.code,    // NULL (Default) || mb_id || mb_level
            "key": keySearch,
        })
    }
    const fetchAgentNote = () => {
        var newPayload = {
            ...payload,
            page: page
        }
        agentList.agentNoteListAPI(newPayload)
            .then((data) => {
                const res = data.list
                setData(res.data)
                setTotalRecords(res.total)
                console.log(res)
            })
            .catch((err) => { console.log(err) })
    }
    useEffect(() => {
        fetchAgentNote()
    }, [page, payload])
    useEffect(() => {
        console.log(bodyContent)
    }, [payload, bodyContent])
    const dataRadio = [
        { label: '전체', value: 'all' },
        { label: '에이전트', value: 'agent' }
    ]
    const menuDropdown = [
        { name: "선택", code: "" },
        { name: "아이디", code: "mb_id" },
        { name: "에이전트", code: "mb_agent" },
        { name: "레벨", code: "mb_level" }
    ]
    const chooseOption = (e) => {
        setSelectOption(e)
    }

    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    function onHandleEdit(data) {
        setRadioValue('agent')
        setBodyContent(data.msg_memo)
        setTitle(data.msg_subject)
        setUserName(data.mb_id)
        setQuestionTime(formatTime(data.msg_confirm_time))
        setUid(data.uid)
    }
    function formatTime(msgtime) {
        const time = new Date(msgtime * 1000)
        const year = time.getFullYear();
        const month = String(time.getMonth() + 1).padStart(2, '0'); 
        const day = String(time.getDate()).padStart(2, '0');
        const hours = String(time.getHours()).padStart(2, '0');
        const minutes = String(time.getMinutes()).padStart(2, '0');
        const seconds = String(time.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    const bodyTemplate = (data, props) => {
        const formattedDate = formatTime(data.msg_confirm_time)

        return (
            <>
                {props.field === 'checkbox' &&
                    <Checkbox onChange={(e) => onIngredientsChange(e)} value={data.uid} checked={ingredients.includes(data.uid)}></Checkbox>
                }
                {props.field === 'btn_action' &&
                    <>
                        <div className="icon-btn" style={{ boxShadow: '0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16)' }}
                            onClick={() => onHandleEdit(data)}
                        >
                            <span ><i style={{ color: 'white' }} className="pi-file-edit pi" /></span>
                        </div>
                    </>
                }
                {props.field === 'msg_subjects' &&
                    <>
                        <span style={{ whiteSpace: 'break-spaces' }}>{data.msg_subject}</span>
                    </>
                }
                {props.field === 'status' &&
                    <>
                        <span style={data.msg_status === '1' ? { color: 'green', whiteSpace: 'break-spaces' } : { color: 'red' }}>{data.msg_status === '1' ? `확인 (숨김처리) ${formattedDate}` : '미확인'}</span>

                    </>
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    const changeEditor = (e) => {
        setBodyContent(e)
    }
    const confirm1 = () => {
        confirmDialog({
            message: "고객센터 작성/수정 성공.",
            header: "고객센터",
            accept: accept,
            acceptLabel: "확인",
            acceptClassName: "p-confirm-dialog-accept",
        });
    };

    const deleteMessage = () => {
        confirmDialog({
            message: "메세지 삭제하시겠습니까?",
            header: "고객센터",
            accept: deleteMessageAction,
            acceptLabel: "확인",
            acceptClassName: "p-confirm-dialog-accept",
        });
    }
    const delete1 = () => {
        confirmDialog({
            message: "고객센터 삭제되었습니다.",
            header: "고객센터",
            accept: cancel,
            acceptLabel: "확인",
            acceptClassName: "p-confirm-dialog-accept",
        });
    };
    const deleteMessageAction = () => {
        agentList.agentNoteSendApi({
            "submit": 1,
            "process": "msg_delete",
            'uid': uid
        }).then((data) => {
            if(data.code === 200){
                toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                fetchAgentNote()
            }
            else{
                toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
            }
        }).catch((err) => { console.log(err) })
    }

    const accept = () => {
        action();
    };
    const cancel = () => {
        agentList.agentHelpActionAPI({
            "submit": 1, 
            "process": "msg_delete_all1",
            'mb_type': 'agent'
        }).then((data) => {
            if(data.code === 200){
                toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                fetchAgentNote()
            }
            else{
                toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
            }
        }).catch((err) => { console.log(err) })
    }
    const action = () => {
        agentList.agentNoteSendApi({
            "submit": 1, 
            "process": "msg_send",  
            "all": radioValue === 'all' ? '1' : '',  
            "receiver_id": radioValue === 'all' ? '' : userName,  
            "msg_subject": title,
            "msg_memo": bodyContent,
            "msg_priority": "N", 
            'level': '',
            "mb_type":'agent'
        }).then((data) => {
            if(data.code === 200){
                toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                fetchAgentNote()
            }
            else{
                toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
            }
        }).catch((err) => { console.log(err) })
    };

    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];
    
        if (e.checked)
          _ingredients.push(e.value);
        else
          _ingredients.splice(_ingredients.indexOf(e.value), 1);
    
        setIngredients(_ingredients);
      }
      function pickall(e) {
        let newArr = []
        setChecked(e.checked)
        if (e.checked) {
          for (let i = 0; i < data.length; i++) {
            newArr.push(data[i].uid)
            setIngredients(newArr)
          }
        }
        else {
          setIngredients([])
        }
      }
      function deleteBoardId() {
        if (ingredients.length > 0) {
          confirmDialog({
            message: "삭제하시겠습니까?",
            header: "고객센터",
            accept: confirmDeteleBoardId,
            acceptLabel: "확인",
            acceptClassName: "p-confirm-dialog-accept",
          });
        }
      }
      function confirmDeteleBoardId() {
        var newStr = ingredients.join('|')
        agentList.agentNoteSendApi({
            "submit": 1,
            "process": "msg_delete",
            'uid': newStr.toString()
        }).then((data) => {
          if (data.code === 200) {
            toast.current.show({ severity: 'success', summary: '성공', detail: data.message, life: 3000 });
            fetchAgentNote();
            setIngredients([])
          }
          else {
            toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
          }
        }).catch((err) => { console.log(err) })
      }
    return (
        <>
            <Toast ref={toast} />
            <div className='refresh card'>
                <span className="text">에이전트 쪽지</span>
                <div className="tool">
                    <div className="cp-dropdown">
                        <CpDropdown
                            options={menuDropdown}
                            onChange={(e) => chooseOption(e)}
                            value={selectOption}
                        />
                    </div>
                    <div className={`cp-input`}>
                        <span className={`p-float-label`}>
                            <InputText
                                id=''
                                type="text"
                                value={keySearch}
                                onChange={(e) => setKeySearch(e.target.value)}
                                onKeyDown={(e)=> {if(e.key ==='Enter') onHandleSearch()}}
                            />
                            <label htmlFor="username">검색</label>
                        </span>
                    </div>

                    <Button onClick={onHandleSearch}>검색</Button>
                    <Button style={{ background: 'red' }} onClick={delete1}>삼일 이전내역삭제</Button>
                </div>
            </div>
            <div className="grid-div">
                <div className="item1 card">
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                    />
                    <CustomDataTable
                        data={data}
                        headers={header}
                        bodyTemplate={bodyTemplate}
                        modalMessage={messageModal}
                        openConfirm={openModalConfirm}
                        scrollable={true}
                    />
                    <div className="item-row">
                        <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                            rows={30}
                            template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}
                        />
                        <Button onClick={deleteBoardId} severity="warning" >삭제</Button>
                    </div>
                </div>
                <div className="item2 card">
                    {dataRadio &&
                        <div className="input-radio">
                            {dataRadio.map((v, i) => (
                                <div className="col-2 md:col-2" key={i}>
                                    <div className="field-radiobutton">
                                        <RadioButton
                                            inputId="v.id"
                                            name="option"
                                            value={v.value}
                                            checked={radioValue === v.value}
                                            onChange={(e) => setRadioValue(e.value)}
                                        />
                                        <label htmlFor="option1">{v.label}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    {radioValue === 'all' &&
                        <div className='cp-input'>
                            <span className='p-float-label'>
                                <InputText
                                    id=''
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <label htmlFor="username">Title</label>
                            </span>
                        </div>
                    }
                    {radioValue === 'agent' &&
                        <>
                            <div className='cp-input'>
                                <span className='p-float-label'>
                                    <InputText
                                        id=''
                                        type="text"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                    <label htmlFor="username">User</label>
                                </span>
                            </div>
                            <div className='cp-input'>
                                <span className='p-float-label'>
                                    <InputText
                                        id=''
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                    <label htmlFor="username">Title</label>
                                </span>
                            </div>
                        </>
                    }


                    <Editor value={bodyContent}
                        onTextChange={(e) => changeEditor(e.htmlValue)}
                        style={{ height: `320px` }}
                    />
                    <div className="div-editor">
                        <div className="title">
                            <span>답변 시간</span>
                        </div>
                        <div className="content-editor">
                            <span>{questionTime}</span>
                        </div>
                    </div>
                    <div className="button-bottom">
                        <Button onClick={confirm1}>저장 하기</Button>
                        <Button style={{ background: 'red' }} onClick={deleteMessage}>삭제</Button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AgentNote;