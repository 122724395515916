import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import './AgentBalanceLog.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import AgentService from "../../../service/AgentServices";
import { SelectButton } from "primereact/selectbutton";
import { formatDate } from "../../../utilities/date";
import moment from "moment-timezone";

const AgentBalanceLog = () => {
    const buttonFilter = [
        { button: '포인트', value: '6' },
        { button: '롤링', value: '1' },
        { button: '벨런스', value: '벨런스' },
        { button: '롤링=>벨런스', value: '2' },
        { button: '매장=>유저', value: '3' },
        { button: '관리자=>매장', value: '4' },
        { button: '에이전트=> 에이전트', value: '5' },
    ]
    const currentDate = moment().toDate();
    const nextDate = moment().add(1, 'days').toDate();
    const [selectOption, setSelectOption] = useState('선택');
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [condition, setCondition] = useState({})
    const [first, setFirst] = useState(0);
    const [filterBtn, setFilterBtn] = useState(buttonFilter[2])
    const [startDate, setStartDate] = useState(currentDate);
    const [endDate, setEndDate] = useState(nextDate);
    const [inpCondition, setInpCondition] = useState('')
    const [filterCondition, setFilterCondition] = useState()
    const header = [
        { field: "types", label: "타입", sortAble: false },
        { field: "balance_type", label: `벨런스 타입`, sortAble: false },
        { field: "agents", label: `에이전트`, sortAble: false },
        { field: "user_ids", label: filterBtn.value === '5'?'에이전트':"유저 아이디", sortAble: false },
        { field: "original", label: "오리지널", sortAble: false },
        { field: "money", label: `충/환전/롤링/관리자`, sortAble: false },
        { field: "remaining_amount", label: `남은 금액`, sortAble: false },
        { field: "registration_date", label: `등록일`, sortAble: false },
        { field: "trees", label: `트리`, sortAble: false },
    ];
    const agentList = new AgentService();
    function fetchAgentBalanceLog() {
        const payload = {
            sdate: startDate? formatDate(startDate):'2023-02-05',
            edate: endDate? formatDate(endDate):'2023-02-14',
            page: page,
            ...condition,
            ...filterCondition
        }
        if(filterBtn.value === '1' && startDate && endDate){
            var tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000;
            var timeDiff = endDate.getTime() - startDate.getTime();
            
            if (timeDiff > tenDaysInMilliseconds) {
                setData()
                alert('검색 시간을 10 일 내에 입력해야 합니다')
            } 
        }
        agentList.agentBalanceLog(payload)
            .then((data) => {
                const res = data.list
                setData(res.data)
                setTotalRecords(res.total)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchAgentBalanceLog()
    }, [page, condition, filterCondition, selectOption])
    const menuDropdown = [
        '선택',
        '에이전트 아이디',
        '에이전트 트리'
    ]
    const chooseOption = (e) => {
        setSelectOption(e)
        if (e === '선택') {
            setInpCondition('');
            setFilterCondition()
        }
    }

    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    const bodyTemplate = (data, props) => {
        var step = data.tree
        step.sort((a, b) => a.step - b.step);
        const tree = step.filter(item => item.step <= 6);
        const getColor = (step) => {
            switch (step) {
                case 1:
                    return 'red';
                case 2:
                    return 'blue';
                case 3:
                    return 'gold';
                case 4:
                    return 'orange';
                case 5:
                    return 'green'
                case 6:
                    return 'black';
                default:
                    return 'black';
            }
        };

        const renderedData = tree.map((item, index) => (
            <span style={{ color: getColor(item.step) }} key={index}>
                {item.value} {index < tree.length - 1 ? <span style={{color:'black'}}>{`>>`} </span> : ''}
            </span>
        ));
        return (
            <>
                {props.field === 'btn_action' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div className="icon-btn">
                            <span style={{}}><i style={{ color: 'white' }} className="pi-file-edit pi" /></span>

                        </div>
                    </>
                }
                {props.field === 'types' &&
                    <>
                        <span style={{ color: `${data.type.color}` }}>{data.type.label}</span>
                    </>
                }
                {props.field === 'agents' &&
                    <div onClick={() => { window.open(`#/agentdetail/${data.agent.value}`, '', "width=1500,height='100vh'") }}>
                        {data.agent.value}
                        &nbsp;
                        <i className="pi-cog pi" style={{ fontSize: ' 12px', color: 'rgb(255, 87, 34)' }} />
                    </div>
                }
                {props.field === 'user_ids' && data.user_id.value !== '' &&
                        <div onClick={() => { window.open(`#/userdetail/${data.user_id.value}`, '', "width=1500,height='100vh'") }}>
                            <span>{data.user_id.value}</span> &nbsp;
                            <i className="pi-star pi" style={{ fontSize: ' 12px', color: 'green' }} />
                        </div>
                }
                {props.field === 'user_ids' && data.user_id.value === '' &&
                        <span>-</span>
                }
                {props.field === 'trees' &&
                    <div>{renderedData}</div>
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    const SelectBtnGroup = () => {
        const handleChangeOpt = (e) => {
            if(e.value ===  null){
                return
            }
            setFilterBtn(e)

            if (e.value === '1') {
                setCondition({ req_type: 1 })
            }
            if (e.value === '2') {
                setCondition({ req_type: 2 })
            }
            if (e.value === '3') {
                setCondition({ req_type: 3 })
            }
            if (e.value === '4') {
                setCondition({ req_type: 4 })
            }
            if (e.value === '5') {
                setCondition({ req_type: 5 })
            }
            if (e.value ==='6'){
                setCondition({ req_type: 6 })
            }
            if(e.value ==='벨런스'){
                setCondition()
            }
        }
        const justifyTemplate = (option) => {
            return <span>{option.button}</span>;
        }
        return (
            <SelectButton value={filterBtn.value} onChange={(e) => handleChangeOpt(e)} itemTemplate={justifyTemplate} options={buttonFilter} />
        )
    }
    addLocale('ko', {
        firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
    function handleFilter() {
        if (selectOption === '에이전트 아이디') {
            setFilterCondition({ keyfield: 'mb_id', key: inpCondition, sdate: startDate ? formatDate(startDate) : '', edate: endDate ? formatDate(endDate) : '' })
        }
        if (selectOption === '에이전트 트리') {
            setFilterCondition({ keyfield: 'mb_name', key: inpCondition, sdate: startDate ? formatDate(startDate) : '', edate: endDate ? formatDate(endDate) : '' })
        }
        if (selectOption === '선택') {
            setFilterCondition({ sdate: startDate ? formatDate(startDate) : '', edate: endDate ? formatDate(endDate) : '' })
        }
    }
    return (
        <>
            <div className='refresh card'>
                <span className="text">에이전트 기록</span>
                <div className="tool">
                    <div className="date-filter">
                        <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                        <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                    </div>
                    <div className="item">
                        <Dropdown
                            options={menuDropdown}
                            onChange={(e) => chooseOption(e.value)}
                            value={selectOption}
                        />
                        <div className="item-row">
                            <InputText placeholder="검색"
                                value={inpCondition} onChange={(e) => setInpCondition(e.target.value)} onKeyDown={(e)=> {if(e.key ==='Enter') handleFilter()}} />
                            <div className="button-search" onClick={handleFilter}>
                                <i className="pi-search pi" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-group-filter">
                    <SelectBtnGroup />
                </div>
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
                <CustomDataTable
                    data={data}
                    headers={header}
                    bodyTemplate={bodyTemplate}
                    scrollable={true}
                />
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
            </div>
        </>
    )
}

export default AgentBalanceLog;