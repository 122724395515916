import React, { useEffect, useRef, useState } from "react";
import CpEditor from "../../../../components/CpEditor";
import CpInput from "../../../../components/CpInput";
import './Message.scss'
import { Button } from "primereact/button";
import { TabPanel } from "primereact/tabview";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../../components/Table/TableUser";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Editor } from "primereact/editor";
import { InputText } from "primereact/inputtext";
import UserService from "../../../../service/UserService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
const MessageTab = (props) => {
    const [text, setText] = useState();
    const [data, setData] = useState();
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [titleValue, setTitleValue] = useState('')
    const [expandedRows, setExpandedRows] = useState(null);
    const [msgView, setMsgView] = useState(null)
    const userList = new UserService()
    const params = useParams()
    const toast = useRef()
    function fetchUserMsg () {
        const payload ={
            page: page,
            id: params.id
        }
        userList.fetchMessageUser(payload)
            .then((data)=>{
                const res =  data?.data?.data
                setData(res)
                setTotalRecords(data?.data?.total)
            })
            .catch((err)=>{
                console.log(err)
            })
    }
    useEffect(()=>{
        fetchUserMsg();
        setExpandedRows(null)
    },[page])
    useEffect(()=>{
        if(expandedRows !== null){
            getMsgView()
        }
    },[expandedRows])
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    
    const bodyTemplate = (data, props) => {
        const status =  data.status_confirm
        return (
            <>
            {props.field === 'status_confirms' &&
                <div>
                    {status === 'X' ?
                        <span style={{color: 'red', fontWeight:'bold'}}>X (미확인)</span>
                        :
                        <span style={{color: 'green', fontWeight:'bold'}}>O (확인)</span>
                    }
                </div>
            }
            <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
     }
    const confirm1 = () => {
        confirmDialog({
            message: `메시지 전송 확인 ${params.id}`,
            header: '유저 쪽지',
            accept: () =>confirmSend(),
            acceptLabel: '확인',
        });
    };
    const confirmSend = () => {
        const payload= {
            msg_priority: 'N',
            msg_subject: titleValue,
            msg_memo: text,
            receiver_id : params.id
        }
        if(titleValue !== ''){
            userList.fetchSendMsg(payload)
            .then(()=>{
                toast.current.show({ severity: 'success', summary: '성공', detail: '메세지 전송 성공했습니', life: 3000 });
                fetchUserMsg();
                setTitleValue('')
                setText('')
            })
            .catch((err)=>{
                console.log(err)
            })
        }
        else{
            toast.current.show({ severity: 'error', summary: '오류', detail: '제목을 비워두면 안 됩니다.', life: 3000 });
        }
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div>
                <span>{data?.msg_memo !== ''? data?.msg_memo : 'No data'}</span>
            </div>
            
        );
    };
    function getMsgView(){
        const id = Object.getOwnPropertyNames(expandedRows)
        if(id.length > 1){
            id.map((v)=> userList.fetchMsgView(v)
            .then((data)=>{
                setMsgView(data?.data);
            })
            .catch((err)=>{
                console.log(err)
            }))
        }
        else{
            userList.fetchMsgView(id)
            .then((data)=>{
                setMsgView(data?.data);
            })
            .catch((err)=>{
                console.log(err)
            })
        }
        
    }
    return (
        <>
        <Toast ref={toast}/>
        <div className="message-tab">
            <div className={`cp-input`}>

                <span className={`p-float-label`}>
                    <InputText
                        type="text"
                        value={titleValue}
                        onChange={(e) => setTitleValue(e.target.value)}
                    />
                    <label>Title</label>
                </span>
            </div>
            <div className="cp-editor">
                <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px' }} />
            </div>
           
            <Button label="저장 하기" onClick={()=>confirm1()} />

            <div className="inventory">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
                    <DataTable
                        value={data}
                        expandedRows={expandedRows}
                        onRowToggle={(e) => {setExpandedRows(e.data)}}
                        // onRowExpand={onRowExpand}
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="uid"
                        tableStyle={{ minWidth: '60rem' }}
                    >
                        <Column expander={true} onClick={(e)=>console.log(e.uid)} style={{ width: '5rem' }} />
                        <Column field="msg_subject" header="타이틀" />

                        <Column
                            field="status_confirms"
                            header="확인/미확인"
                            sortable
                            body={bodyTemplate}
                        />
                        <Column field="msg_time" header="전송일" />
                        <Column field="msg_confirm_time" header="확인일" />

                    </DataTable>
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
            </div>

        </div>
        </>

    )
}
export default MessageTab;