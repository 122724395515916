import React from 'react';

const AppFooter = () => {
    return (
      <div className="layout-footer">
        <div className="grid">
          <div className="col">
            <img
              src="assets/layout/images/logo-white.svg"
              alt="sapphire-layout"
            />
            <div className="layout-footer-appname">
              PrimeReact Premium Template
            </div>
            <div className="layout-footer-kom mt-2">KOM</div>
          </div>
          <div className="col col-align-right">
            <span> @ All Rights Reserved</span>
          </div>
        </div>
      </div>
    );
};

export default AppFooter;
