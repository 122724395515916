import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import './AgentCode.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import { InputText } from "primereact/inputtext";
import AgentService from "../../../service/AgentServices";
import FullCalendar from "@fullcalendar/react";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

const AgentCode = () => {
    const [selectOption, setSelectOption] = useState({ name: "선택", code: "" });
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [first, setFirst] = useState(0);
    const [questionTime, setQuestionTime] = useState('-');
    const [content, setContent] = useState('-');
    const [bodyContent, setBodyContent] = useState('')
    const [defaultValueMap, setDefaultValueMap] = useState({});
    const [codeInput, setCodeInput] = useState('');
    const [url, setUrl] = useState()
    const toast = useRef(null)
    const header = [
        { field: "mb_ids", label: `에이전트`, sortAble: false },
        { field: "mb_id", label: "유저 아이디", sortAble: true },
        { field: "code", label: "코드", sortAble: false },
        { field: "rest_join", label: "남은 가입횟수", sortAble: true },
        { field: "modify_date", label: "생성일", sortAble: true },
        { field: "btn_edit", label: "수정", sortAble: false },
        { field: "btn_delete", label: "삭제", sortAble: false },

    ];
    const agentList = new AgentService();
    function getAgentCodeList() {
        const payload = {
            "submit": "0",
            page: page
        }
        agentList.agentCodeListAPI(payload)
            .then((data) => {
                const res = data.list
                setData(res.data)
                setTotalRecords(res.total)
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getAgentCodeList()
    }, [page])
    useEffect(()=>{
        const newDefaultValueMap = {};
        if(data){
            data.forEach((row) => {
                console.log(row)
                newDefaultValueMap[row.mb_id] = row.mb_code;
              });
              setDefaultValueMap(newDefaultValueMap);
        }
        
    },[page, data])

    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    function onHandleModify(e){
        const urlAction = new URL(e?.action_button?.modify?.link)
        const queryString  = urlAction.search
            confirmDialog({
                message: "유저 정보 를 변경하시겠습니까",
                header: "유저 저장",
                accept: ()=>{confirm(`member${queryString}`, e.mb_no)},
                acceptLabel: "확인",
                acceptClassName: "p-confirm-dialog-accept",
            });
    }
    function onHandelDelete (e){
        const urlAction = new URL(e?.action_button?.delete?.link)
        const queryString  = urlAction.search
            confirmDialog({
                message: "유저 정보 를 변경하시겠습니까",
                header: "유저 저장",
                accept: ()=>{deleteCode(`member${queryString}`, e.mb_no)},
                acceptLabel: "확인",
                acceptClassName: "p-confirm-dialog-accept",
            });
    }
    function confirm(e, id){
        agentList.agentCodeActionAPI(e, {mb_code: codeInput, submit: 1, mb_no: id,"process": "modify", }).then((data) => {
            toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
        }).catch((err) => { console.log(err) })
    }
    function deleteCode(e, id){
        agentList.agentCodeActionAPI(e, { submit: 1, mb_no: id }).then((data) => {
            toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
        }).catch((err) => { console.log(err) })
    }
    function handleChangeCode(mbId, value){
        setCodeInput(value)
        const newDefaultValueMap = { ...defaultValueMap };
        newDefaultValueMap[mbId] = value;
        setDefaultValueMap(newDefaultValueMap);
    }
    const bodyTemplate = (data, props) => {
        const id = data?.mb_id
        return (
            <>
                {props.field === 'btn_action' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div className="icon-btn">
                            <span style={{}}><i style={{ color: 'white' }} className="pi-file-edit pi" /></span>

                        </div>
                    </>
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}

                {props.field === 'mb_ids' &&
                    <div onClick={() => { window.open(`#/agentdetail/${data.mb_id}`, '', "width=1500,height='100vh'") }}>
                        <span className="p-column-title">{props.header}</span>
                        {id} <i className="pi-cog pi" style={{ color: 'red' }} />
                    </div>
                }
                {props.field === 'code' &&
                    <>
                        <span className="p-column-title">{props.header}</span>

                        <div className={`table-input`}>
                            <span className={`p-float-label`}>
                                <InputText
                                    id=''
                                    type="text"
                                    value={defaultValueMap[data?.mb_id]}
                                    onChange={(e) => handleChangeCode(data.mb_id, e.target.value)}
                                    style={{paddingTop: '.7rem'}}
                                />
                                <label ></label>
                            </span>
                        </div>
                    </>
                }
                {props.field === 'rest_join' &&
                    <>
                        <span className="p-column-title">{props.header}</span>

                        <div className={`table-input`}>
                            <span className={`p-float-label`}>
                                <InputText
                                    id=''
                                    type="text"
                                    value='-'
                                    style={{paddingTop: '.7rem'}}
                                    // onChange={(e) => setCodeInput(e.target.value)}
                                //   onKeyDown={(e) => onKeyDown?.(e)}
                                />
                                <label ></label>
                            </span>
                        </div>
                    </>
                }
                {
                    props.field === 'btn_edit' &&
                    <>
                        <span className="p-column-title-edit">{props.header}</span>
                        <Button className="edit"
                            onClick={() => {
                                onHandleModify(data)
                            }}>
                            수정
                        </Button>
                    </>
                }
                {
                    props.field === 'btn_delete' &&
                    <>
                        <span className="p-column-title-delete">{props.header}</span>
                        <Button className="delete"
                            onClick={(e) => {
                                onHandelDelete(data)
                            }}>
                            삭제
                        </Button>
                    </>
                }
            </>
        )
    }
    return (
        <>
            <Toast ref={toast}/>
            {/* <div className='refresh card'>
                <span className="text">가입 코드 생성</span>
                <div className="tool-agent-code">
                    <div className="cp-dropdown">
                        <CpDropdown
                            options={menuDropdown}
                            onChange={(e) => chooseOption(e)}
                            value={selectOption}
                        />
                    </div>
                    {inpGroup.map((v, i) => {
                        return (
                            <div className={`cp-input`} key={i}>
                                <span className={`p-float-label`}>
                                    <InputText
                                        id=''
                                        type="text"
                                        value={''}
                                    //   onChange={(e) => onChangeInput(e.target.value)}
                                    //   onKeyDown={(e) => onKeyDown?.(e)}
                                    />
                                    <label htmlFor={v.name}>{v.placeholder}</label>
                                </span>
                            </div>
                        )
                    })}

                    <Button className="create-join-code-btn">가입코드 생성</Button>
                </div>
            </div> */}
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
                <CustomDataTable
                    data={data}
                    headers={header}
                    bodyTemplate={bodyTemplate}
                    modalMessage={messageModal}
                    // openConfirm={openModalConfirm}
                    scrollable={true}
                    sortMode='multiple'
                />
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
            </div>
        </>
    )
}

export default AgentCode;