import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";
import moment from "moment-timezone";
import { Editor } from "primereact/editor";
import { ColorPicker } from "primereact/colorpicker";

import { notePopupListAPI, notePopupUpdateAPI } from "../../service";

import moneyConvert from "../../utilities/moneyConvert";
import {
  CpButton,
  CpButtonIcon,
  CpInput,
  CpRadio,
  CpDatePicker,
  CpDropdown,
  CpButtonGroup,
  CpButtonGroupActive,
  CpPopup,
  CpColorPicker,
} from "../../components";
import { Dropdown } from "primereact/dropdown";

const dataDropdown = [
  { name: "선택", code: "ALL" },
  { name: "아이디", code: "OT1" },
  { name: "제목", code: "OT2" },
];
const dataButtonGroup = [
  { name: "2. 가상계좌발급 (구리가상)", code: "0" },
  { name: "3.  ★★★ 평생 고정 계좌 안내 입니다 ★★★", code: "1" },
  { name: "1. 입금통장문의 (종이장라인)", code: "2" },
  { name: "★★★  계좌 1원 인증번호  ★★★", code: "2" },
  { name: "★★★  인증 시간 초과시 답변  ★★★", code: "2" },
  { name: "▶▶▶  평생 계좌 입금 안된다고하면 답변   ◀◀◀", code: "2" },
  { name: "확인후 처리완료", code: "2" },
  { name: "환전 마감 안내 ", code: "2" },
  { name: "충전 마감 안내 ", code: "2" },
  { name: "포인트관련문의", code: "2" },
  { name: "은행 점검 시간안내", code: "2" },
  { name: "환전 지연시", code: "2" },
];

const dataRadioBox = [
  { id: 0, value: "0", label: "숨김" },
  { id: 1, value: "1", label: "보기" },
];

const Item = (props) => {
  const { data, onUpdate } = props;
  const [check, setCheck] = useState(false);
  const [editor, setEditor] = useState();
  const [text, setText] = useState("");
  const [dataItem, setDataItem] = useState({
    board_color: "1976D2",
    // board_content: "",
    board_field2: "",
    board_field4: "",
    board_field5: "",
    board_field7: "",
    board_field9: "",
    board_id: null,
    board_subject: "",
  });

  useEffect(() => {
    setDataItem({
      ...data,
      board_color: data.board_color ? data.board_color : "1976D2",
      board_field2: data.board_field2 ? data.board_field2 : "y",
    });
    setEditor(data.board_content);
  }, [data]);
  // console.log("data", data);

  const updateItem = async () => {
    console.log(dataItem, editor);
    if (Object.values(dataItem).includes("") || editor.length === 0) {
      alert("항목을 모두 입력해주세요.");
    } else {
      try {
        const res = await notePopupUpdateAPI({
          type: "mod", // NULL: Add || mod: Update || || list_hidden: Delete
          id: data.board_id, // NULL: Show List/Add  || Otherwise: Update/Delete
          board_subject: dataItem.board_subject,
          board_color: dataItem.board_color,
          board_field5: dataItem.board_field5, // Width
          board_field9: dataItem.board_field9, // Height
          pos_x: dataItem.pos_x,
          pos_y: dataItem.pos_y,
          board_field2: dataItem.board_field2,
          board_content: editor,
          board_field7: dataItem.board_field7,
        });
        if (res.code === 200) {
          console.log(res);
        setText(res.message);
        setCheck(true);
          // onUpdate();
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteItem = async () => {
    console.log(dataItem, editor);
    // if (Object.values(dataItem).includes("") || editor.length === 0) {
    //   alert("항목을 모두 입력해주세요.");
    // } else {
    try {
      const res = await notePopupUpdateAPI({
        type: "delete", // NULL: Add || mod: Update || || list_hidden: Delete
        id: data.board_id, // NULL: Show List/Add  || Otherwise: Update/Delete
      });
      if (res.code === 200) {
        console.log(res);
        setText(res.message)
        setCheck(true)
        onUpdate();
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
    // }
  };

  return (
    <div className="card mb-1">
      <div className="title">
        <CpInput
          placeholder="Title"
          value={dataItem.board_subject}
          onChange={(e) => setDataItem({ ...dataItem, board_subject: e })}
        />
        <CpColorPicker />
      </div>
      <div className="pos mt-3">
        <CpInput
          placeholder="Pos X"
          value={dataItem.pos_x}
          onChange={(e) => setDataItem({ ...dataItem, pos_x: e })}
          type="number"
        />
        <CpInput
          placeholder="Pos Y"
          value={dataItem.pos_y}
          onChange={(e) => setDataItem({ ...dataItem, pos_y: e })}
          type="number"
        />
      </div>
      <div className="w-h mt-2">
        <CpInput
          placeholder="Width"
          value={dataItem.board_field5}
          onChange={(e) => setDataItem({ ...dataItem, board_field5: e })}
          type="number"
        />
        <CpInput
          placeholder="Height"
          value={dataItem.board_field9}
          onChange={(e) => setDataItem({ ...dataItem, board_field9: e })}
          type="number"
        />
      </div>
      <div className="domain mt-3 md:mt-5">
        <CpInput
          placeholder="도메인"
          value={dataItem.board_field2}
          onChange={(e) => setDataItem({ ...dataItem, board_field2: e })}
        />
      </div>
      <div className="ed mt-3">
        <Editor
          placeholder=""
          // ref={refItem}
          value={editor}
          onTextChange={(e) => setEditor(e.htmlValue)}
          style={{ height: "320px" }}
        />
      </div>
      <div className="rd mt-3">
        <CpRadio
          radioValue={dataItem.board_field7 === "1" ? "1" : "0"}
          // onChange={(e) => setRadioValue(e)}
          dataRadio={dataRadioBox}
          onChange={(e) => setDataItem({ ...dataItem, board_field7: e })}
        />
      </div>
      <div className="bt mt-0">
        <CpButton label="저장 하기" onClick={updateItem} />
        <CpButton label="삭제 하기" onClick={deleteItem} />
      </div>
      <CpPopup
        visible={check}
        onHide={() => setCheck(false)}
        label="팝업 공지"
        content={text}
      />
    </div>
  );
};

const NotePopup = () => {
  const navigate = useNavigate();
  // const refEditor = useRef("");
  const [editor, setEditor] = useState();
  const [defaultValue, setDefaultValue] = useState({
    title: "",
    color: "1976D2",
    posX: "0",
    posY: "0",
    width: "300",
    height: "240",
    note: "",
    radio: "1",
  });
  let [data, setData] = useState(null);
  const [check, setCheck] = useState(false);

  const getDataHelp = useCallback(async () => {
    try {
      const res = await notePopupListAPI({
        type: "list",
        board_subject: "",
      });
      if (res.code === 200) {
        console.log(res);
        setData(res.list.data);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  }, [setData]);

  useEffect(() => {
    getDataHelp();
  }, []);

  const addNewItem = async () => {
    if (Object.values(defaultValue).includes("") || editor.length === 0) {
      alert("항목을 모두 입력해주세요.");
    } else {
      try {
        const res = await notePopupUpdateAPI({
          type: "", // NULL: Add || mod: Update || || list_hidden: Delete
          id: "", // NULL: Show List/Add  || Otherwise: Update/Delete
          board_subject: defaultValue.title,
          board_color: defaultValue.color,
          board_field5: defaultValue.width, // Width
          board_field9: defaultValue.height, // Height
          pos_x: defaultValue.posX,
          pos_y: defaultValue.posY,
          board_field2: defaultValue.note,
          board_content: editor,
          board_field7: defaultValue.radio,
        });
        if (res.code === 200) {
          console.log(res);
          setCheck(true);
          getDataHelp(true);
          setDefaultValue({
            title: "",
            color: "1976D2",
            posX: "0",
            posY: "0",
            width: "300",
            height: "240",
            note: "",
            radio: "1",
          });
          setEditor("");
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onUpdate = () => {
    getDataHelp();
    console.log("dsadsa");
  };

  return (
    <div className="prid notepopup">
      <div className="col-12 p-3 card mb-4 header md:p-4">
        <div className="col-12 mb-1 p-0" style={{ fontSize: "16px" }}>
          팝업 공지
        </div>
      </div>
      <div className="p-0 mt-4 flex flex-wrap content">
        <div className="item p-2 col-12 md:col-4">
          <div className="card mb-1">
            <div className="title">
              <span>Title</span>
              <CpInput
                placeholder=""
                value={defaultValue.title}
                onChange={(e) => setDefaultValue({ ...defaultValue, title: e })}
              />
              <CpColorPicker
                value={defaultValue.color}
                onChange={(e) => setDefaultValue({ ...defaultValue, color: e })}
              />
            </div>
            <div className="pos mt-3">
              <CpInput
                placeholder="Pos X"
                value={defaultValue.posX}
                type="number"
                onChange={(e) => setDefaultValue({ ...defaultValue, posX: e })}
              />
              <CpInput
                placeholder="Pos Y"
                value={defaultValue.posY}
                type="number"
                onChange={(e) => setDefaultValue({ ...defaultValue, posY: e })}
              />
            </div>
            <div className="w-h mt-2">
              <CpInput
                placeholder="Width"
                type="number"
                value={defaultValue.width}
                onChange={(e) => setDefaultValue({ ...defaultValue, width: e })}
              />
              <CpInput
                placeholder="Height"
                type="number"
                value={defaultValue.height}
                onChange={(e) =>
                  setDefaultValue({ ...defaultValue, height: e })
                }
              />
            </div>
            <div className="domain mt-3 md:mt-5">
              <CpInput
                placeholder="도메인"
                value={defaultValue.note}
                onChange={(e) => setDefaultValue({ ...defaultValue, note: e })}
              />
            </div>
            <div className="ed mt-3">
              <Editor
                placeholder=""
                // ref={refEditor}
                style={{ height: "320px" }}
                value={editor}
                onTextChange={(e) => setEditor(e.htmlValue)}
              />
            </div>
            <div className="rd mt-3">
              <CpRadio
                radioValue={defaultValue.radio}
                onChange={(e) => setDefaultValue({ ...defaultValue, radio: e })}
                dataRadio={dataRadioBox}
              />
            </div>
            <div className="bt mt-0">
              <CpButton label="저장 하기" onClick={addNewItem} />
            </div>
          </div>
        </div>
        {data?.map((v, i) => (
          <div className="item p-2 col-12 md:col-4" key={i}>
            <Item data={v} onUpdate={onUpdate} />
          </div>
        ))}
      </div>
      <CpPopup
        visible={check}
        onHide={() => setCheck(false)}
        label="팝업 공지"
        content="팝업 공지 작성/수정 성공."
      />
    </div>
  );
};

export default NotePopup;
