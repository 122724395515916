import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";
import moment from "moment-timezone";
import moneyConvert from "../../utilities/moneyConvert";
import {
  CpButton,
  CpButtonIcon,
  CpInput,
  CpPagination,
  CpDatePicker,
  CpDropdown,
  CpButtonGroup,
  CpButtonGroupActive,
} from "../../components";
import { Dropdown } from "primereact/dropdown";
import AdminService from '../../service/AdminServices'
import { Calendar } from "primereact/calendar";
import { formatDate } from "../../utilities/date";
const dataDropdown = [
  { name: "선택", code: "" },
  { name: "에이전트 아이디", code: "mb_id" },
  { name: "유저 아이디", code: "mb_id2" },
];

const EditLog = () => {
  const admin = new AdminService();
  const [dropdownValue, setDropdownValue] = useState({ name: "선택", code: "" });
  const [data, setData] = useState(undefined);
  const [label, setLabel] = useState(undefined);
  const [sDate, setSDate] = useState(new Date())
  const [eDate, setEDate] = useState(new Date())
  const inputRef = useRef('')
  const [page, setPage] = useState(1);
  const [logType, setLogType] = useState(undefined);
  const [btn, setBtn] = useState({name: '전체', code: ''})
  const getEditLogData = (query) => {
    const myQuery = {
      page: page
    }
    return admin.getEditLog(query || myQuery).then((resp) => {
      setData(resp.list)
      setLogType(resp.general.log_type)
      return resp
    }).catch(() => {

    })
  };

  useEffect(() =>{
    const query = {
      start_date: formatDate(sDate),
      end_date: formatDate(eDate),
      keyfield: dropdownValue.code || '',
      key: inputRef.current,
      log_type: btn.code
    }
    getEditLogData(query).then((resp) =>{
      setLabel(resp.label)
    })
  }, [btn]);

  const Template = ({ row, data, dataTemplate }) => {
    return (
      data.map((e, i) => {
        return (
          <div className="item-infor">
            {row.map(r => {
              
              // eslint-disable-next-line react/no-danger-with-children
              return <div className="item" style={{ width: r === 'log_after' || r === 'log_before' ? '30%' : "14.6%" }} dangerouslySetInnerHTML={{__html: dataTemplate(e, r)}} />
            })}
          </div>

        )
      })
    )
  }

  const dataTemplate = (val, row) => {
    if (row === 'log_after') {
      let text = ''
      Object.keys(val[row] ).forEach(e =>{
        text += `${e}: ${val[row][e] || '...'} <br>`
      })
      return text
    }
    if (row === 'log_before') {
      let text = ''
      Object.keys(val[row] ).forEach(e =>{
        text += `${e}: ${val[row][e] || '...'} <br>`
      })
      return text
    }
    return val[row]
  }

  const dataButtonGroup = Object.keys(logType || {}).map(e =>{
    return {name: logType[e].label, code: e}
  });

  // const dataButtonGroup = [
  //   { name: "은행명 변경", code: "0" },
  //   { name: "어드민 에이전트 변경", code: "1" },
  //   { name: "에이전트 베네핏 변경", code: "2" },
  //   { name: "유저 디테일 변경", code: "3" },
  //   { name: "에이전트 => 유저 롤링 변경", code: "4" },
  //   { name: "에이전트 => 에이전트 롤링 변경", code: "5" },
  // ];
  const handlePageChange = (p) =>{
    setPage(p)
    const query = {
      start_date: formatDate(sDate),
      end_date: formatDate(eDate),
      page: p,
      log_type: btn.code
    }
    getEditLogData(query)
  }
  const handleFilter = () =>{
    console.log(dropdownValue);
    if(!sDate && !eDate) return;
    // return
    setPage(1);
    const query = {
      start_date: formatDate(sDate),
      end_date: formatDate(eDate),
      keyfield: dropdownValue.code,
      key: inputRef.current,
      log_type: btn.code
    }
    getEditLogData(query)
  }
  
  const handleLogType = (e) =>{
    if(!sDate && !eDate) return;
    setBtn({...e})
    setPage(1)
  }

  useEffect(() =>{
  }, [btn])

  return (
    <div className="prid edit-log">
      <div className="col-12 p-3 card mb-4 header md:p-4">
        <div className="col-12 mb-2 p-0" style={{ fontSize: "16px" }}>
          수정 / 기록변경
        </div>
        <div className="col-12 de-wi-box p-0 sm:flex sm:flex-wrap">
          <div className="p-0 sm:col-6 md:col-3 xl:col-3 flex align-items-end">
            <CpDatePicker showTime={false} defaulDate={sDate} onChange={(e) => setSDate(prev => prev = e)} />
          </div>
          <div className="p-0 sm:col-6 mt-2 md:col-3 xl:col-3 sm:mt-2 flex align-items-end">
            <CpDatePicker showTime={false} defaulDate={eDate} onChange={(e) => setEDate(prev => prev = e)} />
          </div>
          <div className="p-0 sm:col-6 mt-3 md:col-3 xl:col-2 flex align-items-end md:mt-0">
            <CpDropdown
              style={{ minWidth: "100px" }}
              value={dropdownValue}
              options={dataDropdown}
              onChange={(e) => setDropdownValue({...e})}
              placeholder=""
              
            />
          </div>
          <div className="p-0 mt-1 sm:col-6 md:col-2 xl:col-2 flex align-items-end">
            <CpInput onChange= {((e) => {inputRef.current = e})} placeholder="" />
            {/* <CpButtonIcon icon="pi pi-search" severity="" aria-label="" /> */}
          </div>
          <div className="p-0 mt-3 sm:col-6 md:col-1 xl:col-1 flex align-items-end">
            {/* <CpInput placeholder="검색" /> */}
            <CpButton onClick={handleFilter} label="검색" />
          </div>
        </div>
        <div className="total col-12 p-0 mt-3">
          <CpButtonGroup
            name={btn.name}
            dataGroup={dataButtonGroup}
            onChange={handleLogType}
          />
        </div>
      </div>
      <div className="card">
        <div className="pagi-box">
          {data && <CpPagination page={page} defaulRows={data?.per_page} onChange={handlePageChange} total={data?.total} />} 
        </div>
        <div className="infor-edit-log">
          <div className="depost-infor p-0">
            <div className="header p-0">
              {
                Object.keys(label || {}).map(e => {
                  return (
                    <div className="item" style={{ width: e === 'log_after' || e === 'log_before' ? '30%' : "14.6%" }}>
                      {label[e]}
                    </div>
                  )
                })
              }
            </div>
            <div className="content-box">
              {data && data.data.length ?
                <Template row={Object.keys(label || {})} dataTemplate={dataTemplate} data={data.data} />
                : ''
              }
            </div>
          </div>
        </div>
        <div className="pagi-box">
          {data && <CpPagination page={page} defaulRows={data?.per_page} onChange={handlePageChange} total={data?.total} />} 
        </div>
      </div>
    </div>
  );
};

export default EditLog;
