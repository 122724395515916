import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { Paginator } from "primereact/paginator";
import 'primeicons/primeicons.css';
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import './Withdraw.scss'
import CustomDataTable from "../../components/Table/TableUser";
import MoneyService from "../../service/DepositeWithdraw";
import { Toast } from "primereact/toast";

const WithdrawAgent = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0);    
    const [messageModal, setMessageModal] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);

    const [payload, setPayload] = useState()
    
    const moneyAction = new MoneyService();

    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    const fetchMoneyChange = () => {
      const payload ={
        page: page,
        case: '0,1',
        top: 1,
        role: 'agent'
      }
        moneyAction.getWithdrawHis(payload)
            .then((data) => {
                const res = data?.list
                setData(res?.data)
                setTotalRecords(res?.total)
            })
    }
    useEffect(() => {
      fetchMoneyChange()
    }, [page])
    function fetchMoneyAction (){
        if(payload){
            moneyAction.actionMoneyRequest(payload)
                .then((data) =>{
                  if (data.code === 200) {
                    toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
                    fetchMoneyChange()
                  }
                  else {
                    toast.current.show({ severity: 'error', summary: '실패', detail: data?.message, life: 3000 });
                    fetchMoneyChange()
                  }
                })
                .catch((err)=>{
                    console.log(err)
                })
        }
    }
    const toast = useRef(null)
    function getParams (v){
        setPayload(v?.base?.split('v1/')[1])
    }
    
    const header = [
        { field: "type", label: `종류`, sortAble: false },
        { field: "member_name", label: "아이디" , sortAble: false },
        { field: "member_id", label: "닉네임" , sortAble: false },
        { field: "member", label: "입금자 / 출금자" , sortAble: false },
        { field: "money_action", label: "입금 / 출금" , sortAble: false },
        { field: 'mb_point', label: "보너스 / 롤링" , sortAble: false },
        { field: 'money_value', label: "금일충전 / 금일환전" , sortAble: false },
        { field: 'regist_date', label: "신청날짜 / 완료날짜" , sortAble: false },
        { field: 'tree', label: "소속 / 소속트리" , sortAble: false },
        { field: 'status', label: "상태" , sortAble: false },
        { field: 'action_button', label: "" , sortAble: false },

    ];
    
    const bodyTemplate = (data, props) => {
        const money= data?.charge_money[2]
        const name = data?.member?.split('(');
        const regex = /\(([^)]+)\)/;
        const id = regex.exec(data?.member)
        const status = data?.situation?.label
        const buttonGroup = [
            {title: '완료', value:'confirm', id:'btn-confirm'},
            {title: '대기', value:'waitting', id:'btn-waitting'},
            {title: '취소', value:'reject', id:'btn-reject'}
        ]
        const btnGroupPending =[
          {title: '완료', value:'confirm', id:'btn-confirm'},
          {title: '취소', value:'reject', id:'btn-reject'}
        ]
        const handleChangeStatus =(e)=>{
            if(e.value === 'confirm'){
                setMessageModal({ message: '출금 신청을 완료 하시겠습니까 ?', header: '출금 신청 / 완료' });
                setOpenModalConfirm(true)
                getParams(data?.actions?.process)
            }
            if(e.value === 'waitting'){
                setMessageModal({ message: '출금 신청을 대기 상태로 변경하시겠습니까 ?', header: '출금 신청 / 대기' });
                setOpenModalConfirm(true)
                getParams(data?.actions?.wait)
    
            }if(e.value === 'reject'){
                setMessageModal({ message: '출금 신청을 취소 상태로 변경하시겠습니까 ?', header: '출금 신청 / 취소' });
                setOpenModalConfirm(true)
                getParams(data?.actions?.cancel)
            }
        }
        return (
          <>
            {props.field === 'member_name' &&
                <div onClick={()=>{window.open(`#/userdetail/${data.mb_id}`, '', "width=1500,height='100vh'")}}>
                    {name[0]}
                </div>
            }
            {props.field === 'member_id' &&
                <div>
                    <span>{id[1]}</span>
                </div>
            }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            {props.field ==='type' &&
                   <span style={{color:'red'}}>{data.charge_money[0] || ''}</span> 
            }
            {props.field ==='money_action' &&
                <>
                  <span style={{color: 'red'}}>출금</span>
                </>
            }
            {props.field ==='mb_point'&&
                <>
                  0 / 0
                </>
            }
            { props.field ==='money_value'&&
              <>{money}</>
            }
            { props.field ==='regist_date'&&
              <>{data?.regdate}</>
            }
            { props.field ==='tree'&&
              <>
                {data?.step_1 && 
                  <>
                    <span style={{color: 'red'}}>
                      {data?.step_1}
                    </span>
                  </>
                } &nbsp;
                {data?.step_up && 
                  <>
                    {`>`}  &nbsp;
                    <span style={{color: 'blue'}}>
                      {data?.step_1}
                    </span>
                    
                  </>
                }&nbsp;
                {data?.member && 
                  <>
                    {`>`}  &nbsp;
                    <span style={{color: 'orange'}}>
                      {data?.member}
                    </span>
                    
                  </>
                }
              </>
            }
            {props.field === 'status' &&
                <span style={{backgroundColor:'gray', padding:'10px 5px 10px 5px', borderRadius:'50%'}}>
                    {data?.situation?.label}
                </span>
            }
            {props.field ==='action_button' &&
                <>
                    { status === '요청'?
                    buttonGroup.map((v,i)=>{
                        return (
                            <Button className={v.id} key={i} onClick={()=> handleChangeStatus(v)} label={v.title}/>
                        )
                    })
                    :
                    btnGroupPending.map((v,i)=>{
                      return (
                          <Button className={v.id} key={i} onClick={()=> handleChangeStatus(v)} label={v.title}/>
                      )
                  })
                  }
                </>
            }
          </>
        );
      };  
      return (
        <>  
          <Toast ref={toast}/>
            <div className='refresh'>
              <span className="text-span">출금 신청</span>
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                />
                <CustomDataTable data={data} headers={header} bodyTemplate={bodyTemplate} modalMessage={messageModal} action={fetchMoneyAction} scrollable={true} openConfirm={openModalConfirm}/>
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
                />
            </div>
        </>
    )
}

export default WithdrawAgent