import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useRef, useState } from "react";
import './MoneyExchange.scss'
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { SelectButton } from "primereact/selectbutton";
import CustomDataTable from "../../../../components/Table/TableUser";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import UserService from "../../../../service/UserService";
import { useParams } from "react-router-dom";

const MoneyExchangeTab = (props) => {
    const buttonFilterDate = [
        { button: '전체', value: '전체' },
        { button: '오늘', value: '오늘' },
        { button: '최근일주일', value: '최근일주일' },
        { button: '최근한달', value: '최근한달' }
    ]
    const [actionRadio, setActionRadio] = useState('지급');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterDateRange, setFilterDateRange] = useState(buttonFilterDate[0])
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0)
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [moneyValue, setMoneyvalue] = useState('');
    const [memoMsg, setMemoMsg] = useState('')
    const userList = new UserService();
    const params = useParams();
    const [filterCondition, setFilterCondition] = useState('전체')
    const [activeBtn, setActiveBtn] = useState('active');
    const [pw, setpw] = useState('')
    const [total, setTotal] = useState([])
    const today = new Date()
    function fetchChargeMoney() {
        const payload = {
            sdate: startDate,
            edate: endDate,
            mb_id: params.id,
            // req_type: 1,
            page: page
        }
        var condition = { ...payload }
        if (startDate === null && endDate === null) {
            condition = {
                mb_id: params.id,
                // req_type: 1,
                top: 1,
                page: page

            }
        }
        if (startDate === null && endDate !== null) {
            const pass = new Date(endDate)
            pass.setDate(endDate.getDate() - 1)
            condition = {
                sdate: formatDate(pass),
                edate: formatDate(endDate),
                mb_id: params.id,
                // req_type: 1,
                page: page

            }
        }
        if (startDate !== null && endDate === null) {
            condition = {
                sdate: formatDate(startDate),
                edate: formatDate(today),
                mb_id: params.id,
                // req_type: 1,
                page: page

            }
        }
        if (startDate && endDate) {
            condition = {
                sdate: formatDate(startDate),
                edate: formatDate(endDate),
                mb_id: params.id,
                // req_type: 1,
                page: page

            }
        }
        userList.fetchChargeMoney(condition)
            .then((data) => {
                const res = data?.list?.data
                setData(res)
                setTotalRecords(data?.list?.total)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    function fetchExchangeMoney() {
        const payload = {
            sdate: startDate,
            edate: endDate,
            mb_id: params.id,
            // req_type: 1,
            page: page
        }
        var condition = { ...payload }
        if (startDate === null && endDate === null) {
            condition = {
                mb_id: params.id,
                // req_type: 1,
                top: 1,
                page: page

            }
        }
        if (startDate === null && endDate !== null) {
            const pass = new Date(endDate)
            pass.setDate(endDate.getDate() - 1)
            condition = {
                sdate: formatDate(pass),
                edate: formatDate(endDate),
                mb_id: params.id,
                // req_type: 1,
                page: page
            }
        }
        if (startDate !== null && endDate === null) {
            condition = {
                sdate: formatDate(startDate),
                edate: formatDate(today),
                mb_id: params.id,
                // req_type: 1,
                page: page
            }
        }
        if (startDate && endDate) {
            condition = {
                sdate: formatDate(startDate),
                edate: formatDate(endDate),
                mb_id: params.id,
                // req_type: 1,
                page: page
            }
        }
        userList.fetchExchangeMoney(condition)
            .then((data) => {
                const res = data?.list?.data
                setData(res)
                setTotalRecords(data?.list?.total)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    function fetchUserMoney() {
        const payload = {
            sdate: startDate,
            edate: endDate,
            id: params.id,
            // req_type: 1,
            page: page
        }
        var condition = { ...payload }
        if (startDate === null && endDate === null) {
            condition = {
                id: params.id,
                // req_type: 1,
                top: 1,
                page: page

            }
        }
        if (startDate === null && endDate !== null) {
            const pass = new Date(endDate)
            pass.setDate(endDate.getDate() - 1)
            condition = {
                sdate: formatDate(pass),
                edate: formatDate(endDate),
                id: params.id,
                page: page

                // req_type: 1,
            }
        }
        if (startDate !== null && endDate === null) {
            condition = {
                sdate: formatDate(startDate),
                edate: formatDate(today),
                id: params.id,
                page: page

                // req_type: 1,
            }
        }
        if (startDate && endDate) {
            condition = {
                sdate: formatDate(startDate),
                edate: formatDate(endDate),
                id: params.id,
                page: page

                // req_type: 1,
            }
        }
        userList.fetchUserMoney(condition)
            .then((data) => {
                const res = data?.data?.data
                setData(res)
                setTotalRecords(data?.data?.total)
                setTotal(data.total)

            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        if (filterCondition === '충전') {
            fetchChargeMoney()
        }
        if (filterCondition === '환전') {
            fetchExchangeMoney()
        }
        if (filterCondition === '전체') {
            fetchUserMoney()
        }
    }, [page, filterDateRange, filterCondition])
    useEffect(() => {

    }, [startDate, endDate])
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };

    const actionSubmit = () => {
        const payload = {
            admin_pw: pw,
            submit: 1,
            money: moneyValue,
            memo: memoMsg
        }
        switch (true) {
            case actionRadio === '지급':

                userList.chargeExchangeMoney('money_result', 'in_money', params.id, payload)
                    .then((data)=>{
                        if(data.code !== 200){
                            alert(data.message)
                        }
                    })
                    .catch((err) => alert(err.message))
                break;
            case actionRadio === '회수':
                userList.chargeExchangeMoney('money_result_out', 'out_money', params.id, payload)
                    .then()
                    .catch((err) => alert(err.message))
                break;
            default:
                break;
        }

    }
    const handleActionMoney = () => {
        if (!moneyValue) {
            alert('금액은 비워둘 수 없습니다.')
        }
        if (moneyValue < 0) {
            alert('금액이 유효하지 않습니다. 금액은 양수여야 합니다.')
        }
        if (!pw) {
            alert('비밀번호는 비워두면 안 됩니다')
        }
        else {
            props.action(actionSubmit)
        }
    }
    const buttonGroup = [
        { button: '전체', value: '전체', id: activeBtn },
        { button: '충전', value: '충전' },
        { button: '환전', value: '환전' },
        { button: '운영자+-', value: '운영자+-' },
        { button: '쿠폰', value: '쿠폰' }

    ];

    addLocale('ko', {
        firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
    const FilterDate = () => {
        const handleChangeDateRange = async (e) => {
            setFilterDateRange(e)
            if (e.value === '전체') {
                setStartDate(null)
                setEndDate(null)
            }
            if (e.value === '오늘') {
                setStartDate(today)
                const pass = new Date(today)
                pass.setDate(today.getDate() + 1)
                setEndDate(pass)
            }
            if (e.value === '최근일주일') {
                const pass = new Date(today)
                pass.setDate(today.getDate() - 6)
                setEndDate(today)
                setStartDate(pass)
            }
            if (e.value === '최근한달') {
                const pass = new Date(today)
                pass.setDate(today.getDate() - 29)
                setEndDate(today)
                setStartDate(pass)
            }
        }
        const justifyTemplate = (option) => {
            return <span>{option.button}</span>;
        }
        return (
            <SelectButton value={filterDateRange.value} onChange={(e) => handleChangeDateRange(e)} itemTemplate={justifyTemplate} options={buttonFilterDate} />
        )
    }

    const headerHistory = [
        { field: 'dep/with', label: '입금/출금', data: data },
        { field: 'bonus', label: '보너스', data: data },
        { field: 'rolling', label: '롤링', data: data },
        { field: 'time_request', label: '요청시간', data: data },
        { field: 'status', label: '상태', data: data },
        { field: 'type', label: '타입', data: data }
    ]
    const handleInputChange = (e) => {
        const rawValue = e;
        const numericValue = parseFloat(rawValue.replace(/,/g, '')) || ''
        setMoneyvalue(numericValue);
    };
    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const handleButtonClick = (id, params) => {
        setFilterCondition(params)
        setActiveBtn(id);
        // const pass =  new Date(today)
        // pass.setDate(today.getDate() - params)
        // setStartDate(pass);
        // setEndDate(today);
    };
    function formatDate(date) {
        var yyyy = date?.getFullYear();
        var mm = String(date?.getMonth() + 1).padStart(2, '0');
        var dd = String(date?.getDate()).padStart(2, '0');
        return yyyy + '-' + mm + '-' + dd
    }
    const onBtnSearchDateRange = () => {
        if (startDate > endDate && endDate !== null) {
            alert('다시 선택하세요. 종료 날짜가 시작 날짜보다 적으면 안됩니다')
        }
        else {
            if (filterCondition === '전체') {
                fetchUserMoney()
            }
            if (filterCondition === '환전') {
                fetchExchangeMoney()
            }
            if (filterCondition === '충전') {
                fetchChargeMoney()
            }
        }
    }
    const bodyTemplate = (data, props) => {
        const money = data?.charge_money[2] + data?.charge_money[3]
        const note = data?.charge_money[0]
        const requestTime = data?.regdate
        const status = data?.situation?.label
        const mode = data?.mode
        return (
            <>
                {props.field === 'dep/with' &&
                    <div >
                        <span style={filterCondition === '충전' ? { color: 'green' } : filterCondition === '환전' ? { color: 'red' }: filterCondition === '전체' && mode ==='money_list_1'?  { color: 'red' } : { color: 'green' }}>{money} ({note})</span>
                    </div>
                }
                {props.field === 'bonus' &&
                    <div >
                        <span >-</span>
                    </div>
                }
                {props.field === 'rolling' &&
                    <div >
                        <span >-</span>
                    </div>
                }
                {props.field === 'time_request' &&
                    <div >
                        <span >{requestTime}</span>
                    </div>
                }
                {props.field === 'status' &&
                    <div >
                        <span style={status === '승인완료' ? { color: 'green' } : status === '취소완료' ? { color: 'red' } : { color: 'black' }}>{status}</span>
                    </div>
                }
                {props.field === 'type' &&

                    <div >
                        <span style={filterCondition === '충전' ? { color: 'green' } : filterCondition === '환전' ? { color: 'red' } : { color: 'black' }}>
                            {filterCondition === '충전' ? '충전' : filterCondition === '환전' ? '환전' : ''}
                        </span>
                    </div>
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    const bodyExchange = (data, props)=>{
        console.log(data)
        return (
            <>
            {/* {props.field ==='deposite' &&
                <span>{data?.charge?.sum}</span>
            } */}
            <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    return (
        <>
            <div className="money-exchange-tab">
                <div className="radio-group">
                    <div className="option">
                        <RadioButton inputId="ingredient1" name="supply" value={actionRadio} onChange={(e) => setActionRadio('지급')} checked={actionRadio === '지급'} />
                        <label htmlFor={actionRadio} className="ml-2">지급</label>
                    </div>
                    <div className="option">
                        <RadioButton inputId="ingredient2" name="withdraw" value={actionRadio} onChange={(e) => setActionRadio('회수')} checked={actionRadio === '회수'} />
                        <label htmlFor={actionRadio} className="ml-2">회수</label>
                    </div>
                    <div className="option">
                        <RadioButton inputId="ingredient3" name="point" value={actionRadio} onChange={(e) => setActionRadio('포인트')} checked={actionRadio === '포인트'} />
                        <label htmlFor={actionRadio} className="ml-2">포인트</label>
                    </div>
                    <div className="input-money-supply">
                        <InputText placeholder="지급금액" value={formatNumber(moneyValue)} onChange={(e) => { handleInputChange(e.target.value) }} />
                        <InputText placeholder="롤링" style={{ width: '80px' }} />
                        <InputText placeholder="내용" type="text" onChange={(e) => setMemoMsg(e.target.value)} />
                        <InputText placeholder="계정 비밀번호" autoComplete={false} onChange={(e) => setpw(e.target.value)} style={{ width: '100px' }} type="password" />
                    </div>
                    <div className="button-confirm">
                        <Button onClick={() => handleActionMoney()}>확 인</Button>
                    </div>
                </div>
                <div className="button-group-filter">
                    {buttonGroup.map((v, i) => {
                        return (
                            <Button label={v.button} className={`custom-button ${v.id === 'active' ? 'active' : ''}`} severity="info" key={i} onClick={() => handleButtonClick(v.id, v.value)} />
                        )
                    })}
                </div>
                <div className="flex justify-content-flex-start calendar pt-2">
                    <div>
                        <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                        <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                        <Button label="확 인" className="ml-2" onClick={onBtnSearchDateRange} />
                    </div>

                    <div className="button-group-filter-date ml-2">
                        <FilterDate />
                    </div>
                    <div className="table-dep">
                        <DataTable 
                            value={props?.total}
                            // value={total}
                            showGridlines >
                            <Column field="deposite" header='충전' sortable={false} style={{ color: 'green' }} 
                            // body={bodyExchange}
                            ></Column>
                            <Column field="withdraw" header='환전' sortable={false} style={{ color: 'red' }}></Column>
                            <Column field="total" header='전체' sortable={false} style={{ color: 'orange' }}></Column>
                        </DataTable>
                    </div>
                </div>
                <div className="inventory">
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                    <CustomDataTable data={data} headers={headerHistory} bodyTemplate={bodyTemplate} scrollable={true}/>
                    <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                        rows={30}
                        template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                    />
                </div>


            </div>
        </>
    )
}

export default MoneyExchangeTab