import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export const Error = () => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/');
    };

    return (
        <div className="exception-body  error">
            <div className="exception-panel">
                <div className="exception-image">
                    <img src="assets/layout/images/exception/icon-error.png" alt="sapphire" />
                </div>

                <div className="exception-detail">
                    <h1>ERROR OCCURED</h1>
                    <p>Something went wrong.</p>
                    <Button label="GO TO DASHBOARD" onClick={goDashboard} />
                </div>
            </div>
        </div>
    );
};
