import React, { useEffect, useState, useRef } from "react";
import moment from "moment-timezone";
import './DeporsitAndWithdraw.scss'
import moneyConvert from "../../utilities/moneyConvert";
import 'moment/locale/ko';

import { Dropdown } from "primereact/dropdown";
import CustomDataTable from "../../components/Table/TableUser";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { OverlayPanel } from "primereact/overlaypanel";
import { Paginator } from "primereact/paginator";
import MoneyService from "../../service/DepositeWithdraw";

const dataDropdown = [
  "선택",
  "아이디",
  "이름",
  "에이전트",
];

const DeporsitAndWithdraw = () => {
  useEffect(() => {
    moment.locale('ko');
  }, [])
  const buttonFilter = [
    { button: '모두 보기', value: 'all' },
    { button: '유저', value: 'author' },
    { button: '에이전트', value: 'address' },
    { button: '센터', value: 'center' },
    { button: '취소', value: 'cancel' },
  ]
  const buttonFilterHis = [
    { button: "모두 보기", value: "all" },
    { button: "충전", value: "deposit" },
    { button: "환전", value: "withdraw" },
    { button: "보너스", value: "bounty" },
  ]
  const today = convertTZ(new Date(), "Asia/Seoul")
  const pass = convertTZ(new Date(), "Asia/Seoul")
  pass.setDate(today.getDate() - 1)
  const [dropdownValue, setDropdownValue] = useState("선택");
  const [inpCondition, setInpCondition] = useState('')
  const [filterCondition, setFilterCondition] = useState()
  const [startDate, setStartDate] = useState(pass);
  const [endDate, setEndDate] = useState(today);
  const [filterBtnOpt, setFilterBtnOpt] = useState(buttonFilter[0])
  const [filterBtnHis, setFilterBtnHis] = useState(buttonFilterHis[0])
  const op = useRef(null)
  const [data, setData] = useState([]);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0)
  const [page, setPage] = useState(0);
  const [totalDep, setTotalDep] =useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [total, setTotal] = useState(0);
  const moneyAction = new MoneyService();
  function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }
  const MoneyInpGroup = [
    {title:`충전`, name:'deposite', value: totalDep, color:'red'},
    {title:`환전`, name:'withdraw', value: totalWithdraw, color:'blue'},
    {title:'충전-환전', name:'total', value: total, color:'black'}
  ] 
  const header = [
    { field: 'type', label: '종류', data: data },
    { field: 'id', label: '아이디', data: data },
    { field: 'name', label: '이름', data: data },
    { field: 'nickname', label: '닉네임', data: data },
    { field: 'agent', label: '에이전트', data: data },
    { field: 'dep/with', label: '입금/출금', data: data },
    { field: 'okdate', label: '완료일', data: data },
    { field: 'tree', label: '소속', data: data },

  ]
  const chooseOption = (e) => {
    setDropdownValue(e)
    if(e ==='선택'){
      setInpCondition('');
      setFilterCondition()
    }
  }

  const onPageChange = (event) => {
    setPage(event.page + 1)
    setFirst(event.first);
  };
  function formatDate(date) {
    var yyyy = date?.getFullYear();
    var mm = String(date?.getMonth() + 1).padStart(2, '0');
    var dd = String(date?.getDate()).padStart(2, '0');
    return yyyy + '-' + mm + '-' + dd
}
  const fetchMoneyBalance = ()=>{
    const payload ={
        page: page,
        sdate: formatDate(startDate) || '',
        edate: formatDate(endDate) || '',
        ...filterCondition
    }
    let param = {...payload}
    let newParam ={...payload}
    if(!startDate && !filterCondition){
      param = {
        top:1, page: page
      }
      newParam =  {
        top:1, page: page
      }
    }
    if(filterCondition && !startDate){
      param = {...filterCondition,top:1, page: page}
    }
    if(dropdownValue ==='아이디' && !startDate){
      newParam ={mb_id: filterCondition?.id,top: 1, page: page}
    }
    if(dropdownValue ==='아이디' && startDate){
      newParam ={ mb_id: filterCondition?.id, page: page, sdate: formatDate(startDate),
      edate: formatDate(endDate)}
    }
    if(dropdownValue ==='이름' && !startDate){
      newParam ={mb_name: filterCondition?.name, top: 1, page: page}
    }
    if(dropdownValue ==='이름' && startDate){
      newParam ={ mb_name: filterCondition?.name, page: page, sdate: formatDate(startDate),
      edate: formatDate(endDate)}
    }
    if(filterBtnHis.value ==='all' || filterBtnOpt.value ==='all'){
      moneyAction.fetchUserMoney(param)
        .then((data)=>{
          const res = data?.data
          setData(res.data)
          setTotalRecords(res.total)
          const depMoney = data?.total?.charge?.sum
          const exchangeMoney = data?.total?.exchange?.sum
          const totalMoney = parseInt(depMoney?.replace(/,/g, ""))- parseInt(exchangeMoney?.replace(/,/g, ""))
          setTotalDep(depMoney)
          setTotalWithdraw(exchangeMoney)
          setTotal(moneyConvert(totalMoney))
        })
        .catch((err)=>{
          console.log(err)
        })
    }
    if(filterBtnOpt.value ==='author'){
      param = {...param, role: 'member'}
      moneyAction.fetchUserMoney(param)
        .then((data)=>{
          console.log("USER::",data)
          const res = data?.data
          setData(res.data)
          setTotalRecords(res.total)
          const depMoney = data?.total?.charge?.sum
          const exchangeMoney = data?.total?.exchange?.sum
          const totalMoney = parseInt(depMoney?.replace(/,/g, ""))- parseInt(exchangeMoney?.replace(/,/g, ""))
          setTotalDep(depMoney)
          setTotalWithdraw(exchangeMoney)
          setTotal(moneyConvert(totalMoney))
        })
        .catch((err)=>{
          console.log(err)
        })
    }
    if(filterBtnOpt.value ==='address'){
      param = {...param, role: 'agent'}
      moneyAction.fetchUserMoney(param)
        .then((data)=>{
          const res = data?.data
          setData(res.data)
          setTotalRecords(res.total)
          const depMoney = data?.total?.charge?.sum
          const exchangeMoney = data?.total?.exchange?.sum
          const totalMoney = parseInt(depMoney?.replace(/,/g, ""))- parseInt(exchangeMoney?.replace(/,/g, ""))
          setTotalDep(depMoney)
          setTotalWithdraw(exchangeMoney)
          setTotal(moneyConvert(totalMoney))
        })
        .catch((err)=>{
          console.log(err)
        })
    }
    if(filterBtnOpt.value ==='center'){
      param = {...param, role: 'admin'}
      moneyAction.fetchUserMoney(param)
        .then((data)=>{
          const res = data?.data
          setData(res.data)
          setTotalRecords(res.total)
          const depMoney = data?.total?.charge?.sum
          const exchangeMoney = data?.total?.exchange?.sum
          const totalMoney = parseInt(depMoney?.replace(/,/g, ""))- parseInt(exchangeMoney?.replace(/,/g, ""))
          setTotalDep(depMoney)
          setTotalWithdraw(exchangeMoney)
          setTotal(moneyConvert(totalMoney))
        })
        .catch((err)=>{
          console.log(err)
        })
    }
    if(filterBtnOpt.value ==='cancel'){
      param = {...param, cancel: 1}
      moneyAction.fetchUserMoney(param)
        .then((data)=>{
          const res = data?.data
          setData(res.data)
          setTotalRecords(res.total)
          const depMoney = data?.total?.charge?.sum
          const exchangeMoney = data?.total?.exchange?.sum
          const totalMoney = parseInt(depMoney?.replace(/,/g, ""))- parseInt(exchangeMoney?.replace(/,/g, ""))
          setTotalDep(depMoney)
          setTotalWithdraw(exchangeMoney)
          setTotal(moneyConvert(totalMoney))
        })
        .catch((err)=>{
          console.log(err)
        })
    }
    if(filterBtnHis.value==='deposit'){
      console.log(newParam)
      moneyAction.getDepositHis(newParam)
      .then((data)=>{
        const res = data?.list
        setData(res.data)
        setTotalRecords(res.total)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
    if(filterBtnHis.value==='withdraw'){
      moneyAction.getWithdrawHis(newParam)
      .then((data)=>{
        const res = data?.list
        setData(res.data)
        setTotalRecords(res.total)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
    if(filterBtnHis.value==='bounty'){
      moneyAction.getWithdrawHis({...newParam, role:'bonus'})
      .then((data)=>{
        const res = data?.list
        setData(res.data)
        setTotalRecords(res.total)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
    
  }
  function handleFilter(){
      if(dropdownValue ==='아이디'){
        setFilterCondition({id: inpCondition, sdate: startDate?formatDate(startDate):'', edate: endDate?formatDate(endDate):''})
      }
      if(dropdownValue ==='이름'){
        setFilterCondition({name: inpCondition, sdate: startDate?formatDate(startDate):'', edate: endDate?formatDate(endDate):''})
      }
      if(dropdownValue==='선택'){
        setFilterCondition({ sdate: startDate?formatDate(startDate):'', edate: endDate?formatDate(endDate):''})
      }
  }
  useEffect(()=>{

  },[inpCondition])
  useEffect(()=>{
    if(inpCondition ===''){
      fetchMoneyBalance()
    }
    if(inpCondition !==''){
      fetchMoneyBalance(filterCondition)
    }
  },[filterBtnHis, page, filterCondition,filterBtnOpt])
  const bodyTemplate = (data, props) => {
    const mode = data?.mode
    const conditionHis = filterBtnHis.value
    const name = data?.member?.split('(');
    const regex = /\(([^)]+)\)/;
    const id = regex.exec(data?.member);
    const agent = data?.step_1
    const agentName = data?.step_1?.split('(')
    const money = data?.charge_money[2]
    return (
      <>
        {props.field === 'type' &&
          <div >
            <span 
            // style={conditionHis === 'deposit' ? { color: 'green' } : conditionHis === 'withdraw' ? { color: 'red' }: conditionHis === 'all' && mode ==='money_list_1'?  { color: 'red' } : { color: 'green' }}
            >
              {/* { conditionHis === 'all' &&
              mode ==='money_list_0'?
              '입금': mode ==='money_list_1'?
              '출금': ''}
              { !mode &&
                conditionHis === 'deposit'?
                '입금': 
                conditionHis === 'withdraw'?
                '출금':
              ''} */}
              {data.charge_money[0]}
            </span>
          </div>
        }
        
        {props.field === 'id' &&
          <div onClick={()=>{window.open(`#/userdetail/${name[0]}`, '', "width=1500,height='100vh'")}}>
            <span >{name[0]}&nbsp;
                <i className="pi pi-star" style={{fontSize:' 12px', color: 'rgb(255, 87, 34)'}}/>
            </span>
          </div>
        }
        {props.field === 'name' &&
          <div >
            <span >{id[1]}</span>
          </div>
        }
        {props.field === 'nickname' &&
          <div >
            <span >{id[1]}</span>
          </div>
        }
        {props.field === 'agent' &&
          <div
          onClick={() => {
            window.open(
              `#/agentdetail/${agentName[0]}`,
              "",
              "width=1500,height='100vh'"
            );
          }}
        >
          {agentName[0]}
          &nbsp;
          <i
            className="pi pi-cog"
            style={{ fontSize: " 12px", color: "red" }}
          />
        </div>
        }
        {props.field === 'dep/with' &&
          <div >
            <span style={conditionHis === 'deposit' ? { color: 'green' } : conditionHis === 'withdraw' ? { color: 'red' }: conditionHis === 'all' && mode ==='money_list_1'?  { color: 'red' } : { color: 'green' }}>{money}</span>
          </div>
        }
        {props.field === 'tree' &&
          <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
            {data?.step_1 &&
            <div style={{display:'flex'}}>
              <div style={{width:'20px', height:'20px', background:'red', color:'white'}}>
                <span >본</span> 
              </div>
              &nbsp;
              <div style={{color:'red'}}>
                <span>{data?.step_1} </span>
              </div>
              <span>{'>>'}&nbsp;</span>
            </div>
            }
            {data?.step_up &&
            <div style={{display:'flex'}}>
              <div style={{width:'20px', height:'20px', background:'black', color:'white'}}>
                <span >부</span> 
              </div>
              &nbsp;
              <div style={{color:'blue'}}>
                <span>{data?.step_up} </span>
              </div>
              <span>{'>>'}&nbsp;</span>
            </div>
            }
            <div style={{display:'flex'}}>
              <div style={{width:'20px', height:'20px', background:'green', color:'white'}}>
                <span >총</span> 
              </div>
              &nbsp;
              <div style={{color:'orange'}}>
                <span>{data?.member} </span>
              </div>
            </div>
          </div>
        }
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    )
  }
  addLocale('ko', {
    firstDayOfWeek: 1,
    dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
    monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    today: '오늘',
    clear: '지우기'
  });
  const SelectBtnGroup = () => {
    const handleChangeOpt = async (e) => {
      setFilterBtnOpt(e)

    }
    const justifyTemplate = (option) => {
      return <span>{option.button}</span>;
    }
    return (
      <SelectButton value={filterBtnOpt.value} onChange={(e) => handleChangeOpt(e)} itemTemplate={justifyTemplate} options={buttonFilter} />
    )
  }
  const SelectBtnGroupHis = () => {
    const handleChangeOpt = async (e) => {
      setFilterBtnHis(e)
    }
    const justifyTemplate = (option) => {
      return <span>{option.button}</span>;
    }
    return (
      <SelectButton value={filterBtnHis.value} onChange={(e) => handleChangeOpt(e)} itemTemplate={justifyTemplate} options={buttonFilterHis} />
    )
  }
  function onChangeInput(e, name){
    console.log(e, name)
  }
  function onDownloadHandle(e) {
    const payload = {
      process: "excel_down",
      submit: '1'
    }
    op.current.toggle(e);
    //  userList.getExcelDownload(payload)
    //     .then((data)=>{
    //         console.log(data)
    //         const link = data?.link_download
    //         window.location.href = link
    //         })
    //     .then(()=>{
    //         op.current.toggle(e);
    //     })
    //     .catch((err)=>{
    //         console.log(err)
    //     })
  }
  return (
    <div className="refresh-div">
      <div className="card">
        <div className="col-12 mb-4 p-0" style={{ fontSize: "16px", fontWeight: 'bold' }}>
          입급/출금 완료
        </div>
        <div className="tool-withdraw-deposite">
          <div className="date-filter">
            <Calendar value={startDate} showTime
              hourFormat="24" onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
            <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} showTime
              hourFormat="24" locale="ko" showIcon dateFormat="yy-mm-dd"/>
          </div>
          <div className="items">
            <div className="item-1">
              <Dropdown
                style={{ minWidth: "100px" }}
                value={dropdownValue}
                options={dataDropdown}
                onChange={(e) => chooseOption(e.value)}
                placeholder=""
              />
              <div className="item-rows">
                <InputText placeholder="검색" value={inpCondition} onChange={(e)=>setInpCondition(e.target.value)} onKeyDown={(e)=> {if(e.key ==='Enter') handleFilter()}}/>
                <div className="button-search" onClick={handleFilter}>
                  <i className="pi-search pi" />
                </div>
              </div>
            </div>
          </div>

          <div className="money-row">
              {MoneyInpGroup.map((v,i)=>{
                  return(
                      <div className='inp-group' key={i}>
                          <span className={`p-float-label`}>
                              <InputText
                              id=''
                              type="text"
                              value={v.value}
                              // onChange={(e) => onChangeInput(e.target.value, v.name)}
                              //   onKeyDown={(e) => onKeyDown?.(e)}
                              style={{color:v.color}}
                              />
                              <label htmlFor={v.name} style={{color: v.color}}>{v.title}</label>
                          </span>
                      </div>
                  )
              })}
          </div>
        </div>
        <div className="btn-groups">
          <div className="button-setting" 
            onClick={(e) => op.current.toggle(e)}
          >
            <span>
              <i className="pi pi-cog" />
            </span>
          </div>
          <OverlayPanel ref={op}>
            <span onClick={(e) => onDownloadHandle(e)}><i className="pi pi-fw pi-file " /> 충환전 저장</span>
            <span onClick={(e) => onDownloadHandle(e)}><i className="pi pi-save" /> 첫충 저장</span>
          </OverlayPanel>
          <div className="button-group-filter">
            <SelectBtnGroupHis />
          </div>
          <div className="button-group-filter">
            <SelectBtnGroup />
          </div>
        </div>
      </div>
      <div className="card">
        <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
          rows={30}
          template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

        />
        <CustomDataTable data={data} headers={header} bodyTemplate={bodyTemplate} scrollable={true}/>
        <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
          rows={30}
          template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

        />
      </div>
    </div>
  );
};

export default DeporsitAndWithdraw;
