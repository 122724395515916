export function formatDate(date) {
    if(!date) return ''
    var yyyy = date?.getFullYear() ;
    var mm = String(date?.getMonth() + 1).padStart(2, '0') ;
    var dd = String(date?.getDate()).padStart(2, '0') ;
    return yyyy + '-' + mm + '-' + dd
};
export function convertTime (date){
    const utcTime = new Date(date);

    utcTime.setHours(utcTime.getHours() + 9);

    const options = { timeZone: 'Asia/Seoul', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const kstTime = utcTime.toLocaleTimeString('en-US', options);
    return kstTime
}