import React, { useEffect, useRef, useState } from "react";
import './UserDetail.scss';
import 'primeicons/primeicons.css';
import { useParams } from "react-router-dom";
import { TabView, TabPanel } from 'primereact/tabview';
import UserInfoTab from "./UserInfor/UserInfor";
import HistoryBetting from "./BettingHistory/BettingHistory";
import MoneyExchangeTab from "./MoneyExchange/MoneyExchange";
import MessageTab from "./Message/Message";
import LoginIpTab from "./LoginIp/LoginIpTab";
import BalanceFluctuation from "./BalanceFluctuation/BalanceFluctuation";
import SettlementHistoryTab from "./SettlementHistory/SettlementHistory";
import UserService from "../../../service/UserService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

const UserDetail = () => {
    const params = useParams()
    const [data, setData] = useState();
    const [basicDataUser, setBasicDataUser] = useState()
    const [jsonLogBefore, setJsonLogBefore] = useState()
    const [jsonOptionBet, setJsonOptionBet] = useState()
    const [balanceTodayIn, setBalanceTodayIn] = useState()
    const [balanceTodayOut, setBalanceTodayOut] = useState()
    const toast = useRef(null);

    const [tabHeader, setTabHeader] = useState('기본 정보');
    const [confirmMessage, setConfirmMessage] = useState({message: '정보를 업데이트 하시겠습니까?', header:'통보'})
    const userList = new UserService()
    const fetchUser = () => {
        userList.getUserDetail(params)
            .then((data) => {
                const res = data
                setData(res?.detail)
            })
    }
    const fetchBasicDataUser = () => {
        const payload = {
            'id': params?.id,
            'submit': "0"
        }
        userList.getBasicData(payload)
            .then((data) => {
                const res = data
                console.log(res)
                setBasicDataUser(res?.data)
                setJsonLogBefore(JSON.parse(res?.data?.log_before?.value))
                // setJsonOptionBet(JSON.parse(res?.data?.user_options?.option))
            })
    }
    const fetchBalanceToday = () => {
        userList.fetchBalanceInToday(params)
            .then((data) => {
                console.log(data?.list?.data.length)
                const res = data?.list?.data
                if (res.length === 0) {
                    setBalanceTodayIn(0)
                }
                else {
                    console.log(res)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchUser();
        fetchBasicDataUser();
        fetchBalanceToday();
    }, [])
    useEffect(()=>{
        if(tabHeader ==='기본 정보'){
            setConfirmMessage({message: '정보를 업데이트 하시겠습니까?', header:'통보'})
        }
        if(tabHeader ==='충전/환전'){
            setConfirmMessage({message: '정상 처리되지 않음. 관리자에게 문의 부탁드림니다', header:'유저 디테일'})
        }
    },[tabHeader])
    function convertNumber(e) {
        return parseFloat(e?.replace(/,/g, ''))
    }
    function parseToString(e) {
        return e.toLocaleString()
    }
    const userData = [
        { title: '아이디', data: basicDataUser?.mb_id?.value, color: getColor(`${basicDataUser?.mb_color?.value}`) },
        { title: '비밀번호', data: data?.mb_password },
        { title: '환전비번', data: basicDataUser?.mb_exch_pw?.value },
        { title: '전화번호', data: data?.mb_hp?.value },
        { title: '레벨', data: data?.mb_level?.value },
        { title: '포인트', data:  '0'},
        { title: '롤링', data: 0 },
        { title: '지인루징', data: data?.point?.value },
        { title: '가입코드', data: '-' },
        { title: '충전금액(당일)', data: data?.charge?.general?.sum },
        { title: '환전금액(당일)', data: data?.exchange?.general?.sum },
        { title: '충전-환전 (당일)', data: parseToString(convertNumber(data?.charge?.general?.sum) - convertNumber(data?.exchange?.general?.sum)) },
        { title: '충전금액(주)', data: data?.money_in_week },
        { title: '환전금액(주)', data: data?.money_out_week },
        { title: '충전-환전 금액', data: parseToString(convertNumber(data?.money_in_week) - convertNumber(data?.money_out_week)) },
        { title: '(주)', data: '' },
        { title: '충전금액(월)', data: data?.money_in_month },
        { title: '환전금액(월)', data: data?.money_out_month },
        { title: '충전-환전 금액', data: parseToString(convertNumber(data?.money_in_month) - convertNumber(data?.money_out_month)) },
        { title: '(월)', data: '' },
        { title: '충전금액(합)', data: data?.mb_in_money },
        { title: '환전금액(합)', data: data?.mb_out_money },
        { title: '충전-환전 금액', data: parseToString(convertNumber(data?.mb_in_money) - convertNumber(data?.mb_out_money)) },
        { title: '(합)', data: '' },
    ]
    const parseTime = new Date(jsonLogBefore?.mb_regtime * 1000)
    const regTime = parseTime?.toLocaleDateString()
    useEffect(()=>{
        console.log(basicDataUser)
    },[basicDataUser])
    function getColor (color){
        if (color.startsWith("#")) {
            return color.slice(1);
        } else {
            return color;
        }
    }
    const basicData = [
        { title: '닉네임', data: basicDataUser?.mb_name?.value, type: 'input-name', key: 'mb_name' },
        { title: '비밀번호', data: basicDataUser?.mb_password?.value, type: 'input', key: 'mb_password' },
        { title: '환전비번', data: basicDataUser?.mb_exch_pw?.value, type: 'input', key: 'mb_exch_pw' },
        { title: '회원레벨', data: basicDataUser?.level, type: 'input', key: 'mb_level' },
        { title: '전화번호', data: basicDataUser?.mb_hp?.value, type: 'input', key: 'mb_hp' },
        { title: '추천아이디', data: jsonLogBefore?.mb_rec_id, type: 'input', key: 'mb_rec_id' },
        { title: '은행명', data: basicDataUser?.mb_bank?.options?.mb_bank_name?.value, type: 'input', key: 'mb_bank_name' },
        { title: '통장번호', data: basicDataUser?.mb_bank?.options?.mb_bank_no?.value, type: 'input', key: 'mb_bank_no' },
        { title: '예금주', data: basicDataUser?.mb_bank?.options?.mb_bank_own?.value, type: 'input', key: 'mb_bank_own' },
        { title: '아이디 색상', data: basicDataUser?.mb_color?.value, type: 'input-button', key: 'mb_color' },
        { title: '가입일', data: regTime, type: 'text' },
        { title: '최근접속일', data: basicDataUser?.login_date_last, type: 'text' },
        { title: '가입IP', data: jsonLogBefore?.mb_login_ip, type: 'text' },
        { title: '최근IP', data: basicDataUser?.ip_loged_last, type: 'text' },
        { title: '마지막충전일', data: '-', type: 'text' },
        { title: '마지막환전일', data: '-', type: 'text' },
        { title: '슬롯 롤링', data: basicDataUser?.cxrng?.rate_1, type: 'input', key: 'slot' },
        { title: '카지노 롤링', data: basicDataUser?.cxlive?.rate_1, type: 'input', key: 'casino' },
        { title: '간단메모', data: basicDataUser?.member_in?.item?.mb_memo?.value || '', type: 'input', key: 'member_ins' },
        { title: '메모', data: basicDataUser?.note?.items?.admin_memo?.value || '', type: 'input', key: 'notes' },
        { title: '회원 상태', data: basicDataUser?.mb_status, type: 'radius', key: 'mb_status' },
        { title: '배팅 잠금', data: basicDataUser?.mb_bet_block?.option, type: 'radius-group', key: 'mb_bet_block' },
        { title: '아이디 트리', data: basicDataUser?.tree
        , type: 'text-tree' },
        { title: '총판 트리', data: basicDataUser?.tree_agent, type: 'text-tree-agent' },
        // { title: '아이디 트리', data: '', type: 'text' },
    ]
    const totalMoney = [
        { 
            deposite: parseToString(convertNumber(data?.mb_in_money)),
            withdraw: parseToString(convertNumber(data?.mb_out_money)),
            total: parseToString(convertNumber(data?.mb_in_money) - convertNumber(data?.mb_out_money))
        }
    ]
    
    const confirm1 = (actionSubmit) => {
        confirmDialog({
            message: confirmMessage.message,
            header: confirmMessage.header,
            accept: () => action1(actionSubmit),
            acceptLabel: '확인',
            acceptClassName: 'p-confirm-dialog-accept'
        });
    };
    const action1 = (action) => {
        // toast.current.show({ severity: 'success', summary: '성공', detail: '정보가 업데이트 돠었습니다', life: 3000 });
        fetchUser()
        action()
    }
    const [activeIndex, setActiveIndex] = useState(0);
    const handleTabChange = (e) => {
      setActiveIndex(e.index);
      setTabHeader(e.originalEvent.target.innerText)
    };
    return (
        <>
            <Toast ref={toast}/>
            <div className="body">
                <div className="breadscrum">
                    <i className="pi pi-home"></i>
                    <i className="pi pi-angle-right"></i>
                    UserDetail
                </div>
                <div className="box-info">
                    <div className="box box-1">
                        <p>기본정보</p>
                        <div className="box-1-info">
                            {userData.map((value, index) => {
                                return (
                                    <div className="info-row" key={index}>
                                        <span>{value.title} </span>
                                        <span style={value.color ?{  color:`#${value.color}`} : {color:'black'} }> {value?.data}</span>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    <div className="box box-2">
                   
                    <TabView activeIndex={activeIndex} onTabChange={(e)=>handleTabChange(e)} >
                            <TabPanel header='기본 정보' leftIcon='pi pi-check-circle mr-2' >
                                <UserInfoTab data={basicData}  action={confirm1} initialValue={basicDataUser} status={jsonLogBefore?.mb_status} color={basicDataUser?.mb_color?.value}/>
                            </TabPanel>
                            <TabPanel header='배팅정보' leftIcon='pi pi-user mr-2'>
                                <HistoryBetting />
                            </TabPanel>
                            <TabPanel header='충전/환전'>
                                <MoneyExchangeTab action={confirm1} total={totalMoney}/>
                            </TabPanel>
                            <TabPanel header='쪽지'>
                                <MessageTab />
                            </TabPanel>
                            <TabPanel header='로그인/IP'>
                                <LoginIpTab />
                            </TabPanel>
                            <TabPanel header='머니이동'>
                                <BalanceFluctuation />
                            </TabPanel>
                            <TabPanel header='정산내역'>
                                <SettlementHistoryTab />
                            </TabPanel>
                        </TabView>

                    </div>
                </div>
            </div>
        </>

    )
}
export default UserDetail