import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import './CalculateSubAgent.scss'
import CalculateService from "../../service/CalculateServices";
import moneyConvert from "../../utilities/moneyConvert";
import { SelectButton } from "primereact/selectbutton";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import CustomDataTable from "../../components/Table/TableUser";
const CalculateSubAgent = () => {
    const buttonFilter = [
        { button: "입출금 정산", value: "all" },
        { button: "카지노 Win-Lose 정산", value: "win-lose" },
    ]
    const currentDate = moment().toDate();
    const nextDate = moment().add(1, 'days').toDate();
    const [startDate, setStartDate] = useState(currentDate);
    const [endDate, setEndDate] = useState(nextDate);
    const [filterBtn, setFilterBtn] = useState(buttonFilter[0])
    const [totalData, setTotalData] = useState()
    const [data, setData] = useState()
    const [levelArr, setLevelArr] = useState()
    const [reqType, setReqType] = useState(1)
    const [header, setHeader] = useState([
        {
            field: "lv1", label: '', value: '',
            sortAble: false
        },
        {
            field: "lv2", label: '', value: '',
            sortAble: false
        },
        {
            field: "lv3", label: '', value: '',
            sortAble: false
        },
        {
            field: "lv4", label: ' ', value: '',
            sortAble: false
        },
        {
            field: "lv5", label: ' ', value: '',
            sortAble: false
        },
        {
            field: "lv6", label: ' ', value: '',
            sortAble: false
        },
        {
            field: "charges", label:
                <span class="p-column-title">
                    <div>
                        <div>유저 입금</div>
                        <div>총판 지급</div>
                        <div>관리자 지급</div>
                    </div>
                </span>,
            sortAble: false
        },
        {
            field: "exchanges", label: <span class="p-column-title"><div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div></span>,
            sortAble: false
        },
        {
            field: "profits", label: <span class="p-column-title"><div><div>유저 수익</div><div>총판 수익</div></div></span>,
            sortAble: false
        },
        {
            field: "casinos", label: <span class="p-column-title"><div><div>Casino Bet</div><div>Casino Win</div></div></span>, sortAble: false
        },
        {
            field: "slots", label: <span class="p-column-title"><div><div>Slot Bet</div><div>Slot Win</div></div></span>, sortAble: false
        },
        {
            field: "losings", label:
                <span class="p-column-title">
                    <div>
                        <div>하부유저 지급</div>
                        <div>하부유저 회수</div>
                    </div>
                </span>, sortAble: false
        },
        {
            field: "slot_rollings", label:
                <span class="p-column-title">
                    <div>
                        <div>유저보유금</div>
                        <div>전일 보유</div>
                    </div>
                </span>, sortAble: false
        },
        { field: "casino_rollings", label: `총회원 루징`, sortAble: false },
        { field: "sum_rollings", label: '롤링케쉬전환', sortAble: false },
        {
            field: "moneys", label:`정산금`,
            value: <span class="p-column-title">
                <div>
                    <div>{totalData?.money}</div>
                </div>
            </span>
            , sortAble: false
        },
        {
            field: "partner_charge_exchanges", label: `카지노 롤링`,
            sortAble: false
        },
        {
            field: "sub_user_charge_exchanges", label: `롤링합산`, sortAble: false
        },
        {
            field: "rolling_to_moneys", label:
                <span class="p-column-title"><div><div>파트너충전</div><div>파트너환전</div></div></span>, value:
                <span class="p-column-title">
                    <div>
                        <div style={{ color: 'red' }}>{totalData?.charge?.toLocaleString()}</div>
                        <div style={{ color: 'blue' }}>{totalData?.exchange?.toLocaleString()}</div>
                    </div>
                </span>, sortAble: false
        },
        {
            field: "user_rollings", label: <span class="p-column-title"><div><div>유저 슬롯 롤링</div><div>유저 카지노 롤링</div></div></span>, sortAble: false
        },

    ])
    const params = useParams()
    const calculateSubDetail = new CalculateService()
    function getDetailAgent(param) {
        const payload = {
            ...param,
            mb_id: params.id,
        }
        calculateSubDetail.calculateDetailAgent(payload)
            .then((data) => {
                const res = data.list
                setData(res)
                setTotalData(data.total_partner_charge_exchange)
                setLevelArr(data.general.header.level)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        moment.locale('ko');
    }, [])
    useEffect(() => {
        getDetailAgent({ sdate: formatDate(startDate), edate: formatDate(endDate), req_type: reqType, })
    }, [reqType, filterBtn])
    var lvlist = []
    useEffect(() => {
        if(levelArr){
            Object.values(levelArr).map((v,i)=>{
                var object = {field: `lv${i+1}`, label: `${v}`, value: '',
                sortAble: false }
                console.log(object, v)
                lvlist.push(object)
                return lvlist
            })
        }      
        if (filterBtn.value === 'all') {
            setReqType(1)
            const arr = [
                {
                    field: "charges", label:
                        <span class="p-column-title">
                            <div>
                                <div>유저 입금</div>
                                <div>총판 지급</div>
                                <div>관리자 지급</div>
                            </div>
                        </span>,
                    sortAble: false
                },
                {
                    field: "exchanges", label: <span class="p-column-title"><div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div></span>,
                    sortAble: false
                },
                {
                    field: "profits", label: <span class="p-column-title"><div><div>유저 수익</div><div>총판 수익</div></div></span>,
                    sortAble: false
                },
                {
                    field: "casinos", label: <span class="p-column-title"><div><div>Casino Bet</div><div>Casino Win</div></div></span>, sortAble: false
                },
                {
                    field: "slots", label: <span class="p-column-title"><div><div>Slot Bet</div><div>Slot Win</div></div></span>, sortAble: false
                },
                {
                    field: "sub_user_charge_exchanges", label:
                        <span class="p-column-title">
                            <div>
                                <div>하부유저 지급</div>
                                <div>하부유저 회수</div>
                            </div>
                        </span>, sortAble: false
                },
                {
                    field: "moneys", label:
                        <span class="p-column-title">
                            <div>
                                <div>유저보유금</div>
                                <div>전일 보유</div>
                            </div>
                        </span>, sortAble: false
                },
                { field: "losings", label: `총회원 루징`, sortAble: false },
                { field: "rolling_to_moneys", label: '롤링케쉬전환', sortAble: false },
                {
                    field: "settlement_amount", label:`정산금`,
                    value: <span class="p-column-title">
                        <div>
                            <div>{totalData?.money}</div>
                        </div>
                    </span>
                    , sortAble: false
                },
                { field: "slot_rollings", label: `슬롯 롤링`, sortAble: false },
                {
                    field: "casino_rollings", label: `카지노 롤링`,
                    sortAble: false
                },
                {
                    field: "sum_rollings", label: `롤링합산`, sortAble: false
                },
                {
                    field: "partner_charge_exchanges", label:
                        <span class="p-column-title"><div><div>파트너충전</div><div>파트너환전</div></div></span>, value:
                        <span class="p-column-title">
                            <div>
                                <div style={{ color: 'red' }}>{totalData?.charge?.toLocaleString()}</div>
                                <div style={{ color: 'blue' }}>{totalData?.exchange?.toLocaleString()}</div>
                            </div>
                        </span>, sortAble: false
                },
                {
                    field: "user_rollings", label: <span class="p-column-title"><div><div>유저 슬롯 롤링</div><div>유저 카지노 롤링</div></div></span>, sortAble: false
                },

            ]
            setHeader([...lvlist,...arr])

        }
        
        if (filterBtn.value === 'win-lose') {
            setReqType(2)
            const arr =[
                {
                    field: "charges", label:
                        <span class="p-column-title">
                            <div>
                                <div>유저 입금</div>
                                <div>총판 지급</div>
                                <div>관리자 지급</div>
                            </div>
                        </span>,
                    sortAble: false
                },
                {
                    field: "exchanges", label: <span class="p-column-title"><div><div>유저 출금</div><div>총판 회수
                    </div><div>관리자 회수</div></div></span>,
                    sortAble: false
                },
                {
                    field: "profits", label: <span class="p-column-title"><div><div>유저 수익</div><div>총판 수익</div></div></span>,
                    sortAble: false
                },
                {
                    field: "casinos", label: <span class="p-column-title"><div><div>Casino Bet</div><div>Casino Win</div></div></span>, sortAble: false
                },
                { field: "losings", label: `카지노 루징`, value: `${moneyConvert(totalData?.casino_losing, true)}`, sortAble: false },
                {
                    field: "casino_rollings", label: `카지노 롤링`,
                    sortAble: false
                },
                {
                    field: "settlement_amount", label:`정산금`,
                    sortAble: false
                },
                {
                    field: "partner_charge_exchanges", label: <span class="p-column-title"><div><div>파트너충전</div><div>파트너환전</div></div></span>, value:
                        <span class="p-column-title">
                            <div>
                                <div style={{ color: 'red' }}>{totalData?.charge?.toLocaleString()}</div>
                                <div style={{ color: 'blue' }}>{totalData?.exchange?.toLocaleString()}</div>
                            </div>
                        </span>
                    , sortAble: false
                }

            ]
            setHeader([...lvlist,...arr])
        }
    }, [filterBtn, totalData, levelArr])
    useEffect(() => { }, [totalData])
    console.log(header)
    addLocale('ko', {
        firstDayOfWeek: 1,
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
    function formatDate(date) {
        var yyyy = date?.getFullYear();
        var mm = String(date?.getMonth() + 1).padStart(2, '0');
        var dd = String(date?.getDate()).padStart(2, '0');
        return yyyy + '-' + mm + '-' + dd
    }
    function handleFilterBtnClick() {
        if (filterBtn.value === 'all') {
            getDetailAgent({
                sdate: formatDate(startDate),
                edate: formatDate(endDate),
                req_type: 1,
            })
        }
        if (filterBtn.value === 'win-lose') {
            getDetailAgent({
                sdate: formatDate(startDate),
                edate: formatDate(endDate),
                req_type: 2,
            })
        }
    }
    const SelectBtnGroup = () => {
        const handleChangeOpt = async (e) => {
            if(e.value ===  null){
                return
            }
            setFilterBtn(e)
        }
        const justifyTemplate = (option) => {
            return <span>{option.button}</span>;
        }
        return (
            <SelectButton value={filterBtn.value} onChange={(e) => handleChangeOpt(e)} itemTemplate={justifyTemplate} options={buttonFilter} />
        )
    }
    const bodyTemplate = (data, props) => {
        return (
            <>
                {props.field === 'lv1' && data.level1 &&
                    <>
                        <div onClick={() => { window.open(`#/agentdetail/${data?.level1?.id?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level1?.id?.value} <i className="pi-cog pi" style={{ color: 'red' }} />
                        </div>
                        <div onClick={() => { window.open(`#/calculatesubagent/${data?.level1?.name?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level1?.name?.value} <i className="pi-star pi" style={{ color: 'green' }} />
                        </div>
                    </>
                }
                {props.field === 'lv2' && data.level2 &&
                    <>
                        <div onClick={() => { window.open(`#/agentdetail/${data?.level2?.id?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level2?.id?.value} <i className="pi-cog pi" style={{ color: 'red' }} />
                        </div>
                        <div onClick={() => { window.open(`#/calculatesubagent/${data?.level2?.name?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level2?.name?.value} <i className="pi-star pi" style={{ color: 'green' }} />
                        </div>
                    </>
                }
                {props.field === 'lv3' && data.level3 &&
                    <>
                        <div onClick={() => { window.open(`#/agentdetail/${data?.level3?.id?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level3?.id?.value} <i className="pi-cog pi" style={{ color: 'red' }} />
                        </div>
                        <div onClick={() => { window.open(`#/calculatesubagent/${data?.level3?.name?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level3?.name?.value} <i className="pi-star pi" style={{ color: 'green' }} />
                        </div>
                    </>
                }
                {props.field === 'lv4' && data.level4 &&
                    <>
                        <div onClick={() => { window.open(`#/agentdetail/${data?.level4?.id?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level4?.id?.value} <i className="pi-cog pi" style={{ color: 'red' }} />
                        </div>
                        <div onClick={() => { window.open(`#/calculatesubagent/${data?.level4?.name?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level4?.name?.value} <i className="pi-star pi" style={{ color: 'green' }} />
                        </div>
                    </>
                }
                {props.field === 'lv5' && data.level5 &&
                    <>
                        <div onClick={() => { window.open(`#/agentdetail/${data?.level5?.id?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level5?.id?.value} <i className="pi-cog pi" style={{ color: 'red' }} />
                        </div>
                        <div onClick={() => { window.open(`#/calculatesubagent/${data?.level5?.name?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level5?.name?.value} <i className="pi-star pi" style={{ color: 'green' }} />
                        </div>
                    </>
                }
                {props.field === 'lv6' && data.level6 &&
                    <>
                        <div onClick={() => { window.open(`#/agentdetail/${data?.level6?.id?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level6?.id?.value} <i className="pi-cog pi" style={{ color: 'red' }} />
                        </div>
                        <div onClick={() => { window.open(`#/calculatesubagent/${data?.level6?.name?.value}`, '', "width=1500,height='100vh'") }}>
                            <span className="p-column-title">{props.header}</span>
                            {data?.level6?.name?.value} <i className="pi-star pi" style={{ color: 'green' }} />
                        </div>
                    </>
                }
                {props.field === 'btn_action' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div className="icon-btn">
                            <span style={{}}><i style={{ color: 'white' }} className="pi-file-edit pi" /></span>

                        </div>
                    </>
                }

                {props.field === 'charges' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column', color: 'red' }}>
                            <span>{data?.charge?.user_charge?.toLocaleString()}</span>
                            <span>{data?.charge?.distributor_charge?.toLocaleString()}</span>
                            <span>{data?.charge?.admin_charge?.toLocaleString()}</span>

                        </div>
                    </>
                }
                {props.field === 'exchanges' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column', color: 'blue' }}>
                            <span>{data?.exchange?.user_exchange?.toLocaleString()}</span>
                            <span>{data?.exchange?.distributor_exchange?.toLocaleString()}</span>
                            <span>{data?.exchange?.admin_exchange?.toLocaleString()}</span>

                        </div>
                    </>
                }

                {props.field === 'bet_win' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'red' }}>{data?.real_bet_win?.bet?.toLocaleString()}</span>
                            <span style={{ color: 'blue' }}>{data?.real_bet_win?.win?.toLocaleString()}</span>

                        </div>
                    </>
                }
                {props.field === 'miss_bet' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'red' }}>{data?.missing_bet_win?.bet?.toLocaleString()}</span>
                            <span style={{ color: 'blue' }}>{data?.missing_bet_win?.win?.toLocaleString()}</span>

                        </div>
                    </>
                }
                {props.field === 'casino_losings' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'blue' }}>{data?.casino_losing?.toLocaleString()}</span>

                        </div>
                    </>
                }

                {props.field === 'profits' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'blue' }}>{data?.profit?.user_profit?.toLocaleString()}</span>
                            <span style={{ color: 'red' }}>{data?.profit?.distributor_profit?.toLocaleString()}</span>
                            <span >{data?.profit?.admin_profit?.toLocaleString()}</span>
                        </div>
                    </>
                }
                {props.field === 'casinos' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'red' }}>{data?.casino?.bet?.toLocaleString()}</span>
                            <span style={{ color: 'blue' }}>{data?.casino?.win?.toLocaleString()}</span>
                        </div>
                    </>
                }
                {props.field === 'slots' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'blue' }}>{data?.slot?.bet?.toLocaleString()}</span>
                            <span style={{ color: 'red' }}>{data?.slot?.win?.toLocaleString()}</span>
                        </div>
                    </>
                }
                {props.field === 'losings' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'blue' }}>{data?.losing?.toLocaleString()}</span>
                        </div>
                    </>
                }
                {props.field === 'slot_rollings' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: '#FFD42E' }}>{data?.slot_rolling?.amount?.toLocaleString()}</span>
                            <span style={{ color: 'green' }}>{data?.slot_rolling?.rate?.toLocaleString() + '%'}</span>
                        </div>
                    </>
                }
                {props.field === 'casino_rollings' && filterBtn.value === 'all' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: '#FFD42E' }}>{data?.casino_rolling?.amount?.toLocaleString()}</span>
                            <span style={{ color: 'green' }}>{data?.casino_rolling?.rate?.toLocaleString() + '%'}</span>
                        </div>
                    </>
                }
                {props.field === 'casino_rollings' && filterBtn.value === 'win-lose' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: '#FFD42E' }}>{data?.rolling?.amount?.toLocaleString()}</span>
                            <span style={{ color: 'green' }}>{data?.rolling?.rate?.toLocaleString() + '%'}</span>
                        </div>
                    </>
                }
                {props.field === 'sum_rollings' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: '#FFD42E' }}>{data?.sum_rolling?.toLocaleString()}</span>
                        </div>
                    </>
                }
                {props.field === 'moneys' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column', color: '#34A1F3' }}>
                            <span>{data?.money?.current_money?.toLocaleString()}</span>
                            <span>{data?.money?.pre_money?.toLocaleString()}</span>
                        </div>
                    </>
                }
                {props.field === 'settlement_amounts' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'red' }}>{data?.settlement_amount?.amount?.toLocaleString()}</span>
                            <span>{data?.settlement_amount?.rate}</span>
                        </div>
                    </>
                }
                {props.field === 'partner_charge_exchanges' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'red' }}>{data?.partner_charge_exchange?.charge?.toLocaleString()}</span>
                            <span style={{ color: 'blue' }}>{data?.partner_charge_exchange?.exchange?.toLocaleString()}</span>
                        </div>
                    </>
                }
                {props.field === 'sub_user_charge_exchanges' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: 'red' }}>{data?.sub_user_charge_exchange?.charge?.toLocaleString()}</span>
                            <span style={{ color: 'blue' }}>{data?.sub_user_charge_exchange?.exchange?.toLocaleString()}</span>
                        </div>
                    </>
                }
                {props.field === 'rolling_to_moneys' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ color: '#64ABB1' }}>{data?.rolling_to_money?.toLocaleString()}</span>
                        </div>
                    </>
                }
                {props.field === 'user_rollings' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>{data?.user_rolling?.slot?.toLocaleString()}</span>
                            <span>{data?.user_rolling?.casino?.toLocaleString()}</span>
                        </div>
                    </>
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    const headerGroup = (
        <ColumnGroup>
            <Row>
                {header.map((v, i) => {
                    return (
                        <Column header={v.label} />
                    )
                })}
            </Row>
            <Row >
                {header.map((v, i) => {
                    return (
                        <Column header={v.value} style={{ background: 'rgba(139, 195, 74, 0.34)' }} />
                    )
                })}
            </Row>

        </ColumnGroup>
    );
    return (
        <>
            <div className="body">
                <div className="breadscrum">
                    <i className="pi pi-home"></i>
                    <i className="pi pi-angle-right"></i>
                    Calculatesubagent
                </div>
                <div className="box-info-sub card">
                    <div>
                        <span>부본사 <b>{params.id}</b></span>
                    </div>
                    <div className="box-item">
                        <div className="date-filter">
                            <Calendar value={startDate}
                                hourFormat="24" onChange={(e) => setStartDate(e.value ? e.value : currentDate)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                            <Calendar value={endDate} onChange={(e) => setEndDate(e.value ? e.value : '')}
                                hourFormat="24" locale="ko" showIcon dateFormat="yy-mm-dd"/>
                            <div className="item1">
                                <Button onClick={handleFilterBtnClick}>검색</Button>
                            </div>

                        </div>
                        <div className="button-group-filter">
                            <SelectBtnGroup />
                        </div>
                    </div>

                </div>
                <div className="inventory-sub card">
                    <CustomDataTable
                        data={data}
                        headers={header}
                        bodyTemplate={bodyTemplate}
                        scrollable={true}
                        showGridlines={true}
                        headerColumnGroup={headerGroup}
                    />
                </div>
            </div>
        </>
    )
}

export default CalculateSubAgent