import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import {
  Route,
  Router,
  RouterProvider,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import AppTopbar from "./AppTopbar";
import AppFooter from "./AppFooter";
import AppConfig from "./AppConfig";
import AppBreadcrumb from "./AppBreadcrumb";

import { Login } from "./pages/Login";
import { Error } from "./pages/Error";
import { NotFound } from "./pages/NotFound";
import { Access } from "./pages/Access";
import DepositAgent from "./pages/DepositWithdraw/DepositAgent";
import WithdrawAgent from "./pages/DepositWithdraw/WithdrawAgent";
import DashboardTest from "./pages/Dashboard";
import Deposit from "./pages/DepositWithdraw/Deposit";
import Withdraw from "./pages/DepositWithdraw/Withdraw";
import DeporsitAndWithdrawAdmin from "./pages/DepositWithdraw/DeporsitAndWithdrawAdmin";
import DeporsitAndWithdraw from "./pages/DepositWithdraw/DeporsitAndWithdraw";
import BetCasino from "./pages/Bet/BetCasino";
import BetSlot from "./pages/Bet/BetSlot";
import Calculate from "./pages/Calculate/Calculate";
import CalculateAgent from "./pages/Calculate/CalculateAgent";
import CalculateMainAgent from "./pages/Calculate/CalculateMainAgent";
import Help from "./pages/Note/Help";
import NotePopup from "./pages/Note/NotePopup";
import NoticeUser from "./pages/Note/NoticeUser/NoticeUser";
import NoteUser from "./pages/Note/NoteUser";
import NoteEvent from "./pages/Note/NoteEvent";
import Advertisement from "./pages/Note/Advertisement";
import HelpTemplate from "./pages/Note/HelpTemplate";
import EditLog from "./pages/Note/EditLog";
import CasinoSet from "./pages/SettingPage/CasinoSet/CasinoSet"
import Dashboard from "./components/Dashboard";
import FormLayoutDemo from "./components/FormLayoutDemo";
import InputDemo from "./components/InputDemo";
import FloatLabelDemo from "./components/FloatLabelDemo";
import InvalidStateDemo from "./components/InvalidStateDemo";
import ButtonDemo from "./components/ButtonDemo";
import TableDemo from "./components/TableDemo";
import ListDemo from "./components/ListDemo";
import TreeDemo from "./components/TreeDemo";
import PanelDemo from "./components/PanelDemo";
import OverlayDemo from "./components/OverlayDemo";
import MediaDemo from "./components/MediaDemo";
import MenuDemo from "./components/MenuDemo";
import MessagesDemo from "./components/MessagesDemo";
import FileDemo from "./components/FileDemo";
import ChartDemo from "./components/ChartDemo";
import MiscDemo from "./components/MiscDemo";
import BlocksDemo from "./components/BlocksDemo";
import Documentation from "./components/Documentation";
import IconsDemo from "./utilities/IconsDemo";
import CrudDemo from "./pages/CrudDemo";
import TimelineDemo from "./pages/TimelineDemo";
import CalendarDemo from "./pages/CalendarDemo";
import Invoice from "./pages/Invoice";
// import Help from "./pages/Help";
import Test from "./pages/Test";
import EmptyPage from "./pages/EmptyPage";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./App.scss";
import UserConnectionList from "./pages/User/UserConnection/UserConnectionList";
import UserList from "./pages/User/UserList/UserList";
import UserDetail from "./pages/User/UserDetail/UserDetail";
import AgentDetail from './pages/Agent/AgentDetail/AgentDetail'
import UserTodayList from "./pages/User/UserTodayList/UserTodayList";
import UserHostList from "./pages/User/UserHostList/UserHostList";
import UserGraduationList from "./pages/User/UserGraduationList/UserGraduationList";
import StopList from "./pages/User/StopList/StopList";
import UserIp from "./pages/User/UserIp/UserIp";
import BlockIp from "./pages/User/BlockIp/BlockIp";
import UserRegList from "./pages/User/UserRegList/UserRegList";
import UserDomain from "./pages/User/UserDomain/UserDomain";
import AgentAdd from "./pages/Agent/AgentAdd/AgentAdd";
import AgentBalanceList from "./pages/Agent/AgentBalance/AgentBalanceList";
import AgentHelp from "./pages/Agent/AgentHelp/AgentHelp";
import AgentNote from "./pages/Agent/AgentNote/AgentNote";
import AgentCode from "./pages/Agent/AgentCode/AgentCode";
import AgentRegister from "./pages/Agent/AgentRegister/AgentRegister";
import AgentBalanceLog from "./pages/Agent/AgentBalanceLog/AgentBalanceLog";
import AgentWithdraw from "./pages/Agent/AgentWithdraw/AgentWithdraw";
import AgentMove from "./pages/Agent/AgentRemove/AgentMove";
import AgentNotice from "./pages/Agent/AgentNotice/AgentNotice";
import Level from "./pages/SettingPage/Level/Level";
import CasinoMain from "./pages/SettingPage/Casinomain/Casinomain";
import Slotmain from "./pages/SettingPage/Slotmain/Slotmain";
import SlotSet from "./pages/SettingPage/SlotSet/SlotSet";
import SetLimit from "./pages/SettingPage/SetLimit/SetLimit";
import SetManagerAcc from "./pages/SettingPage/SetManagerAcc/SetManagerAcc";
import Messager from "./pages/SettingPage/Messager/Messager";
import SettingPage from "./pages/SettingPage/SettingPage/SettingPage";
import CalculateSubAgent from "./pages/Calculate/CalculateSubAgent";
import { makeSound, handleFirstEvent } from "./utilities/sound";
import Lobby from "./pages/SettingPage/Lobby/Lobby";
import SettlementPage from "./pages/Calculate/SettlementPage/SettlementPage";
const App = () => {
  const copyTooltipRef = useRef();
  const location = useLocation();
  const [login, setLogin] = useState(false);
  const [horizontal, setHorizontal] = useState(true);
  const [topbarSize, setTopbarSize] = useState("large");
  const [topbarColor, setTopbarColor] = useState("layout-topbar-blue");
  const [menuColor, setMenuColor] = useState("layout-menu-light");
  const [menuActive, setMenuActive] = useState(false);
  const [menuHoverActive, setMenuHoverActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [compactMode, setCompactMode] = useState(false);
  const [layoutColor, setLayoutColor] = useState("blue");
  const [themeColor, setThemeColor] = useState("blue");
  const [inputStyle, setInputStyle] = useState("filled");
  const [ripple, setRipple] = useState(true);

  PrimeReact.ripple = true;

  const breadcrumb = [
    { path: "/", label: "Dashboard" },
    { path: "/formlayout", label: "Form Layout" },
    { path: "/input", label: "Input" },
    { path: "/floatlabel", label: "Float Label" },
    { path: "/invalidstate", label: "Invalid State" },
    { path: "/button", label: "Button" },
    { path: "/table", label: "Table" },
    { path: "/list", label: "List" },
    { path: "/tree", label: "Tree" },
    { path: "/panel", label: "Panel" },
    { path: "/overlay", label: "Overlay" },
    { path: "/media", label: "Media" },
    { path: "/menu", label: "Menu" },
    { path: "/menu/seat", label: "Menu" },
    { path: "/menu/payment", label: "Menu" },
    { path: "/menu/confirmation", label: "Menu" },
    { path: "/messages", label: "Messages" },
    { path: "/file", label: "File" },
    { path: "/chart", label: "Chart" },
    { path: "/misc", label: "Misc" },
    { path: "/crud", label: "Crud" },
    { path: "/timeline", label: "Timeline" },
    { path: "/calendar", label: "Calendar" },
    { path: "/icons", label: "Icons" },
    { path: "/blocks", label: "Blocks" },
    { path: "/invoice", label: "Invoice" },
    { path: "/help", label: "Help" },
    { path: "/empty", label: "Empty" },
    { path: "/documentation", label: "Documentation" },
    { path: "/noteagent", label: "Note Agent" },
    { path: '/userconnectionlist', label: 'Users Connection List'},
    { path: '/userlist', label: 'Users List'},
    { path: '/usertodaylist', label: 'Users Today List'},
    { path: '/userghostlist', label: 'Users G Host List'},
    { path: '/usergraduationlist', label: 'Users Graduation List'},
    { path: '/stoplist', label: 'Stop List'},
    { path: '/userIP', label: 'User IP'},
    { path: '/blockIP', label:'Block IP'},
    { path: '/userreglist', label:'Users Register List'},
    { path: '/userdomain', label:'Users Domain'},
    { path: '/agentAdd', label:'Agent Add'},
    { path: '/agentBalanceList', label:'Agent Balance List'},
    { path: '/agenthelp', label:'Agent Help'},
    { path: '/notepopup', label: 'Note Agent'},
    { path: '/agentnote', label: 'Note Agent'},
    { path: '/agentcode', label:'Agent Code'},
    { path: '/agentreg', label: 'Agent Register'},
    { path: '/agentbalancelog', label:'Agent Balance Log'},
    { path: '/agentwithdraw', label:'Agent Withdraw'},
    { path: '/agentremove', label:'Agent Remove'},
    { path: '/noticeagent', label: 'Notice Agent'},
    { path: '/noteagent', label: 'Note Agent'},
    { path: '/level', label:'Level'},
    { path: '/casinomain', label:'Casino Main'},
    { path: '/casinoset', label: 'Casino Set'},
    { path: '/setslotmain', label:'Set Slot Main'},
    { path: '/setslotSet', label: 'Set Slot Set'},
    { path: '/setlimit', label: 'Set Limit'},
    { path: '/setmanageraccount', label:'Set Manager Account'},
    { path: '/messenger', label:'Messenger'},
    { path: '/setting', label:'Setting'},
    { path: '/noticeuser', label:'Notice User'},
    { path: '/noteuser', label:'Note User'},
    { path: '/noteEvent', label:'Note Event'},
    { path: '/advertisement', label:'Advertisement'},
    { path:'/editlog', label:'Edit log'},
    { path:'/helpTemplate', label:'Help Template'},
    { path:'/betcasino', label:'Bet Casino'},
    { path:'/betslot', label:'Bet Slot'},
    { path:'/calculate', label:'Calculate'},
    { path:'/calculateagent', label:'Calculate Agent'},
    { path:'/calculatemainagent', label:'Calculate Main Agent'},
    { path:'/deposit', label:'Deposit'},
    { path:'/withdraw', label:'Withdraw'},
    { path:'/depositagent', label:'Deposit Agent'},
    { path:'/withdrawagent', label:'Withdraw Agent'},
    { path:'/deporsitandwithdrawAdmin', label:'Deposit & Withdraw Admin'},
    { path:'/deporsitAndwithdraw', label:'Deposit & Withdraw'},
    { path: '/lobby', label:'Lobby'}
  ];

  const menu = [
    { label: "Dashboard", icon: "pi pi-fw pi-briefcase", to: "/" },
    {
      label: "회원관리",
      icon: "pi pi-fw pi-list",
      items: [
        {
          label: "접속 리스트",
          icon: "pi pi-fw pi-desktop",
          to: "/userconnectionlist",
        },
        {
          label: "유저 리스트",
          icon: "pi pi-fw pi-desktop",
          to: "/userlist",
        },
        {
          label: "금일 접속리스트",
          icon: "pi pi-fw pi-desktop",
          to: "/usertodaylist",
        },
        {
          label: "유령 리스트",
          icon: "pi pi-fw pi-desktop",
          to: "/userghostlist",
        },
        {
          label: "졸업 리스트",
          icon: "pi pi-fw pi-desktop",
          to: "/usergraduationlist",
        },
        {
          label: "정지 리스트",
          icon: "pi pi-fw pi-desktop",
          to: "/stoplist",
        },
        {
          label: "IP 접속자 리스트",
          icon: "pi pi-fw pi-desktop",
          to: "/userIP",
        },
        {
          label: "IP 차단",
          icon: "pi pi-fw pi-desktop",
          to: "/blockIP",
        },
        {
          label: "가입대기 리스트",
          icon: "pi pi-fw pi-desktop",
          to: "/userreglist",
        },
        {
          label: "가입 도메인",
          icon: "pi pi-fw pi-desktop",
          to: "/userdomain",
        },
      ],
    },
    {
      label: "충환전관리",
      icon: "pi pi-fw pi-list",
      //   badge: 2,
      //   mega: true,
      items: [
        {
          label: "충전대기",
          icon: "pi pi-fw pi-desktop",
          to: "/deposit",
        },
        {
          label: "환전대기",
          icon: "pi pi-fw pi-desktop",
          to: "/withdraw",
        },
        {
          label: "에이전트 충전",
          icon: "pi pi-fw pi-desktop",
          to: "/depositagent",
        },
        {
          label: "에이전트 환전",
          icon: "pi pi-fw pi-desktop",
          to: "/withdrawagent",
        },
        {
          label: "관리자 지급/회수",
          icon: "pi pi-fw pi-desktop",
          to: "/deporsitandwithdrawAdmin",
        },
        {
          label: "충환전정보",
          icon: "pi pi-fw pi-desktop",
          to: "/deporsitAndwithdraw",
        },
      ],
    },
    {
      label: "베팅내역",
      icon: "pi pi-fw pi-list",
      items: [
        {
          label: "카지노배팅",
          icon: "pi pi-fw pi-desktop",
          to: "/betcasino",
          //   badge: "NEW",
        },
        {
          label: "슬롯배팅",
          icon: "pi pi-fw pi-desktop",
          to: "/betslot",
        },
      ],
    },
    {
      label: "매출관리",
      icon: "pi pi-fw pi-list",
      items: [
        { label: "일/월별 통계", icon: "pi pi-fw pi-desktop", to: "/calculate" },
        {
          label: "매출관리1",
          icon: "pi pi-fw pi-desktop",
          to: "/calculateagent",
        },
        {
          label: "매출관리2",
          icon: "pi pi-fw pi-desktop",
          to: "/calculatemainagent",
        },
      ],
    },
    {
      label: "에이전트",
      icon: "pi pi-fw pi-list",
      items: [
        { label: "에이전트 추가/변경", icon: "pi pi-fw pi-desktop", to: "/agentAdd" },
        {
          label: "에이전트 벨런스",
          icon: "pi pi-fw pi-desktop",
          to: "/agentBalanceList",
        },
        { label: "에이전트 문의", icon: "pi pi-fw pi-desktop", to: "/agenthelp" },
        { label: "에이전트 쪽지", icon: "pi pi-fw pi-desktop", to: "/agentnote" },
        { label: "가입 코드", icon: "pi pi-fw pi-desktop", to: "/agentcode" },
        { label: "에이전트 승인", icon: "pi pi-fw pi-desktop", to: "/agentreg" },
        {
          label: "에이전트 기록",
          icon: "pi pi-fw pi-desktop",
          to: "/agentbalancelog",
        },
        {
          label: "에이전트 충전/환전",
          icon: "pi pi-fw pi-desktop",
          to: "/agentwithdraw",
        },
        {
          label: "에이전트 삭제",
          icon: "pi pi-fw pi-desktop",
          to: "/agentremove",
        },
        {
          label: "에이전트공지",
          icon: "pi pi-fw pi-desktop",
          to: "/noticeagent",
        },
      ],
    },
    {
      label: "커뮤니티",
      icon: "pi pi-fw pi-list",
      items: [
        { label: "고객센터", icon: "pi pi-fw pi-desktop", to: "/help" },
        { label: "팝업공지", icon: "pi pi-fw pi-desktop", to: "/notepopup" },
        { label: "유저공지", icon: "pi pi-fw pi-desktop", to: "/noticeuser" },
        { label: "유저쪽지", icon: "pi pi-fw pi-desktop", to: "/noteuser" },
        { label: "이벤트", icon: "pi pi-fw pi-desktop", to: "/noteEvent" },
        {
          label: "광고설정",
          icon: "pi pi-fw pi-desktop",
          to: "/advertisement",
        },
        { label: "수정/기록 변경", icon: "pi pi-fw pi-desktop", to: "/editlog" },
        {
          label: "고객센터 템플릿",
          icon: "pi pi-fw pi-desktop",
          to: "/helpTemplate",
        },
      ],
    },
    {
      label: "환경설정",
      icon: "pi pi-fw pi-list",
      items: [
        { label: "레벨별 포인트 설정", icon: "pi pi-fw pi-desktop", to: "/level" },
        { label: "레벨별 배팅 리밋", icon: "pi pi-fw pi-desktop", to: "/setlimit" },
        {
          label: "레벨별 통장",
          icon: "pi pi-fw pi-desktop",
          to: "/setmanageraccount",
        },
        { label: "메신저 설정", icon: "pi pi-fw pi-desktop", to: "/messenger" },
        { label: "로비 설정", icon: "pi pi-fw pi-desktop", to: "/lobby" },
        { label: "카지노 설정", icon: "pi pi-fw pi-desktop", to: "/casinomain" },
        { label: "카지노 게임 설정", icon: "pi pi-fw pi-desktop", to: "/casinoset" },
        {
          label: "슬롯 설정",
          icon: "pi pi-fw pi-desktop",
          to: "/setslotmain",
        },
        { label: "슬롯 게임 설정", icon: "pi pi-fw pi-desktop", to: "/setslotSet" },
        {
          label: "설정",
          icon: "pi pi-fw pi-desktop",
          to: "/setting",
        },
       
      ],
    },
  ];

  let menuClick;
  let userMenuClick;

  useEffect(() => {
    window.scrollTo(0, 0);
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onWrapperClick = () => {
    if (!menuClick) {
      setMenuActive(false);
      unblockBodyScroll();

      if (horizontal) {
        setMenuHoverActive(false);
      }
    }

    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    userMenuClick = false;
    menuClick = false;
  };

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRippleChange = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;

    if (!horizontal || isMobile()) {
      setMenuActive((prevMenuActive) => !prevMenuActive);
    }

    event.preventDefault();
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  const onTopbarUserMenuButtonClick = (event) => {
    userMenuClick = true;
    setTopbarUserMenuActive(
      (prevTopbarUserMenuActive) => !prevTopbarUserMenuActive
    );

    event.preventDefault();
  };

  const onTopbarUserMenuClick = (event) => {
    userMenuClick = true;

    if (
      event.target.nodeName === "BUTTON" ||
      event.target.parentNode.nodeName === "BUTTON"
    ) {
      setTopbarUserMenuActive(false);
    }
    event.preventDefault();
  };

  const onRootMenuItemClick = (event) => {
    menuClick = true;
    if (horizontal && isMobile()) {
      setMenuHoverActive(event.isSameIndex ? false : true);
    } else {
      setMenuHoverActive((prevMenuHoverActive) => !prevMenuHoverActive);
    }
  };

  const onMenuItemClick = (event) => {
    if (event.item && !event.item.items) {
      if (!horizontal || isMobile()) {
        setMenuActive(false);
        unblockBodyScroll();
      }

      setMenuHoverActive(false);
    }
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const isMobile = () => {
    return window.innerWidth <= 1024;
  };

  const onTopbarSizeChange = (size) => {
    setTopbarSize(size);
  };

  const onTopbarThemeChange = (color) => {
    setTopbarColor("layout-topbar-" + color);
  };

  const onMenuToHorizontalChange = (mode) => {
    setHorizontal(mode);
  };

  const onMenuThemeChange = (color) => {
    setMenuColor("layout-menu-" + color);
  };

  const onThemeColorChange = (color) => {
    setThemeColor(color);
  };

  const onLayoutColorChange = (color) => {
    setLayoutColor(color);
  };

  const onCompactModeChange = (mode) => {
    setCompactMode(mode);
  };

  useEffect(() => {
    if (menuActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [menuActive]);

  const layoutContainerClassName = classNames(
    "layout-container",
    {
      "layout-menu-horizontal": horizontal,
      "layout-menu-active": menuActive,
      "layout-top-small": topbarSize === "small",
      "layout-top-medium": topbarSize === "medium",
      "layout-top-large": topbarSize === "large",
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple,
    },
    topbarColor,
    menuColor
  );
    const accessToken = localStorage.getItem('access');
    const navigate = useNavigate();
    const media = localStorage.getItem('media')


    useEffect(()=>{
      if (!accessToken ){
        navigate('/login')
      }
      if(!media){
        makeSound().then(handleFirstEvent);
      }else{
        handleFirstEvent()
      }
    },[accessToken, media]);
  return (
    <>
      <div className={layoutContainerClassName} onClick={onWrapperClick}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/error" element={<Error />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="/access" element={<Access />} />
          <Route path="/userdetail/:id" element={<UserDetail />} />
          <Route path="/agentdetail/:id" element={<AgentDetail />} />
          <Route path='/calculatesubagent/:id' element={<CalculateSubAgent/>}/>
          <Route path='/settlement' element={<SettlementPage/>}/>
          <Route
            path="/*"
            element={
              <>
                <Tooltip
                  ref={copyTooltipRef}
                  target=".block-action-copy"
                  position="bottom"
                  content="Copied to clipboard"
                  event="focus"
                />

                <div className="layout-top">
                  <AppTopbar
                    topbarUserMenuActive={topbarUserMenuActive}
                    menuActive={menuActive}
                    menuHoverActive={menuHoverActive}
                    onMenuButtonClick={onMenuButtonClick}
                    onTopbarUserMenuButtonClick={onTopbarUserMenuButtonClick}
                    onTopbarUserMenuClick={onTopbarUserMenuClick}
                    model={menu}
                    horizontal={horizontal}
                    onSidebarClick={onSidebarClick}
                    onRootMenuItemClick={onRootMenuItemClick}
                    onMenuItemClick={onMenuItemClick}
                    isMobile={isMobile}
                  />

                  <div className="layout-topbar-separator" />

                  <AppBreadcrumb routers={breadcrumb} />
                </div>

                <div className="layout-content">
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/" element={<DashboardTest />} />
                    <Route path="/formlayout" element={<FormLayoutDemo />} />
                    <Route path="/input" element={<InputDemo />} />
                    <Route path="/floatlabel" element={<FloatLabelDemo />} />
                    <Route
                      path="/invalidstate"
                      element={<InvalidStateDemo />}
                    />
                    <Route path="/button" element={<ButtonDemo />} />
                    <Route path="/table" element={<TableDemo />} />
                    <Route path="/list" element={<ListDemo />} />
                    <Route path="/tree" element={<TreeDemo />} />
                    <Route path="/panel" element={<PanelDemo />} />
                    <Route path="/overlay" element={<OverlayDemo />} />
                    <Route path="/media" element={<MediaDemo />} />
                    <Route path="/menu/*" element={<MenuDemo />} />
                    <Route path="/messages" element={<MessagesDemo />} />
                    <Route path="/file" element={<FileDemo />} />
                    <Route path="/chart" element={<ChartDemo />} />
                    <Route path="/blocks" element={<BlocksDemo />} />
                    <Route path="/misc" element={<MiscDemo />} />
                    <Route path="/icons" element={<IconsDemo />} />
                    <Route path="/crud" element={<CrudDemo />} />
                    <Route path="/timeline" element={<TimelineDemo />} />
                    <Route path="/calendar" element={<CalendarDemo />} />
                    <Route path="/invoice" element={<Invoice />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/notepopup" element={<NotePopup />} />
                    <Route path="/noticeuser" element={<NoticeUser />} />
                    <Route path="/noteuser" element={<NoteUser />} />
                    <Route path="/noteEvent" element={<NoteEvent />} />
                    <Route path="/advertisement" element={<Advertisement />} />
                    <Route path="/editlog" element={<EditLog />} />
                    <Route path="/helpTemplate" element={<HelpTemplate />} />
                    <Route path="/empty" element={<EmptyPage />} />
                    <Route path="/documentation" element={<Documentation />} />
                    <Route path="/deposit" element={<Deposit />} />
                    <Route
                      path="/deporsitandwithdrawAdmin"
                      element={<DeporsitAndWithdrawAdmin />}
                    />
                    <Route path='/depositagent' element={<DepositAgent/>}/>
                    <Route path='/withdrawagent' element={<WithdrawAgent/>}/>
                    <Route
                      path="/deporsitandwithdraw"
                      element={<DeporsitAndWithdraw />}
                    />
                    <Route path="/betcasino" element={<BetCasino />} />
                    <Route path="/betslot" element={<BetSlot />} />
                    <Route path="/calculate" element={<Calculate />} />
                    <Route
                      path="/calculateagent"
                      element={<CalculateAgent />}
                    />
                    <Route
                      path="/calculatemainagent"
                      element={<CalculateMainAgent />}
                    />
                    <Route path="/withdraw" element={<Withdraw />} />
                    <Route path="/test" element={<Test />} />
                    <Route
                      path="/userconnectionlist"
                      element={<UserConnectionList />}
                    />
                    <Route path="/userlist" element={<UserList />} />
                    <Route path="/usertodaylist" element={<UserTodayList />} />
                    <Route path="/userghostlist" element={<UserHostList />} />
                    <Route
                      path="/usergraduationlist"
                      element={<UserGraduationList />}
                    />
                    <Route path="/stoplist" element={<StopList />} />
                    <Route path="/userIP" element={<UserIp />} />
                    <Route path="/blockIP" element={<BlockIp />} />
                    <Route path="/userreglist" element={<UserRegList />} />
                    <Route path="/userdomain" element={<UserDomain />} />
                    <Route path="/agentAdd" element={<AgentAdd />} />
                    <Route
                      path="/agentBalanceList"
                      element={<AgentBalanceList />}
                    />
                    <Route path="/agenthelp" element={<AgentHelp />} />
                    <Route path="/agentnote" element={<AgentNote />} />
                    <Route path="/agentcode" element={<AgentCode />} />
                    <Route path="/agentreg" element={<AgentRegister />} />
                    <Route
                      path="/agentbalancelog"
                      element={<AgentBalanceLog />}
                    />
                    <Route path="/agentwithdraw" element={<AgentWithdraw />} />
                    <Route path="/agentremove" element={<AgentMove />} />
                    <Route path="/noticeagent" element={<AgentNotice />} />
                    <Route path="/level" element={<Level />} />
                    <Route path="/casinomain" element={<CasinoMain />} />
                    <Route path="/casinoset" element={<CasinoSet />} />
                    <Route path="/setslotmain" element={<Slotmain />} />
                    <Route path="/setslotSet" element={<SlotSet />} />
                    <Route path="/setlimit" element={<SetLimit />} />
                    <Route path="/lobby" element={<Lobby/>}/>
                    <Route
                      path="/setmanageraccount"
                      element={<SetManagerAcc />}
                    />
                    <Route path="/messenger" element={<Messager />} />
                    <Route path="/setting" element={<SettingPage />} />
                  </Routes>
                </div>

                <AppConfig
                  topbarSize={topbarSize}
                  onTopbarSizeChange={onTopbarSizeChange}
                  topbarColor={topbarColor}
                  onTopbarThemeChange={onTopbarThemeChange}
                  horizontal={horizontal}
                  onMenuToHorizontalChange={onMenuToHorizontalChange}
                  menuColor={menuColor}
                  onMenuThemeChange={onMenuThemeChange}
                  themeColor={themeColor}
                  onThemeColorChange={onThemeColorChange}
                  layoutColor={layoutColor}
                  onLayoutColorChange={onLayoutColorChange}
                  compactMode={compactMode}
                  onCompactModeChange={onCompactModeChange}
                  rippleActive={ripple}
                  onRippleChange={onRippleChange}
                  inputStyle={inputStyle}
                  onInputStyleChange={onInputStyleChange}
                />

                <AppFooter />

                {menuActive && <div className="layout-mask" />}
              </>
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default App;
