import { mainAxios } from "../libs/axios";
import { buildMyQueryString } from "../utilities/query";
const accessToken =  localStorage.getItem('User')
const jsonToken = JSON.parse(accessToken)
const token = `Bearer ${jsonToken}`
export default class MoneyService {
    getDepositHis(payload) {
        return mainAxios.request({
            methodType: 'GET',
            url: `/money?mode=money_list_0`,
            params: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
              headers: {
                Accept: 'application/json',
                Authorization: token
              }
            }
          })
    }
    getWithdrawHis(payload) {
      return mainAxios.request({
          methodType: 'GET',
          url: `/money?mode=money_list_1`,
          params: payload,
          requiresToken: true,
          notError: true,
          getError: true,
          config: {
            headers: {
              Accept: 'application/json',
              Authorization: token
            }
          }
        })
  }
    actionMoneyRequest(payload){
      return mainAxios.request({
        methodType: 'POST',
        url: `/${payload}`,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: token
          }
        }
      })
    }
    fetchUserMoney(payload){
      return mainAxios.request({
        methodType: 'GET',
        url: `/money?mode=money_list_01`,
        params: payload,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
    fetchUserMoneyAdmin(query){
      return mainAxios.request({
        methodType: 'GET',
        url: `/money${buildMyQueryString({...query, mode: 'money_list_01', process: 'deporsitandwithdrawAdmin'})}`,
        requiresToken: true,
        notError: true,
        getError: true,
        config: {
          headers: {
            Accept: 'application/json',
            Authorization: `${token}`
          }
        }
      })
    }
}
