import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import moment from "moment-timezone";

const CpDatePicker = (props) => {
  const { defaulDate = new Date(), onChange, placeholder = "", showTime } = props;
  const [date, setDate] = useState('');

  useEffect(() => {
    if (defaulDate) {
      setDate(defaulDate);
    }
  }, []);
  const changeDate = (e) => {
    onChange?.(e);
    setDate(e);
  };
  // console.log(date,moment(date).format("DD/MM/YYYY"));

  return (
    <div className="cp-date-picker">
      <Calendar
        value={date}
        onChange={(e) => changeDate(e.value)}
        showIcon
        appendTo="self"
        placeholder={placeholder}
        showTime={showTime}
        hourFormat="24"
      />
    </div>
  );
};

export default CpDatePicker;
