import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";

const CpButtonGroupActive = (props) => {
  const { dataGroup = [], onChange, value = "" } = props;
  useEffect(() => {
    // if (defaultActive) {
    //   setAt(defaultActive);
    // }
  }, []);
  const onChangeTab = (e) => {
    if (e.value === value) {
      onChange?.("");
    } else {
      onChange?.(e);
    }
  };
  
  return (
    <div className="cp-button-group-active">
      {dataGroup.map((v, i) => (
        <div
          className={`${v.value === value ? "active" : ""} button-item`}
          key={i}
        >
          <Button label={v.label} onClick={() => (v.callBack && v.callBack()) || onChangeTab(v)} />
        </div>
      ))}
    </div>
  );
};

export default CpButtonGroupActive;
