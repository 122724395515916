import { mainAxios } from "../libs/axios";

export const helpListAPI = (params) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/board?mode=user_agent_help&board=board01`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const helpdetailAPI = (params) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/board?mode=view01&board=board01`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

// export const notePostAPI = (params) => {
//   return mainAxios.request({
//     methodType: "GET",
//     url: `/board`,
//     params: params,
//     requiresToken: true,
//     notError: true,
//     getError: true,
//     config: {
//       headers: {
//         contentType: "application/json",
//       },
//     },
//   });
// };

export const notePopupListAPI = (params) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/board?mode=note_popup&board=board04`,
    requiresToken: true,
    params: params,
    // notError: true,
    // getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const notePopupUpdateAPI = (params) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/board?mode=note_popup&board=board04`,
    requiresToken: true,
    params: params,
    // notError: true,
    // getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const noteUserListAPI = (params) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/member?mode=user_agent_note`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const noteUserPostAPI = (params) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/member?mode=user_agent_note`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const noticeUserListAPI = (params) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/board?mode=notice_user&board=board03`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};


export const noteEventListAPI = (params) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/board?mode=note_event&board=board02`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const noteEventPostAPI = (params) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/board?mode=note_event&board=board02`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};