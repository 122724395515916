import React, { useEffect, useState, useRef, createRef } from "react";
import { Button } from "primereact/button";
import AgentService from "../../../../service/AgentServices";
import { useParams } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

const CasinoRolling = () => {
    const agentList = new AgentService()
    const params = useParams()
    const [data, setData] = useState(undefined);
    const values = useRef([]);
    const blockSpam = useRef(false);
    const toast = useRef(null);

    const fetchUser = () => {
        agentList.agentDetailAPI({id:params.id, tab: 'rating'})
            .then((data) => {
                const res = data;
                values.current = Object.keys(res.label['정산 배당설정']).map((_, i) => values.current[i]  ?? createRef())
                setData(res);
            }).catch(err =>{
                toast.current.show({ severity: 'warn', summary: 'Failed', detail: 'Request data failed', life: 3000 });
            })
    }

    useEffect(fetchUser, [])
    const confirm = (game_type, index) => {
        const rate = values.current[index].current.value;
        if(parseInt(rate) < 0 || !rate) return;
        confirmDialog({
          message: '에이전트 루징/롤링을 수정하시겠습니까 ?',
          header: '에이전트 저장',
          icon: 'pi pi-exclamation-triangle',
          accept: () => handleUpdateAgent(game_type, index),
          acceptLabel: '예',
          rejectLabel: '아니요',
          rejectClassName: 'p-button-danger'
        });
    };

    const handleUpdateAgent = async (game_type, index) =>{
        if(blockSpam.current) return;
        const rate = values.current[index].current.value;
        const payload = {
            submit: 1,
            game_type,
            rate: parseInt(rate)
        }
        blockSpam.current = true
        try {
            const resp = await agentList.updateAgentAPI({id:params.id, tab: 'rating'}, payload);
            if(resp?.error) throw new Error('Failed')
            if(resp.code === 200){
                toast.current.show({ severity: 'success', summary: '성공', detail: resp.message, life: 3000 });
            }
            else{
                toast.current.show({ severity: 'error', summary: '성공', detail: resp.message, life: 3000 }); 
            }
        } catch (error) {
            toast.current.show({ severity: 'warn', summary: 'Failed', detail: 'Update failed', life: 3000 });
        } finally {
            blockSpam.current = false
        }
    }

    return (
        <div className="grid">
            {
                data &&
                Object.keys(data.label['정산 배당설정']).map((e, i) =>{
                    return (
                        <>
                         {data.label['정산 배당설정'][e] === '카지노 루징'? <></>:
                            <div className="col-12" key={e}>
                                <div className="flex align-items-center">
                                    <div className="col-12 md:col-2">
                                        <label>{data.label['정산 배당설정'][e] ==='루징'? '루징':data.label['정산 배당설정'][e]} (%)</label>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <input ref={values.current[i]} type="number" placeholder="프로테이지" id="pass" className="p-inputtext p-component" defaultValue={data.list['정산 배당설정'][e] || 0}></input>
                                    </div>
                                    <div className="col-12 md:col-1">
                                        <Button onClick={() => confirm(e, i)}>수정</Button>
                                    </div>
                                </div>
                            </div>
                        }
                        </>
                        
                    )
                })
            }
        <ConfirmDialog />
        <Toast ref={toast} />
        </div>
    )
}

export default CasinoRolling
