import React from "react";
import { Button } from "primereact/button";

const CpButton = (props) => {
  const {
    icon,
    onClick,
    className,
    severity = "success",
    ariaLabel="Search"
  } = props;
  return (
    <div className="cp-button-icon">
      <Button
        className={className}
        icon={icon}
        rounded
        raised
        severity={severity}
        aria-label={ariaLabel}
        onClick={() => onClick?.()}
      />
    </div>
  );
};

export default CpButton;
