import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { CpInput } from "../components";
import { loginApi } from "../service";
import { handleFirstEvent } from "../utilities/sound";

export const Login = () => {
  const [userName, setUserName] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate();

  const onLogin = async () => {
    // navigate("/");
    console.log("login", userName, pass);
    // const fmData = new FormData();
    // fmData.append("id", userName);
    // fmData.append("pwd", pass);
    try {
      const res = await loginApi({
        // fmData,
        id: userName,
        pwd: pass,
      });
      // console.log(res);
      if (res.access_token) {
        console.log(res);
        localStorage.setItem("access", JSON.stringify(res.access_token));
        window.location.href = "/#"
        handleFirstEvent();
      } else {
        alert(res?.message);
        // console.log("err",res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-body">
      <div className="login-panel ui-fluid">
        <div className="login-panel-header">
          <img
            src="assets/layout/images/login/logo-sapphire-color.png"
            alt="sapphire"
          />
        </div>
        <div className="login-panel-content">
          <div className="grid">
            <div className="col-12">
              <h1>낭만 NETWORK</h1>
              <h2>Welcome, please use the form to sign-in</h2>
            </div>
            <div className="col-12">
              <CpInput
                id="username"
                placeholder="Username"
                value={userName}
                onChange={(e) => setUserName(e)}
              />
            </div>
            <div className="col-12">
              <CpInput
                id="password"
                type="password"
                placeholder="Password"
                value={pass}
                onChange={(e) => setPass(e)}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    onLogin();
                  }
                }}
              />
            </div>
            <div className="col-6">
              <button className="p-link">Forget Password?</button>
            </div>
            <div className="col-6" style={{ textAlign: "right" }}>
              <Button
                label="NEXT"
                onClick={onLogin}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
