import {mainAxios} from "../libs/axios";

export const loginApi = (payload) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/login`,
    payload: payload,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
        // Referer: "13.250.113.47",
      },
    },
  });
};
export const logout = () => {
  return mainAxios.request({
    methodType: "POST",
    url: `/logout`,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
        // Referer: "13.250.113.47",
      },
    },
  });
};
