import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import './AgentWithdraw.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import moment from 'moment';
import 'moment/locale/ko';
import AgentService from "../../../service/AgentServices";
import AgentMove from "../AgentRemove/AgentMove";
import { SelectButton } from "primereact/selectbutton";
import moneyConvert from "../../../utilities/moneyConvert";
const AgentWithdraw = () => {

    useEffect(() => {
        moment.locale('ko');
    }, [])
    const currentDate = moment().toDate();
    const nextDate = moment().add(1, 'days').toDate();
    const buttonFilter = [
        { button: "모두 보기", value: "all" },
        { button: "충전", value: "deposit" },
        { button: "환전", value: "withdraw" },
    ]
    const [filterBtn, setFilterBtn] = useState(buttonFilter[0])
    const [selectOption, setSelectOption] = useState('선택');
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [first, setFirst] = useState(0);
    const [startDate, setStartDate] = useState(currentDate);
    const [endDate, setEndDate] = useState(nextDate);
    const [totalDep, setTotalDep] = useState('0');
    const [totalWithdraw, setTotalWithdraw] = useState('0');
    const [sum, setSum] = useState('0')
    const [total, setTotal] = useState(0);
    const [inpCondition, setInpCondition] = useState('')
    const [filterCondition, setFilterCondition] = useState()
    function getSumTotal(a, b) {
        if (a && b) {
            const value = parseInt(a?.replace(/,/g, "")) - parseInt(b?.replace(/,/g, ""))
            setSum(moneyConvert(value)) 
        }

    }
    const MoneyInpGroup = [
        { title: '충전', name: 'deposite', value: totalDep, color: 'green' },
        { title: '환전', name: 'withdraw', value: totalWithdraw, color: 'red' },
        { title: '수익', name: 'total', value: sum, color: 'black' }
    ]
    const header = [
        { field: "modes", label: "상태", sortAble: false }, //1 rút 0 nạp
        { field: "members", label: `에이전트 아이디`, sortAble: false },
        { field: "idx", label: `idx`, sortAble: false },
        { field: "money", label: `충/환전금액`, sortAble: false },
        { field: "bank_names", label: "은행명", sortAble: false },
        { field: "bank_owns", label: "입금자명", sortAble: false },
        { field: "bank_nos", label: `계좌번호`, sortAble: false },
        { field: "regdate", label: `등록일`, sortAble: false },
        { field: "okdate", label: `완료일`, sortAble: false },
        // { field: "tree_agent", label: `에이전트 트리`, sortAble: false },
        // { field: "status", label: `상태`, sortAble: false },
        // { field: "finish", label: `완료`, sortAble: false },
        // { field: "waitting", label: `대기`, sortAble: false },
        // { field: "cancel", label: `취소`, sortAble: false },
    ];
    const agentList = new AgentService();
    function fetchAgentWithdrawList() {
        const payload = {
            page: page,
            ...filterCondition,
            sdate: formatDate(startDate),
            edate: formatDate(endDate),
            status2: "OK"
        }
        if (filterBtn.value === 'all') {
            agentList.agentWithdraw(payload)
                .then((data) => {
                    const res = data.list
                    setData(res.data)
                    setTotalRecords(res.total)
                    setTotalDep(data.total_charge_exchange.charge.sum)
                    setTotalWithdraw(data.total_charge_exchange.exchange.sum)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        if (filterBtn.value === 'deposit') {
            agentList.getDepWithHisNewAPI({...payload, list:'charge'})
                .then((data) => {
                    const res = data.list
                    setData(res.data)
                    setTotalRecords(res.total)
                    setTotalDep(data.total_charge_exchange.charge.sum)
                    setTotalWithdraw('0')
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        if (filterBtn.value === 'withdraw') {
            agentList.getDepWithHisNewAPI({...payload, list:'exchange'})
                .then((data) => {
                    const res = data.list
                    setData(res.data)
                    setTotalRecords(res.total)
                    setTotalDep('0')
                    setTotalWithdraw(data.total_charge_exchange.exchange.sum)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    useEffect(() => {
        fetchAgentWithdrawList()
    }, [page, filterCondition, filterBtn])
    useEffect(()=>{
        getSumTotal(totalDep,totalWithdraw)
    },[totalDep,totalWithdraw, sum])
    const menuDropdown = [
        '선택',
        '에이전트 아이디',
        '에이전트 트리'
    ]
    const chooseOption = (e) => {
        setSelectOption(e)
        if (e === '선택') {
            setInpCondition('');
            setFilterCondition()
        }
    }
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    const bodyTemplate = (data, props) => {
        const conditionHis = filterBtn.value
        const money = data?.charge_money[2]
        const id = data.member.split('(')[0]
        return (
            <>
                {props.field === 'members' &&
                    <div onClick={()=>{window.open(`#/agentdetail/${id}`, '', "width=1500,height='100vh'")}}>
                        {data.member}
                        &nbsp;
                        <i className="pi-cog pi" style={{fontSize:' 12px', color: 'rgb(255, 87, 34)'}}/>
                    </div>
                }
                {props.field === 'bank_names' &&
                    <div >
                        {data.bank_name ?data.bank_name: data.mb_bank_name }
                    </div>
                }
                {props.field === 'bank_owns' &&
                    <div >
                        {data.bank_own ?data.bank_own: data.mb_bank_own }
                    </div>
                }
                {props.field === 'bank_nos' &&
                    <div >
                        {data.bank_no ?data.bank_no: data.mb_bank_no }
                    </div>
                }
                {props.field === 'btn_action' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div className="icon-btn">
                            <span style={{}}><i style={{ color: 'white' }} className="pi-file-edit pi" /></span>

                        </div>
                    </>
                }
                {props.field === 'modes' &&

                    <div >
                        <span>
                            {data.charge_money[0] ===''? '-':data.charge_money[0]}
                        </span>
                    </div>
                }
                {props.field === 'money' &&
                    <div >
                        <span style={conditionHis === 'deposit' ? { color: 'green' } : conditionHis === 'withdraw' ? { color: 'red' } : conditionHis === 'all' && data.mode === 'money_list_1' ? { color: 'red' } : { color: 'green' }}>{money}</span>
                    </div>
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    addLocale('ko', {
        firstDayOfWeek: 1,
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });
    function formatDate(date) {
        var yyyy = date?.getFullYear();
        var mm = String(date?.getMonth() + 1).padStart(2, '0');
        var dd = String(date?.getDate()).padStart(2, '0');
        return yyyy + '-' + mm + '-' + dd
    }
    const SelectBtnGroup = () => {
        const handleChangeOpt = async (e) => {
            if(e.value ===  null){
                return
            }
            setFilterBtn(e)
        }
        const justifyTemplate = (option) => {
            return <span>{option.button}</span>;
        }
        return (
            <SelectButton value={filterBtn.value} onChange={(e) => handleChangeOpt(e)} itemTemplate={justifyTemplate} options={buttonFilter} />
        )
    }
    function handleFilter() {
        if (selectOption === '에이전트 아이디') {
            setFilterCondition({ keyfield: 'mb_id', key: inpCondition, sdate: startDate ? formatDate(startDate) : '', edate: endDate ? formatDate(endDate) : '' })
        }
        if (selectOption === '에이전트 트리') {
            setFilterCondition({ keyfield: 'mb_name', key: inpCondition, sdate: startDate ? formatDate(startDate) : '', edate: endDate ? formatDate(endDate) : '' })
        }
        if (selectOption === '선택') {
            setFilterCondition({ sdate: startDate ? formatDate(startDate) : '', edate: endDate ? formatDate(endDate) : '' })
        }
    }
    return (
        <>
            <div className='refresh card'>
                <span className="text">입금/ 출금 완료</span>
                <div className="tool-withdraw">
                    <div className="date-filter">
                        <Calendar value={startDate}
                            hourFormat="24" onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd"/>
                        <Calendar value={endDate} onChange={(e) => setEndDate(e.value)}
                            hourFormat="24" locale="ko" showIcon dateFormat="yy-mm-dd"/>
                    </div>
                    <div className="item">
                        <div className="item1">
                            <Dropdown
                                options={menuDropdown}
                                onChange={(e) => chooseOption(e.value)}
                                value={selectOption}
                            />
                            <div className="item-row">
                                <InputText placeholder="검색"
                                    value={inpCondition} onChange={(e) => setInpCondition(e.target.value)} onKeyDown={(e)=> {if(e.key ==='Enter') handleFilter()}}/>
                                <div className="button-search" onClick={handleFilter}>
                                    <i className="pi-search pi" />
                                </div>
                            </div>
                        </div>

                        <div className="button-group-filter">
                            <SelectBtnGroup />
                        </div>
                    </div>
                    <div className="money-row">
                        {MoneyInpGroup.map((v, i) => {
                            return (
                                <div className='inp-group' key={i}>
                                    <span className={`p-float-label`}>
                                        <InputText
                                            id=''
                                            type="text"
                                            value={v.value}
                                            style={{ color: `${v.color}` }}
                                        //   onChange={(e) => onChangeInput(e.target.value)}
                                        //   onKeyDown={(e) => onKeyDown?.(e)}
                                        />
                                        <label htmlFor={v.name} style={{ color: v.color }}>{v.title}</label>
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
                <CustomDataTable
                    data={data}
                    headers={header}
                    bodyTemplate={bodyTemplate}
                    modalMessage={messageModal}
                    openConfirm={openModalConfirm}
                    scrollable={true}
                />
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}
                />
            </div>
        </>
    )
}

export default AgentWithdraw;