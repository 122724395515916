import React, { useState, useEffect } from "react";
import { Paginator } from "primereact/paginator";

const CpPagination = (props) => {
  const { page = 1, onChange, total = 10, defaulRows = 10 } = props;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onPageChange = (event) => {
    // setFirst(event.first);
    // setRows(event.rows);
    onChange?.(event.page + 1);
  };
  useEffect(() => {
    if (defaulRows) {
      setRows(defaulRows);
    }
    if (page > 0) {
      if (defaulRows) {
        setFirst((page - 1) * defaulRows);
      } else {
        setFirst((page - 1) * 10);
      }
    }
  }, [page]);

  return (
    <div className="cp-paginator">
      <Paginator
        first={first}
        rows={rows}
        totalRecords={total}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default CpPagination;
