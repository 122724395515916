import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from 'primereact/inputtext'
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../../components/Table/TableUser";
import AgentService from "../../../../service/AgentServices";
import { Toast } from 'primereact/toast';
import { addLocale } from "primereact/api";
import { formatDate } from "../../../../utilities/date";
import { Tag } from "primereact/tag";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { query } from "quill";
import UserService from "../../../../service/UserService";
const AgentPaymentNRecovery = () => {
    const today = new Date();
    today.setHours(today.getHours() + 2);
    const agentList = new AgentService();
    const userList = new UserService();

    const [actionRadio, setActionRadio] = useState('지급');
    const [activeBtn, setActiveBtn] = useState('money_list_01');
    const [activeFilterDateBtn, setActiveFilterDateBtn] = useState('all');
    const [first, setFirst] = useState(0);
    const [sDate, setSDate] = useState('')
    const [eDate, setEDate] = useState('')
    const [totalRecords, setTotalRecords] = useState(0)
    const [data, setData] = useState(undefined);
    const [label, setLabel] = useState(undefined);
    const [filter, setFilter] = useState(undefined);
    const [money, setMoney] = useState(undefined)
    const params = useParams()
    const toast = useRef(null);
    const blockEffect = useRef(true)
    const page = useRef(1);
    const mode = useRef('money_list_01');
    const pw  = useRef('')
    const memo = useRef('')
    const moneyValue = useRef('');
    
    const fetchUIData = () => {
        const myQuery = {
            id: params.id,
            tab: 'charge_exchange',
            sdate: '',
            edate: '',
            page: page.current,
            mode: 'agent_detail'
        }
        agentList.agentDetailAPI(myQuery)
            .then((resp) => {
                // setData((prev) => prev = resp?.list['지급/회수'].data)
                setLabel(resp.label['지급/회수'])
                setFilter(resp.general.filter)
                setMoney(resp.list.total_charge_exchange)
            }).catch(err => {
                // toast.current.show({ severity: 'warn', summary: 'Failed', detail: 'Request data failed', life: 3000 });
            })
    }
    const fetchUser = (query) =>{
        const myQuery = {
            tab: 'charge_exchange',
            mb_id: params.id,
            sdate: formatDate(sDate),
            edate: formatDate(eDate),
            page: page.current,
            mode: mode.current,
            ...(!sDate && !eDate) &&{top:  1},
            ...query
        }
       
        agentList.agentDetailAPI(myQuery)
            .then((resp) => {
                if(resp?.data){
                    setData((prev) => prev = resp.data.data )
                    setMoney(resp.total)
                    setTotalRecords(resp.data.total)
                }
                if(resp.list['지급/회수']){
                    setData((prev) => prev = resp.list['지급/회수'].data)
                    setMoney(resp.list.total_charge_exchange)
                    setTotalRecords(resp.list.total)
                } else{
                    setData((prev) => prev = resp.list.data)
                    setMoney(resp.general.sum)
                    setTotalRecords(resp.list.total)
                }
                blockEffect.current = false
            }).catch(err => {
                // toast.current.show({ severity: 'warn', summary: 'Failed', detail: 'Request data failed', life: 3000 });
        })
    }

    useEffect(() =>{
        fetchUIData();
    },[])

    useEffect(() =>{
        fetchUser();
        blockEffect.current = true
    }, [activeBtn, activeFilterDateBtn])

    useEffect(() =>{
        if(blockEffect.current) return
        if(!sDate){
            setFirst(0)
            setEDate(prev => prev = '')
            setActiveFilterDateBtn('all')
            page.current = 1
            fetchUser({
                top: 1
            })
            return
        }
    }, [sDate]);

    const filterDateButtons = Object.keys(filter || {}).filter(e =>{
        return e !== 'edate' && e !== 'mode' && e !== 'sdate'
    }).map((e, i) =>{
        return { name: e, button: filter[e], value: filter[e] }
    });

    const listMoneyButton = Object.keys(filter?.mode || {}).map(e =>{
        return { name: e, button: filter.mode[e].label, value: filter.mode[e].label }
    })
    const headerHistory = Object.keys(label || {}).map(e =>{
        return {field: e, label: label[e], data}
    });

    const fillDate = (name) => {
        switch (name) {
            case 'today':
                const endDateToday = new Date(today)
                setSDate((prev) => prev = today);
                endDateToday.setDate(today.getDate() + 1)
                setEDate((prev) => prev = endDateToday)
                break;
            case 'last_week':
                const stateDateLastWeek = new Date(today)
                stateDateLastWeek.setDate(today.getDate() - 6)
                setSDate((prev) => prev = stateDateLastWeek);
                setEDate((prev) => prev = today)
                break;
            case 'last_month':
                const stateDateLastMonth = new Date(today)
                stateDateLastMonth.setDate(today.getDate() - 29)
                setSDate((prev) => prev = stateDateLastMonth);
                setEDate((prev) => prev = today)
                break;
            default:
                setSDate((prev) => prev = '')
                setEDate((prev) => prev = '')
                break;
        }
        setActiveFilterDateBtn(name);
        blockEffect.current = false
        page.current = 1
        setFirst(0);
    }

    const handleMoneyBtn = (name) =>{
        setSDate((prev) => prev = '')
        setEDate((prev) => prev = '')
        setActiveFilterDateBtn('all')
        setActiveBtn(name);
        page.current = 1
        setFirst(0);
        mode.current = name
    }

    const handleFilterByDate = () =>{
        if(!sDate || !eDate) return;
        setFirst(0)
        page.current = 1
        fetchUser({
            sdate: formatDate(sDate),
            edate: formatDate(eDate),
            page: page.current
        })
    }

    const onPageChange = (event) => {
        if(page.current === (event.page + 1)) return
        page.current = (event.page + 1 )
        setFirst(event.first)
            fetchUser({
            sdate: formatDate(sDate),
            edate: formatDate(eDate),
            page: page.current
        })
    };
    function convertNumber(e) {
        return parseFloat(e?.replace(/,/g, ''))
    }
    function parseToString(e) {
        return e.toLocaleString()
    }

    addLocale('ko', {
        firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        today: '오늘',
        clear: '지우기'
    });

    const handleSubmit = () =>{
        if (!moneyValue.current.value) {
            alert('금액은 비워둘 수 없습니다.')
            return
        }
        if (moneyValue.current.value < 0) {
            alert('금액이 유효하지 않습니다. 금액은 양수여야 합니다.')
            return
        }
        if (!pw.current.value) {
            alert('비밀번호는 비워두면 안 됩니다')
            return
        }
        const payload = {
            memo: memo.current.value || "",
            money: moneyValue.current.value,
            admin_pw: pw.current.value,
            submit: 1,
        }

        switch (actionRadio) {
            case '지급':
                userList.chargeExchangeMoney('money_result', 'in_money', params.id, payload)
                    .then((data)=>{
                        alert(data.message)
                    })
                    .catch((err) => alert(err.message))
                break;
            case '회수':
                userList.chargeExchangeMoney('money_result_out', 'out_money', params.id, payload)
                    .then((data)=>{
                        alert(data.message)
                    })
                    .catch((err) => alert(err.message))
                break;
            default:
                break;
        }
    }

    const bodyTemplate = (data, props) => {
        const {admin_text, app_type, charge_money, regdate, result_amount} = data
        const chargeMoneyStyle = charge_money[1].replace(`style='color:`, '').replace(`'`, '')
        return (
            <>
                {props.field === 'admin_text' &&
                    <div >
                        {admin_text?.text}
                    </div>
                }
                {props.field === 'app_type' &&
                    <Tag everity={app_type?.style_bgcolor}>
                        {app_type?.label}
                    </Tag>
                }
                
                {props.field === 'charge_money' &&
                    <div style={{color: chargeMoneyStyle}}> 
                        {charge_money[2]+ charge_money[3]}
                    </div>
                }
                {props.field === 'regdate' &&
                    <>
                        {regdate}
                    </>
                }
                {props.field === 'result_amount' &&
                    <>
                        {result_amount}
                    </>
                }
            </>
        )
    }
    const [newData, setNewData] = useState([])
    function getDataTable (){
        console.log(activeBtn)
        if(activeBtn ==='money_list_01') setNewData([{ 
            charge: parseToString(convertNumber(money?.charge?.sum || '0')),
            exchange: parseToString(convertNumber(money?.exchange?.sum || '0')),
            total: money?.sum ? parseToString(convertNumber(money?.sum)) 
            :  parseToString(convertNumber(money?.charge?.sum || '0' ) - convertNumber(money?.exchange?.sum || '0'))
        }]) 
        if(activeBtn === 'money_list_1'){
            setNewData([{ 
                charge: money?.sum ?? parseToString(convertNumber(money?.sum|| '0')),
                exchange: parseToString(convertNumber(money?.exchange?.sum || '0')),
                total: money?.sum ? parseToString(convertNumber(money?.sum)) 
                :  parseToString(convertNumber(money?.charge?.sum || '0' ) - convertNumber(money?.exchange?.sum || '0'))
            }]) 
        }
        if(activeBtn === 'money_list_0'){
            setNewData([{ 
                charge:  parseToString(convertNumber(money?.charge?.sum || '0')),
                exchange: money?.sum ?? parseToString(convertNumber(money?.sum|| '0')),
                total: money?.sum ? parseToString(convertNumber(money?.sum)) 
                :  parseToString(convertNumber(money?.charge?.sum || '0' ) - convertNumber(money?.exchange?.sum || '0'))
            }]) 
        }
    }
    useEffect(()=>{getDataTable()},[money])
    return (
        <>
            {
                data && (
                    <div className="col-12">
                        <div className="grid gap-5">
                            <div className="col-12 md:col-2">
                                <div className="grid">
                                    <div className="col-3 md:col-6 flex align-items-center">
                                        <RadioButton inputId="ingredient1" name="supply" value={actionRadio} onChange={(e) => setActionRadio('지급')} checked={actionRadio === '지급'} />
                                        <label htmlFor={actionRadio} className="ml-2">지급</label>
                                    </div>
                                    <div className="col-3 md:col-6 flex align-items-center">
                                        <RadioButton inputId="ingredient2" name="withdraw" value={actionRadio} onChange={(e) => setActionRadio('회수')} checked={actionRadio === '회수'} />
                                        <label htmlFor={actionRadio} className="ml-2">회수</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-2 ">
                                <InputText type="number" ref={memo} placeholder="내용" />
                            </div>
                            <div className="col-12 md:col-2">
                                <InputText type="number" ref={moneyValue} placeholder="지급금액" />
                            </div>
                            <div className="col-12 md:col-2">
                                <InputText ref={pw} placeholder="계정 비밀번호" type="password" />
                            </div>
                            <div className="col-12 md:col-2">
                                <Button onClick={handleSubmit}>확 인</Button>
                            </div>
                            <div className="col-12">
                                {listMoneyButton.map((v, i) => {
                                    return (
                                        <Button onClick={() => handleMoneyBtn(v.name)} label={v.button} severity={activeBtn === v.name ? 'warning': 'info'} key={i} />
                                    )
                                })}
                            </div>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-2">
                                        <Calendar value={sDate} onChange={(e) => {
                                            blockEffect.current = false
                                            setSDate(e.value)
                                        }} showIcon locale="ko" dateFormat="yy-mm-dd"/>
                                    </div>
                                    <div className="col-2">
                                        <Calendar value={eDate} onChange={(e) => setEDate(e.value)} showIcon locale="ko" dateFormat="yy-mm-dd"/>
                                    </div>
                                    <div className="col-1">
                                        <Button onClick={handleFilterByDate}>확 인</Button>
                                    </div>
                                    <div className="col-4 md:col-2">
                                        {filterDateButtons.map((v, i) => {
                                            return (
                                                <Button onClick={() => fillDate(v.name)} label={v.button} severity={v.name === activeFilterDateBtn ? 'warning': 'info'} key={i} />
                                            )
                                        })}
                                    </div>
                                    <div className="col-2 md:col-4">
                                    <DataTable value={newData} showGridlines >
                                        <Column field="charge" header='충전' sortable={false} style={{ color: 'green' }}></Column>
                                        <Column field="exchange" header='환전' sortable={false} style={{ color: 'red' }}></Column>
                                        <Column field="total" header='전체' sortable={false} style={{ color: 'orange' }}></Column>
                                    </DataTable>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                                    rows={30}
                                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                                />
                                <CustomDataTable data={data} headers={headerHistory} bodyTemplate={bodyTemplate} scrollable={true} />
                                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                                    rows={30}
                                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}

                                />
                            </div>
                        </div>
                    </div>
                )
            }
            <Toast ref={toast}/>
        </>
    )
}

export default AgentPaymentNRecovery
