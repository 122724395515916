import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moneyConvert from "../../utilities/moneyConvert";
import {
  CpButton,
  CpButtonIcon,
  CpInput,
  CpPagination,
  CpDatePicker,
  CpDropdown,
  CpButtonGroup,
  CpButtonGroupActive,
} from "../../components";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../components/Table/TableUser";
import { addLocale } from "primereact/api";
import BetService from "../../service/BetService";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";

const dataDropdown = [
  { name: "선택", code: "" },
  { name: "아이디", code: "mb_id" },
  { name: "에이전트", code: "mb_agent" },
  { name: "에이전트(하부포함)", code: "mb_level" },
];
const dataButtonGroup = [
  { name: "배팅시간", code: "0" },
  { name: "이긴금액", code: "1" },
  { name: "배팅금액", code: "2" },
];

const BetCasino = () => {
  function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }
  const today = convertTZ(new Date(), "Asia/Seoul")
  const pass = convertTZ(new Date(), "Asia/Seoul")
  pass.setDate(today.getDate() - 1)
  const [dropdownValue, setDropdownValue] = useState({ name: "선택", code: "" });
  const [data, setData] = useState();
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0)
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState(pass);
  const [endDate, setEndDate] = useState(today);
  const [payloadSearch, setPayloadSearch] = useState()
  const betCasino = new BetService()
  const [keySearch, setKeySearch] = useState('')
  const [total, setTotal] = useState()
  const onPageChange = (event) => {
    setPage(event.page + 1)
    setFirst(event.first);
  };
  function fetchHistoryBetting() {
    const payload = {
      mode: 'casino',
      page: page,
      sdate: formatDate(startDate)  /**'2020-1-1'*/,
      edate: !endDate ? '' : formatDate(endDate),
      ...payloadSearch
    }
    betCasino.fetchBettingHis(payload)
      .then((data) => {
        const res = data?.data
        setData(res?.data)
        setTotalRecords(res?.total)
        setTotal(data?.total)
      })
      .catch((err) => {
        console.log(err)
      })
  } useEffect(() => {
    fetchHistoryBetting()
  }, [page, startDate, endDate, payloadSearch])
  useEffect(() => { }, [total])
  const header = [
    { field: "regDate", label: `IDX 배팅일`, sortAble: false },
    { field: "mb_id", label: "아이디 에이전트", sortAble: false },
    { field: "betting_value", label: "배팅금 / 이긴금액 / 차액", sortAble: false },
    { field: "Balance", label: "남은금액", sortAble: false },
    { field: "Agent", label: "에이전트/지급/퍼센트/보유", sortAble: false },
    { field: 'gameTitle', label: "정보", sortAble: false },
    { field: 'result', label: "결과", sortAble: false },

  ];
  const bodyTemplate = (data, props) => {
    const betMoney = data?.betTotal;
    const winMoney = data?.winTotal;
    const total = data?.betTotal - data?.winTotal
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
        {props.field === 'betting_value' &&
          <div>
            <span>
              <span style={{ color: 'green' }}>{moneyConvert(data?.betTotal)}</span> / <span style={{ color: 'red' }}>{moneyConvert(data?.winTotal)}</span> / <span style={betMoney > winMoney ? { color: 'green' } : betMoney < winMoney ? { color: 'red' } : { color: 'black' }}> {moneyConvert(total)}</span>
            </span>
          </div>
        }
        {props.field === 'result' &&
          <span>{total > 0 ? <span style={{ color: 'green' }}>이기다</span> : total < 0 ? <span style={{ color: 'red' }}>잃다</span> : <span>무승부</span>}</span>
        }
      </>

    )
  }
  function formatDate(date) {
    var yyyy = date?.getFullYear();
    var mm = String(date?.getMonth() + 1).padStart(2, '0');
    var dd = String(date?.getDate()).padStart(2, '0');
    return yyyy + '-' + mm + '-' + dd
  }
  addLocale('ko', {
    firstDayOfWeek: 1, // Ngày đầu tuần là Chủ Nhật
    dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
    monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    today: '오늘',
    clear: '지우기'
  });
  function handleSearch() {
    if (dropdownValue.code === 'mb_id' || dropdownValue.code === 'mb_agent') {
      setPayloadSearch(
        {
          "id": keySearch
        })
    }
    else {
      setPayloadSearch()
    }
  }
  return (
    <div className="prid bet-casino">
      <div className="col-12 p-3 card mb-4 header md:p-4">
        <div className="col-12 mb-3 p-0" style={{ fontSize: "16px" }}>
          카지노 배팅정보
        </div>
        <div className="col-12 de-wi-box p-0 sm:flex sm:flex-wrap">
          <div className="p-0 sm:col-6 md:col-2 xl:col-2 flex align-items-end">
            <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd" />
          </div>
          <div className="p-0 sm:col-6 mt-2 md:col-2 xl:col-2 sm:mt-2 flex align-items-end">
            <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} locale="ko" showIcon dateFormat="yy-mm-dd" />
          </div>
          <div className="p-0 sm:col-6 mt-3 md:col-1 xl:col-1 flex align-items-end md:mt-0">
            <CpDropdown
              style={{ minWidth: "100px" }}
              value={dropdownValue}
              options={dataDropdown}
              onChange={(e) => setDropdownValue(e)}
              placeholder=""
            />
          </div>
          <div className="p-0 mt-1 sm:col-6 md:col-2 xl:col-2 flex align-items-end">
            <InputText placeholder="" value={keySearch} onChange={(e) => setKeySearch(e.target.value)}
              onKeyDown={(e) => { if (e.key === 'Enter') handleSearch() }}
            />
            {/* <CpButtonIcon icon="pi pi-search" severity="" aria-label="" /> */}
          </div>
          <div className="p-0 mt-3 sm:col-6 md:col-1 xl:col-1 flex align-items-end">
            {/* <CpInput placeholder="검색" /> */}
            <CpButton label="검색" onClick={handleSearch} />
          </div>
        </div>
        <div className="total col-12 p-0 mt-4 flex flex-wrap">
          <div className="infor-total col-12 sm:col-8  md:col-6 xl:col-4 p-0">
            <div className="p-0 infor-header col-12 flex">
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item red">
                  <i className="pi pi-info-circle mr-1"></i>BET
                </div>
              </div>
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">
                  <div className="icon">
                    <i className="pi pi-info mr-1"></i>
                  </div>
                  WIN
                </div>
              </div>
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">
                  <div className="icon">
                    <i className="pi pi-info mr-1"></i>
                  </div>
                  BET - WIN
                </div>
              </div>
            </div>
            <div className="p-0 infor-content col-12 flex">
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">{total?.bet}</div>
              </div>
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">{total?.win}</div>
              </div>
              <div className="p-0 items col-4 flex justify-content-center">
                <div className="item">{total?.sub}</div>
              </div>
            </div>
          </div>
          {/* <div className="col-12 pt-3 pl-0 md:col-6 xl:col-4 md:pt-0 md:pl-3">
            <CpButtonGroupActive
              dataGroup={[
                { label: "배팅시간", value: "0" },
                { label: "이긴금액", value: "1" },
                { label: "배팅금액", value: "2" },
              ]}
              value="0"
            />
          </div> */}
        </div>
      </div>
      <div className="card">
        <div className="pagi-box">
          <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
            rows={100}
            template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }}
          />
          <CustomDataTable rows={100} scrollable={true} data={data} headers={header} bodyTemplate={bodyTemplate} onShowButton={false} />
          <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
            rows={100}
            template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink' }} />
        </div>
      </div>
    </div>
  );
};

export default BetCasino;
