import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AppBreadcrumb = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    let pathname = props.routers.filter((router) => {
        return router.path === location.pathname;
    });

    return (
        <div className="layout-breadcrumb">
            <ul>
                <li>
                    <button type="button" className="p-link" onClick={() => navigate('/')}>
                        <i className="pi pi-home"></i>
                    </button>
                </li>
                <li>
                    <i className="pi pi-chevron-right chevron-icon"></i>
                </li>
                <li>
                    <button className="p-link">{pathname[0]?.label}</button>
                </li>
            </ul>
        </div>
    );
};

export default AppBreadcrumb;
