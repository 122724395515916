import { mainAxios } from "../libs/axios";
import {buildMyQueryString} from '../utilities/query'

const accessToken = localStorage.getItem('User')
const jsonToken = JSON.parse(accessToken)
const token = `Bearer ${jsonToken}`
export default class SettingService {
    lobbyMainApi(payload){
        return mainAxios.request({
            methodType: "GET",
            url: `/game?mode=lobbies_as`,
            params: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    lobbyMainActionApi(payload){
        return mainAxios.request({
            methodType: "POST",
            url: `/game?mode=lobbies_as&process=edit`,
            payload: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    casinoMainApi(payload) {
        return mainAxios.request({
            methodType: "GET",
            url: `/game?mode=games_as`,
            params: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    casinoMainEditApi(payload) {
        const myQuery = {
            mode: 'games_as',
            type: 'casino',
            main: 'vendor',
            no: payload.no || ''
        }
        delete payload.no
        return mainAxios.request({
            methodType: "POST",
            url: `/game${buildMyQueryString({...myQuery})}`,
            payload: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    slotListApi(payload) {
        return mainAxios.request({
            methodType: "GET",
            url: `/game?mode=games_as`,
            params: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    casinoListApi(payload) {
        return mainAxios.request({
            methodType: "GET",
            url: `/game?mode=games_as`,
            params: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                    Authorization: token
                },
            },
        });
    }
    slotSetAPI(payload) {
        return mainAxios.request({
          methodType: "POST",
          url: `/game?mode=games_as&type=slots&main=vendor`,
          payload: payload,
          requiresToken: true,
          notError: true,
          getError: true,
          config: {
            headers: {
              contentType: "application/json",
              Authorization: token,
            },
          },
        });
    }
    slotSetGameAPI(payload){
        return mainAxios.request({
            methodType: "POST",
            url: `/game?mode=games_as&process=edit`,
            payload: payload,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
              headers: {
                contentType: "application/json",
                Authorization: token,
              },
            },
          });
    }
    fetchSettings() {
        return mainAxios.request({
            methodType: "GET",
            url: `/config?mode=site_conf&type=setting`,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                },
            },
        });
    };
    postSettings(payload) {
        return mainAxios.request({
            methodType: "POST",
            payload: payload,
            url: `/config?mode=site_conf&type=setting`,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                },
            },
        });
    };
    getNoti(payload){
        return mainAxios.request({
            methodType: "GET",
            payload: payload,
            url: `/dashboard?mode=reloadSession`,
            requiresToken: true,
            notError: true,
            getError: true,
            config: {
                headers: {
                    contentType: "application/json",
                },
            },
        });
    }
}
