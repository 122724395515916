import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import './style.css';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

const CustomDataTable = ({ data, headers, bodyTemplate, modalMessage, action,
  openConfirm, onShowButton, icon, showGridlines, groupRowsBy, filterDisplay, scrollable,
  selectionMode, rows, sortMode, headerColumnGroup, totalData, rowGroupHeaderTemplate, groupMode, filters, onFilter, actionFilter }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const toast = useRef(null);
  const confirm1 = (message) => {
    confirmDialog({
      message: message.message,
      header: message.header,
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject,
      acceptLabel: '예',
      rejectLabel: '아니요',
      rejectClassName: 'p-button-danger'
    });
  };

  const accept = () => {
    // toast.current.show({ severity: 'success', summary: '성공', detail: '성공적으로 업데이트했습니다', life: 3000 });
    action()
  }
  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  }
  useEffect(() => {
    if (openConfirm) {
      confirm1(modalMessage)
    }
  }, [openConfirm, modalMessage])
  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      {onShowButton &&
        <div className="refresh">

          <span>접속 유저 리스트</span>
          <Button
            label="벨런스 업데이트"
            onClick={confirm1}
            className="mr-2 mb-2 button"
            severity="success"
          />
        </div>
      }
      <div className="inventory">
        <DataTable
          value={data}
          style={{ marginBottom: "20px" }}
          removableSort
          className="p-datatable-orders"
          rows={rows||30}
          selection={selectedProduct}
          onSelectionChange={(e) => setSelectedProduct(e.value)}
          sortIcon={icon}
          showGridlines={showGridlines}
          groupRowsBy={groupRowsBy}
          rowGroupMode={groupMode?groupMode:"rowspan"}
          filterDisplay={filterDisplay}
          scrollable={scrollable}
          scrollHeight="800px"
          sortMode={sortMode}
          headerColumnGroup={headerColumnGroup}
          rowGroupHeaderTemplate={rowGroupHeaderTemplate}
          filters={filters}
          onFilter={onFilter}
        > 
        
          {headers.map((header, index) => (
            <Column
              key={index}
              field={header.field}
              // header={header.label}
              header={typeof header.label === 'string' ? header.label : (
                <span className="p-column-title">
                  <div>
                    {header.label}
                  </div>
                </span>
              )}
              sortable={header.sortAble}
              body={bodyTemplate}
              filter={header.filter}
              filterField={header.filterField}
              filterPlaceholder={header.filterPlaceholder}
              style={header.filterPlaceholder ? { minWidth: "12rem" } : {}}
            />

          ))}
        </DataTable>
      </div>
    </>
  )
}
export default CustomDataTable;