import React from "react";
import { Button } from "primereact/button";

const CpButton = (props) => {
  const { label, onClick, className, rounded = false, raised = false } = props;
  return (
    <div className="cp-button">
      <Button
        label={label ?? "Search"}
        onClick={onClick}
        className={className}
        rounded={rounded}
        raised={raised}
      />
    </div>
  );
};

export default CpButton;
