import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import './AgentMove.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import getUserList from "../../../service/UserService";
import { InputText } from "primereact/inputtext";
import AgentService from "../../../service/AgentServices";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { SelectButton } from "primereact/selectbutton";

const AgentMove = () => {
    const [selectOption, setSelectOption] = useState('선택');
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [inputValue, setInputValue] = useState();
    const [first, setFirst] = useState(0);
    const [payload, setPayload] = useState({
        page: page
    })
    const toast = useRef(null)
    const header = [
        { field: "mb_id", label: "에이전트 아이디", sortAble: false },
        { field: "mb_hp", label: `연락처`, sortAble: false },
        { field: "mb_out_money", label: `환전금액`, sortAble: false },
        { field: "mb_bank_name", label: "은행명", sortAble: false },
        { field: "mb_bank_own", label: "입금자명", sortAble: false },
        { field: "mb_bank_no", label: `계좌번호`, sortAble: false },
        { field: "mb_regtime", label: `등록일`, sortAble: false },
        { field: "modify_date", label: `완료일`, sortAble: false },
        { field: "trees", label: `트리`, sortAble: false },
        { field: "status", label: `상태`, sortAble: false },
        { field: "btn_action", label: `사용`, sortAble: false },
    ];
    const agentList = new AgentService();
    function fetchAgentRemoveList (){
        
        agentList.agentRemoveListAPI(payload)
            .then((data) => {
                const res = data?.list
                setData(res?.data)
                setTotalRecords(res?.total)
            })
            .catch((err)=>{
                console.log(err)
            })
    }
        
    useEffect(() => {
        fetchAgentRemoveList()
        
    }, [page, payload])
    const menuDropdown = [
        '선택',
        '에이전트'
    ]
    const chooseOption = (e) => {
        setSelectOption(e)
    }
    const buttonGroup = [
        { button: '모두 보기', value: '' },
        { button: '삭제', value: 'delete' },
        { button: '중지', value: 'stop' },
    ]
    const [filterAgentList, setFilterAgentList] = useState(buttonGroup[0])

    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    function onHandleButtonAction(e){
        console.log(e)
        const urlAction = new URL(e?.link)
        const queryString  = urlAction.search
        const message = e.confirm
        const header = e.label
            confirmDialog({
                message: message,
                header: header,
                accept: ()=>{confirm(`member${queryString}`)},
                acceptLabel: header,
                acceptClassName: "p-confirm-dialog-accept",
            });
    }
    function confirm(e){
        agentList.agentRegisterActionAPI(e).then((data) => {
            toast.current.show({ severity: 'success', summary: '성공', detail: data?.message, life: 3000 });
            fetchAgentRemoveList()
        }).catch((err) => { console.log(err) })
    }
    const bodyTemplate = (data, props) => {
        const resultArray = Object.values(data.tree)
        .filter(value => value !== "")
        .map(value => value);
        const resultString = resultArray.join(' | ');
        const action = data.action_button.active

        return (
            <>
                {props.field === 'btn_action' &&
                    <>
                        <Button style={{boxShadow: '0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16)',justifyContent: 'center'}} onClick={() => {
                                onHandleButtonAction(action)
                            }}>사</Button>
                    </>
                }
                {props.field ==='trees' &&
                    <span>{resultString}</span>
                }
                {props.field === 'status'&&
                    <span style={{color:'red', fontSize:'1.2rem'}}>{data.mb_status === '0'? '중지': '삭제'}</span>           
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    const justifyTemplate = (option) => {
        return <span>{option.button}</span>;
    }
    function handleChangeStatus(e){
        setFilterAgentList(e)
        var newParams = {...payload}
        newParams ={...payload,status_type: e.value}
        setPayload(newParams)
    }
    function onHandleSearch(){
        if(selectOption ==='에이전트'){
            setPayload({...payload, "keyfield": "mb_id", key: inputValue})
        }
        else{
            setPayload({page: page})
        }
    }
    return (
        <>  
            <Toast ref={toast}/>
            <div className='refresh card'>
                <span className="text">에이전트 삭제</span>
                <div className="tool-remove-agent">
                    <div className="button-group-filter">
                        <SelectButton value={filterAgentList.value} onChange={(e) => handleChangeStatus(e)} itemTemplate={justifyTemplate} options={buttonGroup} />
                    </div>
                    <div className="item-remove">
                        <Dropdown
                            options={menuDropdown}
                            onChange={(e) => chooseOption(e.value)}
                            value={selectOption}
                        />
                        
                        <div className="item-row">
                            <InputText placeholder="검색" onChange={(e)=> setInputValue(e.target.value)}
                            onKeyDown={(e)=> {if(e.key ==='Enter') onHandleSearch()}}
                            />
                        </div>
                        <Button onClick={onHandleSearch}>검색</Button>

                    </div>
                </div>
                
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
                <CustomDataTable
                    data={data}
                    headers={header}
                    bodyTemplate={bodyTemplate}
                    scrollable={true}
                />
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink' }}

                />
            </div>
        </>
    )
}

export default AgentMove;