import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";

import moneyConvert, { moneyConvertToNumber } from "../utilities/moneyConvert";
import { dashboardAPI } from "../service";

const Dashboard = () => {
  const [casino, setCasino] = useState({
    bet: "0",
    win: "0",
  });
  const [slot, setSlot] = useState({
    bet: "0",
    win: "0",
  });
  const [distributor, setDistributor] = useState({
    ex: "0",
    rec: "0",
    sub: "0",
  });
  const [member, setMember] = useState({
    ex: "0",
    rec: "0",
    sub: "0",
  });
  const [infor, setInfor] = useState([
    { label: "알보유", value: 0 },
    { label: "슬롯알", value: 0 },
    { label: "풀루토 알", value: 0 },
    { label: "회원수", value: 0 },
    { label: "금일가입", value: 0 },
    { label: "금일접속", value: 0 },
    { label: "현재접속", value: 0 },
    { label: "카지노 롤링합", value: 0 },
    { label: "슬롯 롤링합", value: 0 },
  ]);

  const documentStyle = getComputedStyle(document.documentElement);
  const dataMember = {
    labels: ["충전", "환전"],
    datasets: [
      {
        data: [
          member.ex.split(",").join("").split(".").join(""),
          member.rec.split(",").join("").split(".").join(""),
        ],
        backgroundColor: [
          documentStyle.getPropertyValue("--pink-500"),
          documentStyle.getPropertyValue("--blue-500"),
        ],
        hoverBackgroundColor: [
          documentStyle.getPropertyValue("--pink-400"),
          documentStyle.getPropertyValue("--blue-400"),
        ],
      },
    ],
  };
  const dataDistribotor = {
    labels: ["충전", "환전"],
    datasets: [
      {
        data: [
          distributor.ex.split(",").join("").split(".").join(""),
          distributor.rec.split(",").join("").split(".").join(""),
        ],
        backgroundColor: [
          documentStyle.getPropertyValue("--pink-500"),
          documentStyle.getPropertyValue("--blue-500"),
        ],
        hoverBackgroundColor: [
          documentStyle.getPropertyValue("--pink-400"),
          documentStyle.getPropertyValue("--blue-400"),
        ],
      },
    ],
  };
  const dataCasino = {
    labels: ["배팅금액", "이긴금액"],
    datasets: [
      {
        data: [
          moneyConvertToNumber(casino.bet) ,
          moneyConvertToNumber(casino.win),
        ],
        backgroundColor: [
          documentStyle.getPropertyValue("--blue-500"),
          documentStyle.getPropertyValue("--yellow-500"),
        ],
        hoverBackgroundColor: [
          documentStyle.getPropertyValue("--blue-400"),
          documentStyle.getPropertyValue("--yellow-400"),
        ],
      },
    ],
  };
  const dataSlot = {
    labels: ["배팅금액", "이긴금액"],
    datasets: [
      {
        data: [
          moneyConvertToNumber(slot.bet) ,
          moneyConvertToNumber(slot.win) ,
        ],
        backgroundColor: [
          documentStyle.getPropertyValue("--blue-500"),
          documentStyle.getPropertyValue("--yellow-500"),
        ],
        hoverBackgroundColor: [
          documentStyle.getPropertyValue("--blue-400"),
          documentStyle.getPropertyValue("--yellow-400"),
        ],
      },
    ],
  };
  const formatCurrency = (value) => {
    const formattedNumber = value.toLocaleString('en-US', { style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 });
    return formattedNumber.replace('$', '');

};
  // console.log(dataSlot);
  function getSumTotal(a, b) {
    if (a && b) {
        const value = parseInt(a?.replace(/,/g, "")) - parseInt(b?.replace(/,/g, ""))
        return formatCurrency(value)
    }

}
  const getDataDashboard = async () => {
    try {
      const res = await dashboardAPI({today:1});
      // console.log(data);

      if (res.code === 200) {
        console.log(res.data["dashboard-nav"]);
        setInfor([
          res.data["dashboard-nav"].reserve_money,
          res.data["dashboard-nav"].reserve_point,
          // res.data["dashboard-nav"]?.point_pluto,
          res.data["dashboard-nav"].members,
          res.data["dashboard-nav"].sign_up_today,
          res.data["dashboard-nav"].access_today,
          res.data["dashboard-nav"].access_current,
          res.data["dashboard-nav"].rolling_casino,
          res.data["dashboard-nav"].rolling_slot,
        ]);
        setCasino(res.data.casino_money);
        setSlot(res.data.slot_money);
        setMember(res.data.rec_ex_money_members);
        setDistributor(res.data.rec_ex_money_distributor);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDataDashboard();
  }, []);

  const navigate = useNavigate();

  const [chartData, setChartData] = useState({});

  return (
    <div className="prid dashboard-box">
      <div className="grid dashboard-grid p-0">
        {infor.map((v, i) => (
          <div
            key={i}
            className="col-12 p-1 sm:col-6 md:col-3 lg:col-2 xl:col-1 sm:p-2 "
          >
            <div className="col-12 p-3 card br-2">
              <div>{v.label}</div>
              <div className="mb-3 mt-1">{v.value}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid dashboard-grid p-0 mt-1">
        <div className="pl-1 pr-1 sm:p-2 col-12 md:col-6 lg:col-4">
          <div className="card">
            <div className="card-title ">충전/환전</div>
            <div className="grid flex">
              <div className="chart-box col-7">
                <Chart
                  type="doughnut"
                  data={dataMember}
                  options={{ cutout: "55%" }}
                  className="w-auto flex justify-content-center"
                />
              </div>
              <div className="chart-box col-5">
                <div className="grid flex">
                  <div className="p2 col-6">충전</div>
                  <div className="p2 col-6">{member.rec}</div>
                </div>
                <div className="grid flex">
                  <div className="p2 col-6">환전</div>
                  <div className="p2 col-6">{member.ex}</div>
                </div>
                <div className="grid flex">
                  <div className="p2 col-6">차액</div>
                  <div className="p2 col-6">{member.sub}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-1 pr-1 sm:p-2 col-12 md:col-6 lg:col-4">
          <div className="card">
            <div className="card-title ">총판 충전/환전</div>
            <div className="grid flex">
              <div className="chart-box col-7">
                <Chart
                  type="doughnut"
                  data={dataDistribotor}
                  options={{ cutout: "55%" }}
                  className="w-auto flex justyfi-content-center"
                />
              </div>
              <div className="chart-box col-5">
                <div className="grid flex">
                  <div className="p2 col-6">충전</div>
                  <div className="p2 col-6">{distributor.rec}</div>
                </div>
                <div className="grid flex">
                  <div className="p2 col-6">환전</div>
                  <div className="p2 col-6">{distributor.ex}</div>
                </div>
                <div className="grid flex">
                  <div className="p2 col-6">차액</div>
                  <div className="p2 col-6">{distributor.sub}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-1 pr-1 sm:p-2 col-12 md:col-6 lg:col-4">
          <div className="card">
            <div className="card-title ">카지노 Win/Lose</div>
            <div className="grid flex">
              <div className="chart-box col-7">
                <Chart
                  type="doughnut"
                  data={dataCasino}
                  options={{ cutout: "55%" }}
                  className="w-auto flex justyfi-content-center"
                />
              </div>
              <div className="chart-box col-5">
                <div className="grid flex">
                  <div className="p2 col-6">배팅금액</div>
                  {/* <div className="p2 col-6">{moneyConvert(casino.lose, true)}</div> */}
                  <div className="p2 col-6">{casino.bet}</div>
                </div>
                <div className="grid flex">
                  <div className="p2 col-6">이긴금액</div>
                  <div className="p2 col-6">{casino.win}</div>
                </div>
                <div className="grid flex">
                  <div className="p2 col-6">합</div>
                  <div className="p2 col-6">{getSumTotal(casino.bet, casino.win)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-1 pr-1 sm:p-2 col-12 md:col-6 lg:col-4">
          <div className="card">
            <div className="card-title ">슬롯 Win/Lose</div>
            <div className="grid flex">
              <div className="chart-box col-7">
                <Chart
                  type="doughnut"
                  data={dataSlot}
                  options={{ cutout: "55%" }}
                  className="w-auto flex justyfi-content-center"
                />
              </div>
              <div className="chart-box col-5">
                <div className="grid flex">
                  <div className="p2 col-6">배팅금액</div>
                  <div className="p2 col-6">{slot.bet}</div>
                </div>
                <div className="grid flex">
                  <div className="p2 col-6">이긴금액</div>
                  <div className="p2 col-6">{slot.win}</div>
                </div>
                <div className="grid flex">
                  <div className="p2 col-6">합</div>
                  <div className="p2 col-6">{getSumTotal(slot.bet, slot.win)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
