import { Dropdown } from "primereact/dropdown";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import './CasinoSet.scss'
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../../components/Table/TableUser";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { RadioButton } from "primereact/radiobutton";
import SettingService from "../../../service/SettingService";
import { Toast } from "primereact/toast";

const radioGroup = [
    { label: '사용', value: 0 },
    { label: '미사용', value: 1 }
]

const CasinoSet = () => {
    const [selectOption, setSelectOption] = useState('모두');
    const [page, setPage] = useState(0);
    const [data, setData] = useState();
    const [totalRecords, setTotalRecords] = useState(0)
    const [messageModal, setMessageModel] = useState({});
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [first, setFirst] = useState(0);
    const [menuCasinoDropdown, setMenuCasinoDropDown] = useState([])
    const [inpValue, setInpValue] = useState('')
    const header = [
        { field: "vendor", label: "게임제공업체", sortAble: false },
        { field: "game_id", label: "게임아이디", sortAble: false },
        { field: "english", label: `영어`, sortAble: false },
        { field: "korean", label: `한국어`, sortAble: false },
        { field: "use", label: "사용", sortAble: false },
        { field: 'vendors', label: '벤더명', sortAble: false },
        { field: "queue_number", label: "정렬 순서", sortAble: false },
        { field: "edit", label: `수정`, sortAble: false },
    ];
    const arrayGameId = []

    const settingList = new SettingService();
    function getCasinoMain() {
        const payload = {
            type: 'casino',
            main: 'vendor'
        }
        settingList.casinoMainApi(payload)
            .then((data) => {
                const res = data.list
                var vendor = []
                res.data.map((v) => {
                    if (v?.ishidden === 0) {
                        return vendor.push(v.vendor)
                    }
                })
                setMenuCasinoDropDown(vendor)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    function settingSlot(v) {
        var payload= {
            type: 'casino',
            pnum: 30,
            page: page,
            ...v
        }
        settingList.slotListApi(payload)
            .then((data) => {
                const res = data.data
                setData(res.data)
                setTotalRecords(res.total)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    function editSlot(payload) {
        settingList.slotSetGameAPI(payload)
            .then((data) => {
                if (data.code === 200) {
                    toast.current.show({
                        severity: "success",
                        summary: "성공",
                        detail: data.message,
                        life: 3000,
                    });
                }
                else {
                    toast.current.show({
                        severity: "error",
                        summary: "성공",
                        detail: data.message,
                        life: 3000,
                    });
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(()=>{
        getCasinoMain()
    },[])
    useEffect(() => {
        if (selectOption === '회사') {
            settingSlot({
                vendor: inpValue
            })
        }
        if (selectOption === '게임이름') {
            settingSlot({
                name: inpValue
            })
        }
        if (selectOption === '모두') {
            settingSlot()
        }
    }, [page])
    useEffect(() => {
        // console.log(menuCasinoDropdown)
    }, [ menuCasinoDropdown])
    const RenderRadioGroup = ({ radioGroup, data, value }) => {
        const [selectUse, setSelectUse] = useState(value)
        const handleChange = (v, id) => {
            setSelectUse(v);
            const index = arrayGameId.findIndex(item => item.game_id === id);
            if (index !== -1) {
                arrayGameId[index].ishidden = v;
            }
            else {
                arrayGameId.push({ game_id: id, ishidden: v })
            }
        };

        return (
            <>
                {radioGroup.map((v, i) => {
                    return (
                        <>
                            <RadioButton
                                inputId={`ingredient${data?.game_id}`}
                                value={v.value}
                                onChange={(e) => { handleChange(e.value, data.game_id) }}
                                checked={selectUse === v.value}
                                key={data?.game_id} />
                            <label className="ml-2" htmlFor={`ingredient${data?.game_id}`}>{v.label}</label>
                        </>
                    )
                })
                }
            </>
        )
    }
    const RenderDropDown = ({ dropDown, data, value }) => {
        const [selectUse, setSelectUse] = useState(value)
        const handleChange = (v, id) => {
            setSelectUse(v);
            const index = arrayGameId.findIndex(item => item.game_id === id);
            if (index !== -1) {
                arrayGameId[index].mask_vendor = v;
            }
            else {
                arrayGameId.push({ game_id: id, mask_vendor: v })
            }
        };

        return (
            <div className="slot-set-div">
                <Dropdown
                    options={dropDown}
                    onChange={(e) => handleChange(e.value, data.game_id)}
                    value={selectUse}
                    style={{minWidth:'155px'}}
                />
            </div>
        )
    }
    const confirm1 = (id, data) => {
        confirmDialog({
            message: '정보를 변경하시겠습니까?',
            header: '설정',
            accept: () => { action(id, data) },
            acceptLabel: '확인',
            acceptClassName: 'p-confirm-dialog-accept'
        });
    };
    const action = (id, data) => {
        const foundObject = arrayGameId.find(item => item.game_id === id);
        var maskLang = JSON.parse(data.mask_langs)

        if (!foundObject?.mask_langs) {
            foundObject.mask_langs = maskLang
            editSlot(foundObject)
        }
        else {
            editSlot(foundObject)
        }

    }
    const onPageChange = (event) => {
        setPage(event.page + 1)
        setFirst(event.first);
    };
    const handleChangePosition = (data, id) => {
        const index = arrayGameId.findIndex(item => item.game_id === id);
        if (index !== -1) {
            arrayGameId[index].position = data;
        }
        else {
            arrayGameId.push({ game_id: id, position: data })
        }
    }
    const handleChangeKoreaLang = (data, id) => {
        const index = arrayGameId.findIndex(item => item.game_id === id);
        if (index !== -1) {
            arrayGameId[index].mask_langs = { 'ko': `${data}` };
        }
        else {
            arrayGameId.push({ game_id: id, mask_langs: { 'ko': `${data}` } })
        }
    }
    const bodyTemplate = (data, props, rowData) => {
        const lang = JSON.parse(data.mask_langs)
        return (
            <>
                {props.field === 'edit' &&
                    <>
                        <span className="p-column-title">{props.header}</span>
                        <div className="btn">
                            <Button onClick={() => { console.log(data); console.log(arrayGameId); confirm1(data.game_id, data) }}>확인</Button>
                        </div>
                    </>
                }
                {props.field === 'queue_number' &&
                    <>
                        <span className="p-column-title">{props.header}</span>

                        <div className={`table-input`}>
                            <span className={`p-float-label`}>
                                <InputText
                                    id=''
                                    type="number"
                                    defaultValue={data.position !== null ? data.position : ''}
                                    onChange={(e) => handleChangePosition(e.target.value, data.game_id)}
                                />
                                <label ></label>
                            </span>
                        </div>
                    </>
                }
                {props.field === 'korean' &&
                    <>
                        <span className="p-column-title">{props.header}</span>

                        <div className={`table-input`}>
                            <span className={`p-float-label`}>
                                <InputText
                                    id=''
                                    type="text"
                                    defaultValue={lang.ko}
                                    onChange={(e) => handleChangeKoreaLang(e.target.value, data.game_id)}
                                />
                                <label ></label>
                            </span>
                        </div>
                    </>
                }
                {props.field === 'use' &&
                    <RenderRadioGroup radioGroup={radioGroup} data={data} value={data.ishidden} />
                }
                {props.field === 'english' &&
                    <span>{data.title} </span>
                }
                {props.field === 'vendors' &&
                    <RenderDropDown dropDown={menuCasinoDropdown} data={data} value={data.mask_vendor} />
                }
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        )
    }
    const menuDropdown = [
        '모두',
        '회사',
        '게임이름',
    ]
    const chooseOption = (e) => {
        setSelectOption(e)
    }
    const toast = useRef(null)
    const onFilterAction = () => {
        if (selectOption === '회사') {
            settingSlot({
                vendor: inpValue
            })
        }
        if (selectOption === '게임이름') {
            settingSlot({
                name: inpValue
            })
        }
        if (selectOption === '모두') {
            setInpValue('')
            settingSlot()
        }
    }
    // function handlerChangeFilterInp(e) {
    //     setInpValue(e)
    // }
    const handlerChangeFilterInp = useCallback((inputValue) => {
        setInpValue(inputValue)
    }, [settingSlot]);
    return (
        <>
            <Toast ref={toast} />
            <div className='refresh card'>
                <span className="text">카지노 게임 설정</span>
                <div className="tool-slot-set">
                    <div className="slot-set-div">
                        <Dropdown
                            options={menuDropdown}
                            onChange={(e) => chooseOption(e.value)}
                            value={selectOption}
                        />
                    </div>
                    <div className='div-remove-rolling'>
                        <span className={`p-float-label`}>
                            <InputText
                                type="text"
                                value={inpValue}
                                onChange={(e) => handlerChangeFilterInp(e.target.value)}
                                onKeyDown={(e) => { if (e.key === 'Enter') onFilterAction() }}

                            />
                            <label>검색</label>
                        </span>
                        <Button onClick={onFilterAction}>검색</Button>
                    </div>
                    <Button onClick={()=>settingSlot({
                        type: 'casino',
                        pnum: 30,
                        page: page
                    })}>카지노 업데이트</Button>

                </div>
            </div>
            <div className="inventory card">
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport  NextPageLink LastPageLink' }}

                />
                <CustomDataTable
                    data={data}
                    headers={header}
                    bodyTemplate={bodyTemplate}
                    modalMessage={messageModal}
                    openConfirm={openModalConfirm}
                    scrollable={true}
                    selectionMode="multiple"
                />
                <Paginator first={first} totalRecords={totalRecords} onPageChange={onPageChange}
                    rows={30}
                    template={{ layout: 'FirstPageLink PrevPageLink PageLinks CurrentPageReport  NextPageLink LastPageLink' }}

                />
            </div>
        </>
    )
}

export default CasinoSet;